<div class="p-4 h-100">
  <div class="admin-layout h-100 d-flex flex-column">
    <nav class="navbar admin-nav px-3 py-0 justify-content-start">
      <span class="navbar-brand mr-4">
        <em class="fa fa-key mr-1"></em>
        <ng-container i18n>Administration</ng-container>
      </span>
      <ul class="navbar-nav flex-row">
        <li class="nav-item">
          <a class="nav-link" routerLink="./groups" routerLinkActive="active" i18n>Groupes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="./users" routerLinkActive="active" i18n>Utilisateurs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="./roles" routerLinkActive="active" i18n>Rôles</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="./activity" routerLinkActive="active" i18n>Suivi Activités</a>
        </li>
      </ul>
    </nav>
    <div class="h-100 w-100 admin-page">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
