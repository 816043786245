import { SearchOption } from './search-option.model';

export class FacetedSearch {

  /**
   * Mots-clés
   */
  keywords: SearchOption[] = [];

  /**
   * Types de métadonnées
   */
  typologies: SearchOption[] = [];
  
  /**
   * Types de représentations spatiales
   */
  spatialRepresentationsTypes: SearchOption[] = [];

  /**
   * Types de liens
   */
  linkTypes: SearchOption[] = [];

  /**
   * Thématiques
   */
  thematics: SearchOption[] = [];

  /**
   * Pôles de données
   */
  dataPoles: SearchOption[] = [];

  /**
   * Organisations
   */
  organizations: SearchOption[] = [];

  /**
   * Points de contact pour la ressource
   */
  pointsOfContactOrgForResource: SearchOption[] = [];

  /**
   * Années de création
   */
  creationYears: SearchOption[] = [];

  /**
   * Années de publication
   */
  publicationYears: SearchOption[] = [];

  /**
   * Modèle à envoyer au back
   */
  private _jsonModel: any;

  /**
   * Sérialise l'objet pour la requête serveur
   */
  public serialize(): any {

    this._jsonModel = {
      keywords: this.keywords,
      typologies: this.typologies.concat(...this.linkTypes),
      thematics: this.thematics,
      dataPoles: this.dataPoles,
      organizations: this.organizations,
      pointsOfContactOrgForResource: this.pointsOfContactOrgForResource,
      creationYears: this.creationYears,
      publicationYears: this.publicationYears
    };

    return this._jsonModel;
  }
}
