import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, throwError } from "rxjs";
import { ToastrService } from 'ngx-toastr';

import { Link, Destination, Project, ClassifiedKeyword, User, Role } from '../../../models';
import { LinkService, LoaderService, MapService, MetadataService, ProjectService, SessionService, UtilsService } from '../../../services';
import { Constants } from '../../../constants';
import { FormListEvent, RegistryService } from '../../../services/registry.service';
import { Angulartics2 } from 'angulartics2';
import { SelectItem } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusService } from '../../../services/status.service';
import {ConfirmModalComponent, DataValidatorModalComponent} from '../../modals';
import { Status } from '../../../models/status.model';
import { Registry } from '../../../models/registry.model';

@Component({
  templateUrl: './link-edit.component.html'
})
export class LinkEditComponent implements OnInit, OnDestroy {

  @ViewChild('nameField') nameField;

  @ViewChild('projectField') projectField;

  @ViewChild('subTypeField') subTypeField;

  @ViewChild('urlField') urlField;

  /**
   * Indique si l'utilisateur est le propriétaire de la ressource (i.e "lien" dans Cupidon)
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur peut modifier la ressource (i.e "lien" dans Cupidon)
   */
  public isEditor: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur
   */
  public isModerator: boolean = false;

  /**
   * Lien en cours d'édition
   */
  public link: Link;

  /**
   * Identifiant du lien en cours d'édition
   */
  public linkId: string;

  /**
   * Est-ce un nouveau lien ?
   */
  public isNew: boolean = true;

  /**
   * Est-on dans le contexte d'un dépôt (i.e "étude" dans Cupidon) ?
   */
  public isInProject: boolean = true;

  /**
   * Liste des geonames de l'autocomplétion
   */
  public geonames: Destination[] = [];

  /**
   * Thématiques filtrées
   */
  public filteredThematics: string[] = [];

  /**
   * Liste des mots-clés au format string
   */
  public keywords: Registry[] = [];

  /**
   * Types de liens
   */
  public subTypes: {[id: number]: string};

  /**
   * Liste de mes dépôts (i.e "études" dans Cupidon)
   */
  public projects: Project[] = [];

  /**
   * Booléen qualifiant si le projet auquel est associé le JDD est publié ou non
   */
  public projectIsPublished:boolean = false;

  /**
   * Route de retour
   */
  public cancelRoute: string[] = ['../'];

  /**
   * Lien avant modification
   */
  private _initialLink: Link;

  /**
   * ID du dépôt (i.e "étude" dans Cupidon) courant
   */
   public currentProjectId: string = null;

  /**
   * Langage de la metadata
   */
  public language = "fre";

  /**
   * Langue actuelle
   */
  public locale = "fr";

  /**
   * Liste des langues proposées par l'application
   */
  public availableLanguages = Constants.languages;

  /**
   * Liste des âges de début pour les dropdowns
   */
  public thematicsSuggestions: SelectItem[] = [];

  /**
   * Liste des mots-clés pour l'auto-complétion
   */
  public keywordsSuggestions: SelectItem[] = [];

  /**
   * Pattern pour la validation d'un lien
   */
  public linkPattern = Constants.linkPattern;

  /**
   * ID correspondant au type de ressource séléctionné
   */
  public linkType : string;

  /**
   * Booléen qualifiant si le formulaire a été soumis au moins une fois
   */
  public formSubmitted:boolean = false;

  /**
   * Suvegarde de l'ID correspondant au type de ressource séléctionné
   */
  private _typeIDBackup :string;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _metadataService: MetadataService,
    private _linkService: LinkService,
    private _projectService: ProjectService,
    private _statusService: StatusService,
    private _mapService: MapService,
    private _toastr: ToastrService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _session: SessionService,
    private _registryService: RegistryService,
    private _modalService: NgbModal,
    private _tracker: Angulartics2,
    private _elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.subTypes = _.clone(Constants.linkTypesi18n[Constants.languageMapping[this.language]]);

    this._route.queryParams
      .subscribe(params => {
        if(params.lang!=null) this.language = params.lang;
      }
    );

    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._linkService.link$.subscribe(link => this._initLink(link)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._projectService.projects$.subscribe(projects => this._setProjectsList(projects)));
    this._subs.add(this._mapService.geonames$.subscribe(geonames => this.geonames = geonames));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initLinkFromParams(params)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));
    this._subs.add(this._statusService.statusByUri$.subscribe(status => this._initStatus(status)));
    this._subs.add(this._statusService.statusUpdate$.subscribe(status => this._router.navigate(["/records/pending"])));

    // Check wether we're in a subroute of "My projects" or of "All projects"
    let url = "/" + this._route.pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(([f]) => f.path).join("/");
    let userProjectsRegex1:RegExp = /^\/my-projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/links/;
    let userProjectsRegex2:RegExp = /^\/my-links\/new/;
    let userProjectsBoolean = (userProjectsRegex1.test(url) || userProjectsRegex2.test(url));
    let allProjectsRegex:RegExp = /^\/projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/links/;
    let allProjectsBoolean = allProjectsRegex.test(url);
    if(userProjectsBoolean) this._projectService.getUserProjects("editor",this.language);
    if(allProjectsBoolean) this._projectService.getAllProjects();

  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public onLanguageChange() {
    // On ne recharge pas s'il s'agit d'une nouvelle donnée
    if (!this.isNew) {
      this._loader.show();
      this._linkService.getLink(this.currentProjectId, this.link.id, this.language);
    }
    switch(this.language) {
      case "fre":
        this.locale = "fr";
        break;
      case "eng":
        this.locale = "en";
        break;
    }

    if (this.isNew)
      this._projectService.getProject(this.link.projectId, false, this.language); // Reload the project to update "thematics" and "keywords" fields

    this._projectService.getUserProjects("editor", this.language); // Reload the list of projects to update "study" field
    this._reloadRegistries();
  }

  /**
   * Ferme la pop-up demandant de choisir une langue
   */
  public closeLanguagePopUp(event?:any) {
    if(event!=null) event.preventDefault();
    let popUp = this._elementRef.nativeElement.querySelector("#language-pop-up");
    popUp.classList.add("hidden");
  }


  /**
   * Accepte la publication de la métadonnée.
   * @param event : the click event of the button, prevented to avoid submitting the form.
   */
  public acceptPublication(event:any):void {
    event.preventDefault();
    if (!this.projectIsPublished){
      const modalRef = this._modalService.open(DataValidatorModalComponent, { windowClass: "confirm-modal" });
      modalRef.componentInstance.title = $localize`Validation de la publication d'une ressource`;
      modalRef.componentInstance.message = $localize`Veuillez d'abord accepter la publication du dépôt au préalable`;
    }else{
      if (this._checkAllFields()) {
        const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
        modalRef.componentInstance.title = $localize`Validation de la publication d'une ressource`;
        modalRef.componentInstance.message = $localize`Voulez-vous vraiment accepter la publication de la ressource "${this.link.name}"?`;
        modalRef.componentInstance.confirmClass = "btn-success";
        modalRef.componentInstance.confirmText = $localize`Accepter la publication`;
        modalRef.result.then(() => {
          this._loader.show();

          this.save(
            true,
            () => this._statusService.acceptPublication(this.link, this.language),
            (error: any) => throwError(error)
          );
        });
      }
    }
  }


  /**
   * Refuse la publication de la métadonnée.
   * @param event : the click event of the button, prevented to avoid submitting the form.
   */
  public refusePublication(event:any):void {
    event.preventDefault();
    const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
    modalRef.componentInstance.title = $localize`Refuser de la publication d'une ressource`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment refuser la publication de la ressource "${this.link.name}"?`;
    modalRef.componentInstance.inputLabel = $localize`Message précisant l'objet du refus`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Refuser la publication`;
    modalRef.result.then((message:string) => {
      this._loader.show();
      this._statusService.refusePublication(this.link, message);
    });
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initLinkFromParams(params: string[]) {
    this._loader.show();
    let url = '/' + this._route.pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(([f]) => f.path).join('/');
    let myProjectsRegex:RegExp = /\/my-projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/links\/new/;
    let projectsRegex:RegExp = /\/projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/links\/new/;
    let urlArray = url.split("/");
    let isFromProject = (myProjectsRegex.test(url) || projectsRegex.test(url));
    let linkId = null;
    let projectId = null;
    if(!isFromProject) linkId = params[params.length - 1];
    else projectId = params[params.length - 1];
    if (params.length > 1) {
      projectId = params[0];
    }
    this._linkService.getLink(projectId, linkId, this.language);
    this.isNew = (urlArray[urlArray.length-1]==="new"); // No params => route links/new   Params => route links/{id}/edit
    if (projectId) {
      this.currentProjectId = projectId;
      if (this.isNew) {
        this.link.projectId = projectId;
        this._projectService.getProject(projectId, false, this.language);
      }
    } else {
      this.isInProject = false;
    }
    if (this.isNew) {
      this.cancelRoute[0] += '../';
      if (this.isInProject) {
        this.cancelRoute[0] += '../';
      }
    }
    this.linkId = linkId;
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
    this._loader.hide();
  }

  /**
   * Clone le lien reçu du serveur pour en faire un objet éditable
   * @param link - Lien issu du serveur
   */
  private _initLink(link: Link) {
    this._typeIDBackup = this.linkType;
    this._initialLink = _.cloneDeep(link);
    if (this.isNew) {
      this._initialLink.name = "";
    }

    this.link = _.cloneDeep(this._initialLink);

    if(!this.isNew) {
      this._projectService.getProject(link.projectId, false, this.language);
      this._statusService.getStatusByMetadataUuid(this.link);
    }


    if (this._typeIDBackup)
		this.linkType = this._typeIDBackup;
    else
		if (this.isNew) this.linkType = null;
		else {
      let classifiedKeywords:ClassifiedKeyword|undefined = this.link.classifiedKeywords.find(ckw => Constants.TYPOLOGIE_KEYWORD_NAME === ckw.typeCodeValue);
      if(classifiedKeywords!=undefined && classifiedKeywords.keywordsWithLink.length>0) this.linkType = classifiedKeywords.keywordsWithLink[0].id; // ;.find(wl => wl.))
    }

    if(!this.isNew) {
      this._metadataService.isPublished(this.link.projectId).subscribe((isProjectPublished: boolean) => {
        this.projectIsPublished = isProjectPublished;
      });
    }

    this._loader.hide();
  }

  private _initProject(project: Project): void {
    this.link.project = project;
    this.link.thematics = _.cloneDeep(project.thematics);
    if(!!this.projects && this.projects.findIndex((item) => item.id === project.id) < 0) {
      this.projects.push(project);
    }
  }

  /**
   * Initialise le statut de la ressource
   * @param status
   */
  private _initStatus(status:Status) {
    this.link.status = status;
  }

  /**
   * Met à jour la liste des dépôts (i.e "étude" dans Cupidon) et éventuellement la traçabilité
   * @param projects Liste des dépôts
   */
  private _setProjectsList(projects: Project[]) {
    this.projects = projects;
  }


  public saveDraft(event:any):void {
    event.preventDefault();
    this.formSubmitted = true;
    if (this._checkMinimalFields()) this.save(false);
  }


  public saveAndAskPublication(event:any):void {
    event.preventDefault();
    this.formSubmitted = true;
    if (this._checkAllFields()) this.save(true);
  }


  /**
   * Enregistre le lien et reviens à la liste des liens
   */
  public save(askPublication:boolean, afterSaveOk?: (value: any) => void, afterSaveKo?: (error: any) => void) {

    this._loader.show();

    var regType : Registry = new Registry();
    this.keywords = [];

    regType.id 	  = this.linkType;
    regType.label = this.subTypes[this.linkType];
    this.keywords.push(regType) ;

    this.link.addClassifiedKeywordsWithLink(Constants.TYPOLOGIE_KEYWORD_NAME, this.keywords, true);
    this.link.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME, this.link.thematics, true);
    this.link.contacts = this.link.project.contacts;

    this.link.language = this.language;

    let link = _.cloneDeep(this.link);
    delete link.owner;
    delete link.status;

    if (!afterSaveOk) {
      afterSaveOk = result => {
        this._tracker.eventTrack.next({
          action: this.isNew ? "Ajout d'une ressource" : "Modification d'une ressource",
          properties: {
            category: this.link.name
          }
        });
        this._loader.hide();
        if (result && result.id) {
          if (this.isNew) {
            this._toastr.success($localize`La ressource ${this.link.name} a été créée avec succès`);
            this._session.setInitPageParams({ id: result.id, type: 'link' });
          } else {
            this._toastr.success($localize`La ressource ${this.link.name} a été modifiée avec succès`);
          }
          let returnRoute = "";
          if(this.isNew) returnRoute = `../${result.id}`;
          else returnRoute = `..`;
          this._router.navigate([returnRoute], { relativeTo: this._route });
        }
      };
    }

    if (!afterSaveKo) afterSaveKo = error => console.error(error);
    
    this._linkService.saveLink(link,askPublication).subscribe(afterSaveOk, afterSaveKo);
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackById(item : Project) {
    return item.id;
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   * @param type - Type de donnée à autocompléter
   */
  public formListAutocomplete(event, type: string): void {
    this._registryService.getSuggestions(type, this.language, event.query);
  }

  /**
   * Affiche la liste complète des suggestions d'une liste quand un élément
   * de type p-autocomplete reçoit le focus
   * @param autocomplete Element p-autocomplete du DOM ayant reçu le focus
   * @param type type de liste de propositions
   */
  public autocompleteFocus(autocomplete,type:string) {
    switch(type) {
      case "categories":
      case "thematics":
        autocomplete._suggestions = this.thematicsSuggestions;
        break;
      case "keywords":
        autocomplete._suggestions = this.keywordsSuggestions;
        break;
    }
    if(autocomplete._suggestions.length > 0) autocomplete.show();
  }

  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case 'categories': this.thematicsSuggestions = event.datas;
        break;
      case 'keywords' : this.keywordsSuggestions = event.datas;
        break;
    }
  }

  /**
   * Recharge les registres suite à un changment de langue
   */
  private _reloadRegistries() {
    this._registryService.getLexiques('thematics', this.language, null, null);
    this._registryService.getSuggestions('keywords', this.language);

    this.subTypes = _.clone(Constants.linkTypesi18n[Constants.languageMapping[this.language]]);
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User):void {
    if(this.isNew) return;
    this.isOwner = this._session.hasRight(this.linkId,'owner');
    this.isEditor = this._session.hasRight(this.linkId,'editor');
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isOwner && !this.isEditor && !this.isAdmin && !this.isModerator) this._router.navigate(["/home"]);
    this._loader.hide();
  }

  /**
   * Vérifie que les champs strictement nécessaires sont présents.
   * @returns
   */
    private _checkMinimalFields():boolean {
      let invalidFields:HTMLElement[] = this._checkCommonFields();

      // Scroll de l'écran vers la première erreur
      if (invalidFields.length > 0) invalidFields[0].scrollIntoView({ behavior: "smooth" });

      return invalidFields.length === 0;
    }

    /**
     * Vérifie que tous les champs nécessaires sont présents.
     */
    private _checkAllFields():boolean {
      let invalidFields:HTMLElement[] = this._checkCommonFields();

      // Resource type
      this._metadataService.addFieldRequiredError(invalidFields, this.subTypeField.control, this.linkType, 'link-type');

      // URL
      this._metadataService.addFieldRequiredError(invalidFields, this.urlField.control, this.link.url, 'link-url');

      // Scroll de l'écran vers la première erreur
      if (invalidFields.length > 0) invalidFields[0].scrollIntoView({ behavior: "smooth" });

      return invalidFields.length === 0;
    }

    private _checkCommonFields():HTMLElement[] {
      let invalidFields:HTMLElement[] = [];

      // Reinit all field errors
      this.nameField.control.setErrors(null);
      this.projectField.control.setErrors(null);
      this.subTypeField.control.setErrors(null);
      this.urlField.control.setErrors(null);

      // Name
      this._metadataService.addFieldRequiredError(invalidFields, this.nameField.control, this.link.name, 'link-name');

      // Repository
      this._metadataService.addFieldRequiredError(invalidFields, this.projectField.control, this.link.projectId, 'link-project');

      return invalidFields;
    }

  updateProject() {
    this._projectService.getProject(this.link.projectId, false, this.language);
  }
}
