<section id="datas" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto white-with-border" i18n>Mes jeux de données</h1>
    <div class="title-button-container">
      <a [routerLink]="['./new']" class="btn btn-primary">
        <em class="fa fa-plus"></em>
        <ng-container i18n>Déposer un nouveau jeu de données</ng-container>
      </a>
    </div>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter white-with-border" *ngIf="totalResults === 0" i18n>Aucun jeu de données</p>
          <p class="list-counter white-with-border" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} jeux de données</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2 white-with-border" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="datas" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="defaultName"><span i18n>Titre</span></th>
            <th class="project" sortable-column="project.defaultName"><span i18n>Dépôt</span></th>
            <th class="creator" sortable-column="creator"><span i18n>Déposé par</span></th>
            <th class="date text-center" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
            <th class="fileType"><span i18n>Formats des fichiers</span></th>
            <th class="status"sortable-column="status.value" sort-direction="desc"><span i18n>Statut</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-data>
          <tr>
            <td>
              <a [routerLink]="[session.hasRight(data.projectId, 'readonly') ? '/my-projects' : '/projects', data.projectId, 'datas', data.id]">
                {{displayName(data)}}
              </a>
            </td>
            <td>
              <a class="neutral-link" *ngIf="data.project" title="{{data.project.defaultName}}"
                [routerLink]="[session.hasRight(data.projectId, 'owner') ? '/my-projects' : '/projects', data.projectId]">
                {{displayProjectName(data)}}
              </a>
              <span *ngIf="!data.project">Aucun dépôt associé</span>
            </td>
            <td>{{ data.creator }}</td>
            <td class="text-center">{{data.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
            <td>{{ getDataFilesExtensions(data) }}
              <ng-container *ngIf="data.files.length > 1">({{data.files.length}} fichiers)</ng-container>
              <ng-container *ngIf="data.files.length == 1">({{data.files.length}} fichier)</ng-container>
            </td>
            <td>
              <ng-container *ngIf="data?.status?.value == 'PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
              <ng-container *ngIf="data?.status?.value == 'PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
              <ng-container *ngIf="data?.status?.value == 'ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
              <ng-container *ngIf="data?.status?.value == 'REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
            </td>
            <td class="text-center btn-icon-container" container="body" placement="bottom-right">
              <div class="" ngbDropdown #myDrop="ngbDropdown">
                <button type="button" id="dropdown-data-{{data.id}}" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownAnchor (focus)="myDrop.open()"></button>
                <div ngbDropdownMenu aria-labelledby="dropdown-data-{{data.id}}">
                  <a class="dropdown-item" [routerLink]="['/my-projects',data.projectId,'datas',data.id]" i18n>Consulter</a>
                  <a *ngIf="(session.hasRight(data.projectId, 'owner') || session.hasRight(data.projectId, 'editor')) && !data.published" class="dropdown-item" [routerLink]="['/my-projects',data.projectId,'datas',data.id, 'edit']" i18n>Modifier</a>
                  <a *ngIf="data.files!=null && data.files.length>0" class="dropdown-item" target="_blank" (click)="downloadFiles(data)">
                    Télécharger
                    <em class="fa fa-external-link-alt ml-2"></em>
                  </a>
                  <a *ngIf="(session.hasRight(data.projectId, 'owner') || session.hasRight(data.projectId, 'editor')) && !data.published" class="dropdown-item" [routerLink]="['/my-projects',data.projectId,'datas',data.id, 'duplicate']" i18n>Dupliquer</a>
                  <a *ngIf="session.hasRight(data.projectId, 'owner') && !data.published" class="dropdown-item item-danger" (click)="deleteData(data, 'data')" i18n>Supprimer</a>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
