import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private _isProgressBarVisible = new Subject<boolean>();
  private _totalFiles = new Subject<number>();
  private _currentFile = new Subject<number>();
  private _nameFile = new Subject<string>();
  private _progressValue = new Subject<number>();
  private _isDownloadValue = new Subject<boolean>();

  public isProgressBarVisible$ = this._isProgressBarVisible.asObservable();
  public totalFiles$ = this._totalFiles.asObservable();
  public currentFile$ = this._currentFile.asObservable();
  public nameFile$ = this._nameFile.asObservable();
  public progressValue$ = this._progressValue.asObservable();
  public isDownloadValue$ = this._isDownloadValue.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  public show(totalFiles: number, currentFile: number, nameFile: string, isDownload:boolean): void {
    this._isProgressBarVisible.next(true);
    this._totalFiles.next(totalFiles);
    this._currentFile.next(currentFile);
    this._nameFile.next(nameFile);
    this._progressValue.next(0);
    this._isDownloadValue.next(isDownload);
  }

  public updateNameFile(_nameFile: string): void {
    this._nameFile.next(_nameFile);
  }

  public updateProgressValue(_currentValue: number): void {
    this._progressValue.next(_currentValue);
  }

  public hide(): void {
    this._isProgressBarVisible.next(false);
    this._totalFiles.next(0);
    this._currentFile.next(0);
    this._nameFile.next('Default_name');
    this._progressValue.next(0);
  }

  public launchDownload(url:string, fileName: string, indexFile?: number, totalFiles?: number) {
    return this._http.get(url, {
      responseType: 'blob',
      observe: 'events',
      reportProgress: true
    })
      .pipe(
        tap((event:HttpEvent<any>) => {
          switch (event.type) {
              case HttpEventType.Sent:
                  if (!indexFile || !totalFiles) {
                    this.show(1, 1, fileName, true);
                  } else {
                    this.show(totalFiles, indexFile, fileName, true);
                  }
                  break;
              case HttpEventType.DownloadProgress:
              case HttpEventType.UploadProgress:
                  if (event.total) {
                      const progress = Math.round(event.loaded / event.total * 100);
                      this.updateNameFile(fileName + ' (' + (event.total / 1024) + ' Ko)');
                      this.updateProgressValue(progress);
                  }
                  break;
              case HttpEventType.Response:
                    if (!!event.body) this.hide();
            } // fin du switch
          }
        ),
        last(),
        map((event:HttpResponse<Blob>) => event.body),
        catchError(error => {
          this.hide();
          return throwError(error);
        })
      );
  }

  public launchDownloadZip(url:string, fileName: string, fileNames: string[]) {
    return this._http.post(url,fileNames ,{
      responseType: 'blob',
      observe: 'events',
      reportProgress: true
    })
    .pipe(
      tap((event:HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
                this.show(1, 1, fileName, true);
              break;
            case HttpEventType.DownloadProgress:
            case HttpEventType.UploadProgress:
              if (event.total) {
                const progress = Math.round(event.loaded / event.total * 100);
                this.updateProgressValue(progress);
              }
              break;
            case HttpEventType.Response:
              if (!!event.body) this.hide();
          }
        }
      ),
      last(),
      map((event:HttpResponse<Blob>) => event.body),
      catchError(error => {
        this.hide();
        return throwError(error);
      })
    );
  }
}
