import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Link } from '../../../models';
import { LinkService, LoaderService, SessionService, TableFilterService, UtilsService } from '../../../services';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from '../../../modules/filtrableTable/components';
import { Status } from '../../../models/status.model';
import { StatusService } from '../../../services/status.service';
import { Constants } from 'src/app/constants';

@Component({
  templateUrl: './links.component.html'
})
export class LinksComponent implements OnInit, OnDestroy {
  /**
   * Liste des liens
   */
  public links: Link[] = [];

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _filterService: TableFilterService,
    private _linkService: LinkService,
    private _statusService: StatusService,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2,
    private _utilsService: UtilsService
  ) { }

  public ngOnInit() {
    // Redirect to /public/home if the user is not logged in
    if (this.session.currentUser==undefined || this.session.currentUser==null) {
      this._router.navigate(["/public/home"]);
    } else {
      this._subs.add(this._linkService.links$.subscribe(links => this._initLinks(links)));
      this._subs.add(this._statusService.allStatus$.subscribe(allStatus => this._initAllStatus(allStatus)));
      this._loader.show();
      if (!this.session.currentUser) {
        this._router.navigate(['/projects']);
        return;
      }
      this._linkService.getUserLinks();
      this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
    }

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprime un lien
   * @param link - lien à supprimer
   */
  public deleteLink(link: Link): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = `Suppression d'une ressource`;
    modalRef.componentInstance.message = `Voulez-vous vraiment supprimer cette ressource : '${link.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = `Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._linkService.deleteLink(link)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de ressource",
            properties: {
              category: link.name
            }
          });
          this._toastr.success(`La ressource '${link.name}' a été supprimée avec succès`);
          this._linkService.getUserLinks();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Initialise la liste des liens
   * @param links - liens reçus du serveur
   */
  private _initLinks(links) {
    links.forEach(l => {
      let classifiedKeywords = l.classifiedKeywords.find(ckw => Constants.TYPOLOGIE_KEYWORD_NAME === ckw.typeCodeValue);
      if(classifiedKeywords.length>0) l.typeName = classifiedKeywords.keywordsWithLink[0].label;
    });
    //Tri le tableau en fonction de la date de dernière modification de la plus récente à la plus ancienne
    this.links = _.orderBy(links, 'lastUpdate','desc');

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'link') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }
    this._statusService.getAllStatus();
    this._loader.hide();
  }

  private _initAllStatus(allStatus) {
    _.each(allStatus , status => {
      _.each(this.links , link => {
        if(link.id==status.metadataUuid) {
          let newStatus = new Status();
          newStatus.metadataUuid = status.metadataUuid;
          newStatus.value = status.value;
          link.status = newStatus;
        }
      });
    });
  }

  public displayName(_link : Link):string {
    if(_link==null) return "";
    if(_link.name != null && _link.name != "") {
      if(_link.name.length >= 195) return _link.name.substring(0,195)+" ...";
      else return _link.name;
    } else {
      if(_link.defaultName != null && _link.defaultName != "") {
        if(_link.defaultName.length >= 195) return _link.defaultName.substring(0,195)+" ...";
        else return _link.defaultName;
      }
      else return "";
    }
  }

  public displayProjectName(_link : Link):string {
    let _project = _link.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    } else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }
}
