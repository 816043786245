import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { RightPanelAnimation, LinkService, SessionService, LoaderService, ProjectService, UtilsService } from '../../../services';
import { Link, Project, Role, User } from '../../../models';
import { Constants } from '../../../constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { MetadataService } from '../../../services/metadata.service';
import { StatusService } from '../../../services/status.service';
import { Status } from '../../../models/status.model';
import { Registry } from 'src/app/models/registry.model';

@Component({
  templateUrl: './link-detail.component.html',
  animations: RightPanelAnimation
})
export class LinkDetailComponent implements OnInit, OnDestroy {

  /**
   * Indique si l'utilisateur est le propriétaire de la ressource (i.e "lien" dans Cupidon)
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur peut modifier la ressource (i.e "lien" dans Cupidon)
   */
  public isEditor: boolean = false;

  /**
   * Indique si l'utilisateur peut consulter la ressource (i.e "lien" dans Cupidon)
   */
  public isReadOnly: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur
   */
  public isModerator: boolean = false;

  /**
   * Lien affiché
   */
  public link: Link;

  /**
   * Identifiant de la ressource en cours d'édition
   */
  public linkId: string;

  /**
   * Booléen qualifiant si la ressource auquel est associé le JDD est publié ou non
   */
  public projectIsPublished:boolean = false;

  /**
   * Booléen décrivant si le nom de la ressource doit être affiché en entier ou non.
   */
  public showNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom du dépôt associée à la ressource doit être affiché en entier ou non.
   */
  public showProjectNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom du jeu de données dépasse le nombre de caractère affichable.
   */
  public tooLongTitle: boolean = false;

  /**
   * Dépôt (i.e "étude" dans Cupidon) auquel appartient le lien
   */
  public project: Project;

  /**
   * Saving URL params
   */
  public params: ParamMap = null;

  /**
   * Base d'url vers le dépôt (i.e "étude" dans Cupidon)
   */
  public projectPath: string;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Liste des langues disponibles pour la metadata affichée
   */
  public availableLanguages = [];

  /**
  * Langue par défaut de la metadata
  */
  public language = Constants.defaultLanguage;

  /**
   * Est-on dans le contexte d'un dépôt (i.e "étude" dans Cupidon) ?
   */
  private _isInProject: boolean = false;

  /**
   * Types possible de la ressource
   */
  public subTypes: {[id: number]: string};

  /**
   * Type de la ressource
   */
   public ressourceType : Registry = new Registry();


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _linkService: LinkService,
    private _projectService: ProjectService,
    private _statusService: StatusService,
    private _session: SessionService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2,
    private _metadataService: MetadataService
  ) { }

  public ngOnInit() {
    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._linkService.link$.subscribe(link => this._initLink(link)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._statusService.statusByUri$.subscribe(status => this._initStatus(status)));
    this._subs.add(this._statusService.statusUpdate$.subscribe(status => this._updateStatus(status)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => {
      this.params = _.clone(params);
      this._initLinkFromParams(params);
    }));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayName():string {
    if(this.link==null) return "";
    if(!this.showNameWithEllipsis) return this.link.name ? this.link.name : this.link.defaultName;
    return this.link.name ?
      (this.link.name && this.link.name.length>=100) ? this.link.name.substring(0,100)+"..." : this.link.name :
      (this.link.defaultName && this.link.defaultName.length>=100) ? this.link.defaultName.substring(0,100)+"..." : this.link.defaultName;
  }

  public displayProjectName():string {
    let _project = this.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    }
    else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }

  public updateNameDisplay(event:any) {
    event.preventDefault();
    this.showNameWithEllipsis = !this.showNameWithEllipsis;
  }

  public updateProjectNameDisplay(event:any) {
    event.preventDefault();
    this.showProjectNameWithEllipsis = !this.showProjectNameWithEllipsis;
  }

  /**
   * Supprimme le lien
   */
  public deleteLink(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'une ressource`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer cette ressource : '${this.link.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._linkService.deleteLink(this.link)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de ressource",
            properties: {
              category: this.link.name
            }
          });
          this._toastr.success($localize`La ressource '${this.link.name}' a été supprimée avec succès`);
          let returnRoute = '../../';
          if (this._isInProject) {
            returnRoute += '../../../';
          }
          this._router.navigate([returnRoute], { relativeTo: this._route });
          this._loader.hide();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Changement du langage de la fiche, on recharge la metadata
   */
  public onLanguageChange() {
    this._loader.show();
    this._linkService.getLink(this.link.projectId, this.link.id, this.language);
  }

  /**
   * Téléchargement du lien à un format donné
   * @param format - Le format de téléchargement
   */
  public downloadMetadata(format:string) {
    this._metadataService.downloadMetadata(this.link.id,format,this.language).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.link.name + "." + format);
    });
  }

  /**
   * Demande la publication de la ressource
   */
   public askPublication(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" });
    modalRef.componentInstance.title = $localize`Demande de publication d'une ressource`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment demander la publication cette ressource : "${this.link.defaultName}" ?`;
    modalRef.componentInstance.confirmClass = "btn-success";
    modalRef.componentInstance.confirmText = $localize`Publier`;
    modalRef.result.then(() => {
      this._loader.show();
      this._statusService.askPublication(this.link,this.language);
    });
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initLinkFromParams(params: any): void {
    this._loader.show();
    let projectId = null;
    if (params.length === 2) {
      projectId = params[params.length - 2];
    }
    let linkId = params[params.length - 1];
    if (params.length > 1) {
      this._isInProject = true;
    }
    this._linkService.getLink(projectId, linkId, this.language);
    this.linkId = linkId;
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
  }

  /**
   * Affiche le lien reçu
   * @param link - Lien issu du serveur
   */
  private _initLink(link: Link): void {
    this.link = link;
    this.tooLongTitle = (!!this.link.name && this.link.name.length >= 100) || (!!this.link.defaultName && this.link.defaultName.length >= 100);
    this.showNameWithEllipsis = this.tooLongTitle;
    this._projectService.getProject(link.projectId, false, this.language);
    this._statusService.getStatusByMetadataUuid(this.link);

	  this.ressourceType = this.link.classifiedKeywords.find(ckw => Constants.TYPOLOGIE_KEYWORD_NAME === ckw.typeCodeValue).keywordsWithLink[0];

    this.subTypes = _.clone(Constants.linkTypes[Constants.languageMapping[this.language]]);

  }

  private _initStatus(status:Status) {
    this.link.status = status;
  }

  private _updateStatus(status:Status) {
    this.link.status = status;
  }

  /**
   * Affiche ledépôt (i.e "étude" dans Cupidon) du lien
   * @param project - Projet du lien, issu du serveur
   */
  private _initProject(project: Project): void {
    this.project = project;
    this.showProjectNameWithEllipsis = ((this.project.name!=null && this.project.name.length>=100) || this.project.defaultName.length>=100);
    this.availableLanguages = [];
    _.forEach(Constants.languages, languageValue => {
      // Si la donnée possède le langage
      if (this.link.languagesAvailable.indexOf(languageValue.value) > -1) {
        // Alors on ajoute le langage dans les langages disponibles de la metadata
        this.availableLanguages.push(languageValue);
      }
    });
    // Si la langue par défaut n'est pas présente alors on prend la première langue disponible
    let hasCurrentLanguage = _.map(this.availableLanguages, 'value').indexOf(this.language) > -1;
    if (!hasCurrentLanguage) {
      this.language = this.availableLanguages[0].value;
      // Si le langage utilisé n'est pas celui par défaut, alors un reload est nécessaire
      this._initLinkFromParams(this.params);
    }

     if (this._session.hasRight(this.project.id, 'owner')) {
        this.projectPath = '/my-projects';
    } else {
        this.projectPath = '/projects';
    }

    this._metadataService.isPublished(this.link.projectId).subscribe((isProjectPublished: boolean) => {
      this.projectIsPublished = isProjectPublished;
    });

    this._loader.hide();
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User):void {
    this.isOwner = this._session.hasRight(this.linkId,'owner');
    this.isEditor = this._session.hasRight(this.linkId,'editor');
    this.isReadOnly = this._session.hasRight(this.linkId,'readonly');
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isOwner && !this.isEditor && !this.isReadOnly && !this.isAdmin && !this.isModerator) this._router.navigate(["/home"]);
    this._loader.hide();
  }


}
