import { Component, OnInit } from '@angular/core';
import { SessionService, AppConfigService } from 'src/app/services';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html'
})
export class PublicHeaderComponent implements OnInit {

  public logoName: string;
  public logoTitle: string;
  public title: string;
  public isAdmin: boolean = false;
  public loginUrl:string = "";

  constructor(
    public session: SessionService,
    private _oidcSecurityService: OidcSecurityService
  ) {}

  public ngOnInit() {
    this.logoName = 'logo-dataterra-public.png';
    this.logoTitle = 'Entrepôt de données - DATATERRA';
    this.title = 'Entrepôt de données - DATATERRA';
    if(this.session.currentUser!=null && this.session.currentUser.roles!=null) {
      this.isAdmin = this.session.currentUser.roles.includes("dataterra_admin");
    }
  }

  /**
   * Déconnecte l'utilisateur
   */
  public logout() {
    this.session.logout();
  }

  /**
   * Envoie l'utilisateur vers la page de connexion
   */
  public login() {
    this._oidcSecurityService.authorize();
  }
}
