import * as _ from 'lodash';

import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

@Component({
  selector: 'metadata-mult-coord-unique',
  templateUrl: './multiple-unique-coord.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: MultipleCoordUniqueComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: MultipleCoordUniqueComponent, multi: true }
  ]
})
export class MultipleCoordUniqueComponent implements ControlValueAccessor, Validator {
  @Input('readonly') isReadOnly;
  @Input() required: boolean = false;
  @Input() id: string = "";
  value: any = {};

  @ViewChild('coordX') xField;
  @ViewChild('coordY') yField;

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  @Output('onPaste') onPasteEmitter = new EventEmitter();

  /**
   * Met à jour le ngModel quand les champs de coordonnées changent
   */
  public onChange() {
    this.onChangeCb(this.value);
  }

  public onPaste(event) {
    let finalEvent: any = {
      id: this.id,
      event: event
    };
    let text = event.clipboardData.getData('text');
    if (text.indexOf("\n") >= 0 || text.indexOf("\t") >= 0) {
      event.preventDefault();
      this.value.x = 0;
      this.value.y = 0;
    }
    this.onPasteEmitter.emit(finalEvent);
  }

  // control methods
  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations = {};

    this.requiredValidator(validations, control);

    if (_.keys(validations).length > 0) {
      return validations;
    }
    return null;
  }

  /**
   * Validator vérifiant l'intégrité des coordonnées
   * @param validations
   * @param control
   */
  requiredValidator(validations, control) {
    if (!control.value) {
      return;
    }

    if (this.required) {
      if (control.value.x === null) {
        validations.x = { value: control.value.x };
        this.xField.control.setErrors({ required: true });
      }
      if (control.value.y === null) {
        validations.y = { value: control.value.y };
        this.yField.control.setErrors({ required: true });
      }
    } else if (control.value.x !== null || control.value.y !== null) {
      if (control.value.x === null) {
        validations.x = { value: control.value.x };
        this.xField.control.setErrors({ invalidCoord: true });
      }
      if (control.value.y === null) {
        validations.y = { value: control.value.y };
        this.yField.control.setErrors({ invalidCoord: true });
      }
    } else {
      this.xField.control.setErrors(null);
      this.yField.control.setErrors(null);
    }

  }

}
