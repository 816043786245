<section id="admin-groups" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Suivi d'activités</h1>
  </div>
  <div class="page-content d-flex flex-column h-100">
    <div class="data-table">
      <vigirisks-fitrable-table [rows]="activities" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="text-center"><span i18n>Metadata</span></th>
            <th class="text-center"><span i18n>Action</span></th>
            <th sortable-column="date" sort-direction="desc" class="text-center"><span i18n>Date</span></th>
            <th  class="text-center"><span i18n>Acteur</span></th>
          </tr>
        </ng-template>
        <ng-template body-content let-activity>
          <tr>
            <td class="text-center">{{activity.metadataUuid}}</td>
            <td class="text-center">{{activity.value}}</td>
            <td class="text-center">{{activity.actionDate | date: localeCalendarFormats[locale].formatWithTime}}</td>
            <td class="text-center">{{activity.actor}}</td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
