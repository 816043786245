// @ts-nocheck
import * as _ from 'lodash';

import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges } from "@angular/core";

import Map from 'ol/Map';
import View from 'ol/View';
import { Image as ImageLayer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource, ImageWMS } from 'ol/source';
import { UtilsService } from "src/app/services";
import { transformExtent as transformExtent, transform as transform } from 'ol/proj';
import { Fill, Stroke, Style } from 'ol/style';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';

@Component({
  selector: 'simple-map',
  templateUrl: './simple-map.component.html'
})
export class SimpleMapComponent implements OnInit, OnChanges {
  /**
   * Emprise à afficher
   */
  @Input() extents: Array<Extent> = [];

  /**
   * Bloc HTML contenant la carte de la carte
   */
  @ViewChild('simpleMap', { static: true }) public mapContainer: ElementRef;

  /**
   * Objet carte OpenLayers
   */
  private _map: Map;

  private _webMercatorExtents: [number, number, number, number][];

  private _extentsLayer: VectorLayer;

  private _internalTimeout;

  constructor(
    private _utils: UtilsService
  ) { }

  ngOnInit() {
    this._createMap();
  }

  ngOnChanges(changes) {
    if(changes.extents) {
      this._updateExtents();
    }
  }

  public update() {
    this._updateExtents();
  }

  /**
   * Crée la carte
   */
  private _createMap() {
    this._map = new Map({
      controls: [],
      target: this.mapContainer.nativeElement,
      layers: this._getLayers(),
      view: this._getView(),
    });
  }

  /**
   * Génère les couches de la carte
   */
  private _getLayers(): any[] {
    let layers = [];

    layers.push(new ImageLayer({
      visible: true,
      source: new ImageWMS({
        ratio: 1,
        url: "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        params: {
          "LAYERS": "MONDE_MOD1_FR"
        },
        imageLoadFunction: (image, src) => {
          this._utils.getImageDataFromProxy(src)
            .subscribe(imageData => image.getImage().src = imageData);
        }
      })
    }));

    this._extentsLayer = new VectorLayer({
      source: new VectorSource({
        wrapX: false
      }),
      style: new Style({
        stroke: new Stroke({
          color: 'red',
          width: 3
        }),
        fill: new Fill({ color: 'rgba(255,255,255,0.4)' })
      })
    });

    layers.push(this._extentsLayer);

    return layers;
  }

  /**
   * Génère la vue de la carte
   */
  private _getView(): View {
    return new View({
      zoom: 5,
      projection: 'EPSG:3857',
      center: transform([2.4609, 46.6061], 'EPSG:4326', 'EPSG:3857')
    });
  }

  /**
   * Met à jour les bbox de la carte
   */
  private _updateExtents() {
    if(this._internalTimeout) {
      clearTimeout(this._internalTimeout);
      this._internalTimeout = null;
    }
    
    if(!this._extentsLayer) {
      this._internalTimeout = setTimeout(() => {
        this._updateExtents();
        this._internalTimeout = null;
      }, 500);
      return;
    }
    
    if (this.extents) {
      let features = [];
      this._webMercatorExtents = [];
      _.each(this.extents, extent => {
        let coordinates = [
          extent.westBoundLongitude,
          extent.northBoundLatitude,
          extent.eastBoundLongitude,
          extent.southBoundLatitude
        ];

        if (coordinates.every(c => !!c)) {
          const mercatorExtent = transformExtent(coordinates, 'EPSG:4326', 'EPSG:3857');
          const polygon = polygonFromExtent(mercatorExtent);
          features.push(new Feature({ geometry: polygon }));
        }
      });

      const extentsSource = this._extentsLayer.getSource();

      extentsSource.clear();
      extentsSource.addFeatures(features);
      
      if (this.extents.length > 0) {
        this._map.getView().fit(extentsSource.getExtent(), { padding: [15, 15, 15, 15] });
      }
    }
  }
}