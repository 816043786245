import { Angulartics2Matomo } from 'angulartics2/matomo'
import { Component, OnInit, enableProdMode } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import * as proj4x from 'proj4';
import { register as proj4Register } from 'ol/proj/proj4';

import { MapService } from './services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _mapService: MapService,
    private _angulartics2Matomo: Angulartics2Matomo,
    private _oidcSecurityService: OidcSecurityService
  ) {
    _angulartics2Matomo.startTracking();
  }

  ngOnInit() {
    if(environment.production) {
      enableProdMode();
    }
    this._mapService.initMaps();

    // custom OL projections
    let proj4 = (proj4x as any).default;
    proj4.defs([
      ["EPSG:2154", "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["EPSG:27563", "+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs"],
      ["EPSG:27582", "+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs"],
      ["EPSG:32740", "+proj=utm +zone=40 +south +datum=WGS84 +units=m +no_defs"]
    ]);

    proj4Register(proj4);

    // return to top page when navigating
    this._router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    document.addEventListener('mouseout', e => {
      let target = e.target as HTMLTextAreaElement;
      if (target.nodeName) {
        let nodeName = target.nodeName.toLowerCase();
        if (nodeName === 'button' || nodeName === 'a') {
          target.blur();
        } else if (target.parentNode) {
          let parentNode = target.parentNode as HTMLTextAreaElement;
          nodeName = parentNode.nodeName.toLowerCase();
          if (nodeName === 'button' || nodeName === 'a') {
            parentNode.blur();
          }
        }
      }
    });

    this._oidcSecurityService.checkAuth(window.location.href,'main').subscribe(() => {
    });
  }
}
