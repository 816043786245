import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, throwError} from "rxjs";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { Data, DataPole, Destination, Project, OnlineResource, Contact, FileToUpload, ContactIdentifier, User, Role, Organization, AnnotatorResult } from '../../../models';
import { DataService, LoaderService, MapService, MetadataService, PrimeNGUtilsService, ProjectService, RorService, SessionService, UtilsService } from '../../../services';
import { Constants, Uris } from '../../../constants';
import { Angulartics2 } from 'angulartics2';
import { FormListEvent, RegistryService } from '../../../services/registry.service';
import { SelectItem } from 'primeng/api';
import { DescribedExtentComponent } from '../form-parts/described-extent/described-extent.component';
import { Extent } from '../../../models/extent.model';
import { StatusService } from '../../../services/status.service';
import { Status } from '../../../models/status.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent, DataValidatorModalComponent} from '../../modals';
import { Registry } from '../../../models/registry.model';
import { Keyword } from '../../../models/keyword.model';
import { HttpClient } from "@angular/common/http";
import {LicenseService} from "../../../services/license.service";
import {License} from "../../../models/license.model";
import { AnnotatorModalComponent } from '../../modals/annotator/annotator-modal.component';


@Component({
  templateUrl: './data-edit.component.html'
})
export class DataEditComponent implements OnInit, OnDestroy {

  @ViewChild('nameField') nameField;

  @ViewChild('descField') descField;

  @ViewChild('projectField') projectField;

  @ViewChild('thematicsField') thematicsField;

  @ViewChild('keywordsField') keywordsField;

  @ViewChild('dataFilesFied') filesField;

  @ViewChild('creationDateField') creationDateField;

  @ViewChild('legalConstraintsField') legalConstraintsField;

  @ViewChild('embargoField') embargoField;

  @ViewChild('releasedDateField') releasedDateField;

  @ViewChild('spaceExtentField') spaceExtentField;

  @ViewChildren('westBoundLongitudeField') westBoundLongitudeFields;

  @ViewChildren('eastBoundLongitudeField') eastBoundLongitudeFields;

  @ViewChildren('northBoundLatitudeField') northBoundLatitudeFields;

  @ViewChildren('southBoundLatitudeField') southBoundLatitudeFields;

  @ViewChild('timeExtentStartField') timeExtentStartField;

  @ViewChild('timeExtentEndField') timeExtentEndField;

  @ViewChild('lineageField') lineageField;

  @ViewChildren('contactLastNameField') contactLastNameFields;

  @ViewChildren('contactFirstNameField') contactFirstNameFields;

  @ViewChildren('contactEmailField') contactEmailFields;

  @ViewChildren('contactOrganisationField') contactOrganisationFields;

  @ViewChildren('contactRoleField') contactRoleFields;

  @ViewChildren('contactIdentifierTypeField') contactIdentifierTypeFields;

  @ViewChildren('contactIdentifierField') contactIdentifierFields;

  @ViewChildren('dataUrlField') dataUrlFields;

  @ViewChildren('dataUrlProtocolField') dataUrlProtocolFields;

  @ViewChildren('dataUrlNameField') dataUrlNameFields;

  @ViewChild('thematicsAutocomplete') thematicsAutocomplete;

  @ViewChild('keywordsAutocomplete') keywordsAutocomplete;

  @ViewChild(DescribedExtentComponent) describedExtentComponent:DescribedExtentComponent;

  public FILE_RESOURCE_CODE = Constants.FILE_RESOURCE_CODE;

  /**
   * Indique si l'utilisateur est le propriétaire du jeu de données
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur peut modifier le jeu de données
   */
  public isEditor: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur
   */
  public isModerator: boolean = false;

  /**
   * Donnée en cours d'édition
   */
  public data: Data;

  /**
   * Identifiant de la donnée en cours d'édition
   */
  public dataId: string;

  /**
   * Est-ce une nouvelle donnée ?
   */
  public isNew: boolean = true;

  /**
   * Est-ce une duplication ?
   */
  public isDuplication: boolean = false;

  /**
   * Est-on dans le contexte d'un dépôt (i.e "étude" dans Cupidon) ?
   */
  public isInProject: boolean = true;

  /**
   * ID du dépôt (i.e "étude" dans Cupidon) courante
   */
  public currentProjectId: string = null;

  /**
   * Liste des geonames de l'autocomplétion
   */
  public geonames: Destination[] = [];

  /**
   * Types de description des emprises géographiques
   */
  public extentsDescriptionTypes:string[] = [];

  /**
   * Liste des mots-clés au format string
   */
  public keywords: Registry[] = [];

  /**
   * Types de données
   */
  public subTypes: string[] = [];

  /**
   * Liste de mes dépôts (i.e "études" dans Cupidon)
   */
  public projects: Project[] = [];

  /**
   * Booléen qualifiant si le projet auquel est associé le JDD est publié ou non
   */
  public projectIsPublished:boolean = false;

  /**
   * Booléen qualifiant si le jeu de données compte au moins une thématique du projet associé
   */
  public hasProjectThematic: boolean = false;

  /**
   * Booléen qualifiant si le jeu de données compte au moins un mot-clé du projet associé
   */
  public hasProjectKeyword: boolean = false;

  /**
   * Backup des thématiques du jeu de données
   */
  public dataThematicsBackup: Registry[] = [];

  /**
   * Backup des mots-clés du jeu de données
   */
  public dataKeywordsBackup: Registry[] = [];

  /**
   * Liste des protocoles pour les liens et services
   */
  public protocols: string[] = [];

  /**
   * Liste des types de représentation spatiale
   */
  public spatialTypes: string[] = [];

  /**
   * Liste des contraintes d'utilisation
   */
  public useConstraints: License[] = [];

  /**
   * La contraintes d'utilisation de la data
   */
  public licenseId: string = "";

  /**
   * Liste des types de contact
   */
  public contactTypes: { label: string, value: string }[] = [];

  /**
   * Liste des types d'identifiant
   */
  public identifiersOrigins: string[] = [];

  /**
   * Route de retour
   */
  public cancelRoute: string[] = ['../'];

  /**
   * Langue actuelle
   */
  public locale: string = "fr";

  /**
   * Configuration des langues
   */
  public localeCalendarConfig = Constants.localeCalendarConfig;

  /**
   * Expressions régulières des langues
   */
  public localeCalendarRegExps = Constants.localeCalendarRegExps;

  /**
   * Format des dates en fonction des langues
   */
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Traduction language -> locale
   */
  public languageToLocale = Constants.languageToLocale;

  /**
   * Limite (en Mo) de taille d'un fichier
   */
  public fileSizeLimit = Constants.FILE_SIZE_LIMIT;

  /**
   * Est-il possible de supprimer un contact "pointOfContact" ?
   */
  public canDeletePointOfContact: boolean = false;

  /**
   * Fichiers à uploader
   */
  public filesToUpload: FileToUpload[] = [];

  /**
   * Base url vers le téléchargement de fichiers
   */
  public datasetsFilePath: string = Uris.DATAS;

  /**
   * Langage de la metadata
   */
  public language = "fre";

  /**
   * Liste des langues proposées par l'application
   */
  public availableLanguages = Constants.languages;

  /**
   * Liste des âges de début pour les dropdowns
   */
   public thematicsSuggestions: SelectItem[] = [];

  /**
   * Liste des mots-clés pour l'auto-complétion
   */
  public keywordsSuggestions: SelectItem[] = [];

  /**
   * Stockage des IDs des thématiques pour le changement de langue
   */
  public thematicsIdentifiersStorage: string[] = [];

  /**
   * Stockage des IDs des mots-clés pour le changement de langue
   */
  public keywordsIdentifiersStorage: string[] = [];

  /**
   * URL vers le registre des thématiques
   */
  public thematicsRegistryUrl: string = Uris.THEMATICS_REGISTRY_URL;

  /**
   * URL vers le registre des mots-clés
   */
  public keywordsRegistryUrl: string = Uris.KEYWORDS_REGISTRY_URL;

  /**
   * Liste de tous les pôles de données
   */
  private allDataPoles: DataPole[] = [];

  /**
   * Liste des organisations pour l'autocomplétion
   */
  public organizationResults: Organization[] = [];

  /**
   * Valeur minimale de la date de libération des données
   */
  public minReleasedDate: Date|null = null;

  /**
   * Valeur maximale de la date de libération des données
   */
  public maxReleasedDate: Date|null = null;

  /**
   * Booléen qualifiant si le formulaire a été soumis au moins une fois
   */
  public formSubmitted:boolean = false;

  /**
   * Donnée avant modification
   */
  private _initialData: Data;

  /**
   * Booléen qualifiant le chargement initial du projet associé au JDD.
   * Utilisé pour éviter un bug ou deux requêtes asynchrones parallèles peuvent
   * effacer les thématiques/mots-clés du formulaire en voulant changer de langue.
   * Requête 1 : récupération du projet dans la nouvelle langue -> appele _initProject au retour.
   * Requête 2 : rechargement des registres dans la nouvelle langue -> appele _updateFormList.
   */
  private firstLoadOfProject:boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Ancienne valeur du champ Rôle d'un contact
   */
  private oldValueRole:string;

  /**
   * Flag "Données téléchargeables"
   */
  public filesDownloadable = false;

  /**
   * String pour info-bulle de l'organisation d'un contact
   */
  tooltipRorSite = "ROR (" + Uris.ROR_SITE+ ")";


  /**
   * String pour info-bulle format des fichiers
   */
  listFilesFormat = Constants.SITE_FILES_FORMAT;

  /**
   * String pour info-bulle du rôle d'un contact
   */
  siteStandards = Constants.STANDARDS_ISO_TC211;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _metadataService: MetadataService,
    private _dataService: DataService,
    private _projectService: ProjectService,
    private _statusService: StatusService,
    private _mapService: MapService,
    private _toastr: ToastrService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    public session: SessionService,
    private _registryService: RegistryService,
    private _modalService: NgbModal,
    private _tracker: Angulartics2,
    private _elementRef: ElementRef,
    private _primeNGUtilsService: PrimeNGUtilsService,
    private _http: HttpClient,
    private _rorService: RorService,
    private _licenseService: LicenseService
  ) { }

  ngOnInit() {
    this.locale = this._utils.getLocaleFromNavigator();

    this._route.queryParams
      .subscribe(params => {
        if(params.lang!=null) {
          this.language = params.lang;
          this.locale = this.languageToLocale[this.language];
        }
      }
    );

    this._subs.add(this.session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._dataService.data$.subscribe(data => this._initData(data)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._projectService.projects$.subscribe(projects => this._setProjectsList(projects)));
    this._subs.add(this._mapService.geonames$.subscribe(geonames => this.geonames = geonames));
    this._subs.add(this._mapService.geonamesBoundingBox$.subscribe(result => this._updateBoundingBox(result.boundingBox, result.index)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initDataFromParams(params)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));
    this._subs.add(this._statusService.statusByUri$.subscribe(status => this._initStatus(status)));
    this._subs.add(this._statusService.statusUpdate$.subscribe(status => this._router.navigate(["/records/pending"])));
    this._subs.add(this._licenseService.license$.subscribe(datas => this._initLicenses(datas)));

    this._licenseService.getLicenses();

    this.subTypes = Constants.dataTypes;
    this.protocols = Constants.dataOnlineResourceProtocols;
    this.spatialTypes = Constants.spatialRepresentationTypes;
    this.identifiersOrigins = Constants.identifiersOrigins;
    this.contactTypes = [];
    for(let i:number=0 ; i< Constants.contactTypes.length ; i++) {
      let elt = Constants.contactTypes[i];
      let value:string = elt.value;
      for(let j:number=0 ; j<elt.labels.length ; j++) {
        if(elt.labels[j].language==this.language) {
          let label:string = elt.labels[j].label;
          this.contactTypes.push({label:label,value:value});
          break;
        }
      }
    }
    this.contactTypes.sort((a,b) => a.label.localeCompare(b.label));

    // Check wether we're in a subroute of "My projects" or of "All projects"
    let url = "/" + this._route.pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(([f]) => f.path).join("/");
    let userProjectsRegex1:RegExp = /^\/my-projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/datas/;
    let userProjectsRegex2:RegExp = /^\/my-data\/new/;
    let userProjectsBoolean = (userProjectsRegex1.test(url) || userProjectsRegex2.test(url));
    let allProjectsRegex:RegExp = /^\/projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/datas/;
    let allProjectsBoolean = allProjectsRegex.test(url);
    if(userProjectsBoolean) this._projectService.getUserProjects("editor",this.language);
    if(allProjectsBoolean) this._projectService.getAllProjects();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Lance la requête pour l'autocomplétion des geonames
   * @param event - event primeng
   */
  public searchGeonames(event: any,index:number) {
    let formattedLanguage = "";
    if (this.language == "fre") formattedLanguage = "fr";
    else if (this.language == "eng") formattedLanguage = "en";

    this._mapService.searchGeonames(event.query, this.extentsDescriptionTypes[index], formattedLanguage);
  }

  /**
   * Lorsque l'on sélectionne un item Geonames, on envoie une requête
   * pour récupérer sa bounding box.
   * @param item : l'item sélectionné.
   * @param index : la position de l'emprise concernée dans la liste des emprises.
   */
  public geonamesItemSelected(item:Destination, index:number) {
    this.data.extents[index].geonameId = item.geonameId;
    this.data.extents[index].description = { label: item.label };

    let formattedLanguage = "";
    if (this.language == "fre") formattedLanguage = "fr";
    else if (this.language == "eng") formattedLanguage = "en";

    this._mapService.getItemBoundingBox(item.geonameId, index, formattedLanguage);
  }

  public updateProject() {
    this._projectService.getProject(this.data.projectId, false, this.language);
  }

  public updateInformationsWithProject() {
    // Update thematics when project is selected
    let project = this.data.project;
    _.each(project.thematics, thematic => {
      if(this.data.thematics.filter(t => t.label==thematic.label).length == 0 && this.isNew) {
        let thematicEntity = new Registry();
        thematicEntity.id = thematic.id;
        thematicEntity.label = thematic.label;
        this.data.thematics.push(thematicEntity);
      }
      setTimeout(()=>{
        this.hasProjectThematic = false;
        let thematicsElements = this._elementRef.nativeElement.querySelectorAll("#data-thematics-autocomplete li");
        _.each(thematicsElements , elt => {
          let labelElement = elt.querySelector(".p-autocomplete-token-label");
          if(labelElement!=null) {
            let text = labelElement.textContent;
            let isThematicOfProject = project.thematics.filter(t => t.label==text.trim()).length > 0;
            if(isThematicOfProject) {
              elt.classList.add("autocomplete-project-element");
              elt.lastElementChild.classList.remove("p-autocomplete-token-icon");
              elt.lastElementChild.classList.remove("pi");
              elt.lastElementChild.classList.remove("pi-times-circle");
              this.hasProjectThematic = true;
            }
          }
        });
      },10);
    });

    // Update keywords when project is selected
    _.each(project.freeKeywords, keyword => {
      if(this.keywords.filter(k => k.label==keyword.label).length == 0 && this.isNew) {
        let keywordEntity = new Registry();
        keywordEntity.id = keyword.id;
        keywordEntity.label = keyword.label;
        this.keywords.push(keywordEntity);
      }
      setTimeout(()=>{
        this.hasProjectKeyword = false;
        let keywordsElements = this._elementRef.nativeElement.querySelectorAll("#data-keywords-autocomplete li");
        _.each(keywordsElements , elt => {
          let labelElement = elt.querySelector(".p-autocomplete-token-label");
          if(labelElement!=null) {
            let text = labelElement.textContent;
            let isKeywordOfProject = project.freeKeywords.filter(k => k.label==text.trim()).length > 0;
            if(isKeywordOfProject) {
              elt.classList.add("autocomplete-project-element");
              this.hasProjectKeyword = true;
            }
          }
        });
      },10);
    });

  }


  /**
   * Affiche, avec ou sans ellipse, le titre du dépôt associé au JDD.
   * @param _data : le jeu de données.
   * @returns le titre du dépôt associé, avec ou sans ellipse.
   */
  public displayProjectName(_project : Project, shortMode:boolean = false):string {
    let maxSize = shortMode ? 95 : 195;
    if(_project==null) return "";
      if(_project.name != null && _project.name != "") {
        if(_project.name.length >= maxSize) return _project.name.substring(0,maxSize)+" ...";
        else return _project.name;
    } else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= maxSize) return _project.defaultName.substring(0,maxSize)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }


  /**
   * Affiche, selon le format de la langue utilisée, la date maximale de libération
   * @returns la date maximale de libération au format adapté
   */
  public displayMinReleasedDate():string {
    if (!this.minReleasedDate) return "";
    let format = this.localeCalendarFormats[this.locale].formatMoment;
    if (!format) return "";
    return moment(this.minReleasedDate).format(format);
  }


  /**
   * Affiche, selon le format de la langue utilisée, la date maximale de libération
   * @returns la date maximale de libération au format adapté
   */
  public displayMaxReleasedDate():string {
    if (!this.maxReleasedDate) return "";
    let format = this.localeCalendarFormats[this.locale].formatMoment;
    if (!format) return "";
    return moment(this.maxReleasedDate).format(format);
  }


  /**
   * Vérifie qu'une date est présente et au bon format.
   * @param field
   * @returns
   */
  public checkDate(field:any, checkRequired:boolean) {
    // Check if value is present and has the right format
    if (checkRequired && !!field && !field.control.value) {
      field.control.setErrors({ missing: true });
      return;
    }

    let formatRegExp = this.localeCalendarRegExps[this.locale];
    if (!!field && !formatRegExp.test(field.control.value)) field.control.setErrors({ format: true });
  }


  /**
   * Vérifie que la date de libération a le bon format et la bonne valeur.
   * @param field
   * @param selectedInCalendar
   */
  public checkReleasedDate(selectedInCalendar:boolean) {
    if (!selectedInCalendar) this.checkDate(this.releasedDateField, false);

    let format = this.localeCalendarFormats[this.locale].formatMoment;
    let creationDate = !!this.data.creationDate ? moment(this.data.creationDate, format).toDate() : null;
    let releasedDate = !!this.data.releasedDate ? moment(this.data.releasedDate, format).toDate() : null;

    if (!!creationDate && !!releasedDate && releasedDate.getTime() < creationDate.getTime()) this.releasedDateField.control.setErrors({ range: true });
    else if (!!creationDate && !!releasedDate && releasedDate.getTime() > this.maxReleasedDate.getTime()) this.releasedDateField.control.setErrors({ rangeMax: true });
  }


  /**
   * Vérifie que la date de création est présente et avec le bon format.
   */
  public checkCreationDate(selectedInCalendar:boolean, onlyChecks:boolean) {
    // Check the value of the field
    if (!selectedInCalendar) this.checkDate(this.creationDateField, true);

    // Check if <= today
    if (!this.creationDateField.control.errors) {
      let today = new Date(),
        format = this.localeCalendarFormats[this.locale].formatMoment;

      if (!!this.creationDateField.control.value
          && moment(this.creationDateField.control.value, format).toDate().getTime() > today.getTime()) this.creationDateField.control.setErrors({ rangeToday: true });
    }

    // Contrôles sur l'étendue temporelle
    if (!onlyChecks) this.checkTemporalExtentDates(null, null, false);
  }


  /**
   * Vérifie que les valeurs de l'étendue temporelle sont présentes, au bon format et avec les bonnes valeurs.
   * @param field
   * @returns
   */
  public checkTemporalExtentDates(field:any, type:string, selectedInCalendar:boolean) {
    this.timeExtentStartField.control.setErrors(null);
    this.timeExtentEndField.control.setErrors(null);

    // Check if value is present and has the right format
    if (!selectedInCalendar) this.checkDate(field, true);

    // Check if start < end
    let format = this.localeCalendarFormats[this.locale].formatMoment;
    let creationDate:Date|null = !!this.data.creationDate && this.data.creationDate!="" ? moment(this.data.creationDate, format).toDate() : null;
    let startDate:Date|null = type == "start" ? moment(field.control.value, format).toDate() :
                                              (!!this.data.temporalExtentStart ? moment(this.data.temporalExtentStart, format).toDate() : null);
    let endDate:Date|null = type == "end" ? moment(field.control.value, format).toDate() :
                                          (!!this.data.temporalExtentEnd ? moment(this.data.temporalExtentEnd, format).toDate() : null);
    let today = new Date();

    // Check if start <= today
    if (!!startDate && startDate.getTime() > today.getTime()) this.timeExtentStartField.control.setErrors({ rangeToday: true });

    // Check if start <= creation
    if (!!startDate && !!creationDate && startDate.getTime() > creationDate.getTime()) this.timeExtentStartField.control.setErrors({ rangeCreation: true });

    // Check if end <= today
    if (!!endDate && endDate.getTime() > today.getTime()) this.timeExtentEndField.control.setErrors({ rangeToday: true });

    // Check if end <= creation
    if (!!endDate && !!creationDate && endDate.getTime() > creationDate.getTime()) this.timeExtentEndField.control.setErrors({ rangeCreation: true });

    // Check if start <= end
    if (!!startDate && !!endDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors({ rangeEnd: true });
  }


  public updateExtentsDescriptionTypes(value:string,index:number) {
    this.extentsDescriptionTypes[index] = value;
  }


  /**
   * Changement de la langue de la metadata
   */
  public onLanguageChange() {
    this.thematicsIdentifiersStorage = this.data.thematics!=null ? _.cloneDeep(this.data.thematics).map(t => t.id) : [];
    this.keywordsIdentifiersStorage = this.keywords!=null ? _.cloneDeep(this.keywords).map(k => k.id) : [];
    this.data.thematics = [];
    let oldLocale = this.locale;
    this.locale = this.languageToLocale[this.language];
    let newLocale = this.locale;
    this.data.creationDate = this.changeDateFormat(this.data.creationDate,oldLocale,newLocale);
    this.data.releasedDate = this.changeDateFormat(this.data.releasedDate,oldLocale,newLocale);
    this.data.temporalExtentStart = this.changeDateFormat(this.data.temporalExtentStart,oldLocale,newLocale);
    this.data.temporalExtentEnd = this.changeDateFormat(this.data.temporalExtentEnd,oldLocale,newLocale);
    this.subTypes = [];
    _.each(Constants.dataTypes , type => {
      this.subTypes.push(type[this.language]);
    });

    // On ne recharge pas s'il s'agit d'une nouvelle donnée
    if (!this.isNew) {
      this._loader.show();
      this._dataService.getData(this.currentProjectId, this.data.id, this.language);
    }

    if (this.isNew)
      this._projectService.getProject(this.data.projectId, false, this.language); // Reload the project to update "thematics" and "keywords" fields

    this._projectService.getUserProjects("editor", this.language); // Reload the list of projects to update "study" field


    this.contactTypes = [];
    for (let i:number = 0; i < Constants.contactTypes.length; i++) {
      let elt = Constants.contactTypes[i];
      let value:string = elt.value;

      for (let l of elt.labels) {
        if (l.language == this.language) {
          let label:string = l.label;
          this.contactTypes.push({ label:label, value:value });
          break;
        }
      }
    }
    this.contactTypes.sort((a,b) => a.label.localeCompare(b.label));

    this._primeNGUtilsService.setLanguage(this.locale);
  }

  /**
   * Ferme la pop-up demandant de choisir une langue
   */
  public closeLanguagePopUp(event?:any) {
    if(event!=null) event.preventDefault();
    let popUp = this._elementRef.nativeElement.querySelector("#language-pop-up");
    popUp.classList.add("hidden");
  }

  /**
   * Demande la donnée à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initDataFromParams(params: string[]) {
    this._loader.show();
    let url = '/' + this._route.pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(([f]) => f.path).join('/');
    let myProjectsRegex:RegExp = /\/my-projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/datas\/new/;
    let projectsRegex:RegExp = /\/projects\/[a-zA-Z0-9]*(-[a-zA-Z0-9]*)*\/datas\/new/;
    let urlArray = url.split("/");
    let isFromProject = (myProjectsRegex.test(url) || projectsRegex.test(url));
    let dataId = null;
    let projectId = null;
    if(!isFromProject) dataId = params[params.length - 1];
    else projectId = params[params.length - 1];
    if (params.length > 1) {
      projectId = params[0];
    }
    this._dataService.getData(projectId, dataId, this.language);
    this.isNew = (urlArray[urlArray.length-1]==="new");
    this.isDuplication = (urlArray[urlArray.length-1]==="duplicate");
    if (projectId) {
      this.currentProjectId = projectId;
      if (this.isNew) {
        this.data.projectId = projectId;
        this._projectService.getProject(projectId, false, this.language);
      }
    } else {
      this.isInProject = false;
      this._registryService.getLexiques("dataPoles",this.language, null, null);
      this._registryService.getLexiques("thematics",this.language, null, null);
      this._registryService.getLexiques("keywords", this.language, null, null, "");
    }
    if (this.isNew) {
      this.cancelRoute[0] += '../';
      if (this.isInProject) {
        this.cancelRoute[0] += '../';
      }
    }
    this.dataId = dataId;
    if(!this.session.currentUser) this.session.getCurrentUser();
    else this._checkRoles(this.session.currentUser);
  }

  /**
   * Clone la donnée reçue du serveur pour en faire un objet éditable
   * @param data - Donnée issue du serveur
   */
  private _initData(data: Data) {
    this._initialData = _.cloneDeep(data);
    if (this.isNew) {
      this._initialData.name = "";

      let firstContact = new Contact();
      firstContact.individualLastName = this.session.currentUser.lastName;
      firstContact.individualFirstName = this.session.currentUser.firstName;
      firstContact.email = this.session.currentUser.email;
      firstContact.role = "pointOfContact";
      this._initialData.contacts.push(firstContact);

      let secondContact = new Contact();
      secondContact.role = "author";
      this._initialData.contacts.push(secondContact);
    }

    if (this.isDuplication) {
      this._initialData.name = "Copie de la fiche " + this._initialData.name;
      this._initialData.files = [];
      this._initialData.onlineResources = this._initialData.onlineResources.filter(t => t.protocol != Constants.FILE_RESOURCE_PROTOCOL);
    }

    this.data = _.cloneDeep(this._initialData);

    this.data.extents = [];
    _.each(data.extents , extent => {
      let newExtent = new Extent();
      newExtent.geonameId = extent.geonameId;
      newExtent.description = extent.description;
      newExtent.westBoundLongitude = extent.westBoundLongitude;
      newExtent.eastBoundLongitude = extent.eastBoundLongitude;
      newExtent.northBoundLatitude = extent.northBoundLatitude;
      newExtent.southBoundLatitude = extent.southBoundLatitude;
      this.data.extents.push(newExtent);
    });

    this.filesToUpload = [];
    _.each(this.data.files, (file: OnlineResource) => {
      this.filesToUpload.push({
        label: file.name,
        existent: true,
        url: file.url
      });
    });

    if(!this.isNew) {
      this.keywords = this.data.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME);
    } else {
      this.keywords = [];
    }

    // Store initial values of thematics and keywords
    this.dataThematicsBackup = _.cloneDeep(this.data.thematics);
    this.dataKeywordsBackup = _.cloneDeep(this.keywords);

    if(!this.isNew) {
      this._projectService.getProject(data.projectId, false, this.language);
      if (this.isDuplication){
        this.data.status = new Status();
        this.data.status.value = 'PROGRESS';
      }else{
        this._statusService.getStatusByMetadataUuid(this.data);
      }
    }
    this.checkPointsOfContacts(null);

    // Initialize the maximal temporal extent value (start or end)
    if(!!this.data.creationDate) {
      let format = this.localeCalendarFormats[this.locale].formatMoment;
      let creationDate = moment(this.data.creationDate,format).toDate();
    }

    // Initialize minimum released date : today
    this.minReleasedDate = new Date();
    // Initialize maximum released date : today + 2 years
    this.maxReleasedDate = moment(new Date()).add(2, 'y').toDate();

    this._primeNGUtilsService.setLanguage(this.locale);

    if (this.data.legalConstraints)
      this.licenseId = this.data.legalConstraints.licenseId;

    this.canDownloadDatasetsFiles();

    this._loader.hide();

  }

  /**
   * Initialise le dépôt auquel est associé le jeu de données
   * @param project
   */
  private _initProject(project: Project): void {
    // Remove the thematics of the previous project
    if(!this.firstLoadOfProject && this.data.project!=null && this.data.project.thematics!=null) {
      let cleanThematics:any[] = [];
      _.each(this.data.thematics , thematic => {
        let text = thematic.label;
        let isThematicOfProject = this.data.project.thematics.filter(t => t.label==text).length > 0;
        if(!isThematicOfProject) cleanThematics.push(thematic);
      });
      this.data.thematics = cleanThematics;
    }

    // Remove the keywords of the previous project
    if(!this.firstLoadOfProject && this.data.project!=null && this.keywords!=null) {
      let cleanKeywords:Keyword[] = [];
      _.each(this.keywords , keyword => {
        let text = keyword.label;
        let isKeywordOfProject = this.data.project.freeKeywords.filter(k => k.label==text).length > 0;
        if(!isKeywordOfProject) cleanKeywords.push(keyword);
      });
      this.keywords = cleanKeywords;
    }

    this.data.project = project;
    if (this.isNew) {
      this.data.thematics = _.cloneDeep(project.thematics);
      this.keywords = !!project.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME) ? project.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME) : [];
    }else{
        _.each(project.thematics , thematic => {
          if (this.data.thematics.filter(t => t.id==thematic.id).length == 0){
            this.data.thematics.push(thematic);
          }
        });
      this.data.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME,this.data.thematics, true);
    }

    if(!!this.projects && this.projects.findIndex((item) => item.id === project.id) < 0) {
      this.projects.push(project);
    }

    this._reloadRegistries();

    setTimeout(()=> {
      this.updateInformationsWithProject();
    }, 100);

    this._metadataService.isPublished(this.data.projectId).subscribe((isProjectPublished: boolean) => {
      this.projectIsPublished = isProjectPublished;
    });

    this.firstLoadOfProject = true;
  }


  /**
   * Initialise le statut du jeu de données
   * @param status
   */
  private _initStatus(status:Status) {
    this.data.status = status;
  }

  /**
   * Met à jour la liste des suggestions Geonames.
   * @param geonames
   */
  private _updateGeonames(geonames:Destination[]) {
    this.geonames = geonames;
  }

  /**
   * Met à jour la bounding box d'une emprise.
   * @param boundingBox : la bounding box de l'emprise.
   * @param index : la position de l'emprise dans la liste des emprises.
   * @returns
   */
  private _updateBoundingBox(boundingBox:Extent, index:number) {
    if (!this.data.extents || this.data.extents.length < index + 1) return;

    this.data.extents[index].westBoundLongitude = boundingBox.westBoundLongitude;
    this.data.extents[index].eastBoundLongitude = boundingBox.eastBoundLongitude;
    this.data.extents[index].northBoundLatitude = boundingBox.northBoundLatitude;
    this.data.extents[index].southBoundLatitude = boundingBox.southBoundLatitude;

    if (!!boundingBox.westBoundLongitude && !!boundingBox.eastBoundLongitude && !!boundingBox.northBoundLatitude && !!boundingBox.southBoundLatitude) {
      this.describedExtentComponent.updateBBoxes();
      this.describedExtentComponent.onChangeCb(this.data.extents);
    }
  }

  /**
   * Met à jour la liste des dépôts (i.e "étude" dans Cupidon) et éventuellement la traçabilité
   * @param projects Liste des dépôts
   */
  private _setProjectsList(projects: Project[]) {
    this.projects = projects;
  }

  /**
   * Enregistre la donnée et reviens à la liste des données
   */
  public save(askPublication:boolean = false, afterSaveOk?: (value: any) => void, afterSaveKo?: (error: any) => void) {
    this._loader.show();
    if (this.isDuplication){
      this.data.id = null;
    }
    _.each(this.filesToUpload, file => {
      let res:OnlineResource = new OnlineResource();
      res.name = file.label;
      res.url = file.url;
      res.protocol = Constants.FILE_RESOURCE_PROTOCOL;
      this.data.files.push(res);
    });

    if (this.keywords) {
      this.data.freeKeywords = this.keywords;
    } else {
      this.data.freeKeywords = [];
    }
    this.data.addClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME, this.keywords, true);
    this.data.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME, this.data.thematics, true);

    this.data.language = this.language;

    if (!afterSaveOk) {
      afterSaveOk = result => {
        this._tracker.eventTrack.next({
          action: this.isNew ? "Ajout d'une donnée" : this.isDuplication ? "Duplication d'une donnée" : "Modification d'une donnée",
          properties: {
            category: this.data.name
          }
        });
        this._loader.hide();
        if (result && result.id) {
          if (this.isNew || this.isDuplication) {
            this._toastr.success($localize`La donnée ${this.data.name} a été créée avec succès`);
            this.session.setInitPageParams({ id: result.id, type: 'data' });
          }
          else {
            this._toastr.success($localize`La donnée ${this.data.name} a été modifiée avec succès`);
          }
          let returnRoute = this.isNew ? `../${result.id}` : this.isDuplication ? `../../${result.id}` : '..';
          this._router.navigate([returnRoute], { relativeTo: this._route });
        }
      };
    }

    if (!afterSaveKo) afterSaveKo = error => console.error(error);

    this._dataService.saveData(this.data, this.filesToUpload, askPublication).subscribe(afterSaveOk, afterSaveKo);
  }


  public saveDraft(event:any):void {
    event.preventDefault();
    this.formSubmitted = true;
    if (this._checkMinimalFields()) this.save(false);
  }


  public saveAndAskPublication(event:any):void {
    event.preventDefault();
    this.formSubmitted = true;
    if (this._checkAllFields()) this.save(true);
  }


  /**
   * Met à jour une date du formulaire en fonction du nouveau format imposé par la langue choisie.
   * @param date la valeur de la date en tant que chaîne de caractères.
   * @returns la langue au nouveau format.
   */
  public changeDateFormat(date:string, oldLocale:string, newLocale:string):string {
    if (!date) return "";

    let oldFormat = this.localeCalendarFormats[oldLocale].formatMoment;
    let newFormat = this.localeCalendarFormats[newLocale].formatMoment;
    if (!oldFormat || !newFormat) return "";

    return moment(date, oldFormat).format(newFormat);
  }

  /**
   * Ajoute un lien ou service
   */
  public addOnlineResource() {
    this.data.onlineResources.push(new OnlineResource());
  }

  /**
   * Supprime un lien ou service
   * @param index emplacement de l'objet
   */
  public removeOnlineResource(index: number) {
    this.data.onlineResources.splice(index, 1);
  }

  /**
   * Ajoute une emprise
   */
  public addExtent() {
    let extent = new Extent();
    extent.westBoundLongitude = null;
    extent.eastBoundLongitude = null;
    extent.northBoundLatitude = null;
    extent.southBoundLatitude = null;
    this.data.extents.push(extent);
    this.describedExtentComponent.onChangeCb(this.data.extents);
    this.extentsDescriptionTypes.push("place");
  }

  /**
   * Supprime une emprise
   */
  public removeExtent(index: number) {
    this.data.extents.splice(index, 1);
    this.describedExtentComponent.updateBBoxes();
    this.describedExtentComponent.onChangeCb(this.data.extents);
  }

  /**
   * Ajoute un contact
   */
  public addContact() {
    this.data.contacts.push(new Contact());
  }

  /**
   * Supprime un contact
   * @param index emplacement de l'objet
   */
  public removeContact(index: number) {
    this.data.contacts.splice(index, 1);
    this.checkPointsOfContacts(null);
  }

  /**
   * Ajoute un identifiant au contact
   * @param contact
   */
  public addIdentifierToContact(contact: Contact) {
    contact.contactIdentifiers.push(new ContactIdentifier());
  }

  /**
   * Supprime un identifiant du contact
   * @param contact
   * @param index emplacement de l'objet
   */
  public removeIdentifierFromContact(contact: Contact, index: number) {
    contact.contactIdentifiers.splice(index, 1);
  }

  /**
   * Vérifie si on peut encore supprimer des "pointOfContact"
   * @param contact Contact
   */
  public checkPointsOfContacts(contact:Contact) {
    let pointOfContacts = _.filter(this.data.contacts, { role: "pointOfContact" });
    this.canDeletePointOfContact = (pointOfContacts.length > 2);

    if (!!contact && (this.oldValueRole === 'funder' || contact.role === 'funder')) this.clearOrganization(contact);
  }

  /**
   * Stockage de l'actuelle valeur du champ Rôle d'un contact (pour gérer la réinitialisation du champ Organisation si besoin)
   * @param contact Contact
   */
  public onFocusRole(contact:Contact) {
    this.oldValueRole = !!contact ? contact.role : null;
  }

  /**
   * Lance la recherche des organisations (via ROR)
   * @param event Evénement contenant la saisie
   * @param contact Contact
   */
  public searchOrganization(event, contact:Contact) {
    if (!!event && !!event.query && event.query.length >= 3) {
      this._rorService.searchObs(event.query).subscribe(results => this.organizationResults = results);
    } else this.organizationResults = [];
  }

  /**
   * Réinitialise l'organisation du contact
   * @param contact Contact
   */
  public clearOrganization(contact:Contact) {
    if (!!contact) {
      contact.organisation = null;
    }
  }

  /**
   * Accepte la publication de la métadonnée.
   * @param event : the click event of the button, prevented to avoid submitting the form.
   */
  public acceptPublication(event:any):void {
    event.preventDefault();

    if (!this.projectIsPublished){
      const modalRef = this._modalService.open(DataValidatorModalComponent, { windowClass: "confirm-modal" });
      modalRef.componentInstance.title = $localize`Demande de publication d'un jeu de données`;
      modalRef.componentInstance.message = $localize`Veuillez d'abord accepter la publication du dépôt au préalable`;
    }else{
      if (this._checkAllFields()) {
        const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
        modalRef.componentInstance.title = $localize`Validation de la publication d'un jeu de données`;
        modalRef.componentInstance.message = $localize`Voulez-vous vraiment accepter la publication du jeu de données "${this.data.name}"? Celui-ci sera publié, puis un DOI lui sera automatiquement attribué.`;
        modalRef.componentInstance.confirmClass = "btn-success";
        modalRef.componentInstance.confirmText = $localize`Accepter la publication`;
        modalRef.result.then(() => {
          this._loader.show();

          this.save(
            true,
            () => this._statusService.acceptPublication(this.data, this.language),
            (error: any) => throwError(error)
          );
        });
      }
    }
  }


  /**
   * Refuse la publication de la métadonnée.
   * @param event : the click event of the button, prevented to avoid submitting the form.
   */
  public refusePublication(event:any):void {
    event.preventDefault();
    const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
    modalRef.componentInstance.title = $localize`Refuser de la publication d'un jeu de données`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment refuser la publication du jeu de données "${this.data.name}"?`;
    modalRef.componentInstance.inputLabel = $localize`Message précisant l'objet du refus`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Refuser la publication`;
    modalRef.result.then((message:string) => {
      this._loader.show();
      this._statusService.refusePublication(this.data, message);
    });
  }


  /**
   * Optimisation pour le ngFor
   * @param i
   * @param group
   */
  public trackById(group) {
    return group.id;
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackByValue(item) {
    return item.value;
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   * @param type - Type de donnée à autocompléter
   */
  public formListAutocomplete(event, type: string): void {
    switch (type) {
      case 'categories':
      case 'dataPoles':
        this._registryService.getLexiques(type, this.language, null, null, event!=null ? event.query : "", {});
        break;
      case 'keywords':
        if (this.data && this.data.project && this.data.project.dataPoles)
          this._registryService.getLexiques(type, this.language, this.data.project.dataPoles, null, event!=null ? event.query : "", {});
        else
          this._registryService.getLexiques(type, this.language, null, null, event!=null ? event.query : "", {});
        break;
      case 'thematics':
        {
          if (this.data && this.data.project && this.data.project.thematics)
            this._registryService.getLexiques(type, this.language, null, this.data.project.thematics, event!=null ? event.query : "", {});
          else
            this._registryService.getLexiques(type, this.language, null, null, event!=null ? event.query : "", {});
        break;
      }
    }
    // Uncomment code below to activate minimal length of keywords autocomplete
    /* if(type=="keywords" && event.query.length<3) {
      let autocompleteElt = this._elementRef.nativeElement.querySelector("#data-keywords-autocomplete");
      if(autocompleteElt!=null) autocompleteElt.classList.add("hide-load-icon");
      return;
    } else {
      let autocompleteElt = this._elementRef.nativeElement.querySelector("#data-keywords-autocomplete");
      if(autocompleteElt!=null) autocompleteElt.classList.remove("hide-load-icon");
    }
    this._registryService.getLexiques(type, this.language, event!=null ? event.query : "", {}); */
  }

  /**
   * Affiche la liste complète des suggestions d'une liste quand un élément
   * de type p-autocomplete reçoit le focus
   * @param autocomplete Element p-autocomplete du DOM ayant reçu le focus
   * @param type type de liste de propositions
   */
  public autocompleteFocus(autocomplete,type:string) {
    if(autocomplete==null || autocomplete==undefined) return;
    switch(type) {
      case "thematics":
        autocomplete._suggestions = this.thematicsSuggestions;
        break;
      case "keywords":
        autocomplete._suggestions = this.keywordsSuggestions;
        break;
    }
    if(autocomplete._suggestions.length > 0) autocomplete.show();
  }

  /**
   * Fonction permettant de supprimer les duplicatas dans la liste des thématiques/mots-clés
   * si certains ont été importés d'un dépôt
   * @param type Permet de savoir quel input a appelé cette fonction
   */
  public autocompleteSelected(type:string) {
    if(type==="thematics") {
      let thematicsWithoutDuplicate:Registry[] = [];
      _.each(this.data.thematics , thematic => {
        if(thematicsWithoutDuplicate.filter(t=>t.id==thematic.id).length==0) thematicsWithoutDuplicate.push(thematic);
      })
      this.data.thematics = thematicsWithoutDuplicate;
    }
    else if(type==="keywords") {
      let keywordsWithoutDuplicate:Registry[] = [];
      _.each(this.keywords , keyword => {
        if(keywordsWithoutDuplicate.filter(k=>k.id==keyword.id).length==0) keywordsWithoutDuplicate.push(keyword);
      })
      this.keywords = keywordsWithoutDuplicate;
    }
  }

  /**
   * On supprime la date d'embargo si on annule l'embargo
   */
  public changeEmbargoStatus() {
    if (!this.data.embargo) {
      this.data.releasedDate = null;
      this.minReleasedDate = null;
      this.maxReleasedDate = null;
    } else {
      this.minReleasedDate = new Date();
      this.maxReleasedDate = moment(new Date()).add(2, 'y').toDate();
    }
  }

  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case 'dataPoles':
        this.allDataPoles = event.datas;
        break;
      case 'thematics':
        this.thematicsSuggestions = event.datas;
        if(this.data && this.data.project && this.data.project.thematics) {
          _.each(this.data.project.thematics, thematic => {
            this.thematicsSuggestions.push(thematic);
          });
        }
        if(this.data!=undefined && this.data!=null) {
          _.each(this.thematicsSuggestions , thematic => {
            if(this.thematicsIdentifiersStorage.includes(thematic.id) && this.data.thematics.filter(t=>t.id==thematic.id).length==0) this.data.thematics.push(thematic);
          });
        }
        break;
      case 'keywords' :
        this.keywordsSuggestions = event.datas;
        if (!!this.data) {
          _.each(this.keywordsSuggestions , suggestion => {
            let keyword = this.keywords.find(k => k.id == suggestion.id);
            if (!!keyword) {
              keyword.label = suggestion.label;
            } else if (!!this.keywordsIdentifiersStorage.find(id => id == suggestion.id)) {
              this.keywords.push(suggestion);
            }
          });
        }
        break;
      case 'geonames':
        this._updateGeonames(event.datas);
        break;
    }
  }

  /**
   * Recharge les registres suite à un changment de langue
   */
  private _reloadRegistries() {
    this._registryService.getLexiques("dataPoles",this.language, null, null);
    this._registryService.getLexiques("thematics",this.language, null, this.data.project.thematics);
    this._registryService.getLexiques("keywords", this.language, this.data.project.dataPoles, null, "");
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User):void {
    if(this.isNew) return;
    this.isOwner = this.session.hasRight(this.dataId,'owner');
    this.isEditor = this.session.hasRight(this.dataId,'editor');
    let roles:Role[] = this.session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isOwner && !this.isEditor && !this.isAdmin && !this.isModerator) this._router.navigate(["/home"]);
    this._loader.hide();
  }

  /**
   * Vérifie que les champs strictement nécessaires sont présents.
   */
  private _checkMinimalFields():boolean {
    let invalidFields:HTMLElement[] = this._checkCommonFields();

    // Scroll de l'écran vers la première erreur
    if (invalidFields.length > 0) invalidFields[0].scrollIntoView({ behavior: "smooth" });

    return invalidFields.length === 0;
  }

  /**
   * Vérifie que tous les champs nécessaires sont présents.
   */
  private _checkAllFields():boolean {
    let invalidFields:HTMLElement[] = this._checkCommonFields(),
      formatRegExp = this.localeCalendarRegExps[this.locale],
      format = this.localeCalendarFormats[this.locale].format;

    // Description
    this._metadataService.addFieldRequiredError(invalidFields, this.descField.control, this.data.description, 'data-description');

    // Thematics
    this._metadataService.addFieldMultiRequiredError(invalidFields, this.thematicsField.control, this.data.thematics, 'data-thematics-autocomplete');

    // Keywords
    this._metadataService.addFieldMultiRequiredError(invalidFields, this.keywordsField.control, this.keywords, 'data-keywords-autocomplete');

    // Files
    if (!this.filesField.control.errors) {
      if (!this.filesToUpload || this.filesToUpload.length === 0 || !this.filesToUpload.find(file => !file.deleted)) {
        this.filesField.control.setErrors(Constants.errorOptions.required);
      }

      let filesElt:HTMLElement|null = document.getElementById('data-files-drop-zone');
      if (!!filesElt && !!this.filesField.control.errors) invalidFields.push(filesElt);
    }

    // Legal constraints
    this._metadataService.addFieldRequiredError(invalidFields, this.legalConstraintsField.control, this.data.legalConstraints, 'data-constraints');

    // Embargo
    if (this.data.embargo === null) {
      this.embargoField.control.setErrors(Constants.errorOptions.required);
      let elt:HTMLElement|null = document.getElementById('data-embargo');
      if (!!elt) invalidFields.push(elt);
    }

    // Released date
    if (!!this.data.embargo && !!this.data.releasedDate) {
      this.checkReleasedDate(false);

      let releasedDateElt:HTMLElement|null = document.getElementById('data-released-date');
      if (!!releasedDateElt && !!this.releasedDateField.control.errors) invalidFields.push(releasedDateElt);
    }

    // Spatial extents
    this._metadataService.addFieldMultiRequiredError(invalidFields, this.spaceExtentField.control, this.data.extents, 'data-extent');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.westBoundLongitudeFields._results, 'data-west-bound-longitude-');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.eastBoundLongitudeFields._results, 'data-east-bound-longitude-');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.northBoundLatitudeFields._results, 'data-north-bound-latitude-');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.southBoundLatitudeFields._results, 'data-south-bound-latitude-');

    // Lineage
    this._metadataService.addFieldRequiredError(invalidFields, this.lineageField.control, this.data.lineage, 'data-lineage');

    /* Contacts */
    // Contacts last names
    this._metadataService.addFieldEachPatternError(invalidFields, this.contactLastNameFields._results, 'contact-lastname-', Constants.regExps.name);

    // Contacts first names
    this._metadataService.addFieldEachPatternError(invalidFields, this.contactFirstNameFields._results, 'contact-firstname-', Constants.regExps.name);

    // Contacts mails
    this._metadataService.addFieldEachPatternError(invalidFields, this.contactEmailFields._results, 'contact-email-', Constants.regExps.mail);

    // Contacts organisations
    this._metadataService.addFieldEachRequiredError(invalidFields, this.contactOrganisationFields._results, 'contact-organisation-');

    // Contacts roles
    this._metadataService.addFieldEachRequiredError(invalidFields, this.contactRoleFields._results, 'contact-role-');

    // Identifiers types
    this._metadataService.addFieldEachRequiredError(invalidFields, this.contactIdentifierTypeFields._results, 'contact-identifier-type-');

    // Identifiers
    this._metadataService.addFieldEachRequiredError(invalidFields, this.contactIdentifierFields._results, 'contact-identifier-');
    /* End Contacts */

    /* Associated resources */
    // Associated resources URLs
    this._metadataService.addFieldEachPatternError(invalidFields, this.dataUrlFields._results, 'data-or-url-', Constants.regExps.urIHttpOrHttps);

    // Associated resources types
    this._metadataService.addFieldEachRequiredError(invalidFields, this.dataUrlProtocolFields._results, 'data-or-protocol-');

    // Associated resources names
    this._metadataService.addFieldEachRequiredError(invalidFields, this.dataUrlNameFields._results, 'data-or-name-');
    /* End Associated resources */

    // Creation date
    this.checkCreationDate(false, true);

    let creationDateElt:HTMLElement|null = document.getElementById('data-creation-date');
    if (!!creationDateElt && !!this.creationDateField.control.errors) invalidFields.push(creationDateElt);

    // Files
    if (!!this.filesToUpload && !!this.filesToUpload.find(file => !file.deleted && !!file.file && !!file.file?.size && file.file?.size > Constants.FILE_SIZE_LIMIT_VALUE)) {
      this.filesField.control.setErrors(Constants.errorOptions.size);
    }

    let filesElt:HTMLElement|null = document.getElementById('data-files-drop-zone');
    if (!!filesElt && !!this.filesField.control.errors) invalidFields.push(filesElt);

    // Time extent start
    if (!!this.data.temporalExtentStart) {
      if (!formatRegExp.test(this.data.temporalExtentStart)) this.timeExtentStartField.control.setErrors(Constants.errorOptions.format);
      else {
        if (!this.data.temporalExtentEnd) this.timeExtentEndField.control.setErrors(Constants.errorOptions.bothDates);
        else {
          let startDate = !!this.data.temporalExtentStart ? moment(this.data.temporalExtentStart, format).toDate() : null;
          let endDate = !!this.data.temporalExtentEnd ? moment(this.data.temporalExtentEnd, format).toDate() : null;

          if (!!startDate) {
            if (!!endDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors(Constants.errorOptions.range);
          } else if (!endDate) this.timeExtentEndField.control.setErrors(Constants.errorOptions.bothDates);
        }
      }
    } else this.timeExtentStartField.control.setErrors(Constants.errorOptions.required);

    let timeExtentStartElt:HTMLElement|null = document.getElementById('data-time-extent-start');
    if (!!timeExtentStartElt && (!!this.timeExtentStartField.control.errors || !!this.timeExtentEndField.control.errors)) invalidFields.push(timeExtentStartElt);

    // Time extent end
    if (!!this.data.temporalExtentEnd) {
      if (!formatRegExp.test(this.data.temporalExtentEnd)) {
        this.timeExtentEndField.control.setErrors(Constants.errorOptions.format);
      } else {
        if (!this.data.temporalExtentStart) this.timeExtentStartField.control.setErrors(Constants.errorOptions.bothDates);
        else {
          let startDate = !!this.data.temporalExtentStart ? moment(this.data.temporalExtentStart, format).toDate() : null;
          let endDate = !!this.data.temporalExtentEnd ? moment(this.data.temporalExtentEnd, format).toDate() : null;

          if (!!endDate) {
            if (!!startDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors(Constants.errorOptions.range);
          } else if (!startDate) this.timeExtentStartField.control.setErrors(Constants.errorOptions.bothDates);
        }
      }
    } else this.timeExtentEndField.control.setErrors(Constants.errorOptions.required);

    let timeExtentEndElt:HTMLElement|null = document.getElementById('data-time-extent-end');
    if (!!timeExtentEndElt && !!this.timeExtentEndField.control.errors) invalidFields.push(timeExtentEndElt);

    if (!!timeExtentStartElt && !this.timeExtentStartField.control.errors && !!timeExtentEndElt && !this.timeExtentEndField.control.errors) {
      this.checkTemporalExtentDates(null, null, false);

      if (!!this.timeExtentStartField.control.errors) invalidFields.push(timeExtentStartElt);
      if (!!this.timeExtentEndField.control.errors) invalidFields.push(timeExtentEndElt);
    }

    // Scroll de l'écran vers la première erreur
    if (invalidFields.length > 0) invalidFields[0].scrollIntoView({ behavior: "smooth" });

    return invalidFields.length === 0;
  }

  private _checkCommonFields():HTMLElement[] {
    let invalidFields:HTMLElement[] = [], reinitErrors = field => field.control.setErrors(null);

    // Reinit all field errors
    this.nameField.control.setErrors(null);
    this.projectField.control.setErrors(null);
    this.creationDateField.control.setErrors(null);
    this.timeExtentStartField.control.setErrors(null);
    this.timeExtentEndField.control.setErrors(null);
    this.descField.control.setErrors(null);
    this.thematicsField.control.setErrors(null);
    this.keywordsField.control.setErrors(null);
    this.filesField.control.setErrors(null);
    this.legalConstraintsField.control.setErrors(null);
    this.embargoField.control.setErrors(null);
    if (!!this.data.embargo) this.releasedDateField.control.setErrors(null);
    this.spaceExtentField.control.setErrors(null);
    this.lineageField.control.setErrors(null);

    this.westBoundLongitudeFields._results.forEach(reinitErrors);
    this.eastBoundLongitudeFields._results.forEach(reinitErrors);
    this.northBoundLatitudeFields._results.forEach(reinitErrors);
    this.southBoundLatitudeFields._results.forEach(reinitErrors);
    this.filesField.control.setErrors(null);

    this.contactLastNameFields._results.forEach(reinitErrors);
    this.contactFirstNameFields._results.forEach(reinitErrors);
    this.contactEmailFields._results.forEach(reinitErrors);
    this.contactOrganisationFields._results.forEach(reinitErrors);
    this.contactRoleFields._results.forEach(reinitErrors);
    this.contactIdentifierTypeFields._results.forEach(reinitErrors);
    this.contactIdentifierFields._results.forEach(reinitErrors);

    this.dataUrlFields._results.forEach(reinitErrors);
    this.dataUrlProtocolFields._results.forEach(reinitErrors);
    this.dataUrlNameFields._results.forEach(reinitErrors);

    // Name
    this._metadataService.addFieldRequiredError(invalidFields, this.nameField.control, this.data.name, 'data-name');

    // Repository
    this._metadataService.addFieldRequiredError(invalidFields, this.projectField.control, this.data.projectId, 'data-project');

    /* Contacts */
    let roleFields = this.contactRoleFields._results;
    for (let i:number = 0; i < roleFields.length; i++) {
      if (roleFields[i].model === "pointOfContact"){
        // last name
        if (this.contactLastNameFields && this.contactLastNameFields._results && this.contactLastNameFields._results.length > i && this.contactLastNameFields._results[i]){
          let lastNameField = this.contactLastNameFields._results[i];
          this._metadataService.addFieldPatternError(invalidFields, lastNameField.control, lastNameField.model, 'contact-lastname-'+ i, Constants.regExps.name);
        }
        // first name
        if (this.contactFirstNameFields && this.contactFirstNameFields._results && this.contactFirstNameFields._results.length > i && this.contactFirstNameFields._results[i]){
          let firstNameField = this.contactFirstNameFields._results[i];
          this._metadataService.addFieldPatternError(invalidFields, firstNameField.control, firstNameField.model, 'contact-firstname-'+ i, Constants.regExps.name);
        }
        // mail
        if (this.contactEmailFields && this.contactEmailFields._results && this.contactEmailFields._results.length > i && this.contactEmailFields._results[i]){
          let emailField = this.contactEmailFields._results[i];
          this._metadataService.addFieldPatternError(invalidFields, emailField.control, emailField.model, 'contact-email-'+ i, Constants.regExps.mail);
        }
        // organisation
        if (this.contactOrganisationFields && this.contactOrganisationFields._results && this.contactOrganisationFields._results.length > i && this.contactOrganisationFields._results[i]){
          let organisationField = this.contactOrganisationFields._results[i];
          this._metadataService.addFieldRequiredError(invalidFields, organisationField.control, organisationField.model, 'contact-organisation-'+ i);
        }
        // role
        let pointOfContactRoleField = this.contactRoleFields._results[i];
        this._metadataService.addFieldRequiredError(invalidFields, pointOfContactRoleField.control, pointOfContactRoleField.model, 'contact-role-'+ i);
      }
    }
    /* End Contacts */

    /* Associated resources */
    // Associated resources URLs
    for (let i:number = 0; i < this.dataUrlFields._results.length; i++) {
      let control = this.dataUrlFields._results[i].control;
      if (!!control.value && !Constants.regExps.urIHttpOrHttps.test(control.value)) {
        control.setErrors(Constants.errorOptions.pattern);
        invalidFields.push(document.getElementById(control.name));
      }
    }
    /* End Associated resources */

    return invalidFields;
  }

  private _initLicenses(datas) {
    _.each(datas, data  => {
      let license: License = new License();
      license.licenseId = data.licenseId;
      license.reference = data.reference;
      this.useConstraints.push(license);
    })
  }

  updateLicense() {
    _.each(this.useConstraints, useConstraint  => {
      if (this.licenseId == useConstraint.licenseId)
        this.data.legalConstraints = useConstraint;
    })
  }

  /**
   * Indique si l'utilisateur peut télécharger les fichiers du JDD
   */
  private canDownloadDatasetsFiles() {
    if (this.data && this.data.id){
      this._dataService.canDownloadFiles(this.data.id).subscribe(result => {
        this.filesDownloadable = result;
      });
    }
  }

  public launchAnnotatorSearch():void {
    if (!this.data.name && !this.data.description) {
      this._toastr.error('Veuillez renseigner le titre ou le résumé pour la suggestion de mots-clés additionnels');
      return;
    }

    const modalRef = this._modalService.open(AnnotatorModalComponent, { size: 'lg', windowClass: "confirm-modal" });
    modalRef.componentInstance.ontologies = this.setOntologies(!!this.data.project ? this.data.project.dataPoles : []);
    modalRef.componentInstance.searchText = (this.data.name ?? '') + ' ' + (this.data.description ?? '');

    modalRef.result.then((results:AnnotatorResult[]) => {
      results.forEach(r => {
        let newRegistry = new Registry();
        newRegistry.id = r.id;
        newRegistry.label = r.label;

        this.keywords.push(newRegistry);
      });

      this.autocompleteSelected("keywords");
    });
  }

  /**
   * Détermination des ontologies dans lesquelles effectuer la recherche des mots-clés additionnels
   * @param dataPoles Liste des pôles de données du dépôt
   * @returns Liste des ontologies dans lesquelles effectuer la recherche des mots-clés additionnels
   */
  private setOntologies(dataPoles:Registry[]):string[] {
    let ontologies:string[] = Object.assign([], Constants.ONTOLOGIES_BY_IR.common.ontologies),
      idsDataPoles:string[] = dataPoles.map(r => r.id),
      dataPolesWithSubRegistry:DataPole[] = this.allDataPoles.filter(dp => !!idsDataPoles.find(id => id === dp.id)),
      idsDataPolesWithSubRegistry:string[] = idsDataPoles.concat(...dataPolesWithSubRegistry.map(dp => dp.subRegistry.map(r => r.id))),
      listPoles:string[] = Object.keys(Constants.ONTOLOGIES_BY_IR).filter(p => 'common' !== p);

    // Ajout des ontologies spécifiques aux pôles présents dans le dépôt
    listPoles.forEach(p => {
      if (!!idsDataPolesWithSubRegistry.find(id => id === Constants.ONTOLOGIES_BY_IR[p].pole)) {
        ontologies.push(...Constants.ONTOLOGIES_BY_IR[p].ontologies);
      }
    });

    // Si aucun ajout précédemment, ajout de toutes les ontologies
    if (ontologies.length === Constants.ONTOLOGIES_BY_IR.common.ontologies.length) {
      listPoles.forEach(p => ontologies.push(...Constants.ONTOLOGIES_BY_IR[p].ontologies));
    }

    return ontologies;
  }
}
