import * as _ from 'lodash';

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterService, FilterEvent } from '../../services/filter.service';

@Component({
  selector: 'vigirisks-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnDestroy {
  /**
   * Nombre de numéros de page max à afficher
   */
  @Input() public maxSize: number;

  /**
   * Taille des données filtrées
   */
  public collectionSize: number = 0;

  /**
   * Nombre d'éléments par page
   */
  public pageSize: number = 12;

  /**
   * Page courante
   */
  public page: number = 1;

  /**
   * Souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(private _filterService: FilterService) { }

  public ngOnInit() {
    this._subs.add(this._filterService.tableFiltered$.subscribe(event => this.updateCurrentPage(event)));

    this._filterService.updateFilters({});
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public onPageChange(event) {
    this._filterService.updateFilters({ page: event });
  }

  /**
   * Met à jour les paramètres de pagination en fonction de la configuration de filtrage
   */
  private updateCurrentPage(event: FilterEvent) {
    if (this.page != event.page) {
      this.page = event.page;
    }
    if(_.isFinite(event.itemsPerPage)) {
      this.pageSize = event.itemsPerPage;
    }
    if(_.isFinite(event.collectionSize)) {
      this.collectionSize = event.collectionSize;
    }
  }
}
