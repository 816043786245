<section id="help" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="white-with-border" i18n>Aide</h1>
  </div>
  <div class="page-content">
    <div class="subcontent">
      <a href="/assets/documents/GuideUser_EaSyData.pdf" target="_blank">
        <img id="publication-guide-image" src="/assets/images/guide_publication_EasyData.png"/>
      </a>
    </div>
  </div>
</section>
