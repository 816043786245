import { Component, OnInit, OnDestroy } from "@angular/core";
import { Group } from "src/app/models";
import { UserService, UtilsService, LoaderService } from "src/app/services";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "src/app/components/modals";
import { ToastrService } from "ngx-toastr";
import { UserNameModalAdminComponent } from "../../modals";
import { Constants } from "src/app/constants";

@Component({
  templateUrl: 'group-manage.component.html'
})
export class GroupManageAdminComponent implements OnInit, OnDestroy {
  /**
   * Groupe à afficher
   */
  public group: Group;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _utils: UtilsService,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.group$.subscribe(group => this._initGroup(group)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initGroupFromParams(params)));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Demande confirmation et supprimer le groupe affiché
   */
  public deleteGroup() {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un groupe`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce groupe : ${this.group.name} ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._userService.deleteGroup(this.group)
        .subscribe(() => {
          this._toastr.success($localize`Le groupe ${this.group.name} a été supprimé avec succès`);
          this._router.navigate(['../../'], { relativeTo: this._route });
          this._loader.hide();
        });
    }, () => null);
  }

  /**
   * Ajoute un utilisateur au groupe
   */
  public addUser() {
    const modalRef = this._modalService.open(UserNameModalAdminComponent, { windowClass: "confirm-modal" });

    modalRef.result.then(user => {
      if (user) {
        if (this.group.users.indexOf(user) < 0) {
          this._loader.show();
          this._userService.addUserToGroup(this.group, user)
            .subscribe(() => {
              this._toastr.success($localize`L'utilisateur ${user} a été ajouté au groupe avec succès`);
              this.group.users.push(user);
              this.group.users.sort();
              this._loader.hide();
            });
        } else {
          this._toastr.error($localize`L'utilisateur ${user} fait déjà partie de ce groupe`, null, Constants.toastrErrorOptions);
        }
      }
    }, () => null);
  }

  /**
   * Demande confirmation et retire un utilisateur du groupe
   */
  public removeUser(user: string) {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Retirer un utilisateur du groupe`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment retirer l'utilisateur ${user} du groupe ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._userService.removeUserFromGroup(this.group, user)
        .subscribe(() => {
          this._toastr.success($localize`L'utilisateur ${user} a été retiré du groupe avec succès`);
          this.group.users.splice(this.group.users.indexOf(user), 1);
          this._loader.hide();
        });
    }, () => null);
  }

  /**
   * Initialise le groupe à afficher
   * @param group - groupe reçu du serveur
   */
  private _initGroup(group: Group) {
    this.group = group;
    this._loader.hide();
  }

  /**
   * Demande la récupération du groupe
   * @param params - paramètres de l'url
   */
  private _initGroupFromParams(params) {
    if (params && params.length > 0) {
      this._loader.show();
      this._userService.getGroup(params[0]);
    }
  }
}
