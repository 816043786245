import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  templateUrl: './records.component.html'
})
export class RecordsAdminComponent implements OnInit, OnDestroy {

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

}
