import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AnnotatorResult } from "src/app/models";
import { RegistryService } from "src/app/services/registry.service";

@Component({
  templateUrl: './annotator-modal.component.html'
})
export class AnnotatorModalComponent implements OnInit {

  @Input() ontologies:string[];

  @Input() searchText:string;

  public annotatorResults:AnnotatorResult[] = [];

  private annotatorSelected:AnnotatorResult[];

  constructor(
    public modal:NgbActiveModal,
    private registryService:RegistryService
  ) { }

  ngOnInit():void {
    this.annotatorSelected = [];
    this.registryService.annotatorSearch(this.ontologies, this.searchText).subscribe(results => this.annotatorResults = results);
  }

  updateSelection(event, annotator:AnnotatorResult):void {
    if (event.target.checked) {
      if (!this.annotatorSelected.find(a => a == annotator)) {
        this.annotatorSelected.push(annotator);
      }
    } else {
      let index = this.annotatorSelected.findIndex(a => a == annotator);
      if (index > -1) {
        this.annotatorSelected.splice(index, 1);
      }
    }
  }

  save():void {
    this.modal.close(this.annotatorSelected);
  }
}