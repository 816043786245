import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Group, Role, UserRole } from '../models';
import { Uris } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  /**
   * Source de la liste des emails
   */
  private _emailsSource = new Subject<string[]>();

  /**
   * Source de la liste des groupes
   */
  private _groupsSource = new Subject<Group[]>();

  /**
   * Source d'un groupe
   */
  private _groupSource = new Subject<Group>();

  /**
   * Source de la liste des roles
   */
  private _rolesSource = new Subject<Role[]>();

  /**
   * Source d'un role
   */
  private _roleSource = new Subject<Role>();

  /**
   * Source de la liste des roles
   */
  private _userRolesSource = new Subject<UserRole[]>();

  /**
   * Source d'un role
   */
  private _userRoleSource = new Subject<UserRole>();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste d'emails
   */
  public emails$ = this._emailsSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de groupes
   */
  public groups$ = this._groupsSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un groupe
   */
  public group$ = this._groupSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de rôles
   */
  public roles$ = this._rolesSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un rôle
   */
  public role$ = this._roleSource.asObservable();
  
  /**
   * Observable qui envoie un event à chaque récupération d'une liste de rôles utilisateur
   */
  public userRoles$ = this._userRolesSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un groupe
   */
  public userRole$ = this._userRoleSource.asObservable();
  
  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Recherche des emails de users
   * @param searchText - email à chercher (peut être partiel)
   */
  public searchUsers(searchText: string = ""): void {
    this.searchUsersObs(searchText)
      .subscribe(users => this._emailsSource.next(users));
  }

  /**
   * Observable de recherche des emails de users
   * @param searchText - email à chercher (peut être partiel)
   */
  public searchUsersObs(searchText: string = ""): Observable<string[]> {
    return this._http.post<string[]>(Uris.USERS + 'search', { email: searchText });
  }
  
  /**
   * Observable des groupes auxquels l'utilisateur appartient
   * @param user Email de l'utilisateur
   */  
  public getUserGroups(userId: string): Observable<Group[]> {
    return this._http.get<Group[]>(Uris.USERS + userId + '/groups')
      .pipe(
        map(groups => groups.map(g => new Group().deserialize(g)))
      );
  }

  /**
   * Récupère la liste des groupes de droits
   */
  public getGroups() {
    this._http.get<Group[]>(Uris.GROUPS)
      .pipe(
        map(groups => groups.map(g => new Group().deserialize(g)))
      )
      .subscribe(groups => this._groupsSource.next(groups));
  }

  /**
   * Récupère un groupe
   * @param id - ID du groupe
   */
  public getGroup(id: number | string) {
    if (id === 'new') {
      let newGroup = new Group();
      newGroup.name = $localize`Nouveau groupe`;
      this._groupSource.next(newGroup);
    } else {
      this._http.get<Group>(Uris.GROUPS + id)
        .pipe(
          map(group => new Group().deserialize(group))
        )
        .subscribe(group => this._groupSource.next(group));
    }
  }

  /**
   * Enregistre un groupe
   * @param group - groupe à enregistrer
   */
  public saveGroup(group: Group): Observable<any> {
    let obs;
    if (group.id) {
      obs = this._http.put<any>(Uris.GROUPS + group.id, group.serialize());
    } else {
      obs = this._http.post<any>(Uris.GROUPS, group.serialize());
    }
    return obs;
  }

  /**
   * Supprimer un groupe
   * @param group - groupe à supprimer
   */
  public deleteGroup(group: Group): Observable<any> {
    return this._http.delete<any>(Uris.GROUPS + group.id);
  }

  /**
   * Retire un utilisateur d'un groupe
   * @param group Groupe duquel retirer l'utilisateur
   * @param user Email de l'utilisateur
   */
  public removeUserFromGroup(group: Group, user: string): Observable<any> {
    return this._http.delete<any>(Uris.GROUPS + group.id + '/users/' + user);
  }

  /**
   * Ajoute un utilisateur à un groupe
   * @param group Groupe auquel ajouter l'utilisateur
   * @param user Email de l'utilisateur
   */
  public addUserToGroup(group: Group, user: string): Observable<any> {
    return this._http.post<any>(Uris.GROUPS + group.id + '/users/' + user, {});
  }

  /**
   * Ajoute un utilisateur
   * @param user Email de l'utilisateur
   */
  public addAppUser(user: string): Observable<any> {
    return this._http.post<any>(Uris.USERS, user);
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  /**
   * Récupère la liste des rôles
   */
  public getRoles() {
    this._http.get<Role[]>(Uris.ROLES)
      .pipe(
        map(roles => roles.map(r => new Role().deserialize(r)))
      )
      .subscribe(roles => this._rolesSource.next(roles));
  }

  /**
   * Récupère un rôle
   
   * @param id - ID du rôle
   */
  public getRole(id: number | string) {
  this._http.get<Role>(Uris.ROLES + id)
   	.pipe(
        map(role => new Role ().deserialize(role))
      )
      .subscribe(role => this._roleSource.next(role));
  }
  
  /**
   * Récupère toutes les associations rôle-utilisateur
   */
  public getUserRoles(): void{  	
    this._http.get<UserRole[]>(Uris.ROLES + 'userRole')
      .pipe(
          map(roles => roles.map(ur => new UserRole().deserialize(ur)))
      )
      .subscribe(roles => this._userRolesSource.next(roles));
  }

  /**
   * Récupère une association rôle-utilisateur via son identifiant
   * @param id - ID de l'association rôle-utilisateur
   */
  public getUserRoleById(id: number|string):void {
    if (id === 'new') {
      let newUserRole = new UserRole();
      this._userRoleSource.next(newUserRole);
    } else {
      this._http.get<UserRole>(Uris.ROLES + 'userRole/' + id)
        .pipe(
          map(userRole => new UserRole().deserialize(userRole))
        )
        .subscribe(userRole => this._userRoleSource.next(userRole));
    }
  }
  

  /**
   * Récupère les associations rôle-utilisateur d'un utilisateur particulier via son adresse mail
   * @param email - adresse mail de l'utilisateur
   */
  public getUserRolesByEmail(email: string):Observable<any>{  	
    return this._http.get<UserRole[]>(Uris.ROLES + 'userRole/mail/' + email)
      .pipe(
          map(userRoles => userRoles.map(ur => new UserRole().deserialize(ur)))
      )
  }


  /**
   * Associe un nouveau rôle à un utilisateur ou en modifie un existant
   * @param userRole 
   * @returns le DTO de l'association rôle-utilisateur renvoyé par le back-end
   */
  public saveUserRole(userRole:UserRole):Observable<any> {
    if (userRole.id) return this._http.put<any>(Uris.ROLES + 'userRole/' + userRole.id, userRole.serialize());
    else return this._http.post<any>(Uris.ROLES + 'userRole', userRole.serialize());
  }


  /**
   * Retire un rôle à un utilisateur
   * @param userRole 
   * @returns
   */
  public deleteUserRole(userRole:UserRole): Observable<any> {
    return this._http.delete<any>(Uris.ROLES + 'userRole/' + userRole.id);
  }

}
