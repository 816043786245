<header id="header" class="public-header container-fluid">
  <nav class="navbar navbar-expand no-gutters align-items-center">
    <a *ngIf="logoName" class="navbar-brand text-center" routerLink="/public/home">
      <img src="/assets/images/EaSyData_sansfond_png.png" alt="">
    </a>
    <ul class="navbar-nav right-navbar ml-auto">
      <li class="nav-item">
        <a class="btn btn-icon nav-link" routerLink="/help">
          <i class="fa fa-question-circle"></i>
        </a>
      </li>
      <li class="nav-item separator" ngbDropdown placement="bottom-right">
        <ng-container *ngIf="session.currentUser==null">
          <a class="nav-link account" id="user-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" ngbDropdownToggle *ngIf="session.currentUser==null">
            <div>
              Non connecté
              <i class="fa fa-chevron-down"></i>
            </div>
            <i class="fa fa-user"></i>
          </a>
          <div aria-labelledby="user-dropdown" ngbDropdownMenu>
            <a class="dropdown-item" (click)="login()">
              <ng-container i18n>Se connecter</ng-container>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="session.currentUser!=null">
          <a class="nav-link account" id="user-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" ngbDropdownToggle>
            <div>
              {{session.currentUser.email}}
              <i class="fa fa-chevron-down"></i>
            </div>
            <i class="fa fa-user"></i>
          </a>
          <div aria-labelledby="user-dropdown" ngbDropdownMenu>
            <dl class="groups-container">
              <dt i18n>Mes groupes</dt>
              <dd>
                <ul>
                  <li *ngFor="let group of session.currentUser.groups; trackBy: trackById">
                    {{group.name}}
                  </li>
                </ul>
              </dd>
            </dl>
            <a class="dropdown-item" [routerLink]="['/account']">
              <i class="fa fa-user mr-2"></i>
              <ng-container i18n>Mon compte</ng-container>
            </a>
            <a class="dropdown-item" (click)="logout()">
              <i class="fa fa-power-off mr-2"></i>
              <ng-container i18n>Se déconnecter</ng-container>
            </a>
          </div>
        </ng-container>
      </li>
    </ul>
  </nav>
</header>