import { EntityMapper } from "./entity-mapper.model";

export class Status extends EntityMapper {

  /**
   * UUID de la métadonnée
   */
  metadataUuid:string = "";

  /**
   * Statut de la métadonnée (PROGRESS, PENDING, ACCEPTED, REFUSED)
   */
  value:string = "";

  /**
   * Type de métadonnée (PROJECT, DATA, LINK)
   */
  type:string = "";
  
  constructor() {
    super();
    this._mapperDefs = [
      { front: 'metadataUuid', back: 'metadataUuid' },
      { front: 'value', back: 'value' },
      { front: 'type', back: 'type' }
    ];
  }

}
