export class Uris {

  public static API_VERSION = "v1";
  public static PUBLIC_API_VERSION = "v1";

  public static APP_VERSION = `/back/public-api/${Uris.PUBLIC_API_VERSION}/application/version`;
  public static APP_ROLES = `/back/public-api/${Uris.PUBLIC_API_VERSION}/application/roles`;
  public static APP_KEYCLOAK_URL = `/back/public-api/${Uris.PUBLIC_API_VERSION}/application/keycloakUrl`;

  public static LOGOUT = `/back/api/users/logout`;

  public static PROJECTS = `/back/api/${Uris.API_VERSION}/metadata/projects/`;
  public static PUBLIC_PROJECTS = `/back/public-api/${Uris.PUBLIC_API_VERSION}/metadata/projects/`;

  public static LINKS = `/back/api/${Uris.API_VERSION}/metadata/links/`;
  public static PUBLIC_LINKS = `/back/public-api/${Uris.PUBLIC_API_VERSION}/metadata/links/`;

  public static DATAS = `/back/api/${Uris.API_VERSION}/metadata/datasets/`;
  public static PUBLIC_DATAS = `/back/public-api/${Uris.PUBLIC_API_VERSION}/metadata/datasets/`;

  public static RESOURCES = `/back/api/${Uris.API_VERSION}/metadata/`;
  public static PUBLIC_RESOURCES = `/back/public-api/${Uris.PUBLIC_API_VERSION}/metadata/`;

  public static STATUS = `/back/api/${Uris.API_VERSION}/metadata/status/`;

  public static MAIL_PUBLISH = `/back/api/${Uris.API_VERSION}/mail/publish`;
  public static MAIL_ACCEPT = `/back/api/${Uris.API_VERSION}/mail/accept`;
  public static MAIL_REFUSE = `/back/api/${Uris.API_VERSION}/mail/refuse`;

  public static USERS = `/back/api/${Uris.API_VERSION}/users/`;

  public static GROUPS = `/back/api/${Uris.API_VERSION}/groups/`;
  public static ROLES = `/back/api/${Uris.API_VERSION}/roles/`;

  public static PROXY = `/back/api/${Uris.API_VERSION}/proxy/`;

  public static RECORDS = `/back/api/${Uris.API_VERSION}/records/`;

  public static MAPS = `fakedata/maps.json`;

  public static REGISTRY_DATA_POLES = `/back/public-api/${Uris.API_VERSION}/registries/{language}/dataPoles`;
  public static REGISTRY_THEMATICS = `/back/public-api/${Uris.API_VERSION}/registries/{language}/thematics`;
  public static REGISTRY_KEYWORDS = `/back/public-api/${Uris.API_VERSION}/registries/{language}/keywords`;

  public static GEONAMES_BOUNDING_BOX = `/back/public-api/${Uris.API_VERSION}/geonames/boundingBox/`;
  public static GEONAMES_SEARCH = `/back/public-api/${Uris.API_VERSION}/geonames/search`;

  public static ROR_SEARCH = `/back/public-api/${Uris.API_VERSION}/ror/search`;
  public static ROR_SITE = `https://ror.org/search`;

  public static DATA_POLES_REGISTRY_URL = "https://terra-vocabulary.org/ncl/DataTerraRepositoryFairIncubator/_InfraRecherche";
  public static THEMATICS_REGISTRY_URL = "https://terra-vocabulary.org/ncl/DataTerraRepositoryFairIncubator/_Voc_thematique";
  public static KEYWORDS_REGISTRY_URL = "https://terra-vocabulary.org/ncl/DataTerraRepositoryFairIncubator/motsClefs";

  public static EARTH_PORTAL_ANNOTATOR_URL = `/back/public-api/${Uris.API_VERSION}/earthPortal/annotatorSearch?ontologies={ontologies}&text={searchText}`;
  public static EARTH_PORTAL_CLASSES_URL = `/back/public-api/${Uris.API_VERSION}/earthPortal/classesSearch/{ontology}`;

  public static LICENSES_REGISTRY = `/back/public-api/${Uris.API_VERSION}/licenses/`;

  public static ACTIVITY = `/back/api/${Uris.API_VERSION}/activity/`;
}
