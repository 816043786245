import { EntityMapper } from "./entity-mapper.model";

export class ContactIdentifier extends EntityMapper {

  /**
   * Type de l'identifiant (exemple: ORCID)
   */
   identifierType: string = "";

  /**
   * Identifiant (exemple: https://orcid.org/0000-0002-9993-5302)
   */
   identifier: string = "";

   /**
    * Indique si l'identifiant est cliquable
    */
   identifierClickable = false;

   constructor() {
     super();

     this._mapperDefs = [
      { front: "identifierType", back: "identifierType" },
      { front: "identifier", back: "identifier" }
    ];

    }

   public deserialize(json: any): this {
    super.deserialize(json);

    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    
    this.identifierClickable = !!pattern.test(this.identifier);

    return this;
   }

}
