<div class="map-container" #mapContainer>
  <autocomplete *ngIf="mapConfig.controls.goToDestination" [map]="map" (destinationChangeEvent)="goToDestination($event)" locale="{{locale}}"></autocomplete>
  <div class="bbox-help" *ngIf="isBboxSelection">
    <p i18n>
      PC : Ctrl + cliquer-glisser pour sélectionner l'emprise<br/>
      Mac : Cmd + cliquer-déposer pour sélectionner l'emprise
    </p>
  </div>

  <div class="layer-switcher" *ngIf="mapConfig.controls.layerSwitcher && displayLayerSwitcher" [@layerSwitcherAnimation]="(displayLayerSwitcher) ? 'open': 'close'">
    <div class="layer-switcher-header d-flex align-items-center">
      <h4 class="mr-auto" i18n>Liste des couches</h4>
      <div *ngIf="mapConfig.controls.backgroundSelector && currentBgLayers.length > 1" class="background-selector d-flex align-items-center mr-3">
        <label for="background-selector"> <ng-container i18n>Fond</ng-container>: </label>
        <select id="background-selector" class="ml-2 form-control" name="current-background" [(ngModel)]="currentBackground" (ngModelChange)="updateBackgroundLayer()">
          <option *ngFor="let bgLayer of currentBgLayers" [ngValue]="bgLayer">{{bgLayer.title}}</option>
        </select>
      </div>
      <button type="button" class="btn btn-icon" i18n-title title="Fermer la liste des couches" (click)="displayLayerSwitcher = false"> <em class="fa fa-arrow-right"></em> </button>
    </div>

    <p class="no-layers mb-0" *ngIf="!currentSelectedExtents && currentLayers.length === 0 && resultsLayers.length === 0" i18n>Aucune couche affichée</p>

    <h5 *ngIf="currentSelectedExtents" class="layerswitcher-section-header" i18n>Emprises choisies</h5>
    <div class="layer-switcher-rows-container position-relative">
      <div class="layer-switcher-row" *ngFor="let feature of currentSelectedExtents; trackBy: trackByIdx" id="result-row-{{feature.idx}}">
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-icon mx-1" i18n-title title="Aller à l'emprise" (click)="goToExtent(feature.extent)">
            <em class="fa fa-expand"></em>
          </button>
          <span class="title-result">{{feature.name}}</span>
          <button type="button" class="ml-auto btn btn-icon btn-close"
            i18n-title title="Supprimer la couche" (click)="removeBBOXExtent(feature)"> &times; </button>
        </div>
      </div>
    </div>

    <h5 *ngIf="resultsLayers.length > 0" class="layerswitcher-section-header" i18n>Couches de résultats</h5>
    <div class="layer-switcher-rows-container position-relative" dragula="RESULTS" [(dragulaModel)]="resultsLayers">
      <div class="layer-switcher-row" *ngFor="let layer of resultsLayers; trackBy: trackByIdx" id="result-row-{{layer.idx}}">
        <div class="d-flex align-items-center">
          <div class="btn btn-icon drag-handler" i18n-title title="Cliquer-glisser pour réordonner les couches" draggable>
            <em class="fa fa-grip-vertical"></em>
          </div>
          <button type="button" class="btn btn-icon mx-1" i18n-title title="Aller à l'emprise de la couche" (click)="goToExtent(layer.extent)"  [disabled]="layer.cantDisplay">
            <em class="fa fa-expand"></em>
          </button>
          <div class="form-check mx-1">
            <input type="checkbox" id="visible_{{layer.idx}}" class="form-check-input" [(ngModel)]="layer.visible" (ngModelChange)="toggleLayer(layer)" />
            <label for="visible_{{layer.idx}}" class="form-check-label" i18n-title title="Afficher/masquer la couche">&nbsp;</label>
          </div>
          <div class="slider-container mr-2">
            <ng5-slider [(value)]="layer.opacity" [options]="sliderOptions" (valueChange)="onOpacityChange(layer)"></ng5-slider>
          </div>
          <span class="title-result">{{layer.name}}</span>
          <button type="button" class="ml-auto btn btn-icon btn-close" i18n-title title="Supprimer la couche" (click)="removeLayer(layer, resultsLayers)"> &times; </button>
        </div>
      </div>
    </div>

    <h5 *ngIf="resultsLayers.length > 0 && currentLayers.length > 0" class="layerswitcher-section-header" i18n>Couches par défaut</h5>
    <div class="layer-switcher-rows-container position-relative" dragula="LAYERS" [(dragulaModel)]="currentLayers">
      <div class="layer-switcher-row" *ngFor="let layer of currentLayers; trackBy: trackByIdx" id="layer-row-{{layer.idx}}">
        <div class="d-flex align-items-center">
          <div class="btn btn-icon drag-handler" i18n-title title="Cliquer-glisser pour réordonner les couches" draggable>
            <em class="fa fa-grip-vertical"></em>
          </div>
          <button type="button" class="btn btn-icon mx-1" i18n-title title="Aller à l'emprise de la couche" (click)="goToExtent(layer.extent)"  [disabled]="layer.cantDisplay">
            <em class="fa fa-expand"></em>
          </button>
          <div class="form-check mx-1">
            <input type="checkbox" id="visible_{{layer.idx}}" class="form-check-input" [(ngModel)]="layer.visible" (ngModelChange)="toggleLayer(layer)" [disabled]="layer.cantDisplay" />
            <label for="visible_{{layer.idx}}" class="form-check-label" i18n-title title="{{layer.cantDisplay ? 'La projection actuelle n\'est pas supportée pour cette couche' : 'Afficher/masquer la couche'}}">&nbsp;</label>
          </div>
          <div class="slider-container mr-2">
            <ng5-slider [(value)]="layer.opacity" [options]="sliderOptions" (valueChange)="onOpacityChange(layer)"></ng5-slider>
          </div>
          <a class="title-link" (click)="showLayerInfo(layer)">{{layer.title}}</a>
          <button type="button" class="ml-auto btn btn-icon btn-close" i18n-title title="Supprimer la couche" (click)="removeLayer(layer, currentLayers)"> &times; </button>
        </div>
        <dl class="layer-details d-flex" [ngClass]="{open: layer.displayInfo}">
          <div class="w-100 pr-3" *ngIf="layer.infos">
            <ng-container *ngIf="layer.infos.serviceTitle">
              <dt>Service title</dt>
              <dd>{{layer.infos.serviceTitle}}</dd>
            </ng-container>
            <ng-container *ngIf="layer.infos.abstract">
              <dt>Abstract</dt>
              <dd>{{layer.infos.abstract}}</dd>
            </ng-container>
            <ng-container *ngIf="layer.infos.visibility">
              <dt>Visibility</dt>
              <dd>{{layer.infos.visibility}}</dd>
            </ng-container>
            <ng-container *ngIf="layer.infos.contact">
              <dt>Contact</dt>
              <dd>
                <address>
                  <strong>{{layer.infos.contact.person}}</strong><br>
                  <strong>{{layer.infos.contact.organization}}</strong><br>
                  {{layer.infos.contact.address}}<br>
                  {{layer.infos.contact.postCode}} {{layer.infos.contact.city}}<br>
                  {{layer.infos.contact.country}}<br>
                  <a [href]="'mailto:' + layer.infos.contact.email">{{layer.infos.contact.email}}</a>
                </address>
              </dd>
            </ng-container>
          </div>
          <div class="trosace ml-auto mt-3" *ngIf="layer.infos && layer.infos.extent">
            <span class="tnorth">{{layer.infos.extent[3]}}</span>
            <span class="teast">{{layer.infos.extent[0]}}</span>
            <span class="twest">{{layer.infos.extent[2]}}</span>
            <span class="tsouth">{{layer.infos.extent[1]}}</span>
          </div>
        </dl>
      </div>
    </div>
  </div>

  <div class="layer-switcher layer-legends" *ngIf="mapConfig.controls.legend && displayLayerLegends" [@layerSwitcherAnimation]="(displayLayerLegends) ? 'open': 'close'">
    <div class="layer-switcher-header d-flex align-items-center">
      <h4 class="mr-auto">Légende</h4>
      <button type="button" class="btn btn-icon" i18n-title title="Fermer la légende" (click)="displayLayerLegends = false"> <em class="fa fa-arrow-right"></em> </button>
    </div>
    <div class="layer-switcher-rows-container">
      <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" [closeOthers]="true">
        <ngb-panel [title]="layer.title" *ngFor="let layer of currentLayers; trackBy: trackByIdx">
          <ng-template ngbPanelContent>
            <p *ngIf="!layer.infos || !layer.infos.legendUrl" i18n>Aucune légende</p>
            <img *ngIf="layer.infos && layer.infos.legendUrl" [src]="layer.infos.legendUrl | sanitizeImage" />
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="layer-switcher layer-measure" *ngIf="mapConfig.controls.measureTools && displayMeasureTools" [@mapToolsAnimation]="(displayMeasureTools) ? 'open': 'close'">
    <div class="layer-switcher-header d-flex align-items-center">
      <h4 class="mr-auto" i18n>Outils de mesure</h4>
      <button type="button" class="btn btn-icon" i18n-title title="Fermer la légende" (click)="disableMeasureTools()"> <em class="fa fa-arrow-right"></em> </button>
    </div>
    <div class="layer-switcher-rows-container p-3">
      <div class="d-flex justify-content-center measure-buttons">
        <div class="btn-container">
          <button type="button" class="btn btn-icon btn-length py-1" [ngClass]="{active: measureMode == 'length'}" i18n-title title="Mesurer une distance" (click)="changeMeasureMode('length')"> <em class="fas fa-ruler"></em> </button>
          <button type="button" class="btn btn-icon btn-area py-1" [ngClass]="{active: measureMode == 'area'}" i18n-title title="Mesurer une aire" (click)="changeMeasureMode('area')"> <em class="fas fa-draw-polygon"></em> </button>
        </div>
      </div>
      <p class="measure-result mt-3 mb-0">
        <strong *ngIf="measureMode == 'length'"><ng-container i18n>Distance</ng-container>:</strong>
        <strong *ngIf="measureMode == 'area'"><ng-container i18n>Aire</ng-container>:</strong>
        {{measureResult}}
        <sup *ngIf="measureResult && measureMode == 'area'">2</sup>
      </p>
    </div>
  </div>

  <div class="layer-switcher layer-export" *ngIf="mapConfig.controls.exportable && displayExport" [@mapToolsAnimation]="(displayExport) ? 'open': 'close'">
    <div class="layer-switcher-header d-flex align-items-center">
      <h4 class="mr-auto" i18n>Export de la carte</h4>
      <button type="button" class="btn btn-icon" i18n-title title="Fermer la légende" (click)="displayExport = false"> <em class="fa fa-arrow-right"></em> </button>
    </div>
    <div class="layer-switcher-rows-container p-2">
      <button type="button" class="btn btn-icon btn-block text-left py-1" (click)="exportAsImage()" i18n-title title="Télécharger la carte en PNG">
        <em class="fas fa-file-image mr-2"></em>
        <ng-container i18n>Carte seulement</ng-container>
      </button>
      <button type="button" class="btn btn-icon btn-block text-left py-1" (click)="exportAsPdf()" i18n-title title="Télécharger la carte et la légende en PDF">
        <em class="fas fa-file-pdf mr-2"></em>
        <ng-container i18n>Carte et légende</ng-container>
      </button>
    </div>
  </div>

  <div *ngIf="displayResetDataDraw" class="ol-control ol-unselectable reset-points-control">
    <button i18n-title title="Effacer les points" (click)="resetDataPoints()">
      <em class="fas fa-undo-alt"></em>
    </button>
  </div>

  <div class="bottom-controls d-flex" *ngIf="mapConfig.controls.srsSelector || mapConfig.controls.territoriesDestinations">
    <div *ngIf="mapConfig.controls.srsSelector && mapConfig.projections.length > 1"
      class="ol-unselectable ol-control srs-selector map-control-selector">
      <div class="d-flex align-items-center">
        <label for="srs-selector"><ng-container i18n>SRS</ng-container>: </label>
        <select id="srs-selector" class="form-control ml-2" name="srs-selector" [(ngModel)]="currentProjection" (ngModelChange)="updateProjection()">
          <option *ngFor="let proj of mapConfig.projections; trackBy: trackByName" [ngValue]="proj.name">{{proj.label}}</option>
        </select>
      </div>
    </div>

    <div *ngIf="mapConfig.controls.territoriesDestinations && destinations && destinations.length > 0" class="ol-unselectable ol-control territory-destination-selector map-control-selector">
      <div class="d-flex align-items-center">
        <label for="territory-destination-selector"><ng-container i18n>Aller à</ng-container>: </label>
        <select id="territory-destination-selector" class="form-control ml-2"
          name="territory-destination-selector" [(ngModel)]="currentDestinationExtent"
          (ngModelChange)="chooseDestination(currentDestinationExtent)">
          <option [ngValue]="null" i18n>Aller directement à...</option>
          <option *ngFor="let dest of destinations" [ngValue]="dest.extent">{{dest.label}}</option>
        </select>
      </div>
    </div>
  </div>

</div>

<div class="result-popup" #resultPopup>
  <dl *ngIf="currentData">
    <ng-container *ngFor="let item of currentData | keyvalue">
      <dt>{{item.key}}</dt>
      <dd>{{item.value}}</dd>
    </ng-container>
  </dl>
</div>
