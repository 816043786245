import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { LoaderService } from './loader.service';
import { MailService } from './mail.service';
import { Uris } from '../constants/uri.constants';
import { Status } from '../models/status.model';
import { EntityMetadata } from '../models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class StatusService {

    /**
     * Source d'un statut récupéré via l'URI de sa métadonnée
     */
    private statusByUriSource = new Subject<Status>();

    /**
     * Source de la liste des statuts
     */
    private allStatusSource = new Subject<Status[]>();

    /**
     * Source d'un statut mis à jour jour
     */
    private statusUpdateSource = new Subject<Status>();

    /**
     * Observable qui envoie un event à chaque récupération d'un statut via l'URI de sa métadonnée
     */
    public statusByUri$ = this.statusByUriSource.asObservable();

    /**
     * Observable qui envoie un event à chaque mise à jour du statut d'une métadonnée
     */
    public statusUpdate$ = this.statusUpdateSource.asObservable();

    /**
     * Observable qui envoie un event à chaque récupération d'une liste de statuts
     */
    public allStatus$ = this.allStatusSource.asObservable();

    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _loader: LoaderService,
        private _toastr: ToastrService,
        private _mail: MailService
    ) { }

    public getAllStatus():void {
      this._http.get<Status[]>(`${Uris.STATUS}`).subscribe(
          allStatus => this.allStatusSource.next(allStatus)
      );
    }

    public getStatusByType(type:string):void {
      this._http.get<Status[]>(`${Uris.STATUS}findByType/${type}`).subscribe(
          allStatus => this.allStatusSource.next(allStatus)
      );
    }


    /**
     * Récupère le statut d'une métadonnée à partir de l'URI de celle-ci
     * @param metadata
     */
    public getStatusByMetadataUuid(metadata) {
      this._http.get<Status>(`${Uris.STATUS}findByUri/${metadata.id}`).subscribe(
        status => {
          this.statusByUriSource.next(status);
        }
      );
    }


    /**
     * Récupère tous les statuts avec une certaine valeur.
     * progress : Fiche en cours de rédaction
     * pending : Publication demandée et en attente de réponse
     * accepted : Publication acceptée
     * refused : Publication refusée
     * @param value : valeur utilisé pour filtrer les statuts
     */
    getStatusByValue(value:string) {
      this._http.get<Status[]>(`${Uris.STATUS}findByStatus/${value}`).subscribe(
        allStatus => this.allStatusSource.next(allStatus)
      );
    }


    /**
     * Demande la publication de la métadonnée
     */
    public askPublication(metadata:EntityMetadata,language:string):void {
        metadata = _.cloneDeep(metadata);
        const headers = new HttpHeaders({
          'Accept-Language': language,
        });
        this._http.put<any>(`${Uris.STATUS}askPublication/${metadata.id}`,null , { headers : headers })
          .subscribe(
            status => {
              metadata.status.value = "PENDING";
              this.statusUpdateSource.next(metadata.status);
              this._loader.hide();
              this._toastr.success($localize`La demande de publication a été envoyée avec succès.`);
              this._mail.sendMailPublishMetadata(metadata.id);
            }
        );
    }


    /**
     * Demande la publication de la métadonnée
     */
    public acceptPublication(metadata:EntityMetadata,language:string):void {
        metadata = _.cloneDeep(metadata);
        const headers = new HttpHeaders({
          'Accept-Language': language,
        });
        this._http.put<any>(`${Uris.STATUS}accept/${metadata.id}`, null, { headers : headers })
          .subscribe(
            status => {
              metadata.status.value = "ACCEPTED";
              this.statusUpdateSource.next(metadata.status);
              this._loader.hide();
              this._toastr.success($localize`La validation de la publication a été envoyée avec succès.`);
              this._router.navigate(["/records/pending"]);
              this._mail.sendMailAcceptMetadata(metadata.id);
            }
        );
    }


    /**
     * Refuse la publication de la métadonnée
     */
    public refusePublication(metadata:EntityMetadata, motifRejet:string):void {
        metadata = _.cloneDeep(metadata);
        this._http.put<any>(`${Uris.STATUS}refuse/${metadata.id}`,null)
          .subscribe(
            status => {
              metadata.status.value = "REFUSED";
              this.statusUpdateSource.next(metadata.status);
              this._loader.hide();
              this._toastr.success($localize`Le refus de la publication a été envoyé avec succès.`);
              this._router.navigate(["/records/pending"]);
              this._mail.sendMailRefuseMetadata(metadata.id, motifRejet);
            },
            error => {
              this._router.navigate(["/records/pending"]);
            }
        );
    }

}
