import * as _ from 'lodash';

import { transformExtent as transformExtent, transform as transform } from 'ol/proj';
import { extend as extendExtent} from 'ol/extent';

import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './bbox-map-modal.component.html'
})
export class BboxMapModalComponent implements OnInit, OnDestroy {
  @Input() metadata;
  @Input() features;
  public options: any;
  public shadowData;
  @Input() locale: string;

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (this.metadata.type === "bbox" || this.metadata.type === "coord") {
      this.shadowData = _.cloneDeep(this.metadata.value);
    } else if (this.metadata.type === "coord_mult") {
      this.shadowData = [];
      _.each(this.metadata.values, value => {
        this.shadowData.push(_.clone(value));
      });
    }

    this.options = {
      displayProjection: this.metadata.mapConfig.projection
    };
    let targetProj = this.metadata.targetProj || 'EPSG:4326';

    switch (this.metadata.type) {
      case "bbox": this._setBboxOptions(targetProj);
        break;
      case "coord": this._setCoordOptions(targetProj);
        break;
      case "coord_mult": this._setMultipleCoordOptions(targetProj);
        break;
    }
  }

  ngOnDestroy() {

  }

  /**
   * Définit les options pour capturer une bbox
   * @param targetProj - Projection d'origine des données metadata
   */
  private _setBboxOptions(targetProj: string): void {
    let multipleExtents;
    if (this.metadata.isMultiple) {
      multipleExtents = [];
      let finalExtent;
      _.each(this.metadata.value, e => {
        let extent = transformExtent([e.minX, e.minY, e.maxX, e.maxY], targetProj, 'EPSG:4326');
        multipleExtents.push({extent: extent, geonameId: e.geonameId, label: e.label});
        if (finalExtent) {
          finalExtent = extendExtent(finalExtent, extent);
        } else {
          finalExtent = _.clone(extent);
        }
      });
      this.options.startExtent = finalExtent;
    } else if (this.metadata.value[0] && this._isValidBbox(this.metadata.value[0])) {
      this.options.startExtent = transformExtent([
        this.metadata.value[0].minX,
        this.metadata.value[0].minY,
        this.metadata.value[0].maxX,
        this.metadata.value[0].maxY
      ], targetProj, 'EPSG:4326');
    }
    this.options.dataInteraction = {
      type: "bbox",
      extent: multipleExtents ? multipleExtents : this.options.startExtent,
      isMultiple: this.metadata.isMultiple || false
    };
  }

  /**
   * Vérifie qu'une emprise donnée est valide
   * @param bbox Emprise
   */
  private _isValidBbox(bbox: { minX: number, minY: number, maxX: number, maxY: number }): boolean {
    if (_.isFinite(bbox.minX) && _.isFinite(bbox.minY) && _.isFinite(bbox.maxX) && _.isFinite(bbox.maxY)) {
      return bbox.minX < bbox.maxX && bbox.minY < bbox.maxY;
    }
    return false;
  }

  /**
   * Définit les options pour capturer une coordonnée
   * @param targetProj  - Projection d'origine des données metadata
   */
  private _setCoordOptions(targetProj: string): void {
    if (_.isFinite(this.metadata.value.x) && _.isFinite(this.metadata.value.y)) {
      this.options.startCenter = transform([
        this.metadata.value.x,
        this.metadata.value.y
      ], targetProj, 'EPSG:4326');
    }
    this.options.dataInteraction = {
      type: "coord",
      point: this.options.startCenter
    };
  }

  /**
   * Définit les options pour capturer plusieurs coordonnées ou une ligne cassée
   * @param targetProj  - Projection d'origine des données metadata
   */
  private _setMultipleCoordOptions(targetProj: string): void {
    let points = [];
    if (this.metadata.values && _.isArray(this.metadata.values) && this.metadata.values.length > 0) {
      let finalExtent = [null, null, null, null];
      _.each(this.metadata.values, coords => {
        let point = transform([coords.x, coords.y], targetProj, 'EPSG:4326');
        if (finalExtent[0] === null || finalExtent[0] < point[0]) {
          finalExtent[0] = point[0];
        }
        if (finalExtent[1] === null || finalExtent[1] < point[1]) {
          finalExtent[1] = point[1];
        }
        if (finalExtent[2] === null || finalExtent[2] > point[0]) {
          finalExtent[2] = point[0];
        }
        if (finalExtent[3] === null || finalExtent[3] > point[1]) {
          finalExtent[3] = point[1];
        }
        points.push(point);
      });
      this.options.startExtent = finalExtent;
    }

    // set start extent autour des points
    this.options.dataInteraction = {
      type: "coord_mult",
      points: points,
      isLine: this.metadata.isLine
    }
  }

  /**
   * Convertit les données issues de la carte au format metadata
   * @param data - Donnée provenant de la carte
   */
  public onDataMapChange(data) {
    let targetProj = this.metadata.targetProj || 'EPSG:4326';
    if (this.metadata.type == "bbox") {
      if (data.extent){
        if(this.metadata.isMultiple) {
          this.shadowData = [];
          for (let i = 0; i < data.extent.length; i = i + 1) {
            let extent = transformExtent(data.extent[i].extent, 'EPSG:4326', targetProj);
              this.shadowData.push({
                minX: extent[0],
                minY: extent[1],
                maxX: extent[2],
                maxY: extent[3],
                label: data.extent[i].label,
                geonameId: data.extent[i].geonameId
              });
          }
        } else {
          this.shadowData = [];
          let extent = transformExtent(data.extent, 'EPSG:4326', targetProj);
          this.shadowData.push({
            minX: extent[0],
            minY: extent[1],
            maxX: extent[2],
            maxY: extent[3],
            label: data.label,
            geonameId: data.geonameId
          });
        }
      }else{
        if(this.metadata.isMultiple) {
          this.shadowData = [];
          _.each(data, e => {
            let extent = transformExtent(e, 'EPSG:4326', targetProj);
            this.shadowData.push({
              minX: extent[0],
              minY: extent[1],
              maxX: extent[2],
              maxY: extent[3]
            });
          })
        } else {
          let extent = transformExtent(data, 'EPSG:4326', targetProj);
          this.shadowData.minX = extent[0];
          this.shadowData.minY = extent[1];
          this.shadowData.maxX = extent[2];
          this.shadowData.maxY = extent[3];
        }
      }
    } else if (this.metadata.type == "coord") {
      let point = transform(data, 'EPSG:4326', targetProj);
      this.shadowData.x = point[0];
      this.shadowData.y = point[1];
    } else if (this.metadata.type === "coord_mult") {
      this.shadowData = [];
      _.each(data, d => {
        let coords = transform(d, 'EPSG:4326', targetProj);
        this.shadowData.push({
          x: coords[0],
          y: coords[1]
        });
      });
    }
  }
}
