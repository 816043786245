import * as _ from 'lodash';
import * as moment from 'moment';

import { EntityMetadata } from './entity-metadata.model';
import { OnlineResource } from './online-resource.model';
import { Constants } from '../constants';
import { Project } from './project.model';
import {License} from "./license.model";

export class Data extends EntityMetadata {

  /**
   * Identifiant du dépôt (i.e "étude" dans Cupidon) lié
   */
  projectId: string = null;

  /**
   * Dépôt (i.e "étude" dans Cupidon) lié
   */
  project: Project = null;

  /**
   * Date de création de la donnée d'origine
   */
  creationDate: string = "";

  /**
   * Indique si la metadata est publiée
   */
  published = false;

  /**
   * Date de publication de la donnée d'origine
   */
  publicationDate: Date = null;

  /**
   * Indique si un embargo est présent sur les JDD du dataset
   */
  embargo = false;

  /**
   * Date de libération du JDD (=des fichiers uploadé) => Permet la gestion des données sous embargo
   */
  releasedDate: string = "";

  /**
   * Contraintes d'utilisation
   */
  legalConstraints: License = {
    licenseId:"CC-BY-4.0",
    reference:"https://spdx.org/licenses/CC-BY-4.0.html"
  };

  /**
   * Type de représentation spatiale
   */
  spatialRepresentationType: string = null;

  /**
   * Résolution spatiale
   */
  //spatialResolution: number = null;

  /**
   * Traçabilité
   */
  lineage: string = "";

  /**
   * Liste des resources de la donnée
   */
  onlineResources: OnlineResource[] = [];

  /**
   * Lien vers le fichier
   */
  file: OnlineResource = null;

  /**
   * Lien vers les fichiers
   */
  files: OnlineResource[] = [];

  /**
   * Début de l'étendue temporelle
   */
  temporalExtentStart: string = "";

  /**
   * Fin de l'étendue temporelle
   */
  temporalExtentEnd: string = "";

  /**
   * DOI de la métadonnée
   */
  doi: string = null;

  /**
   * Teste si un dataset possède un embargo sur ses JDDs
   * @returns Vrai si le dataset possède un embargo sur ses JDDs
   */
  public hasEmbargo(): boolean {
    if(this.releasedDate==null) return false;
    let releasedDate = new Date(
      parseInt(this.releasedDate.split("/")[2]),
      parseInt(this.releasedDate.split("/")[1])+1,
      parseInt(this.releasedDate.split("/")[0])
    );
    return releasedDate.getTime() > new Date().getTime();
  }

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "projectId", back: "parentIdentifier" },
      { front: "creationDate", class: Date },
      { front: "publicationDate", class: Date },
      { front: "revisionDate", class: Date },
      { front: "releasedDate", class: Date },
      { front: "spatialRepresentationType", back: "spatialRepresentationType.codeListValue" },
      { front: "lineage", back: "dataQualityLineage" },
      { front: "onlineResources", class: OnlineResource },
      { front: "legalConstraints", class: License }
    ]);
  }

  public serialize() {
    super.serialize();
    this._jsonModel.temporalExtent = {
      description : "",
      start : this.temporalExtentStart,
      end : this.temporalExtentEnd
    };
    return this._jsonModel;
  }

  public deserialize(json: any, language:string="fre"): this {
    super.deserialize(json);

    this.thematics = this.getClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME);
    this.freeKeywords = this.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME);

    let locale = Constants.languageToLocale[language]
    let format = Constants.localeCalendarFormats[locale].formatMoment;

    if(json.creationDate!=null) {
      let creationDate:Date = new Date(json.creationDate);
      let momentObject = moment(creationDate,format);
      this.creationDate = momentObject.format(format);
    }

    if(json.releasedDate!=null) {
      let releasedDate:Date = new Date(json.releasedDate);
      let momentObject = moment(releasedDate,format);
      this.releasedDate = momentObject.format(format);
    }

    if(json.temporalExtent!=null && json.temporalExtent.start!=null) {
      let temporalExtentStart:Date = new Date(json.temporalExtent.start);
      let momentObject = moment(temporalExtentStart,format);
      this.temporalExtentStart = momentObject.format(format);
    }

    if(json.temporalExtent!=null && json.temporalExtent.end!=null) {
      let temporalExtentEnd:Date = new Date(json.temporalExtent.end);
      let momentObject = moment(temporalExtentEnd,format);
      this.temporalExtentEnd = momentObject.format(format);
    }

    let downloadResource = _.filter(this.onlineResources, { type: Constants.FILE_RESOURCE_CODE });
    if (downloadResource.length > 0) {
      this.file = downloadResource[downloadResource.length - 1];
      this.files = downloadResource;
    }

    if (!!this.releasedDate) {
      this.embargo = true;
    }

    if(json.legalConstraints!=null) {
      this.legalConstraints = json.legalConstraints;
    }

    return this;
  }

  /**
   * Récupère la locale du navigateur
   */
  private getLocaleFromNavigator(): string {
    return !!navigator.language ? navigator.language.substring(0, 2) || 'fr' : 'fr';
  }
}
