import { Pipe, PipeTransform } from "@angular/core";
import { Contact } from 'src/app/models';

@Pipe({
  name: 'contact'
})
export class ContactPipe implements PipeTransform {
  constructor() { }

  transform(value: Contact) {
    if (!!value.organisation && !!value.organisation.name) return `${value.email} (${value.organisation.name})`;
    return `${value.email}`;
  }
}