import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Uris} from '../constants/uri.constants';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {


  private _licenseSource = new Subject<any>();
  public license$ = this._licenseSource.asObservable();

  constructor(
    private _http: HttpClient

  ) { }


  /**
   * Récupère la liste des licences (useConstraints)
   */
   public getLicenses(): void {

    this.getLicenseObs()
      .subscribe(
        datas => this._licenseSource.next(datas)
      );
  }

  public getLicenseObs(): Observable<any[]> {
    return this._http.get<any[]>(Uris.LICENSES_REGISTRY);
  }

}
