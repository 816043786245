import { EntityMapper } from "./entity-mapper.model";

export class Keycloak extends EntityMapper {

  /**
   * URL de connexion du keycloak
   */
  loginUrl: string = "";

  resource: string = "";
}