import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { transformExtent as transformExtent } from 'ol/proj';

import { BboxMapModalComponent } from 'src/app/components/modals';

@Component({
  selector: 'metadata-bbox',
  templateUrl: './bbox.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: BboxComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: BboxComponent, multi: true }
  ]
})
export class BboxComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() metadata;
  @Input('readonly') isReadOnly;
  value: any = {};
  private _targetProj: string;
  private _displayProj: string;

  @ViewChild('minX', { static: true }) minXField;
  @ViewChild('minY', { static: true }) minYField;
  @ViewChild('maxX', { static: true }) maxXField;
  @ViewChild('maxY', { static: true }) maxYField;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this._targetProj = this.metadata.targetProj || 'EPSG:4326';
    this._displayProj = this.metadata.mapConfig.projection || 'EPSG:4326';
  }

  /**
   * Met à jour le ngModel quand un des champs de la bbox change
   */
  public onChange() {
    let value = this._getFormatedBboxValue(this.value, 'target');

    this.onChangeCb(value);
    this.onMetadataChange.emit(this.metadata);
  }

  /**
   * Ouvre la carte pour sélectionner une bbox
   */
  public openMap() {
    const modalRef = this.modalService.open(BboxMapModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "map-modal" })
    let editedMetadata = _.cloneDeep(this.metadata);

    editedMetadata.value = this._getFormatedBboxValue(this.value, 'target');
    modalRef.componentInstance.metadata = editedMetadata;

    modalRef.result.then(result => {
      this.writeValue(result);
      this.onChangeCb(result);
      this.onMetadataChange.emit(this.metadata);
    }, reason => {

    });
  }

  /**
   * Formate les valeurs de la bbox
   * @param value Valeur à formatter
   * @param purpose But du formatage (utilisé pour la conversion de projection)
   */
  private _getFormatedBboxValue(value: any, purpose: string): { minX: number, minY: number, maxX: number, maxY: number } {
    let minX = _.isFinite(value.minX) ? value.minX : null;
    let minY = _.isFinite(value.minY) ? value.minY : null;
    let maxX = _.isFinite(value.maxX) ? value.maxX : null;
    let maxY = _.isFinite(value.maxY) ? value.maxY : null;

    let formatedValue = { minX: minX, minY: minY, maxX: maxX, maxY: maxY };

    if (minX !== null && minY !== null && maxX !== null && maxY !== null && minX < maxX && minY < maxY) {
      let extent;
      if (purpose === 'display') {
        extent = transformExtent([
          minX, minY,
          maxX, maxY
        ], this._targetProj, this._displayProj);
      } else {
        extent = transformExtent([
          minX, minY,
          maxX, maxY
        ], this._displayProj, this._targetProj);
      }
      formatedValue = {
        minX: extent[0], minY: extent[1],
        maxX: extent[2], maxY: extent[3]
      };
    }

    return formatedValue;
  }

  // control methods
  writeValue(value) {
    if (!value) return;
    this.value = this._getFormatedBboxValue(value, 'display');
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations = {};

    this._requiredValidator(validations, control);

    if (_.keys(validations).length === 0) {
      this._validBboxValidator(validations, control);
    }

    if (_.keys(validations).length > 0) {
      return validations;
    }
    return null;
  }

  private _requiredValidator(validations: any, control: AbstractControl) {
    if (!control.value) {
      return;
    }
    if (this.metadata.required) {
      if (control.value.minX === null) {
        validations.minX = { value: control.value.minX };
        this.minXField.control.setErrors({ required: true });
      }
      if (control.value.minY === null) {
        validations.minY = { value: control.value.minY };
        this.minYField.control.setErrors({ required: true });
      }
      if (control.value.maxX === null) {
        validations.maxX = { value: control.value.maxX };
        this.maxXField.control.setErrors({ required: true });
      }
      if (control.value.maxY === null) {
        validations.maxY = { value: control.value.maxY };
        this.maxYField.control.setErrors({ required: true });
      }
    } else if (control.value.minX === null || control.value.minY === null || control.value.maxX === null || control.value.maxY === null) {
      if (control.value.minX === null) {
        validations.minX = { value: control.value.minX };
        this.minXField.control.setErrors({ invalidBbox: true });
      }
      if (control.value.minY === null) {
        validations.minY = { value: control.value.minY };
        this.minYField.control.setErrors({ invalidBbox: true });
      }
      if (control.value.maxX === null) {
        validations.maxX = { value: control.value.maxX };
        this.maxXField.control.setErrors({ invalidBbox: true });
      }
      if (control.value.maxY === null) {
        validations.maxY = { value: control.value.maxY };
        this.maxYField.control.setErrors({ invalidBbox: true });
      }
    } else {
      this.minXField.control.setErrors(null);
      this.minYField.control.setErrors(null);
      this.maxXField.control.setErrors(null);
      this.maxYField.control.setErrors(null);
    }
  }

  /**
   * Vérifie la validité de la bbox, lancé seulement si le requiredValidator est clean
   * @param validations
   * @param control
   */
  private _validBboxValidator(validations: any, control: AbstractControl) {
    if (!control.value) {
      return;
    }

    if (control.value.minX >= control.value.maxX) {
      this.maxXField.control.setErrors({ invalidBbox: true });
    }
    if (control.value.minY >= control.value.maxY) {
      this.maxYField.control.setErrors({ invalidBbox: true });
    }
  }
}
