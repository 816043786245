<section id="service" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="white-with-border" i18n>Service CSW</h1>
  </div>
  <br>
  <div class="page-content d-flex flex-column h-100">
    <div class="subcontent">
      <div>
        <p>
          <ng-container><a>{{cswUrl}}</a></ng-container>
        </p>
      </div>
    </div>
  </div>
</section>
