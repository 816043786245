<form #addUserForm="ngForm" class="edit-fields-form" [ngClass]="{'ng-submitted': addUserForm.submitted}"
  (ngSubmit)="save(addUserForm)">
  <div class="modal-header">
    <h2 class="modal-title" i18n>Ajouter un utilisateur</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="confirm-modal">
    <div class="d-flex align-items-center">
      <label for="add-user-name" class="mb-0 mr-3 nowrap" i18n>Email de l'utilisateur à ajouter</label>
      <input type="email" class="form-control" name="add-user-name" id="add-user-name" [(ngModel)]="user" required
             [pattern]="patternMail" #emailField="ngModel">
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" [disabled]="addUserForm.invalid && emailField.invalid" class="btn btn-primary ml-auto" i18n>Ajouter</button>
  </div>
</form>
