import { EntityMapper } from "./entity-mapper.model";
import { Organization } from "./organization.model";

export class Owner extends EntityMapper {
    
    individualFirstName:string = "";
    individualLastName:string = "";
    email:string = "";
    organisation:Organization = null;
    role:string = "";
    
    constructor() {
        super();
        this._mapperDefs = [
            { front: 'individualFirstName', back: 'individualFirstName' },
            { front: 'individualLastName', back: 'individualLastName' },
            { front: 'email', back: 'email' },
            { front: 'organisation', class: 'organisation' },
            { front: 'role', class: 'role' }
        ];
    }

}
