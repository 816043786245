export class Extent {

    /**
     * ID dans Geonames
     */
    public geonameId:string = "";

    /**
     * Description
     */
    public description:{label:string} = {label:""};

    /**
     * Longitude ouest
     */
    public westBoundLongitude:number = 0.0;

    /**
     * Longitude est
     */
    public eastBoundLongitude:number = 0.0;

    /**
     * Latitude nord
     */
    public northBoundLatitude:number = 0.0;

    /**
     * Latitude sud
     */
    public southBoundLatitude:number = 0.0;

    constructor() {
    }

    public deserialize(json: any): this {
        this.geonameId = json.geonameId;
        this.description = json.description;
        this.westBoundLongitude = json.westBoundLongitude;
        this.eastBoundLongitude = json.eastBoundLongitude;
        this.northBoundLatitude = json.northBoundLatitude;
        this.southBoundLatitude = json.southBoundLatitude;
        return this;
    }

}
