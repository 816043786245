import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SessionService, TableFilterService, LoaderService, DataService, UtilsService } from 'src/app/services';
import { Data, OnlineResource } from 'src/app/models';
import { Status } from '../../../models/status.model';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from '../../../constants';
import { StatusService } from '../../../services/status.service';

@Component({
  templateUrl: './datas.component.html'
})
export class DatasComponent implements OnInit, OnDestroy {
  /**
   * Liste des données
   */
  public datas: Data[] = [];

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _filterService: TableFilterService,
    private _dataService: DataService,
    private _statusService: StatusService,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2,
    private _utilsService: UtilsService
  ) { }

  public ngOnInit() {
    // Redirect to /public/home if the user is not logged in
    if (this.session.currentUser==undefined || this.session.currentUser==null) {
      this._router.navigate(["/public/home"]);
    } else {
      // Initialize the page if user is logged in
      this._subs.add(this._dataService.datas$.subscribe(datas => this._initDatas(datas)));
      this._subs.add(this._statusService.allStatus$.subscribe(allStatus => this._initAllStatus(allStatus)));
      this._loader.show();
      if (!this.session.currentUser) {
        this._router.navigate(['/projects']);
        return;
      }
      this._dataService.getUserDatas("fre");
      this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
    }

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprimme une donnée
   * @param data - donnée à supprimer
   */
  public deleteData(data: Data): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un jeu de données`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce jeu de données : '${data.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._dataService.deleteData(data)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de jeu de données",
            properties: {
              category: data.name
            }
          });
          this._toastr.success($localize`Le jeu de données '${data.name}' a été supprimé avec succès`);
          this._dataService.getUserDatas("fre");
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Récupère les formats des fichiers d'un jeu de données sous forme d'une chaîne de caractères
   * @param data
   * @returns les extensions des fichiers sous forme d'une chaîne de caractères
   */
  public getDataFilesExtensions(data:Data) {
    let extensions = [];
    for(let file of data.files) {
      let extension = file.url.substring(file.url.lastIndexOf(".")+1);
      if(extension!="" && !extensions.includes(extension.toUpperCase())) extensions.push(extension.toUpperCase());
    }
    return extensions.join(", ");
  }

  /**
   * Download all the files of a data
   * @param data
   */
  public downloadFiles(data:Data) {
    this.downloadFileIndex(data.id, data.files, 0);
  }

  private downloadFileIndex(id: string, files: OnlineResource[], index: number) {
    if (!!files && index < files.length) {
      this._dataService.downloadFile(id, files[index].name, index + 1, files.length).subscribe(blob => {
        this._utilsService.launchDownloadFile(blob, files[index].name);
        this.downloadFileIndex(id, files, index + 1);
      });
    }
  }

  public displayName(_data : Data):string {
    if(_data==null) return "";
    if(_data.name != null && _data.name != "") {
      if(_data.name.length >= 195) return _data.name.substring(0,195)+" ...";
      else return _data.name;
    } else {
      if(_data.defaultName != null || _data.defaultName != "") {
        if(_data.defaultName.length >= 195) return _data.defaultName.substring(0,195)+" ...";
        else return _data.defaultName;
      }
      else return "";
    }
  }


  public displayProjectName(_data : Data):string {
    let _project = _data.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    } else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }

  /**
   * Initialise la liste des données
   * @param datas - données reçues du serveur
   */
  private _initDatas(datas) {
    //Tri le tableau en fonction de la date de dernière modification de la plus récente à la plus ancienne
    this.datas = _.orderBy(datas, 'lastUpdate','desc');

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'data') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }
    this._statusService.getAllStatus();
    this._loader.hide();
  }

  private _initAllStatus(allStatus) {
    _.each(allStatus , status => {
      _.each(this.datas , data => {
        if(data.id==status.metadataUuid) {
          let newStatus = new Status();
          newStatus.metadataUuid = status.metadataUuid;
          newStatus.value = status.value;
          data.status = newStatus;
        }
      });
    });
  }

}
