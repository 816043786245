<label>
  <label-info [metadata]="metadata"></label-info>
  {{metadata.label}}
</label>
<div class="bbox-container">
  <div class="d-flex form-group" *ngFor="let value of values;let index = index">
    <div class="mr-2">
      <metadata-mult-coord-unique [(ngModel)]="values[index]" [readonly]="isReadOnly || metadata.readOnly"
        [id]="'coord' + index" (ngModelChange)="onChange()" [required]="true" (onPaste)="onPaste($event)">
      </metadata-mult-coord-unique>
    </div>
    <button type="button" class="btn btn-close" (click)="deleteCoords(index)">
      <span>&times;</span>
    </button>
  </div>
  <button type="button" class="btn link-style px-0 py-1 mb-2" (click)="addCoords()">
    <em class="fa fa-plus mr-2"></em>
    <ng-container i18n>Ajouter une coordonnée</ng-container>
  </button>
  <button type="button" class="btn btn-default btn-block" (click)="openMap()"
    [disabled]="isReadOnly || metadata.readOnly">
    <em class="fa fa-map-marker-alt"></em>
    <ng-container>Sélectionner sur la carte</ng-container>
  </button>
</div>
