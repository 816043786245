import { Injectable } from "@angular/core";

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

import { SessionService } from "../services";
import { Role, User } from "../models";
import { Constants } from "../constants";


@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private _session: SessionService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user:User = this._session.currentUser;
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    let isAdmin = false;
    if(!!adminRole) isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let pointOfContactRole = roles.find(role => role.name == Constants.ROLE_REFERENT);
    let isPointOfContact = false;
    if(!!pointOfContactRole) isPointOfContact = user.userRoles.findIndex(userRole => userRole.roleId==pointOfContactRole.id)!=-1;
    if(!isAdmin && !isPointOfContact) {
      this._router.navigate(["/home"]);
      return false;
    }
    return true;
  }
}
