import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { Constants } from "src/app/constants";
import { DataService, LoaderService, SessionService, TableFilterService, UtilsService } from 'src/app/services';
import { Data } from 'src/app/models';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import * as _ from "lodash";

@Component({
  templateUrl: './public-datas.component.html'
})
export class PublicDatasComponent implements OnInit, OnDestroy {
  /**
   * Liste des données
   */
  public datas: Data[] = [];

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _filterService: TableFilterService,
    private _dataService: DataService,
    private _loader: LoaderService,
    private _utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this._subs.add(this._dataService.datas$.subscribe(datas => this._initDatas(datas)));

    this._loader.show();
    this._dataService.getPublishedDatas();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayName(_data : Data):string {
    if(_data==null) return "";
    if(_data.name != null && _data.name != "") {
      if(_data.name.length >= 195) return _data.name.substring(0,195)+" ...";
      else return _data.name;
    } else {
      if(_data.defaultName != null && _data.defaultName != "") {
        if(_data.defaultName.length >= 195) return _data.defaultName.substring(0,195)+" ...";
        else return _data.defaultName;
      }
      else return "";
    }
  }

  public displayProjectName(_data : Data):string {
    let _project = _data.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    } else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }

  /**
   * Initialise la liste des données
   * @param datas - données reçues du serveur
   */
  private _initDatas(datas) {
    //Tri le tableau en fonction de la date de dernière modification de la plus récente à la plus ancienne
    this.datas = _.orderBy(datas, 'publicationDate','desc');
    this._loader.hide();
  }

}
