<div class="container-fluid main d-flex flex-column">
  <app-public-header></app-public-header>
  <div class="d-flex h-100">
    <app-public-menu></app-public-menu>
    <div class="page w-100 h-100 d-flex flex-column">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-public-footer></app-public-footer>
</div>
<loader></loader>
<app-progress-bar></app-progress-bar>
