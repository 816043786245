<header id="header" class="container-fluid">
  <nav class="navbar navbar-expand no-gutters">
    <a *ngIf="logoName" class="navbar-brand text-center" routerLink="/home">
      <img [src]="'/assets/images/' + logoName" [alt]="logoTitle">
    </a>
    <form class="form-inline col-6 mr-auto no-gutters" (ngSubmit)="doSearch()" #searchForm="ngForm">
      <div class="input-group mr-4 col search-group">
        <i class="fa fa-search search-icon" ></i>
        <input class="form-control search" type="search" i18n-placeholder placeholder="Rechercher"
          [(ngModel)]="search.text" #searchText name="searchText" aria-label="Rechercher">
        <div class="input-group-append" ngbDropdown>
          <button class="btn type-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            ngbDropdownToggle>
            <div>
              {{search.typeLabel}} <i class="fa fa-caret-right fa-rotate-90 "></i>
            </div>
          </button>
          <div ngbDropdownMenu>
            <a class="dropdown-item" (click)="setSearchType('', 'Tout')" i18n>Tout</a>
            <a class="dropdown-item" (click)="setSearchType('project', 'Dépôts')" i18n>Dépôts</a>
            <a class="dropdown-item" (click)="setSearchType('data', 'Jeu de données')" i18n>Jeux de données</a>
            <a class="dropdown-item" (click)="setSearchType('link', 'Ressources')" i18n>Ressources</a>
          </div>
        </div>
      </div>
      <button class="btn search-btn" type="submit" i18n>Rechercher</button>
    </form>
    <ul class="navbar-nav right-navbar ml-auto">
      <li class="nav-item" *ngIf="isAdmin || isModerator">
        <a class="btn btn-icon nav-link" routerLink="/records">
          <em class="fa fa-table"></em>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAdmin || isPointOfContact">
        <a class="btn btn-icon nav-link" routerLink="/admin">
          <em class="fa fa-key"></em>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn btn-icon nav-link" routerLink="/help">
          <em class="fa fa-question-circle"></em>
        </a>
      </li>
      <li class="nav-item separator" ngbDropdown placement="bottom-right">
        <a class="nav-link account" id="user-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" ngbDropdownToggle>
          <div>
            {{session.currentUser.email}}
            <i class="fa fa-chevron-down"></i>
          </div>
          <em class="fa fa-user"></em>
        </a>
        <div aria-labelledby="user-dropdown" ngbDropdownMenu>
          <dl class="groups-container">
            <dt i18n>Mes groupes</dt>
            <dd>
              <ul>
                <li *ngFor="let group of session.currentUser.groups; trackBy: trackById">
                  {{group.name}}
                </li>
              </ul>
            </dd>
          </dl>
          <a class="dropdown-item" [routerLink]="['/account']">
            <em class="fa fa-user mr-2"></em>
            <ng-container i18n>Mon compte</ng-container>
          </a>
          <a class="dropdown-item" (click)="logout()">
            <em class="fa fa-power-off mr-2"></em>
            <ng-container i18n>Se déconnecter</ng-container>
          </a>
        </div>
      </li>
    </ul>
  </nav>
</header>
