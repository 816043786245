import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'i18nYesNo' })
export class I18nYesNoPipe implements PipeTransform {
  constructor() {}

  transform(value: boolean) {
    return value ? $localize`Oui` : $localize`Non`;
  }

}
