<section id="link-detail" class="meta-detail d-flex flex-column h-100" *ngIf="link">

  <div class="d-flex align-items-start options-buttons-row">
    <a [routerLink]="['/projects', link.projectId, 'links', link.id, 'edit']" [queryParams]="{ lang: language }" *ngIf="(isOwner || isEditor || _metadataService.canModerate(project)) && link.status.value != 'ACCEPTED'" class="btn btn-default">
      <em class="fa fa-edit"></em>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <div class="btn-icon-container ml-3" ngbDropdown container="body" placement="bottom-right">
      <button type="button" class="btn btn-primary" ngbDropdownToggle>
        <div>
          Télécharger les metadonnées
          <i class="fa fa-chevron-down"></i>
        </div>
      </button>
      <div aria-labelledby="row-dropdown" class="download-dropdown-menu" ngbDropdownMenu>
        <a class="dropdown-item" (click)="downloadMetadata('json')" i18n>JSON</a>
        <a class="dropdown-item" (click)="downloadMetadata('pdf')" i18n>PDF</a>
        <a class="dropdown-item" (click)="downloadMetadata('xml')" i18n>XML</a>
        <a class="dropdown-item" (click)="downloadMetadata('datacite')" i18n>DATACITE</a>
        <a class="dropdown-item" (click)="downloadMetadata('zip')" i18n>ZIP</a>
      </div>
    </div>
    <button class="btn btn-danger ml-3" (click)="deleteLink()" *ngIf="(isOwner || isModerator || isAdmin) && !link.published">
      <em class="fa fa-trash-alt"></em>
      <ng-container i18n>Supprimer</ng-container>
    </button>
    <button class="btn btn-primary ml-3" (click)="askPublication()" *ngIf="!link.published && link.status!=null && link.status.value!='PENDING' && (isOwner || isModerator || isAdmin)">
      <em class="fa fa-lock-open"></em>
      <ng-container i18n>Publier</ng-container>
    </button>
    <button class="btn btn-primary ml-3" *ngIf="link.published" [disabled]="true">
      <em class="fa fa-lock"></em>
      <ng-container i18n>Publiée</ng-container>
    </button>
  </div>
  <div class="d-flex align-items-start publication-asked" *ngIf="!link.published && projectIsPublished && !!link.status && link.status.value == 'PENDING' && _metadataService.canModerate(project)">
    <div class="col">
      <h2 class="white-with-border">Publication demandée</h2>
      <div>
        <a [routerLink]="['./edit']" [queryParams]="{ lang: language }" class="btn btn-default">Vérifier le contenu de la fiche</a>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto white-with-border">{{displayName()}}
     <button *ngIf="tooLongTitle && showNameWithEllipsis" (click)="updateNameDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
     <button *ngIf="tooLongTitle && !showNameWithEllipsis" (click)="updateNameDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
    </h1>
  </div>
  <div class="informations-page-content page-content d-flex flex-column">
    <div class="informations-subcontent subcontent">
      <dl class="mb-0 row">
        <div class="col-12 col-xl-8">
          <h2 i18n>Informations</h2>
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <dt class="d-inline" i18n>Statut / <em>Status</em> :</dt>&nbsp;
                <dd class="d-inline" i18n>
                  <ng-container *ngIf="link?.status?.value=='PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
                  <ng-container *ngIf="link?.status?.value=='PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
                  <ng-container *ngIf="link?.status?.value=='ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
                  <ng-container *ngIf="link?.status?.value=='REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Dépôt / <em>Repository</em> :</dt>&nbsp;
                <dd class="d-inline">
                  <a [routerLink]="[projectPath, link.projectId]">{{displayProjectName()}}</a>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Type / <em>Type</em> :</dt>&nbsp;
                <dd class="d-inline">
                  <span *ngIf="ressourceType">{{ressourceType.label}}</span>
                  <span *ngIf="!ressourceType">Non renseigné.</span>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Url / <em>Uri</em> :</dt>&nbsp;
                <dd class="d-inline">
                  <a *ngIf="link.url!=null && link.url!=''" [href]="link.url" target="_blank" class="nowrap">
                    {{link.url}}
                    <em class="fa fa-external-link-alt"></em>
                  </a>
                  <span *ngIf="link.url==null || link.url==''">Non renseignée.</span>
                </dd>
              </div>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideDescription = !hideDescription">
                  <span i18n>Résumé / <em>Abstract</em></span>
                  <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></em>
                </button>
              </dt>
              <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{link.description}}</dd>
            </div>
          </div>

          <div class="col">

          </div>
          <div class="col">

          </div>
        </div>
      </dl>
    </div>
  </div>

</section>
