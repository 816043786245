import { Component, OnInit } from '@angular/core';

import { Constants } from "src/app/constants";
import { AppVersion } from 'src/app/models';
import { AppConfigService, LoaderService, SessionService, UtilsService } from 'src/app/services';

@Component({
  selector: 'app-public-menu',
  templateUrl: './public-menu.component.html'
})
export class PublicMenuComponent implements OnInit {
  public collapsed: boolean = true;

  public appVersion = null;
  public buildDate: Date = null;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  constructor(
    private _loaderService: LoaderService,
    private _appConfigService: AppConfigService,
    private _utilsService: UtilsService,
    public session: SessionService
  ) { }

  ngOnInit(): void {
    this._appConfigService.getAppVersion().subscribe((appVersion: AppVersion) => {
      this.appVersion = appVersion.version;
      this.buildDate = appVersion.buildDate;
    });

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public toggleMenu() {
    this.collapsed = !this.collapsed;
    this._loaderService.changeMenuWidth(this.collapsed);
  }
}
