import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';

import { DataService, LoaderService, MetadataService, ProjectService, SessionService, UtilsService } from 'src/app/services';
import {Project, Data, Role, Contact} from 'src/app/models';
import { Constants } from 'src/app/constants';
import { FormListEvent, RegistryService } from 'src/app/services/registry.service';

@Component({
  selector: 'app-public-data-detail',
  templateUrl: './public-data-detail.component.html',
  styleUrls: ['./public-data-detail.component.scss']
})
export class PublicDataDetailComponent implements OnInit {

  public FILE_RESOURCE_PROTOCOL = Constants.FILE_RESOURCE_PROTOCOL;
  public DOI_RESOURCE_PROTOCOL = Constants.DOI_RESOURCE_PROTOCOL;

  /**
   * Indique si l'utilisateur est le propriétaire du jeu de données
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Donnée affichée
   */
  public data: Data;

  /**
   * Booléen décrivant si le nom du jeu de données doit être affiché en entier ou non.
   */
  public showNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom l'étude associée au jeu de données doit être affiché en entier ou non.
   */
  public showProjectNameWithEllipsis: boolean = false;

  /**
  * Booléen décrivant si le nom du jeu de données dépasse le nombre de caractère affichable.
  */
 public tooLongTitle: boolean = false;

  /**
   * Saving URL params
   */
  public params: ParamMap = null;

  /**
   * Dépôt (i.e "étude" dans Cupidon) auquel appartient la donnée
   */
  public project: Project;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Base d'url vers le dépôt (i.e "étude" dans Cupidon)
   */
  public projectPath: string;

  /**
   * Booléen indiquant si le projet est publié ou non
   */
  public projectIsPublished:boolean = false;

  /**
   * Booléen indiquant si on a fini ou non de vérifier la publication du dépôt
   */
  public projectPublicationChecked:boolean = false;

  /**
   * Liste des langues disponibles pour la metadata affichée
   */
  public availableLanguages = [];

  /**
   * Langue par défaut de la metadata
   */
  public language = Constants.defaultLanguage;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Date du jour, utilisée pour vérifier si l'on a ou non atteint la date de libération
   */
  public currentDate: Date = new Date();

  /**
   * Date de libération du JDD.
   */
  public releasedDate: Date|null = null;

  /**
   * Flag "Données téléchargeables"
   */
  public filesDownloadable = false;

  /**
   * Gestion des formats de citation
   */
  public citationFormats: string[] = [];
  public citationFormat: string;
  public citation: string;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
    * Stockage des IDs des thématiques pour le changement de langue
    */
  public thematicsIdentifiersStorage: string[] = [];

  /**
   * Stockage des IDs des mots-clés pour le changement de langue
   */
  public keywordsIdentifiersStorage: string[] = [];

  /*
  * Les contacts de type point de contact
   */
  private pointOfContacts: Contact[];

  constructor(
    private _route: ActivatedRoute,
    private _dataService: DataService,
    private _projectService: ProjectService,
    private _metadataService: MetadataService,
    public session: SessionService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _registryService: RegistryService,
    private _elementRef: ElementRef
  ) { }

  public ngOnInit() {
    this._subs.add(this._dataService.data$.subscribe(data => this._initData(data)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateLexiques(event)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => {
      this.params = _.clone(params);
      this._initDataFromParams(params);
    }));
    this.currentDate.setHours(0,0,0);

    if (!!this.session.currentUser && !!this.session.currentUser.roles) {
      let roles:Role[] = this.session.allRoles;
      let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
      if (!!adminRole) this.isAdmin = !!this.session.currentUser.userRoles.find(userRole => userRole.roleId == adminRole.id);
    }
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayName():string {
    if(this.data==null) return "";
    if(!this.showNameWithEllipsis) return this.data.name ? this.data.name : this.data.defaultName;
    return this.data.name ?
      (this.data.name && this.data.name.length>=100) ? this.data.name.substring(0,100)+"..." : this.data.name :
      (this.data.defaultName && this.data.defaultName.length>=100) ? this.data.defaultName.substring(0,100)+"..." : this.data.defaultName;
  }

  public displayProjectName():string {
    let _project = this.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    }
    else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }

  public updateNameDisplay(event:any) {
    event.preventDefault();
    this.showNameWithEllipsis = !this.showNameWithEllipsis;
  }

  public updateProjectNameDisplay(event:any) {
    event.preventDefault();
    this.showProjectNameWithEllipsis = !this.showProjectNameWithEllipsis;
  }

  /**
   * Changement de la langue de la metadata
   */
  public onLanguageChange() {
    this._loader.show();
    this._dataService.getPublicData(this.data.id, this.language);
  }

  /**
   * Téléchargement du JDD à un format donné
   * @param format - Le format de téléchargement
   */
  public downloadMetadata(format:string) {
    this._metadataService.downloadPublicMetadata(this.data.id,format,this.language).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.data.name + "." + format);
    });
  }

  /**
   * Télécharge un fichier
   * @param fileName Nom du fichier
   */
  public downloadFile(fileName:string) {
    this._dataService.downloadPublicFile(this.data.id, fileName).subscribe(blob => {
      this._utils.launchDownloadFile(blob, fileName);
    });
  }

  /**
   * Rechargement de la citation
   */
  public reloadCitation() {
    this._metadataService.getPublicCitation(this.data.id, this.citationFormat, this.language).subscribe((citation: string) => {
      this.citation = citation;
    });
  }

  /**
   * Changement du format de citation
   */
  public onCitationFormatChange(format: string) {
    this.citationFormat = format;
    this.reloadCitation();
  }

  /**
   * Copie la citation dans le presse-papier
   */
  public copyCitationToClipboard() {
    if(!navigator.clipboard) return;
    let citationContainer = this._elementRef.nativeElement.querySelector("#citation-container");
    let text:string = citationContainer.innerHTML;
    navigator.clipboard.writeText(text);
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initDataFromParams(params: any) {
    this._loader.show();
    let dataId = params[params.length - 1];
    this.isOwner = this.session.hasRight(dataId, 'owner');
    this._dataService.getPublicData(dataId, this.language);
  }

  /**
   * Affiche le lien reçu
   * @param data - Lien issu du serveur
   */
  private _initData(data: Data): void {
    this.data = data;
    this.tooLongTitle = (!!this.data.name && this.data.name.length >= 100) || (!!this.data.defaultName && this.data.defaultName.length >= 100);
    this.showNameWithEllipsis = this.tooLongTitle;
    this.availableLanguages = [];
    _.forEach(Constants.languages, languageValue => {
      // Si la donnée possède le langage
      if (this.data.languagesAvailable.indexOf(languageValue.value) > -1) {
        // Alors on ajoute le langage dans les langages disponibles de la metadata
        this.availableLanguages.push(languageValue);
      }
    });
    // Si la langue par défaut n'est pas présente alors on prend la première langue disponible
    let hasCurrentLanguage = _.map(this.availableLanguages, 'value').indexOf(this.language) > -1;
    if (!hasCurrentLanguage) {
      this.language = this.availableLanguages[0].value;
      // Si le langage utilisé n'est pas celui par défaut, alors un reload est nécessaire
      this._initDataFromParams(this.params);
    }
    this.locale = Constants.languageToLocale[this.language];

    if(!!this.data.releasedDate) {
      let format = this.localeCalendarFormats[this.locale].formatMoment;
      this.releasedDate = moment(this.data.releasedDate,format).toDate();
    }

    this._metadataService.isPublished(this.data.projectId).subscribe((isProjectPublished: boolean) => {
      this.projectPublicationChecked = true;
      this.projectIsPublished = isProjectPublished;
      if(this.projectIsPublished) {
        this._projectService.getPublicProject(data.projectId, true, this.language);
      }
    });

    this._generateContacts();
    this.canDownloadDatasetsFiles();
    this._metadataService.getPublicCitationFormats(data.id).subscribe((formats: string[]) => {
      this.citationFormats = formats;
      if (this.citationFormats !== null && this.citationFormats.length > 0) {
        this.citationFormat = this.citationFormats[0];
        this.onCitationFormatChange(this.citationFormat);
      }
    });

    this.loadRegistreSoft();

    this._loader.hide();
  }


  /**
   * Affiche le dépôt (i.e "étude" dans Cupidon) du lien
   * @param project - Projet du lien, issu du serveur
   */
  private _initProject(project: Project): void {
    this.project = project;
    this.showProjectNameWithEllipsis = ((this.project.name!=null && this.project.name.length>=100) || this.project.defaultName.length>=100);
    this.projectPath = '/public/metadata';
    this._loader.hide();
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.data.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.data.contacts, c => c.role !== 'pointOfContact');
    this.data.contacts = firstContacts.concat(othersContacts);
    this.pointOfContacts = firstContacts;

    _.each(Constants.contactTypes, role => {
      _.each(role.labels , l => {
        if(l.language==this.language) this.contactsRolesNames[role.value] = l.label;
      });
    });
  }

  /**
   * Indique si l'utilisateur peut télécharger les JDD
   */
  private canDownloadDatasetsFiles() {
    if (!!this.session.currentUser) {
      this._dataService.canDownloadFiles(this.data.id).subscribe(result => {
        this.filesDownloadable = result;
      });
    } else this.filesDownloadable = !this.releasedDate || this.currentDate >= this.releasedDate;
  }

  private loadRegistreSoft(){
    this.thematicsIdentifiersStorage = this.data.thematics!=null ? _.cloneDeep(this.data.thematics).map(t => t.id) : [];
    this.keywordsIdentifiersStorage = this.data.freeKeywords!=null ? _.cloneDeep(this.data.freeKeywords).map(k => k.id) : [];
    this.data.thematics = [];
    this._reloadRegistries();
  };

  /**
   * Recharge les registres suite à un changment de langue
   */
  private _reloadRegistries() {
    this._registryService.getLexiques("dataPoles",this.language, null, null);
    this._registryService.getLexiques("thematics",this.language, null, null);
    this._registryService.getLexiques("keywords", this.language, null, null, "");
  }

  /**
   * Met à jour les thématics et les keywords
   * @param event - Événement reçu du service FormListService
   */
  private _updateLexiques(event: FormListEvent): void {
    switch (event.type) {
      case 'thematics':
        if(this.data!=undefined && this.data!=null) {
          _.each(event.datas , thematic => {
            if(this.thematicsIdentifiersStorage.includes(thematic.id) && this.data.thematics.filter(t=>t.id==thematic.id).length==0) this.data.thematics.push(thematic);
          });
        }
        break;
      case 'keywords' :
        if (!!this.data) {
          _.each(event.datas, d => {
            let keyword = this.data.freeKeywords.find(k => k.id == d.id);
            if (!!keyword) {
              keyword.label = d.label;
            }
          });
        }
        break;
    }
  }

  /**
   * Télécharge un zip contenant tous les fichiers du jeu de données
   */
  public downloadZipFiles() {
    const fileNames = this.data.files.map(file => file.name);
    this._dataService.downloadPublicZipFile(this.data.id, fileNames).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.data.id+".zip");
    });
  }
}
