<section id="admin-group-manage" class="d-flex flex-column h-100" *ngIf="userRole">
  <div class="d-flex align-items-start page-header">
    <div class="mr-auto">
      <h1>{{userRole.name}}</h1>
    </div>
    <a [routerLink]="['./edit']" *ngIf="canEdit()" class="btn btn-default nowrap">
      <em class="fa fa-edit"></em>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <button class="btn btn-danger nowrap ml-3" *ngIf="canDelete()" (click)="deleteUserRole()">
      <em class="fa fa-trash-alt"></em>
      <ng-container i18n>Supprimer</ng-container>
    </button>
  </div>
  <div class="page-content">
    <div class="row group-content">
      <div class="col-12">
        <h2 i18n>Informations</h2>
        <div class="mb-2">
          <dt class="d-inline" i18n><strong>Utilisateur / <em>User</em> :</strong></dt>&nbsp;
          <dd class="d-inline">{{userRole.mail}}</dd>
        </div>
        <div class="mb-2">
          <dt class="d-inline" i18n><strong>Rôle / <em>Role</em> :</strong></dt>&nbsp;
          <dd class="d-inline"><ng-container *ngIf="role!=null">{{role.name}}</ng-container></dd>
        </div>
        <ng-container *ngIf="isAdmin">
          <div class="mb-2">
            <dt class="d-inline" i18n><strong >Thématiques / <em>Thematics</em> :</strong>&nbsp;</dt>
            <dd class="d-inline">
              <ul class="vertical-list" *ngIf="userRole.thematics!=null && userRole.thematics.length>0">
                <li *ngFor="let thematic of userRole.thematics"><a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a></li>
              </ul>
              <span *ngIf="userRole.thematics==null || userRole.thematics.length==0">Aucune thématique renseignée.</span>
            </dd>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAdmin && isPointOfContact">
          <div class="mb-2">
            <dt class="d-inline" i18n><strong >Thématiques associées à votre rôle / <em>Thematics associated to your role</em> :</strong>&nbsp;</dt>
            <dd class="d-inline">
              <ul class="vertical-list" *ngIf="getRoleThematics().length>0">
                <li *ngFor="let thematic of getRoleThematics()"><a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a></li>
              </ul>
            </dd>
          </div>
          <div class="mb-2">
            <dt class="d-inline" i18n><strong >Autres thématiques / <em>Other thematics</em> :</strong>&nbsp;</dt>
            <dd class="d-inline">
              <ul class="vertical-list" *ngIf="getOtherThematics().length>0">
                <li *ngFor="let thematic of getOtherThematics()"><a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a></li>
              </ul>
              <span *ngIf="getOtherThematics().length==0">Aucune thématique.</span>
            </dd>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
