<form #rightsForm="ngForm" class="edit-fields-form d-flex flex-column h-100"
  [ngClass]="{'ng-submitted': rightsForm.submitted}" (ngSubmit)="save(rightsForm)">
  <div class="modal-header">
    <h2 class="modal-title" i18n>Modifier les droits de {{item.name}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body h-100" id="confirm-modal">
    <p class="alert-info p-3">
      <em class="fa fa-info-circle mr-2"></em>
      <ng-container i18n>
        Les droits d'accès sont définis au niveau des dépôts et sont appliqués à tout ce qu'elles incluent.
        Les droits sont attribués globalement à des groupes ou à des utilisateurs individuels.
        À chaque action, le système vérifie les droits de l'utilisateur.
        Si aucun droit n'est précisé, alors les droits des groupes auxquels il appartient s'appliquent.
        Seuls les propriétaires et les administrateurs peuvent modifier les droits d'accès.
      </ng-container>
    </p>
    <div class="row">
      <div class="col-6">
      <h3 i18n>Droits individuels</h3>
      <table class="table">
        <thead>
          <tr>
            <th class="w-50" i18n>Utilisateur</th>
            <th class="w-50" i18n>Permission</th>
            <th class="btn-cell-head">
              <button type="button" class="btn btn-icon" (click)="addRight('indiv')">
                <em class="fa fa-plus"></em>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let permission of individualPermissions;let i = index">
            <td>
              <p-autoComplete [(ngModel)]="permission.userId" [id]="'indiv-right-user-' + permission.id"
                [name]="'indiv-right-user-' + permission.id" [suggestions]="emails" appendTo="body"
                inputStyleClass="form-control" styleClass="w-100"  [forceSelection]="true"
                (completeMethod)="autocompleteUsers($event)" required></p-autoComplete>
            </td>
            <td>
              <select [name]="'indiv-right-' + permission.id" [id]="'indiv-right-' + permission.id"
                (change)="verifyOwners()" [disabled]="permission.code === 'owner' && !canEditOwner"
                class="form-control" [(ngModel)]="permission.code">
                <option value="readonly" i18n>Lecteur</option>
                <option value="editor" i18n>Éditeur</option>
                <option value="owner" i18n>Propriétaire</option>
              </select>
            </td>
            <td class="btn-cell">
              <button *ngIf="permission.code !== 'owner' || canEditOwner" type="button" class="btn btn-icon" (click)="removeRight('indiv', i)">&times;</button>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="col-6">
      <h3 i18n>Droits par groupe</h3>
      <table class="table">
        <thead>
          <tr>
            <th class="w-50" i18n>Groupe</th>
            <th class="w-50" i18n>Permission</th>
            <th class="btn-cell-head">
              <button type="button" class="btn btn-icon" (click)="addRight('group')">
                <em class="fa fa-plus"></em>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let permission of groupPermissions;let i = index">
            <td class="w-50">
              <select [name]="'group-right-group-' + permission.id" [id]="'group-right-group-' + permission.id"
                class="form-control" [(ngModel)]="permission.group" required>
                <option *ngFor="let group of groups; trackBy: trackById" [ngValue]="group">{{group.name}}</option>
              </select>
            </td>
            <td class="w-50">
              <select [name]="'group-right-' + permission.id" [id]="'group-right-' + permission.id"
                class="form-control" [(ngModel)]="permission.code">
                <option value="readonly" i18n>Lecteur</option>
                <option value="editor" i18n>Éditeur</option>
              </select>
            </td>
            <td class="btn-cell">
              <button type="button" class="btn btn-icon" (click)="removeRight('group', i)">&times;</button>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" class="btn btn-primary ml-auto" i18n>Enregistrer</button>
  </div>
</form>
