<section id="projects" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto white-with-border">
      <ng-container *ngIf="isOnlyMine" i18n>Mes dépôts</ng-container>
      <ng-container *ngIf="!isOnlyMine" i18n>Tous les dépôts</ng-container>
    </h1>
    <div class="title-button-container">
      <a [routerLink]="['./new']" class="btn btn-primary">
        <em class="fa fa-plus"></em>
        <ng-container i18n>Créer un dépôt</ng-container>
      </a>
    </div>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter white-with-border" *ngIf="totalResults === 0" i18n>Aucun dépôt</p>
          <p class="list-counter white-with-border" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} dépôts</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2 white-with-border" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="projects" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="defaultName"><span i18n>Titre</span></th>
            <th class="owner" sortable-column="ownerEmail"><span i18n>Contact</span></th>
            <th class="creator" sortable-column="creator"><span i18n>Déposé par</span></th>
            <th class="last-update text-center" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
            <th class="status" sortable-column="status.value" sort-direction="desc">Statut</th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-project>
          <tr>
            <td>
              <a [routerLink]="[session.hasRight(project.id, 'readonly') ? './' : '/public/projects/metadata/', project.id]">
                {{displayName(project)}}
              </a>
            </td>
            <td>{{project.ownerEmail}} <ng-container *ngIf="project.ownerOrganisation">({{project.ownerOrganisation}})</ng-container></td>
            <td>{{ project.creator }}</td>
            <td class="text-center">{{project.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
            <td>
              <ng-container *ngIf="project?.status?.value == 'PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
              <ng-container *ngIf="project?.status?.value == 'PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
              <ng-container *ngIf="project?.status?.value == 'ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
              <ng-container *ngIf="project?.status?.value == 'REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
            </td>
            <td class="text-center btn-icon-container" container="body" placement="bottom-right">
              <div class="" ngbDropdown #myDrop="ngbDropdown">
                <button type="button" id="dropdown-project-{{project.id}}" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownAnchor (focus)="myDrop.open()"></button>
                <div ngbDropdownMenu aria-labelledby="dropdown-project-{{project.id}}">
                  <a class="dropdown-item" [routerLink]="['./', project.id]" i18n>Consulter</a>
                  <a *ngIf="session.hasRight(project.id, 'editor') && !project.published" class="dropdown-item" [routerLink]="['./', project.id, 'edit']" i18n>Modifier</a>
                  <a *ngIf="session.hasRight(project.id, 'owner') && !project.published" class="dropdown-item item-danger" (click)="deleteProject(project)" i18n>Supprimer</a>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
