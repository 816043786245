import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SessionService } from "../../../../services";
import { Role, User } from "../../../../models";
import { Router } from "@angular/router";
import { Constants } from "src/app/constants";

@Component({
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit {

  /**
   * Booléen qualifiant si l'utilisateur actuellement connecté a le rôle "Administrateur"
   */
  public isAdmin:boolean = false;

  /**
   * Booléen qualifiant si l'utilisateur actuellement connecté a le rôle "Référent"
   */
  public isPointOfContact:boolean = false;
  
  private _subs: Subscription = new Subscription();

  constructor(
    private _router: Router,
    public _session: SessionService
  ) { }

  ngOnInit(): void {
    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
  }

  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User) {
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(adminRole!=undefined) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let pointOfContactRole = roles.find(role => role.name == Constants.ROLE_REFERENT);
    if(pointOfContactRole!=undefined) this.isPointOfContact = user.userRoles.findIndex(userRole => userRole.roleId==pointOfContactRole.id)!=-1;
    if(!this.isAdmin && !this.isPointOfContact) this._router.navigate(["/home"]);
  }

}