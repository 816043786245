import { EntityMapper } from "./entity-mapper.model";

/**
 * Groupe de droits d'accès aux métadonnées
 */
export class Role extends EntityMapper {
  /**
   * ID du rôle
   */
  id: number = null;

  /**
   * Nom du rôle
   */
  name: string = "";

  /**
   * Description du rôle
   */
  description: string = "";
  
}