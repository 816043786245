<div class="drop-files">
  <input hidden #fileInput type="file" [id]="inputId" [multiple]="multiple"
    (change)="onFilesChange($event.target.files)">
  <div class="drop-zone d-flex flex-column align-items-center justify-content-center"
    [ngClass]="{dragover: dropState === 'hover'}" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)" (click)="fileInput.click()">
    <p class="mb-2" *ngIf="!multiple">Glissez-déposez un fichier ou cliquez ici pour ajouter un fichier</p>
    <p class="mb-2" *ngIf="multiple">Glissez-déposez des fichiers ou cliquez ici pour ajouter des fichiers</p>
    <div class="drop-icon d-flex align-items-center justify-content-center">
      <em class="fa fa-download"></em>
    </div>
    <p class="mb-0 mt-2">
      <ng-container i18n>Taille limite par fichier:</ng-container>
      {{sizeLimit}}
      <ng-container i18n>Mo</ng-container>
    </p>
  </div>
  <p *ngIf="files.length === 0" i18n>Aucun fichier choisi</p>
  <ul class="file-list">
    <ng-container *ngFor="let file of files">
      <li *ngIf="!file.deleted" class="d-flex align-items-center" [ngClass]="{invalid: file.invalid}">
        <ng-container>
          <a *ngIf="file.existent" (click)="downloadFile(file)" class="download-link">
            {{file.label}}
            <ng-container *ngIf="file.file">
              <ng-container *ngIf="!file.invalid">({{file.file.size | fileSize}})</ng-container>
              <ng-container *ngIf="file.invalid"> ({{file.file.size | fileSize}} / {{(sizeLimit * 1024 * 1024) | fileSize}})</ng-container>
            </ng-container>
          </a>
          <span *ngIf="!file.existent" class="download-link">
            {{file.label}}
            <ng-container *ngIf="file.file">
              <ng-container *ngIf="!file.invalid">({{file.file.size | fileSize}})</ng-container>
              <ng-container *ngIf="file.invalid"> ({{file.file.size | fileSize}} / {{(sizeLimit * 1024 * 1024) | fileSize}})</ng-container>
            </ng-container>
          </span>
          <ng-container *ngIf="file.existent" i18n>&nbsp;(Existant)</ng-container>
        </ng-container>
        <button type="button" class="ml-auto btn btn-icon" (click)="deleteFile(file)"> &times; </button>
      </li>
    </ng-container>
  </ul>
</div>
