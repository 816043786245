import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Project } from 'src/app/models';
import { LoaderService, MetadataService, ProjectService, SessionService, TableFilterService, UtilsService } from 'src/app/services';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Status } from '../../../models/status.model';
import { StatusService } from '../../../services/status.service';
import { Constants } from "../../../constants";

@Component({
  templateUrl: './projects.component.html'
})
export class ProjectsComponent implements OnInit, OnDestroy {
  /**
   * Liste des dépôts (i.e "études" dans Cupidon)
   */
  public projects: Project[] = [];

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name'], filter: "" }
  };

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Est-on dans "mes dépôts" (i.e "mes étude" dans Cupidon) ?
   */
  public isOnlyMine: boolean = false;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _projectService: ProjectService,
    private _statusService: StatusService,
    private _metadataService: MetadataService,
    private _filterService: TableFilterService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2,
    private _utilsService: UtilsService
  ) { }

  public ngOnInit() {
    // Redirect to /public/home if the user is not logged in
    if (this.session.currentUser==undefined || this.session.currentUser==null) {
      this._router.navigate(["/public/home"]);
    } else {
      this._subs.add(this._projectService.projects$.subscribe(projects => this._initProjects(projects)));
      this._subs.add(this._statusService.allStatus$.subscribe(allStatus => this._initAllStatus(allStatus)));
      this.isOnlyMine = (this._route.parent.routeConfig.path === 'my-projects');
      if (this.isOnlyMine && !this.session.currentUser) {
        this._router.navigate(['/projects']);
      }
      this._getProjects();
      this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
    }

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprime un dépôt (i.e "étude" dans Cupidon)
   * @param project - dépôt à supprimer
   */
  public deleteProject(project: Project): void {
    this._projectService.getProjectChildrenCount(project.id)
      .subscribe((count: number) => {
        if (count === 0) {
          const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
          modalRef.componentInstance.title = $localize`Suppression d'un dépôt`;
          modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce dépôt : '${project.name}' ?`;
          modalRef.componentInstance.confirmClass = "btn-danger";
          modalRef.componentInstance.confirmText = $localize`Supprimer`;

          modalRef.result.then(() => {
            this._loader.show();
            this._projectService.deleteProject(project)
              .subscribe(() => {
                this._tracker.eventTrack.next({
                  action: "Suppression de dépôt",
                  properties: {
                    category: project.name
                  }
                });
                this._toastr.success($localize`Le dépôt '${project.name}' a été supprimé avec succès`);
                this._getProjects();
              }, error => console.error(error));
          }, () => null);
        } else {
          this._toastr.error($localize`Votre dépôt doit être totalement vide pour pouvoir être supprimé`, null, Constants.toastrErrorOptions);
        }
      }, () => null);

  }

  /**
   * Récupère les descriptions des emprises d'un dépôt sous forme d'une chaîne de caractères
   * @param project
   * @returns les descriptions des emprises sous forme d'une chaîne de caractères
   */
  public getExtentsDescriptions(project:Project) {
    let descriptions = [];
    for(let extent of project.extents) {
      let description = extent.description.label;
      if(!descriptions.includes(description)) descriptions.push(description);
    }
    return descriptions.join(", ");
  }

  public displayName(_project : Project):string {
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    } else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }

  /**
   * Initialise la liste des dépôts (i.e "études" dans Cupidon)
   * @param projects - dépôts reçues du serveur
   */
  private _initProjects(projects) {
    //Tri le tableau en fonction de la date de dernière modification de la plus récente à la plus ancienne
    this.projects = _.orderBy(projects, 'lastUpdate','desc');

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'project') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }
    this._statusService.getStatusByType("project");
    this._loader.hide();
  }

  /**
   * Demande la liste des dépôts (i.e "études" dans Cupidon)
   */
  private _getProjects() {
    this._loader.show();
    if (this.isOnlyMine) {
      this._projectService.getUserProjects("readonly");
    } else {
      this._projectService.getAllProjects();
    }
  }

  private _initAllStatus(allStatus) {
    _.each(allStatus , status => {
      _.each(this.projects , project => {
        if(project.id==status.metadataUuid) {
          let newStatus = new Status();
          newStatus.metadataUuid = status.metadataUuid;
          newStatus.value = status.value;
          project.status = newStatus;
        }
      });
    });
  }

}
