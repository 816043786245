import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';
import { Contact, OnlineResource, Data, Project } from 'src/app/models';
import { DataService, LoaderService, MetadataService, ProjectService, SessionService, UtilsService } from 'src/app/services';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants, Uris } from 'src/app/constants';

@Component({
  selector: 'app-public-project-detail',
  templateUrl: './public-project-detail.component.html',
  styleUrls: ['./public-project-detail.component.scss'],
})
export class PublicProjectDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Onglet initialement ouvert
   */
  public activeTab: string = "info-tab";

  /**
   * Dépôt (i.e "étude" dans Cupidon) à afficher
   */
  public project: Project;

  /**
   * Booléen décrivant si le nom du projet doit être affiché en entier ou non.
   */
  public showNameWithEllipsis:boolean = true;

  /**
   * Booléen décrivant si le nom du jeu de données dépasse le nombre de caractère affichable.
   */
  public tooLongTitle: boolean = false;

  /**
   * Saving URL params
   */
  public params: ParamMap = null;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Afficher la description complète du dépôt (i.e "étude" dans Cupidon) ?
   */
  public showFullDescription: boolean = false;

  /**
   * Version actuellement visible de la description du dépôt (i.e "étude" dans Cupidon)
   */
  public currentDescription: string;

  /**
   * Base url vers le téléchargement de fichiers
   */
  public datasetsFilePath: string = Uris.DATAS;

  /**
   * Liste des langues disponibles pour la metadata affichée
   */
   public availableLanguages = [];

  /**
   * Langue par défaut de la metadata
   */
  public language = Constants.defaultLanguage;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  private _targetId: string;

  private _dataTable: FiltrableTableComponent;

  /*
  * Les contacts de type point de contact
   */
  private pointOfContacts: Contact[];

  @ViewChildren(FiltrableTableComponent) dataTables: QueryList<FiltrableTableComponent>;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _projectService: ProjectService,
    private _metadataService: MetadataService,
    private _session: SessionService,
    private _loader: LoaderService,
    private _dataService: DataService,
    private _utils: UtilsService
  ) { }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  public ngOnInit() {
    this._loader.show();
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._route.paramMap.subscribe(params => {
      this.params = params;
      this._initProjectFromParams(params);
    }));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngAfterViewInit() {
    this._subs.add(this.dataTables.changes.subscribe((dataTables: QueryList<FiltrableTableComponent>) => {
      if (dataTables.first) {
        this._dataTable = dataTables.first;
        if (this._targetId) {
          setTimeout(() => {
            this._dataTable.filter({ target: { value: this._targetId, field: 'id' } });
            this._targetId = null;
          }, 0);
        }
      }
    }));
  }

  public displayName():string {
    if(this.project==null) return "";
    if(!this.showNameWithEllipsis) return this.project.name ? this.project.name : this.project.defaultName;
    return this.project.name ?
      (this.project.name && this.project.name.length>=100) ? this.project.name.substring(0,100)+"..." : this.project.name :
      (this.project.defaultName && this.project.defaultName.length>=100) ? this.project.defaultName.substring(0,100)+"..." : this.project.defaultName;
  }

  public updateNameDisplay(event:any) {
    event.preventDefault();
    this.showNameWithEllipsis = !this.showNameWithEllipsis;
  }

  /**
   * Active/désactive la vue complète de la description du dépôt (i.e "étude" dans Cupidon)
   */
  public toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
    if (this.showFullDescription) {
      this.currentDescription = this.project.description;
    } else {
      this.currentDescription = this.project.description.substring(0, 260);
    }
  }

  /**
   * Changement de la langue de la metadata
   */
   public onLanguageChange() {
    this._loader.show();
    this._projectService.getPublicProject(this.project.id, true, this.language);
  }

  /**
   * Téléchargement de l'étude à un format donné
   * @param format - Le format de téléchargement
   */
  public downloadMetadata(format:string) {
    this._metadataService.downloadPublicMetadata(this.project.id,format,this.language).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.project.name + "." + format);
    });
  }

  /**
   * Télécharge un zip contenant tous les fichiers du jeu de données
   */
  public downloadZipFiles(data:Data) {
    const fileNames = data.files.map(file => file.name);
    this._dataService.downloadPublicZipFile(data.id, fileNames).subscribe(blob => {
      this._utils.launchDownloadFile(blob, data.id+".zip");
    });
  }

  /**
   * Initialise le dépôt (i.e "étude" dans Cupidon) venu du serveur
   * @param project - Projet reçu
   */
  private _initProject(project) {
    this.project = project;
    this.tooLongTitle = (!!this.project.name && this.project.name.length >= 100) || (!!this.project.defaultName && this.project.defaultName.length >= 100);
    this.showNameWithEllipsis = this.tooLongTitle;
    this.availableLanguages = [];
    _.forEach(Constants.languages, languageValue => {
      // Si la donnée possède le langage
      if (this.project.languagesAvailable.indexOf(languageValue.value) > -1) {
        // Alors on ajoute le langage dans les langages disponibles de la metadata
        this.availableLanguages.push(languageValue);
      }
    });
    // Si la langue par défaut n'est pas présente alors on prend la première langue disponible
    let hasCurrentLanguage = _.map(this.availableLanguages, 'value').indexOf(this.language) > -1;
    if (!hasCurrentLanguage) {
      this.language = this.availableLanguages[0].value;
      // Si le langage utilisé n'est pas celui par défaut, alors un reload est nécessaire
      this._initProjectFromParams(this.params);
    }
    this.locale = Constants.languageToLocale[this.language];

    this._generateContacts();
    this.currentDescription = this.showFullDescription ? this.project.description : this.project.description?.substring(0, 260);

    let params = this._session.getInitPageParams();
    if (params) {
      if (params.type) {
        this.activeTab = params.type + '-tab';
        if (params.id) {
          this._targetId = params.id
        }
      }
    }

    this._loader.hide();
  }

  /**
   * Demande la récupération du dépôt (i.e "étude" dans Cupidon) à partir de l'ID reçu
   * @param params
   */
  private _initProjectFromParams(params) {

    const projectId = params.get('id');
    this._projectService.getPublicProject(projectId, true, this.language);
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.project.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.project.contacts, c => c.role !== "pointOfContact");
    this.project.contacts = firstContacts.concat(othersContacts);
    this.pointOfContacts = firstContacts;

    _.each(Constants.contactTypes, role => {
      _.each(role.labels , l => {
        if(l.language==this.language) this.contactsRolesNames[role.value] = l.label;
      });
    });
  }
}
