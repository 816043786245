import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { LoaderService } from './loader.service';
import { UtilsService } from './utils.service';
import { Thematic } from '../models/thematic.model';
import { Uris } from '../constants/uri.constants';
import { Constants } from '../constants';
import { DataPole } from '../models/data-pole.model';
import { Keyword } from '../models/keyword.model';
import {Registry} from "../models/registry.model";
import { AnnotatorResult } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RegistryService {

  /**
   * Source privée utilisée uniquement pour déclencher l'Observable de la liste
   */
  private _registriesSource = new Subject<FormListEvent>();

   /**
    * Observable de la liste. Souscrire à cet observable pour réagir à chaque fois qu'une liste est reçue
    */
  public registries$ = this._registriesSource.asObservable();

  private _cachedLexiques = {};

  constructor(
    private _http: HttpClient,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _util: UtilsService

  ) { }

  /**
   * Lance l'appel serveur pour obtenir une liste particulière
   * @param type - type de donnée demandé
   * @param searchText - Texte à rechercher (optionnel)
   */
   public getLexiques(type: string, language: string, poles: Registry[], thematics:Thematic[], searchText: string = "", options: any = {}): void {
    let sortField = 'label';
    options.searchText = searchText;

    this.getLexiqueObs(type, language, poles, thematics, options)
      .pipe(
        map(datas => {
          let results = this._searchInList(datas, searchText, type,["label"]);
          return _.sortBy(results, data => data[sortField]);
        })
      )
      .subscribe(
        datas => this._registriesSource.next({ datas: datas, type: type })
      );
  }

  public getLexiqueObs(type: string, language: string, poles:Registry[], thematics:Thematic[], options: any = {}): Observable<any[]> {
    let TypeClass, uri;
    let httpParams: HttpParams = null;
    let strPoles = "", strThematics = "";
    if (poles && poles[0])
      strPoles = "?poles=" + poles.map(p => p.id).join(",");
    else if (thematics && thematics[0])
      strThematics = "?thematics=" + thematics.map(p => p.id).join(",");
    switch (type) {
      case 'categories':
      case 'thematics':
        TypeClass = Thematic;
        uri = Uris.REGISTRY_THEMATICS;
        if (strPoles)
          uri+= strPoles;
        else if (strThematics)
          uri+= strThematics;
        break;
      case 'dataPoles':
        TypeClass = DataPole;
        uri = Uris.EARTH_PORTAL_CLASSES_URL.replace('{ontology}', 'EASYDATA_INFRA');
        break;
      case 'keywords':
        TypeClass = Keyword;
        uri = Uris.REGISTRY_KEYWORDS;
        if (strPoles)
          uri+= strPoles;
        break;
    }

    uri = uri.replace('{language}', Constants.languageMapping[language]);

    /*if (this._cachedLexiques[type] && this._cachedLexiques[type][language]) {
      return of(this._cachedLexiques[type][language]);
    }*/
    return this._http.get<any[]>(uri, { params: httpParams })
      .pipe(
        map(datas => datas.map(data => new TypeClass().deserialize(data))),
        tap(datas => {
          if (!this._cachedLexiques[type]) {
            this._cachedLexiques[type] = [];
          }
          this._cachedLexiques[type][language] = datas;
        })
      );
  }

  public getSuggestions(type: string, language:string, searchText: string = "") {
    let list = [];
    let searchFields = [];
    switch(type) {
      case "keywords":
        list = Constants.keywordsSuggestions[language];
        searchFields = ["label"];
        break;
      default:
        break;
    }
    let results = this._searchInList(list, searchText, type, searchFields);
    results = _.sortBy(results, data => data.label.toLowerCase());
    this._registriesSource.next({ datas: results, type: type })
  }

  /**
   * Recherche une donnée dans une liste
   * @param list - Liste dans laquelle chercher
   * @param searchText - Texte à rechercher
   * @param listType - Type de donnée de la liste
   */
  private _searchInList(list: any[], searchText: string, listType: string, searchFields:string[]): any[] {
    if (!searchText) {
      return (listType === 'cities') ? [] : list;
    }
    return _.filter(list, el => {
      let addToList = false;
      _.each(searchFields, field => {
        addToList = (addToList || this._util.containsStr(searchText, el[field]));
      });
      return addToList;
    });
  }

  public annotatorSearch(ontologies:string[], searchText:string):Observable<AnnotatorResult[]> {
    if (!ontologies || ontologies.length == 0 || !searchText) {
      return null;
    }

    return this._http.get<AnnotatorResult[]>(Uris.EARTH_PORTAL_ANNOTATOR_URL.replace('{ontologies}', ontologies.join(',')).replace('{searchText}', searchText));
  }
}

export interface FormListEvent {

  /**
   * Liste obtenue
   */
  datas: any[];

  /**
   * Type de liste obtenue
   */
  type: string;

}
