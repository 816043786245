<section id="data-detail" class="meta-detail d-flex flex-column h-100" *ngIf="data">

  <div class="d-flex align-items-start page-language-choice">
    <div class="form-group row no-gutters align-items-center">
      <label for="language" class="mr-3 mb-0 white-with-border">
        <ng-container i18n>Langue utilisée / <em>Language</em></ng-container>
      </label>
      <div class="col">
        <select id="language" class="form-control" name="language" [(ngModel)]="language" (change)="onLanguageChange()">
          <option *ngFor="let availableLanguage of availableLanguages" [value]="availableLanguage.value">{{ availableLanguage.flag }}&nbsp;{{ availableLanguage.label }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto white-with-border">{{displayName()}}
      <button *ngIf="tooLongTitle && showNameWithEllipsis" (click)="updateNameDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
      <button *ngIf="tooLongTitle && !showNameWithEllipsis" (click)="updateNameDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
    </h1>
  </div>

  <div class="d-flex align-items-start page-citation-choice">
    <div class="form-group row no-gutters align-items-center">
      <div *ngIf="data.published && citationFormats && citationFormats.length > 0" class="col-6 citation-nav-bar">
        <label for="citation-formats" class="mr-3 mb-0 white-with-border">
          <ng-container i18n>Citation / <em>Citation</em></ng-container>
        </label>
        <span *ngFor="let format of citationFormats" (click)="onCitationFormatChange(format);" class="btn badge badge-primary" [ngClass]="{'selected': citationFormat === format }">{{format}}</span>
        <span class="copy-to-clipboard far fa-copy" (click)="copyCitationToClipboard()" title="Copier dans le presse-papier"></span>
      </div>
      <div [ngClass]="data.published ? 'col-6 citation-nav-bar' : 'col-12 citation-nav-bar'">
        <label class="mr-3 mb-0 white-with-border">
          <ng-container i18n>Télécharger les metadonnées</ng-container>
        </label>
        <span class="btn badge badge-primary" (click)="downloadMetadata('json')" i18n>JSON</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('pdf')" i18n>PDF</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('xml')" i18n>XML</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('datacite')" i18n>DATACITE</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('zip')" i18n>ZIP</span>
      </div>
      <div id="citation-container" [innerHtml]="citation" *ngIf="data.published && citation" class="col-12 citation"></div>
    </div>
  </div>

  <div class="d-flex align-items-start options-buttons-row">
    <a [routerLink]="!!data.projectId ? ['/projects', data.projectId, 'datas', data.id, 'edit'] : ['/my-data', data.id, 'edit']" [queryParams]="{ lang: language }" *ngIf="(isEditor || isOwner || _metadataService.canModerate(data)) && !data.published" class="btn btn-default ml-3">
      <em class="fa fa-edit"></em>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <button class="btn btn-danger ml-3" (click)="deleteData()" *ngIf="(isOwner || isModerator || isAdmin) && !data.published">
      <em class="fa fa-trash-alt"></em>
      <ng-container i18n>Supprimer</ng-container>
    </button>
    <button class="btn btn-primary ml-3" (click)="askPublication()" *ngIf="data!=null && !data.published && data.status!=null && data.status.value!='PENDING' && data.status.value!='ACCEPTED' && (isOwner || (isModerator || isAdmin))">
      <em class="fa fa-lock-open"></em>
      <ng-container i18n>Publier</ng-container>
    </button>
    <button class="btn btn-default ml-3" (click)="goToDuplication()" *ngIf="(isEditor || isOwner || _metadataService.canModerate(data)) && !data.published">
      <em class="fa fa-clone"></em>
      <ng-container i18n>Dupliquer</ng-container>
    </button>
    <a [href]="data.doi" target="_blank" *ngIf="data!=null && data.doi!=null && data.doi!=''" class="btn btn-primary ml-3">
      <em class="fa fa-passport"></em>
      <ng-container i18n>DOI: {{data.doi}}</ng-container>
    </a>
    <button (click)="downloadZipFiles()" class="btn btn-primary ml-3" *ngIf="data!=null && data.files && data.files.length>0 && filesDownloadable">
      <em class="fa fa-download"></em>
      <ng-container i18n>Télécharger le dataset(zip)</ng-container>
    </button>
  </div>

  <div class="d-flex align-items-start publication-asked" *ngIf="!!data && !data.published && projectIsPublished && !!data.status && data.status.value == 'PENDING' && _metadataService.canModerate(data)">
    <div class="col">
      <h2 class="white-with-border">Publication demandée</h2>
      <div>
        <a [routerLink]="['./edit']" [queryParams]="{ lang: language }" class="btn btn-default">Vérifier le contenu de la fiche</a>
      </div>
    </div>
  </div>

  <div class="page-header-and-content">
    <div class="informations-page-content page-content d-flex flex-column">
      <div class="informations-subcontent subcontent">
        <dl class="mb-0 row">
          <div class="col-12 col-xl-8">
            <h2 i18n>Informations données</h2>
            <div class="row">
              <div class="col-6">
                <div class="mb-2">
                  <dt class="d-inline" i18n>Statut / <em>Status</em> :</dt>&nbsp;
                  <dd class="d-inline" i18n>
                    <ng-container *ngIf="data?.status?.value=='PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
                    <ng-container *ngIf="data?.status?.value=='PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
                    <ng-container *ngIf="data?.status?.value=='ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
                    <ng-container *ngIf="data?.status?.value=='REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
                  </dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Mots-clés / <em>Keywords</em> :</dt>&nbsp;
                  <dd class="d-inline">
                    <ng-container *ngIf="data.freeKeywords!=null && data.freeKeywords.length>0">
                      <ul class="vertical-list">
                        <li *ngFor="let keyword of data.freeKeywords"><a href="{{keyword.id}}" target="_blank">{{keyword.label}}</a></li>
                      </ul>
                    </ng-container>
                    <ng-container *ngIf="data.freeKeywords==null || data.freeKeywords.length==0">Aucun mot-clé renseigné.</ng-container>
                  </dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Thématiques / <em>Thematics</em> :</dt>&nbsp;
                  <dd class="d-inline">
                    <ng-container *ngIf="data.thematics!=null && data.thematics.length>0">
                      <ul class="vertical-list">
                        <li *ngFor="let thematic of data.thematics"><a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a></li>
                      </ul>
                    </ng-container>
                    <ng-container *ngIf="data.thematics==null || data.thematics.length==0">Aucune thématique renseignée.</ng-container>
                  </dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Dépôt / <em>Repository</em> :</dt>&nbsp;
                  <dd class="d-inline">
                    <a *ngIf="project!=null" [routerLink]="[projectPath, data.projectId]">
                      {{displayProjectName()}}
                    </a>
                    <ng-container *ngIf="project==null">Non renseigné.</ng-container>
                  </dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>
                    <em class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="La date de création est au format {{localeCalendarFormats[locale].label}}."
                      placement="top" container="body"></em>
                    Date de création / <em>Creation date</em> :
                  </dt>&nbsp;
                  <dd class="d-inline">{{data.creationDate | date: localeCalendarFormats[locale].format}}</dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>
                    <em class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="La date de publication est au format {{localeCalendarFormats[locale].label}}."
                      placement="top" container="body"></em>
                    Date de publication / <em>Publication date</em> :
                  </dt>&nbsp;
                  <dd class="d-inline" *ngIf="data.publicationDate">{{data.publicationDate | date: localeCalendarFormats[locale].format}}</dd>
                  <dd class="d-inline" *ngIf="!data.publicationDate">Pas encore publiée</dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Embargo sur la donnée / <em>Embargo on data</em> :</dt>&nbsp;
                  <dd class="d-inline">{{data.embargo | i18nYesNo }}</dd>
                </div>
                <div class="mb-2" *ngIf="data.embargo">
                  <dt class="d-inline" i18n>
                    <em class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="La date de libération est au format {{localeCalendarFormats[locale].label}}."
                      placement="top" container="body"></em>
                    Date de libération du jeu de données / <em>Data release date</em> :
                  </dt>&nbsp;
                  <dd class="d-inline">{{data.releasedDate | date: localeCalendarFormats[locale].format}}</dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>
                    <em class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="Les dates de l'étendue temporelle sont au format {{localeCalendarFormats[locale].label}}."
                      placement="top" container="body"></em>
                    Étendue temporelle / <em>Temporal extent</em> :
                  </dt>&nbsp;
                  <dd class="d-inline">
                    <ng-container *ngIf="data.temporalExtentStart && data.temporalExtentEnd">Du
                      {{data.temporalExtentStart | date: localeCalendarFormats[locale].format}}
                      au
                      {{data.temporalExtentEnd | date: localeCalendarFormats[locale].format}}
                    </ng-container>
                    <ng-container *ngIf="!data.temporalExtentStart || !data.temporalExtentEnd">Non renseignée.</ng-container>
                  </dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Type de représentation spatiale / <em>Spatial resolution type</em> :</dt>&nbsp;
                  <dd class="d-inline">
                    <ng-container *ngIf="data.spatialRepresentationType!=null">{{data.spatialRepresentationType}}</ng-container>
                    <ng-container *ngIf="data.spatialRepresentationType==null">Non renseigné.</ng-container>
                  </dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Droits d'accès / <em>Access rights</em> :</dt>&nbsp;
                  <dd class="d-inline" *ngIf="data.embargo">Embargo</dd>
                  <dd class="d-inline" *ngIf="!data.embargo">Open Access</dd>
                </div>
                <ng-container *ngIf="data.files && data.files.length > 0">
                  <dt class="accordion-title">
                    <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                      (click)="hideFiles = !hideFiles">
                      <span i18n>Liens de téléchargement / <em>Download links</em></span>
                      <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideFiles}"></em>
                    </button>
                  </dt>
                  <dd class="nl2br accordion-content" [ngClass]="{closed: hideFiles}">
                    <ng-container *ngIf="filesDownloadable">
                      <a class="d-block" *ngFor="let file of data.files" (click)="downloadFile(file.name)">
                        {{file.name}}&nbsp;<em class="fa fa-external-link-alt"></em>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!filesDownloadable">
                      <small>Les fichiers ne peuvent pas être téléchargés car la date de libération ({{data.releasedDate | date: localeCalendarFormats[locale].format}}) n'est pas encore atteinte.</small>
                      <span class="d-block" *ngFor="let file of data.files">{{file.name}}</span>
                    </ng-container>
                  </dd>
                </ng-container>
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                          (click)="hideDescription = !hideDescription">
                    <span i18n>Résumé / <em>Abstract</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{data.description}}</dd>
              </div>
              <div class="col-6">
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                    (click)="hideContacts = !hideContacts">
                    <span i18n>Contacts / <em>Contacts</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContacts}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContacts}">
                  <ul class="contacts">
                    <li *ngFor="let contact of data.contacts">
                      <em class="d-block">{{contactsRolesNames[contact.role]}}</em>
                      <span *ngIf="contact.individualLastName || contact.individualFirstName || contact.organisation?.name">{{contact.individualLastName}} {{contact.individualFirstName}}
                        <ng-container *ngIf="!!contact.organisation?.name">({{contact.organisation.name}})</ng-container>
                      </span>
                      <a *ngIf="contact.email" class="d-block" [href]="'mailto:' + contact.email">{{contact.email}}</a>
                      <ul class="contactIdentifiers">
                        <li *ngFor="let contactIdentifier of contact.contactIdentifiers">
                          <dt class="d-inline">{{contactIdentifier.identifierType}} :</dt>&nbsp;
                          <dd class="d-inline" *ngIf="contactIdentifier.identifierClickable"><a [href]="contactIdentifier.identifier">{{contactIdentifier.identifier}}</a></dd>
                          <dd class="d-inline" *ngIf="!contactIdentifier.identifierClickable">{{contactIdentifier.identifier}}</dd>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </dd>
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                    (click)="hideLineage = !hideLineage">
                    <span i18n>Traçabilité (origine) / <em>Lineage (origin)</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLineage}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLineage}">{{data.lineage}}</dd>
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                          (click)="hideLegals = !hideLegals">
                    <span i18n>Contraintes légales / <em>Legal constraints</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLegals}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLegals}">
                  {{data.legalConstraints.licenseId}}
                </dd>
              </div>
            </div>
            <hr class="my-3">
            <h2>Ressources et services associés / <em>Associated resources and services</em></h2>
            <div class="row">
              <ng-container *ngFor="let resource of data.onlineResources">
                <div class="col-6" *ngIf="resource.protocol != FILE_RESOURCE_PROTOCOL && resource.protocol !== DOI_RESOURCE_PROTOCOL">
                  <dt>
                    <ng-container *ngIf="resource.name">{{resource.name}}</ng-container>
                    <ng-container *ngIf="!resource.name" i18n>(Sans nom)</ng-container>
                  </dt>
                  <dd>
                    <p>
                      <em *ngIf="resource.protocol != FILE_RESOURCE_PROTOCOL && resource.protocol !== DOI_RESOURCE_PROTOCOL" class="d-block">{{resource.protocol}}</em>
                      <a style="overflow: hidden;" class="d-block" [href]="resource.url" target="_blank">
                        {{resource.url}}
                      </a>
                      <span class="nl2br">{{resource.description}}</span>
                    </p>
                  </dd>
                </div>
              </ng-container>
              <dd class="col-6" *ngIf="data.onlineResources==null || data.onlineResources.length==0">Aucune ressource ou service associé.</dd>
            </div>
          </div>
          <div class="col-12 col-xl-4">
            <hr class="my-3 d-xl-none">
            <h2>Emprises / <em>Extents</em></h2>
            <dt>
              <ol class="extents-list" *ngIf="data!=null && data.extents!=null && data.extents.length!=0">
                <ng-container *ngFor="let extent of data.extents">
                  <li *ngIf="extent.description!=null && extent.description.label!=null && extent.description.label!=''">{{extent.description.label}}</li>
                </ng-container>
              </ol>
            </dt>
            <dd>
              <simple-map [extents]="data.extents"></simple-map>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="informations-page-content page-content d-flex flex-column">
      <div class="informations-subcontent subcontent">
        <dl class="mb-0 row">
          <div class="col-12 col-xl-12">
            <h2 i18n>Informations Metadonnées</h2>
            <div class="row">
              <div class="col-4">
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                          (click)="hideIdentifiant = !hideIdentifiant">
                    <span i18n>Identifiant / <em>Identifiant</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideIdentifiant}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideIdentifiant}">
                  <a class="d-block" [href]="data.uriMetadata">{{data.uriMetadata}}</a>
                </dd>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Date de création / <em>Creation date</em> :</dt>&nbsp;
                  <dd class="d-inline">{{data.metadataCreationDate | date: localeCalendarFormats[locale].format}}</dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Date de mise à jour / <em>Update date</em> :</dt>&nbsp;
                  <dd class="d-inline" *ngIf="data.metadataRevisionDate">{{data.metadataRevisionDate | date: localeCalendarFormats[locale].format}}</dd>
                  <dd class="d-inline" *ngIf="!data.metadataRevisionDate">Pas encore modifiée</dd>
                </div>
                <div class="mb-2">
                  <dt class="d-inline" i18n>Date de publication / <em>Publication date</em> :</dt>&nbsp;
                  <dd class="d-inline" *ngIf="data.metadataPublicationDate">{{data.metadataPublicationDate | date: localeCalendarFormats[locale].format}}</dd>
                  <dd class="d-inline" *ngIf="!data.metadataPublicationDate">Pas encore publiée</dd>
                </div>
              </div>
              <div class="col-4">
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                          (click)="hideMetadataContacts = !hideMetadataContacts">
                    <span i18n>Contacts / <em>Contacts</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideMetadataContacts}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideMetadataContacts}">
                  <ul class="contacts">
                    <li *ngIf="data.dataterraContact">
                      <em class="d-block">{{contactsRolesNames[data.dataterraContact.role]}}</em>
                      <span *ngIf="data.dataterraContact.organisation.name">{{data.dataterraContact.organisation.name}}</span>
                      <a *ngIf="data.dataterraContact.email" class="d-block" [href]="'mailto:' + data.dataterraContact.email">{{data.dataterraContact.email}}</a>
                    </li>
                    <li *ngFor="let pointOfContact of pointOfContacts">
                      <em class="d-block">{{contactsRolesNames[pointOfContact.role]}}</em>
                      <span *ngIf="pointOfContact.individualLastName || pointOfContact.individualFirstName || pointOfContact.organisation.name">{{pointOfContact.individualLastName}} {{pointOfContact.individualFirstName}}
                        <ng-container *ngIf="!!pointOfContact.organisation?.name">({{pointOfContact.organisation.name}})</ng-container>
                      </span>
                      <a *ngIf="pointOfContact.email" class="d-block" [href]="'mailto:' + pointOfContact.email">{{pointOfContact.email}}</a>
                    </li>
                  </ul>
                </dd>
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                          (click)="hideMetadaLegals = !hideMetadaLegals">
                    <span i18n>Contraintes légales / <em>Legal constraints</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideMetadaLegals}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideMetadaLegals}">
                  {{data.metadataLegalConstraints.licenseId}}
                </dd>
              </div>
              <div class="col-12 col-xl-4">
                <hr class="my-3 d-xl-none">
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                          (click)="hideContactsDistributor = !hideContactsDistributor">
                    <span i18n>Contacts de distribution / <em>Distributor Contacts</em></span>
                    <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContactsDistributor}"></em>
                  </button>
                </dt>
                <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContactsDistributor}">
                  <ul class="contacts">
                    <em class="d-block">{{contactsRolesNames[data.distributorContact.role]}}</em>
                    <span *ngIf="data.distributorContact.organisation.name">{{data.distributorContact.organisation.name}}</span>
                    <a *ngIf="data.distributorContact.email" class="d-block" [href]="'mailto:' + data.distributorContact.email">{{data.distributorContact.email}}</a>
                  </ul>
                </dd>
              </div>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>

</section>
