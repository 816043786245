import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Data, Link, Project, User } from "../../../../../models";

import { Subscription } from 'rxjs';
import { DataService, LinkService, LoaderService, MetadataService, ProjectService, SessionService } from "../../../../../services";

import { FiltrableTableComponent } from '../../../../../modules/filtrableTable/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../../../modals';
import { Router } from '@angular/router';
import { Summary } from '../../../../../models/summary.model';
import { MetadataGroup } from '../../../../../models/metadata-group.model';
import { Constants } from "../../../../../constants";

@Component({
  templateUrl: './accepted-records.component.html'
})
export class AcceptedRecordsComponent implements OnInit, OnDestroy {

  /**
   * Indique si l'utilisateur est admin
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur
   */
  public isModerator: boolean = false;

  /**
   * Liste des métadonnées
   */
  public metadataGroups:MetadataGroup[] = [];

  /**
   * Nombre total de fiches
   */
  public metadataNumber:number = 0;

  /**
   * Booléen décrivant si toutes les métadonnées sont chargées
   */
  public metadataGroupsLoaded:boolean = false;

  /**
   * Booléen indiquant si la vérification des accès de l'utilisateur est terminée
   */
  public ready:boolean = false;

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();


  constructor(
      public router:Router,
      public session: SessionService,
      private _modalService: NgbModal,
      private _metadataService:MetadataService,
      private _projectService:ProjectService,
      private _dataService:DataService,
      private _linkService:LinkService,
      private _loader: LoaderService,
      private _toastr: ToastrService,
      private _tracker: Angulartics2
  ) {}


  ngOnInit() {
    this._subs.add(this.session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._metadataService.metadata$.subscribe(metadata => this._initMetadata(metadata)));
    if(!this.session.currentUser) this.session.getCurrentUser();
    else this._checkRoles(this.session.currentUser);
    this._metadataService.getMetadataByStatus("accepted");
  }


  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public languageFlag(language:string):string {
    let languageObject = _.find(Constants.languages , l => l.value==language);
    if(languageObject!=null) return languageObject.flag;
    else return "";
  }


  public deleteMetadataGroup(metadataGroup:MetadataGroup):void {
    const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
    modalRef.componentInstance.title = $localize`Suppression du groupe de métadonnée`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer le projet "${metadataGroup.project.title}", ainsi que tous les jeux de données et ressources qui lui sont associés?`;
    modalRef.componentInstance.confirmClass = "btn-success";
    modalRef.componentInstance.confirmText = $localize`Supprimer le groupe`;
    modalRef.result.then(() => {
      this._loader.show();
      this._metadataService.deleteMetadataGroup(metadataGroup,"accepted");
    });
  }


  /**
   * Initialise la liste des métadonnées
   * @param metadata - métadonnées reçues du serveur
   */
  private _initMetadata(metadataResponse) {
    if(metadataResponse.type!="accepted") return;
    let metadataGroups = metadataResponse.content;
    this.metadataGroups = metadataGroups;
    this.metadataNumber = 0;
    for(let group of this.metadataGroups) {
      this.metadataNumber++; // Counts the project
      this.metadataNumber += group.data.length;
      this.metadataNumber += group.links.length;
    }
    this.metadataGroupsLoaded = true;
  }


  /**
   * Supprime un dépôt (i.e "étude" dans Cupidon)
   * @param project - dépôt à supprimer
   */
  public deleteProject(project: Summary): void {
    this._projectService.getProjectChildrenCount(project.identifier)
      .subscribe((count: number) => {
        if (count === 0) {
          const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
          let projectTitle = project.title.length<=100 ? project.title : project.title.substring(0,100)+"...";
          modalRef.componentInstance.title = $localize`Suppression d'un dépôt`;
          modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce dépôt : '${projectTitle}' ?`;
          modalRef.componentInstance.confirmClass = "btn-danger";
          modalRef.componentInstance.confirmText = $localize`Supprimer`;

          modalRef.result.then(() => {
            this._loader.show();
            let id = project.identifier;
            let projectEntity = new Project();
            projectEntity.id = id;
            this._projectService.deleteProject(projectEntity)
              .subscribe(() => {
                this._tracker.eventTrack.next({
                  action: "Suppression de dépôt",
                  properties: {
                    category: project.title
                  }
                });
                this._toastr.success($localize`Le dépôt '${projectTitle}' a été supprimé avec succès`);
                for(let i=0 ; i<this.metadataGroups.length ; i++) {
                  let group = this.metadataGroups[i];
                  if(group.project.identifier==id) {
                    this.metadataGroups.splice(i,1);
                    break;
                  }
                }
                this._loader.hide();
                this.dataTable.filter();
              }, error => console.error(error));
          }, () => null);
        } else {
          this._toastr.error($localize`Votre dépôt doit être totalement vide pour pouvoir être supprimé`, null, Constants.toastrErrorOptions);
        }
      }, () => null);

  }


  /**
   * Supprimme une donnée
   * @param data - donnée à supprimer
   */
  public deleteData(data: Summary): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    let dataTitle = data.title.length<=100 ? data.title : data.title.substring(0,100)+"...";
    modalRef.componentInstance.title = $localize`Suppression d'un jeu de données`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce jeu de données : '${dataTitle}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      let id = data.identifier;
      let dataEntity = new Data();
      dataEntity.id = data.identifier;
      dataEntity.projectId = data.parentUri;
      this._dataService.deleteData(dataEntity)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de jeu de données",
            properties: {
              category: data.title
            }
          });
          this._toastr.success($localize`Le jeu de données '${dataTitle}' a été supprimé avec succès`);
          for(let group of this.metadataGroups) {
            for(let j=0 ; j<group.data.length ; j++) {
              if(group.data[j].identifier==id) {
                group.data.splice(j,1);
                break;
              }
            }
          }
          this._loader.hide();
          this.dataTable.filter();
        }, error => console.error(error));
    }, () => null);
  }


  /**
   * Supprime un lien
   * @param link - lien à supprimer
   */
  public deleteLink(link: Summary): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    let linkTitle = link.title.length<=100 ? link.title : link.title.substring(0,100)+"...";
    modalRef.componentInstance.title = `Suppression d'une ressource`;
    modalRef.componentInstance.message = `Voulez-vous vraiment supprimer cette ressource : '${linkTitle}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = `Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      let id = link.identifier;
      let linkEntity = new Link();
      linkEntity.id = link.identifier;
      linkEntity.projectId = link.parentUri;
      this._linkService.deleteLink(linkEntity)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de ressource",
            properties: {
              category: link.title
            }
          });
          this._toastr.success(`La ressource '${linkTitle}' a été supprimée avec succès`);
          for(let group of this.metadataGroups) {
            for(let j=0 ; j<group.links.length ; j++) {
              if(group.links[j].identifier==id) {
                group.links.splice(j,1);
                break;
              }
            }
          }
          this._loader.hide();
          this.dataTable.filter();
        }, error => console.error(error));
    }, () => null);
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User) {
    let roles = this.session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isAdmin && !this.isModerator) this.router.navigate(["/home"]);
    this._loader.hide();
  }

}
