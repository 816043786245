import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { Contact, OnlineResource, Data, Project, Role, User } from '../../../models';
import { DataService, LinkService, LoaderService, MetadataService, ProjectService, ResourceService, SessionService, UtilsService } from '../../../services';
import { ConfirmModalComponent } from '../../modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from '../../../modules/filtrableTable/components';
import { Constants, Uris } from '../../../constants';
import { StatusService } from '../../../services/status.service';
import { Status } from '../../../models/status.model';


@Component({
  templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * Onglet initialement ouvert
   */
  public activeTab: string = "info-tab";

  /**
   * Dépôt (i.e "étude" dans Cupidon) à afficher
   */
  public project: Project;

  /**
   * Identifiant du dépôt en cours d'édition
   */
  public projectId: string;

  /**
   * Booléen décrivant si le nom du dépôt doit être affiché en entier ou non.
   */
  public showNameWithEllipsis:boolean = true;

  /**
   * Booléen décrivant si le nom du jeu de données dépasse le nombre de caractère affichable.
   */
  public tooLongTitle: boolean = false;

  /**
   * Saving URL params
   */
  public params: ParamMap = null;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Afficher la description complète du dépôt (i.e "étude" dans Cupidon) ?
   */
  public showFullDescription: boolean = false;

  /**
   * Indique si l'utilisateur est le propriétaire du dépôt (i.e "étude" dans Cupidon)
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur peut modifier le dépôt (i.e "étude" dans Cupidon)
   */
  public isEditor: boolean = false;

  /**
   * Indique si l'utilisateur peut consulter le dépôt (i.e "étude" dans Cupidon)
   */
  public isReadOnly: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur
   */
  public isModerator: boolean = false;

  /**
   * Version actuellement visible de la description du dépôt (i.e "étude" dans Cupidon)
   */
  public currentDescription: string;

  /**
   * Base url vers le téléchargement de fichiers
   */
  public datasetsFilePath: string = Uris.DATAS;

  /**
   * Liste des langues disponibles pour la metadata affichée
   */
   public availableLanguages = [];

  /**
   * Langue par défaut de la metadata
   */
  public language = Constants.defaultLanguage;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  private _targetId: string;

  private _dataTable: FiltrableTableComponent;

  /*
  * Les contacts de type point de contact
   */
  private pointOfContacts: Contact[];

  @ViewChildren(FiltrableTableComponent) dataTables: QueryList<FiltrableTableComponent>;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _projectService: ProjectService,
    private _dataService: DataService,
    private _linkService: LinkService,
    private _resourceService: ResourceService,
    private _statusService: StatusService,
    private _session: SessionService,
    private _loader: LoaderService,
    private _metadataService: MetadataService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2,
    private _utils: UtilsService
  ) { }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  public ngOnInit() {
    this._loader.show();
    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._route.paramMap.subscribe(params => {
      this.params = params;
      this._initProjectFromParams(params);
    }));
    this._subs.add(this._resourceService.editRights$.subscribe(newRights => this._updateNewRights(newRights)));
    this._subs.add(this._statusService.allStatus$.subscribe(allStatus => this._initAllStatus(allStatus)));
    this._subs.add(this._statusService.statusByUri$.subscribe(status => this._initStatus(status)));
    this._subs.add(this._statusService.statusUpdate$.subscribe(status => this._updateStatus(status)));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngAfterViewInit() {
    this._subs.add(this.dataTables.changes.subscribe((dataTables: QueryList<FiltrableTableComponent>) => {
      if (dataTables.first) {
        this._dataTable = dataTables.first;
        if (this._targetId) {
          setTimeout(() => {
            this._dataTable.filter({ target: { value: this._targetId, field: 'id' } });
            this._targetId = null;
          }, 0);
        }
      }
    }));
  }

  public displayName():string {
    if(this.project==null) return "";
    if(!this.showNameWithEllipsis) return this.project.name ? this.project.name : this.project.defaultName;
    return this.project.name ?
      (this.project.name && this.project.name.length>=100) ? this.project.name.substring(0,100)+"..." : this.project.name :
      (this.project.defaultName && this.project.defaultName.length>=100) ? this.project.defaultName.substring(0,100)+"..." : this.project.defaultName;
  }

  public updateNameDisplay(event:any) {
    event.preventDefault();
    this.showNameWithEllipsis = !this.showNameWithEllipsis;
  }

  /**
   * Active/désactive la vue complète de la description du dépôt (i.e "étude" dans Cupidon)
   */
  public toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
    if (this.showFullDescription) {
      this.currentDescription = this.project.description;
    } else {
      this.currentDescription = this.project.description.substring(0, 260);
    }
  }

  /**
   * Télécharge un zip contenant tous les fichiers du jeu de données
   */
  public downloadZipFiles(data:Data) {
    const fileNames = data.files.map(file => file.name);
    this._dataService.downloadZipFile(data.id, fileNames).subscribe(blob => {
      this._utils.launchDownloadFile(blob, data.id+".zip");
    });
  }

  /**
   * Supprimme une metadata
   * @param metadata - metadata à supprimer
   */
  public deleteMetadata(metadata: any, metadataType: string): void {
    let title, text, successText, obs;
    switch (metadataType) {
      case 'data':
        title = $localize`Suppression d'un jeu de données`;
        text = $localize`Voulez-vous vraiment supprimer ce jeu de données : '${metadata.name}' ?`;
        successText = $localize`Le jeu de données '${metadata.name}' a été supprimé avec succès`;
        obs = this._dataService.deleteData(metadata);
        break;
      case 'link':
        title = $localize`Suppression d'une ressource`;
        text = $localize`Voulez-vous vraiment supprimer cette ressource : '${metadata.name}' ?`;
        successText = $localize`La ressource '${metadata.name}' a été supprimée avec succès`;
        obs = this._linkService.deleteLink(metadata);
        break;
    }

    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = text;


    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      obs.subscribe(() => {
        this._toastr.success(successText);
        this._projectService.getProject(this.project.id, true, this.language);
      }, error => console.error(error));
    }, () => null);
  }

  /**
   * Ouvre la popup d'édition des droits
   */
  public openEditRightsModal() {
    this._resourceService.editResourceRights(this.project, 'project');
  }

  /**
   * Supprimme le dépôt (i.e "étude" dans Cupidon)
   */
  public deleteProject(): void {
    if (this.project.datas.length === 0 && this.project.links.length === 0) {
      const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
      modalRef.componentInstance.title = $localize`Suppression d'un dépôt`;
      modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce dépôt : '${this.project.name}' ?`;
      modalRef.componentInstance.confirmClass = "btn-danger";
      modalRef.componentInstance.confirmText = $localize`Supprimer`;

      modalRef.result.then(() => {
        this._loader.show();
        this._projectService.deleteProject(this.project)
          .subscribe(() => {
            this._tracker.eventTrack.next({
              action: "Suppression de dépôt",
              properties: {
                category: this.project.name
              }
            });
            this._toastr.success($localize`Le dépôt '${this.project.name}' a été supprimé avec succès`);
            this._router.navigate(['../../'], { relativeTo: this._route });
            this._loader.hide();
          }, error => console.error(error));
      }, () => null);
    } else {
      this._toastr.error($localize`Votre dépôt doit être totalement vide pour pouvoir être supprimé`, null, Constants.toastrErrorOptions);
    }
  }

  /**
   * Changement de la langue de la metadata
   */
  public onLanguageChange() {
    this._loader.show();
    this._projectService.getProject(this.project.id, true, this.language);
  }

  /**
   * Téléchargement de l'étude à un format donné
   * @param format - Le format de téléchargement
   */
  public downloadMetadata(format:string) {
    this._metadataService.downloadMetadata(this.project.id,format,this.language).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.project.name + "." + format);
    });
  }


  /**
   * Initialise le dépôt (i.e "étude" dans Cupidon) venu du serveur
   * @param project - Projet reçu
   */
  private _initProject(project) {
    this.project = project;
    this.tooLongTitle = (!!this.project.name && this.project.name.length >= 100) || (!!this.project.defaultName && this.project.defaultName.length >= 100);
    this.showNameWithEllipsis = this.tooLongTitle;
    this.availableLanguages = [];
    _.forEach(Constants.languages, languageValue => {
      // Si la donnée possède le langage
      if (this.project.languagesAvailable.indexOf(languageValue.value) > -1) {
        // Alors on ajoute le langage dans les langages disponibles de la metadata
        this.availableLanguages.push(languageValue);
      }
    });
    // Si la langue par défaut n'est pas présente alors on prend la première langue disponible
    let hasCurrentLanguage = _.map(this.availableLanguages, 'value').indexOf(this.language) > -1;
    if (!hasCurrentLanguage) {
      this.language = this.availableLanguages[0].value;
      // Si le langage utilisé n'est pas celui par défaut, alors un reload est nécessaire
      this._initProjectFromParams(this.params);
    }
    this.locale = Constants.languageToLocale[this.language];

    this.project.freeKeywords = this.project.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME);
    this.project.dataPoles = this.project.getClassifiedKeywordsWithLink(Constants.DATA_POLE_KEYWORD_NAME);

    this._generateContacts();
    this.currentDescription = this.showFullDescription ? this.project.description : this.project.description?.substring(0, 260);

    let params = this._session.getInitPageParams();
    if (params) {
      if (params.type) {
        let activParam : number;
        switch (params.type) {
          case 'info': activParam = 1 ;
            break;
          case 'data': activParam = 2 ;
            break;
          case 'link': activParam = 3 ;
            break;
          default: activParam = 1 ;
            break;
        }
      }
    }

    this._statusService.getStatusByMetadataUuid(this.project);
    this._statusService.getAllStatus();
    this._loader.hide();
  }

  /**
   * Demande la récupération du dépôt (i.e "étude" dans Cupidon) à partir de l'ID reçu
   * @param params
   */
  private _initProjectFromParams(params) {
    const projectId = params.get('id');
    this.isOwner = this._session.hasRight(projectId, 'owner');
    this.isEditor = this._session.hasRight(projectId, 'editor');
    this.isReadOnly = this._session.hasRight(projectId, 'readonly');
    this._projectService.getProject(projectId, true, this.language);
    this.projectId = projectId;
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
  }

  private _initStatus(status:Status) {
    this.project.status = status;
  }

  private _updateStatus(status:Status) {
    this.project.status = status;
  }

  /**
   * Met à jour les permissions après leur modification
   * @param newRights - nouvelles permissions
   */
  private _updateNewRights(newRights: any) {
    if (newRights) {
      this.project.individualPermissions = newRights.individualPermissions;
      this.project.groupPermissions = newRights.groupPermissions;
      this.project.generateOwners();

      if (!this._session.hasRight(this.project.id, 'owner') && !this._session.hasRole(this._session.roles.admin)) {
        this._router.navigate(['my-projects']);
      }
    }
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.project.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.project.contacts, c => c.role !== "pointOfContact");
    this.project.contacts = firstContacts.concat(othersContacts);
    this.pointOfContacts = firstContacts;

    _.each(Constants.contactTypes, role => {
      _.each(role.labels , l => {
        if(l.language==this.language) this.contactsRolesNames[role.value] = l.label;
      });
    });
  }

  private _initAllStatus(allStatus) {
    _.each(allStatus , status => {
      _.each(this.project.datas , data => {
        if(data.id==status.metadataUuid) {
          let newStatus = new Status();
          newStatus.metadataUuid = status.metadataUuid;
          newStatus.value = status.value;
          data.status = newStatus;
        }
      });
      _.each(this.project.links , link => {
        if(link.id==status.metadataUuid) {
          let newStatus = new Status();
          newStatus.metadataUuid = status.metadataUuid;
          newStatus.value = status.value;
          link.status = newStatus;
        }
      });
    });
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User):void {
    this.isOwner = this._session.hasRight(this.projectId,'owner');
    this.isEditor = this._session.hasRight(this.projectId,'editor');
    this.isReadOnly = this._session.hasRight(this.projectId,'readonly');
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isOwner && !this.isEditor && !this.isReadOnly && !this.isAdmin && !this.isModerator) this._router.navigate(["/home"]);
    this._loader.hide();
  }


}
