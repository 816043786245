<section id="account" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="white-with-border">
      <em class="fa fa-user mr-2"></em>
      <ng-container i18n>Mon compte</ng-container>
    </h1>
  </div>
  <div class="page-content">
    <div class="subcontent">
      <div class="subsection">
        <h2>Mes informations</h2>
        <dl>
          <div class="def-line">
            <dt i18n>Email :</dt>&nbsp;<dd>{{session.currentUser.email}}</dd>
          </div>
        </dl>
      </div>
      <div class="subsection">
        <h2>Mes groupes</h2>
        <ul class="group-list" *ngIf="session.currentUser.groups.length>0">
          <li *ngFor="let group of session.currentUser.groups; trackBy: trackById">
            <strong class="d-block">{{group.name}}</strong>
            <p>{{group.description}}</p>
          </li>
        </ul>
        <p *ngIf="session.currentUser.groups.length==0">Vous n'appartenez encore à aucun groupe.</p>
      </div>
      <div class="subsection">
        <h2>Mes rôles</h2>
        <div *ngIf="session.currentUser.userRoles.length>0">
          <div *ngFor="let userRole of session.currentUser.userRoles; trackBy: trackById">
            <strong class="d-block">{{displayRoleName(userRole)}}</strong>
            <ul *ngIf="userRole.thematics.length>0">
              <li *ngFor="let thematic of userRole.thematics">
                <a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a>
              </li>
            </ul>
          </div>
        </div>
        <p *ngIf="session.currentUser.userRoles.length==0">Aucun rôle ne vous a encore été affecté.</p>
      </div>
    </div>
  </div>
</section>