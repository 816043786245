import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoaderService, UtilsService} from "src/app/services";
import {Subscription} from "rxjs";
import {SuiviActivity} from "../../../../models/suivi-activity.model";
import {SuiviActivityService} from "../../../../services/suivi-activity.service";
import {Constants} from "../../../../constants";
import * as _ from "lodash";

@Component({
  templateUrl: './suivi-activity.component.html'
})
export class SuiviActivityComponent implements OnInit, OnDestroy {
  /**
   * Liste complète des groupes
   */
  public activities: SuiviActivity[] = [];

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  public locale: string = "fr";

  constructor(
    private _suiviActivityService: SuiviActivityService,
    private _loader: LoaderService,
    private _utils: UtilsService
  ) { }

  ngOnInit() {
    this._loader.show();
    this.locale = this._utils.getLocaleFromNavigator();
    this._subs.add(this._suiviActivityService.activities$.subscribe(activities => this._initActivities(activities)));
    this._suiviActivityService.getActivities();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }


  /**
   * Récupère toutes les activités
   * @param activities
   */
  private _initActivities(activities: SuiviActivity[]) {
    this.activities = activities.sort((a,b) => a.actionDate.localeCompare(b.actionDate));
    this._loader.hide();
  }
}
