<div class="container-fluid main d-flex flex-column">
  <app-public-header></app-public-header>
  <div class="d-flex h-100">
    <app-public-menu></app-public-menu>
    <div class="page w-100 h-100 d-flex flex-column">
      <section id="error-page">
        <div class="page-header">
          <h1 class="white-with-border" i18n>Erreur 404 - Page non trouvée</h1>
        </div>
        <div class="page-content">
          <div class="subcontent">
            <p i18n>La page que vous essayer d'atteindre n'existe pas ou plus.</p>
            <p>
              <ng-container i18n>Vous pouvez consulter les pages de l'entrepôt par le menu à gauche ou cliquer sur</ng-container>
              &nbsp;<a routerLink="/public/home" i18n>ce lien</a>&nbsp;
              <ng-container i18n>pour revenir à l'accueil.</ng-container>
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
