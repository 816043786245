import * as _ from 'lodash';

import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { AppConfig, AppVersion, Keycloak } from "../models";
import { Uris } from '../constants';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private _currentConfig: AppConfig;

  constructor(
    private _titleService: Title,
    private _utils: UtilsService
  ) { }

  //https://blog.bitsrc.io/dynamic-page-titles-in-angular-98ce20b5c334
  /**
   * Définit la configuration de l'application en fonction du host
   */
  public initAppConfig(): Observable<AppConfig> {
    let config = new AppConfig();
    config.title = $localize`EaSy Data`;
    config.logoName = "EaSyData_sansfond_png.png";

    this._titleService.setTitle(config.title);
    this._currentConfig = config;

    return of(this._currentConfig);
  }

  public getAppConfig(): AppConfig {
    return _.cloneDeep(this._currentConfig);
  }

  /**
   * Récupère la version et la date de la version de l'application
   * @returns La version et la date de la version de l'application
   */
  public getAppVersion(): Observable<AppVersion> {
    return this._utils.deserializeFromGetApi(AppVersion, Uris.APP_VERSION);
  }

  /**
   * Récupère les infos de connexion du Keycloak
   * @returns Infos de connexion du Keycloak
   */
  public getKeycloakUrl():Observable<Keycloak> {
    return this._utils.deserializeFromGetApi(Keycloak, Uris.APP_KEYCLOAK_URL);
  }
}
