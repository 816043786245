import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PrimeNGConfig } from "primeng/api";


@Injectable({
  providedIn: 'root',
})
export class PrimeNGUtilsService {
  constructor(
    private _translateService: TranslateService,
    private _primeNGConfig: PrimeNGConfig
  ) { }

  public setLanguage(lang:string) {
    this._translateService.use(lang);
    this._translateService.get('primeng').subscribe(data => this._primeNGConfig.setTranslation(data));
  }
}