import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { Observable, Subject, of, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as moment from 'moment';

import { Project, Permission, Link } from '../models';
import { Constants, Uris } from '../constants';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RegistryService } from './registry.service';
import * as _ from "lodash";
import "rxjs-compat/add/observable/concat";
import {MailService} from "./mail.service";

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  /**
   * Source de la liste des dépôts (i.e "étude" dans Cupidon)
   */
  private _projectsSource = new Subject<Project[]>();

  /**
   * Source d'un dépôt (i.e "étude" dans Cupidon) unique
   */
  private _projectSource = new Subject<Project>();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de dépôts (i.e "étude" dans Cupidon)
   */
  public projects$ = this._projectsSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un dépôt (i.e "étude" dans Cupidon) unique
   */
  public project$ = this._projectSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _registryService: RegistryService,
    private _toastr: ToastrService,
    private _router: Router,
    private _mail: MailService
  ) { }

  /**
   * Récupère tous les dépôts (i.e "étude" dans Cupidon) auxquels le user a accès
   */
  public getAllProjects(): void {
    this._http.get<Project[]>(Uris.PROJECTS)
      .pipe(
        map(projects => projects.map(p => new Project().deserialize(p,p.language)))
      )
      .subscribe(
        projects => this._projectsSource.next(projects)
      );
  }

  public getProjectsByStatus(status:string): void {
    this._http.get<Project[]>(Uris.PROJECTS+'findByStatus/'+status)
      .pipe(
        map(projects => projects.map(p => new Project().deserialize(p,p.language)))
      )
      .subscribe(
        projects => this._projectsSource.next(projects)
      );
  }

  /**
   * Récupère tous les dépôts (i.e "étude" dans Cupidon) dont l'utilisateur courant est propriétaire
   */
  public getUserProjects(role:string,language:string="fre"): void {
    const headers = {
      headers: new HttpHeaders({
        "Accept-Language": language
      })
    };
    this._http.get<Project[]>(Uris.PROJECTS + '?permission=' + role, headers)
      .pipe(
        map(projects => projects.map(p => new Project().deserialize(p,language)))
      )
      .subscribe(
        projects => this._projectsSource.next(projects)
      );
  }

  /**
   * Récupère toutes les dépôts (i.e "étude" dans Cupidon) publiées
   */
  public getPublishedProjects(): void {
    this._http.get<Project[]>(Uris.PUBLIC_PROJECTS)
      .pipe(
        map(projects => projects.map(p => new Project().deserialize(p,p.language)))
      )
      .subscribe(
        projects => this._projectsSource.next(projects)
      )
  }

  /**
   * Récupère le dépôt (i.e "étude" dans Cupidon) actuel ou en renvoie un nouveau à l'observable
   * @param id - ID du dépôt
   * @param getChildren - (optionnel) récupérer les enfants du projet ? vrai par défaut
   * @param language - Language à utiliser pour charger le dépôt
   */
  public getProject(id: string, getChildren: boolean = true, language: string = "fre"): void {
    if (!id) {
      let newProject = new Project();
      newProject.name = $localize`Nouveau dépôt`;
      this._projectSource.next(newProject);
    } else {
      const headers = {
        headers: new HttpHeaders({
          "Accept-Language": language
        })
      };

      this._http.get<any>(Uris.PROJECTS + id, headers)
        .pipe(
          catchError(error => {
            if (error.status === 403 || error.status === 404) {
              this._router.navigate(['/my-projects']);
            }

            return throwError(error);
          }),
          switchMap(project => {
            if (getChildren) {
              return this._http.get<any>(Uris.PROJECTS + id + '/datasets')
                .pipe(
                  catchError(error => {
                    return throwError(error);
                  }),
                  map(datas => {
                    project.datas = datas;
                    return project;
                  })
                );
            }
            return of(project);
          }),
          switchMap(project => {
            if (getChildren) {
              return this._http.get<any>(Uris.PROJECTS + id + '/links')
                .pipe(
                  catchError(error => {
                    return throwError(error);
                  }),
                  map(links => {
                    project.links = links;
                    return project;
                  })
                );
            }
            return of(project);
          }),
          switchMap(project => {
            return this._http.get<Permission[]>(Uris.RESOURCES + id + '/rights/users')
              .pipe(
                catchError(error => {
                  return throwError(error);
                }),
                map(rights => {
                  project.individualPermissions = rights;
                  return project;
                })
              );
          }),
          switchMap(project => {
            if (getChildren && this._session.hasRight(id, 'owner')) {
              return this._http.get<Permission[]>(Uris.RESOURCES + id + '/rights/groups')
                .pipe(
                  catchError(error => {
                    return throwError(error);
                  }),
                  map(rights => {
                    project.groupPermissions = rights;
                    return project;
                  })
                );
            }
            return of(project);
          }),
          map(project => new Project().deserialize(project,language))
        )
        .subscribe(
          project => this._projectSource.next(project),
          error => {
            console.error(error);
            this._loader.hide();
          }
        );
    }
  }


  /**
   * Récupère le dépôt (i.e "étude" dans Cupidon) actuel ou en renvoie un nouveau à l'observable
   * @param id - ID du dépôt
   * @param getChildren - (optionnel) récupérer les enfants du projet ? vrai par défaut
   * @param language - Language à utiliser pour charger le dépôt
   */
  public getPublicProject(id: string, getChildren: boolean = true, language: string = "fre"): void {
    const headers = {
      headers: new HttpHeaders({
        "Accept-Language": language
      })
    };
    this._http.get<any>(Uris.PUBLIC_PROJECTS + id, headers)
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
        switchMap(project => {
          if (getChildren) {
            return this._http.get<any>(Uris.PUBLIC_PROJECTS + id + '/datasets')
              .pipe(
                catchError(error => {
                  return throwError(error);
                }),
                map(datas => {
                  project.datas = datas;
                  return project;
                })
              );
          }
          return of(project);
        }),
        switchMap(project => {
          if (getChildren) {
            return this._http.get<any>(Uris.PUBLIC_PROJECTS + id + '/links')
              .pipe(
                catchError(error => {
                  return throwError(error);
                }),
                map(links => {
                  project.links = links;
                  return project;
                })
              );
          }
          return of(project);
        }),
        map(project => new Project().deserialize(project,language))
      )
      .subscribe(
        project => this._projectSource.next(project)
      );
  }

  /**
   * Compte le nombre d'enfants (données et liens) d'un dépôt (i.e "étude" dans Cupidon)
   * @param id - ID du dépôt
   */
  public getProjectChildrenCount(id: string): Observable<number> {
    return this._http.get<any>(Uris.PROJECTS + id + '/datasets')
      .pipe(
        map(datasets => {
          let count = 0;
          count += datasets.length;
          return count;
        }),
        switchMap(count => {
          return this._http.get<any>(Uris.PROJECTS + id + '/links')
            .pipe(
              map(links => {
                count += links.length;
                return count;
              })
            );
        })
      );
  }

  /**
   * Enregistre un dépôt (i.e "étude" dans Cupidon)
   * @param project - Dépôt à enregistrer
   */
  public saveProject(project:Project, askPublication:boolean): Observable<any> {

    _.each(project.links , link => {
      link.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME,project.thematics, true);
      link.contacts = project.contacts;
    });

  const updateLinks: Observable<any>[] = project.links.map(
      (link) => this._http.put<any>(`${Uris.PROJECTS}${link.projectId}/links/${link.id}`, link.serialize(), { headers: new HttpHeaders({
          'Accept-Language': link.language,
        }) })
    );

    let obs;
    // Création des headers HTTP
    const headers = new HttpHeaders({
      'Accept-Language': project.language,
    });
    let locale = Constants.languageToLocale[project.language];
    let format = Constants.localeCalendarFormats[locale].formatMoment;

    project.temporalExtentStart = !!project.temporalExtentStart ? moment(project.temporalExtentStart, format).format(Constants.dateFormatSpring) : null;
    project.temporalExtentEnd = !!project.temporalExtentEnd ? moment(project.temporalExtentEnd, format).format(Constants.dateFormatSpring) : null;

    if (project.id) {
      obs = this._http.put<any>(Uris.PROJECTS + project.id + (askPublication ? '/full' : ''), project.serialize(), { headers: headers });
    } else {
      obs = this._http.post<any>(Uris.PROJECTS + (askPublication ? 'full' : ''), project.serialize(), { headers: headers });
    }
    return obs
      .pipe(
        switchMap((result: any) => {
          Observable.concat(...updateLinks)
            .subscribe(() => {
            }, (error: any) => {return throwError(error)});
            if (askPublication)
              this._mail.sendMailPublishMetadata(result.id);
          return this._session.getUserPermissionsObs(result)
        }
        ),
        catchError(error => {
          if (error.status === 403 || error.status === 404) this._router.navigate(['/my-projects']);

          return throwError(error);
        })
      );
  }

  /**
   * Supprimer un dépôt (i.e "étude" dans Cupidon)
   * @param project - dépôt à supprimer
   */
  public deleteProject(project: Project): Observable<any> {
    return this._http.delete<any>(Uris.PROJECTS + project.id)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
