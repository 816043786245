<section [ngClass]="{hidden : !ready}" id="admin-groups" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <div class="column-title">
      <h1 class="mr-auto" i18n>Fiches refusées
        <ng-container *ngIf="metadataGroupsLoaded">({{metadataNumber}})</ng-container>
        <ng-container *ngIf="!metadataGroupsLoaded"><span class="load-icon"></span></ng-container>
      </h1>
    </div>
  </div>
  <div class="page-content d-flex flex-column h-100">
    <div class="data-table">
      <vigirisks-fitrable-table [rows]="metadataGroups" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Nom</span></th>
            <th class="language"><span i18n>Langues</span></th>
            <th scope="col" class="status" sortable-column="creationDate"><span i18n>Date de création</span></th>
            <th class="status" sortable-column="status"><span i18n>Statut</span></th>
            <th class="type" sortable-column="type"><span i18n>Type de fiche</span></th>
            <th class="users" sortable-column="users.length"><span i18n>Point de contact</span></th>
            <th *ngIf="isAdmin" class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-group>
          <tr>
            <td class="name-cell">
              <div>
                <em class="fa fa-folder-open"></em> <a [routerLink]="group.project.status!='ACCEPTED' ? ['/projects', group.project.identifier,'edit'] : ['/projects', group.project.identifier]" [queryParams]="{ lang: group.project.defaultLanguage}" title="{{group.project.title}}">{{group.project.title}} <em class="flag flag-france"></em></a>
              </div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data" class="group-sub-item">
                  <em class="fa fa-file-alt"></em><a [routerLink]="data.status!='ACCEPTED' ? ['/projects',data.parentUri,'datas',data.identifier,'edit'] : ['/projects',data.parentUri,'datas',data.identifier]" [queryParams]="{ lang: data.defaultLanguage}" title="{{data.title}}">{{data.title}}</a>
                </div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links" class="group-sub-item">
                  <em class="fa fa-link"></em><a [routerLink]="link.status!='ACCEPTED' ? ['/projects',link.parentUri,'links',link.identifier,'edit'] : ['/projects',link.parentUri,'links',link.identifier]" [queryParams]="{ lang: link.defaultLanguage}" title="{{link.title}}">{{link.title}}</a>
                </div>
              </ng-container>
            </td>
            <td>
              <div class="group-sub-item">
                <ng-container *ngFor="let language of group.project.languages">
                  <a class="flag-link" [routerLink]="group.project.status!='ACCEPTED' ? ['/projects', group.project.identifier,'edit'] : ['/projects', group.project.identifier]" [queryParams]="{ lang: language}">{{languageFlag(language)}}</a>
                </ng-container>
              </div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data" class="group-sub-item">
                  <ng-container *ngFor="let language of data.languages">
                    <a class="flag-link" [routerLink]="data.status!='ACCEPTED' ? ['/projects', data.parentUri,'datas',data.identifier,'edit'] : ['/projects', data.parentUri,'datas',data.identifier]" [queryParams]="{ lang: language}">{{languageFlag(language)}}</a>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links" class="group-sub-item">
                  <ng-container *ngFor="let language of link.languages">
                    <a class="flag-link" [routerLink]="link.status!='ACCEPTED' ? ['/projects', link.parentUri,'links',link.identifier,'edit'] : ['/projects', link.parentUri,'links',link.identifier]" [queryParams]="{ lang: language}">{{languageFlag(language)}}</a>
                  </ng-container>
                </div>
              </ng-container>
            </td>
            <td>
              <div>
                <ng-container>{{group.project.creationDate}}</ng-container>
              </div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data">
                  <ng-container>{{data.creationDate}}</ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links">
                  <ng-container>{{link.creationDate}}</ng-container>
                </div>
              </ng-container>
            </td>
            <td>
              <div>
                <span *ngIf="group.project.status=='PROGRESS'" class="status-progress">En cours de rédaction</span>
                <span *ngIf="group.project.status=='PENDING'" class="status-pending">Publication demandée</span>
                <span *ngIf="group.project.status=='ACCEPTED'" class="status-accepted">Publication acceptée</span>
                <span *ngIf="group.project.status=='REFUSED'" class="status-refused">Publication refusée</span>
              </div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data">
                  <span *ngIf="data.status=='PROGRESS'" class="status-progress">En cours de rédaction</span>
                  <span *ngIf="data.status=='PENDING'" class="status-pending">Publication demandée</span>
                  <span *ngIf="data.status=='ACCEPTED'" class="status-accepted">Publication acceptée</span>
                  <span *ngIf="data.status=='REFUSED'" class="status-refused">Publication refusée</span>
                </div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links">
                  <span *ngIf="link.status=='PROGRESS'" class="status-progress">En cours de rédaction</span>
                  <span *ngIf="link.status=='PENDING'" class="status-pending">Publication demandée</span>
                  <span *ngIf="link.status=='ACCEPTED'" class="status-accepted">Publication acceptée</span>
                  <span *ngIf="link.status=='REFUSED'" class="status-refused">Publication refusée</span>
                </div>
              </ng-container>
            </td>
            <td>
              <div>Dépôt</div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data">Jeu de données</div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links">Ressource</div>
              </ng-container>
            </td>
            <td>
              <div>
                <ng-container *ngIf="group.project.author!=null">{{group.project.author}}</ng-container>
                <ng-container *ngIf="group.project.author==null">Non renseigné</ng-container>
              </div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data">
                  <ng-container *ngIf="data.author!=null">{{data.author}}</ng-container>
                  <ng-container *ngIf="data.author==null">Non renseigné</ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links">
                  <ng-container *ngIf="link.author!=null">{{link.author}}</ng-container>
                  <ng-container *ngIf="link.author==null">Non renseigné</ng-container>
                </div>
              </ng-container>
            </td>
            <td *ngIf="isAdmin">
              <em class="fa fa-trash-alt delete-icon" (click)="deleteMetadataGroup(group)"></em>
            </td>
            <!-- TODO <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <div>
                <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                  <a class="dropdown-item" [routerLink]="['/projects', group.project.identifier]" i18n>Consulter</a>
                  <a class="dropdown-item" [routerLink]="['/projects', group.project.identifier, 'edit']" i18n>Modifier</a>
                  <a class="dropdown-item item-danger" (click)="deleteProject(group.project)" i18n>Supprimer</a>
                </div>
              </div>
              <ng-container *ngIf="group.data!=null">
                <div *ngFor="let data of group.data">
                  <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                  <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                    <a class="dropdown-item" [routerLink]="['/projects',data.parentUri,'datas',data.identifier]" i18n>Consulter</a>
                    <a class="dropdown-item" [routerLink]="['/projects',data.parentUri,'datas',data.identifier, 'edit']" i18n>Modifier</a>
                    <a class="dropdown-item item-danger" (click)="deleteData(data)" i18n>Supprimer</a>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="group.links!=null">
                <div *ngFor="let link of group.links">
                  <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                  <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                    <a class="dropdown-item" [routerLink]="['/projects',link.parentUri,'links',link.identifier]" i18n>Consulter</a>
                    <a class="dropdown-item" [routerLink]="['/projects',link.parentUri,'links',link.identifier, 'edit']" i18n>Modifier</a>
                    <a class="dropdown-item item-danger" (click)="deleteLink(data)" i18n>Supprimer</a>
                  </div>
                </div>
              </ng-container>
            </td> -->
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
