import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, UtilsService, LoaderService } from "src/app/services";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "src/app/components/modals";
import { ToastrService } from "ngx-toastr";
import { Role, UserRole } from "../../../../models";
import { SessionService } from "../../../../services";
import { Registry } from "../../../../models/registry.model";
import { Constants } from "src/app/constants";

@Component({
  templateUrl: 'role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementAdminComponent implements OnInit, OnDestroy {
  
  public isAdmin:boolean = false;
  
  public isPointOfContact:boolean = false;

  /**
   * Liste des divers rôles existants
   */
  public allRoles: Role[] = [];

  /**
   * UserRole à afficher
   */
  public userRole: UserRole;

  /**
   * Rôle du UserRole
   */
  public role: Role | null = null;

  public roleThematics: Registry[] = [];

  public otherThematics: Registry[] = [];

  /**
   * Route permettant de revenir à la page de la liste des UserRole
   */
  public cancelRoute: string = '../';

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _session: SessionService,
    private _utils: UtilsService,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.roles$.subscribe(roles => this._initAllRoles(roles)));
    this._subs.add(this._userService.userRole$.subscribe(userRole => this._initUserRole(userRole)));
    this._subs.add(this._userService.role$.subscribe(role => this._initRole(role)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initUserRoleFromParams(params)));
    this._userService.getRoles();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Demande confirmation et supprime le rôle attribué à l'utilisateur
   * @param group - groupe à supprimer
   */
  public deleteUserRole() {
    let roleId = this.userRole.roleId;
    let chosenRole = this.allRoles.find(r => r.id==roleId);
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Retrait d'un rôle`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment retirer le rôle ${chosenRole?.name} à ${this.userRole.mail} ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Retirer le rôle`;
    modalRef.result.then(() => {
      this._loader.show();
      this._userService.deleteUserRole(this.userRole)
        .subscribe(() => {
          this._toastr.success($localize`Le rôle ${chosenRole?.name} a été retiré à ${this.userRole.mail} avec succès.`);
          this._router.navigate([this.cancelRoute], { relativeTo: this._route });
          this._loader.hide();
        });
    }, () => null);
  }


  public canEdit():boolean {
    if(this._session.currentUser==undefined || this._session.currentUser==null) return false;
    if(this.allRoles.length==0) return false;
    return this.isAdmin || this.isPointOfContact;
  }


  public canDelete():boolean {
    if(this.allRoles.length==0) return false;
    if(this.isAdmin) return true;
    let pointOfContactRole:Role = this.allRoles.find(role => role.name == Constants.ROLE_REFERENT);
    if(!this.isPointOfContact) return false;
    let userPointOfContactRole:UserRole = this._session.currentUser.userRoles.find(userRole => userRole.roleId==pointOfContactRole.id);
    for(let thematic of this.userRole.thematics) {
      if(userPointOfContactRole.thematics.find(t=>t.label==thematic.label)==undefined) return false;
    }
    return true;
  }


  public getRoleThematics():Registry[] {
    if(!this.userRole) return [];
    if(!!this._session.currentUser.userRoles.find(ur => ur.roleId==1)) return this.userRole.thematics;
    let pointOfContactRole = this._session.currentUser.userRoles.filter(ur => ur.roleId==2)[0];
    if(!pointOfContactRole) return [];
    let currentUserThematics = pointOfContactRole.thematics;
    let roleThematics:Registry[] = [];
    for(let thematic of this.userRole.thematics) {
      if(currentUserThematics.find(t=>t.id==thematic.id)!=undefined) roleThematics.push(thematic);
    }
    return roleThematics;
  }

  
  public getOtherThematics():Registry[] {
    if(!this.userRole) return [];
    if(!!this._session.currentUser.userRoles.find(ur => ur.roleId==1)) return this.userRole.thematics;
    let pointOfContactRole = this._session.currentUser.userRoles.filter(ur => ur.roleId==2)[0];
    if(!pointOfContactRole) return [];
    let currentUserThematics = pointOfContactRole.thematics;
    let roleThematics:Registry[] = [];
    for(let thematic of this.userRole.thematics) {
      if(currentUserThematics.find(t=>t.id==thematic.id)==undefined) roleThematics.push(thematic);
    }
    return roleThematics;
  }

  /**
   * Initialise le groupe à afficher
   * @param group - groupe reçu du serveur
   */
  private _initUserRole(userRole: UserRole) {
    this.userRole = userRole;
    this._loader.hide();
    this._userService.getRole(this.userRole.roleId);
  }


  /**
   * Initialise la liste des différents rôles disponibles
   * @param roles : la liste des rôles
   */
  private _initRole(role:Role) {
    this.role = role;
    this._loader.hide();
  }


  /**
   * Initialise la liste des différents rôles disponibles
   * @param roles : la liste des rôles
   */
  private _initAllRoles(roles:Role[]) {
    this.allRoles = roles.sort((a,b) => a.id - b.id);
    
    let adminRole:Role = roles.find(role => role.name == Constants.ROLE_ADMIN);
    this.isAdmin = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!==-1;
    let pointOfContactRole:Role = roles.find(role => role.name == Constants.ROLE_REFERENT);
    this.isPointOfContact = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==pointOfContactRole.id)!=-1;
    if(!this.isAdmin && !this.isPointOfContact) this._router.navigate(["/home"]);

    this._loader.hide();
  }


  /**
   * Demande la récupération du groupe
   * @param params - paramètres de l'url
   */
  private _initUserRoleFromParams(params) {
    if (params && params.length > 0) {
      this._loader.show();
      this._userService.getUserRoleById(params[0]);
    }
  }

  
}