import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MetadataService, DataService, SessionService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { Data } from 'src/app/models';

@Component({
  selector: 'metadatas',
  templateUrl: './metadatas.component.html'
})
export class MetadatasComponent implements OnInit, OnDestroy {
  @Input('list') metadatas;
  @Input('editable') isEditable = true;
  @ViewChild('metadataForm', { static: true }) public metadataForm: NgForm;

  public datasList: { currentProject: Data[], otherProjects: Data[] } = null;

  @Output() onCancel = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @Output() onMetadatasChange = new EventEmitter();

  private _subs: Subscription = new Subscription();

  constructor(
    private _metadataService: MetadataService,
    private _dataService: DataService,
    private _session: SessionService
  ) { }

  ngOnInit() {
    this._initDatasList();

    _.each(this.metadatas, metadata => {
      if(metadata.dependingFields) {
        this._metadataService.updateDependentFields(metadata, this.metadatas);
      }
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  onChange($event) {
    this._metadataService.updateDependentFields($event, this.metadatas);
    this.onMetadatasChange.emit({ event: $event, metadatas: this.metadatas });
  }

  submit() {
    if (this.metadataForm.valid) {
      this.onSubmit.emit(this.metadatas);
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  updateDependentFields($event) {
    this._metadataService.updateDependentFields($event, this.metadatas);
  }
  
  /**
   * Trie la liste des données selon qu'elles soient dans le dépôt (i.e "étude" dans Cupidon) courant ou non
   * @param datas - Liste des données
   */
  private _initDatasList() {
    let datas = this._dataService.getCachedData();
    
    let context = this._session.getWorkflowContext();
    let datasList = {
      currentProject: [],
      otherProjects: []
    };
    _.each(datas, (data: Data) => {
      if(data.projectId === context.projectId) {
        datasList.currentProject.push(data);
      } else {
        datasList.otherProjects.push(data);
      }
    });

    datasList.currentProject = _.sortBy(datasList.currentProject, ['name']);
    datasList.otherProjects = _.sortBy(datasList.otherProjects, ['name']);
    this.datasList = datasList;
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param group 
   */
  public trackByName(i, item) {
    return item.name;
  }
}
