import { EntityMapper } from "./entity-mapper.model";
import { Summary } from "./summary.model";

/**
 * Entité des résumés de métadonnées
 */
export class MetadataGroup extends EntityMapper {

    /**
     * Résumé du dépôt
     */
    project:Summary = new Summary();

    /**
     * Résumé des jeux de données associés au dépôt
     */
    data:Summary[] = [];

    /**
     * Résumé des ressources associées au dépôt
     */
    links:Summary[] = [];

    constructor() {
        super();
        this._mapperDefs = [
            { front : "project" , back : "project" },
            { front : "data" , back : "data"},
            { front : "links" , back : "links"}
        ];
    }

}