import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SessionService, ResourceService, AppConfigService } from '../../../services';
import { SearchData, Group, User, Role } from '../../../models';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public search = {
    text: '',
    type: "",
    typeLabel: "Tout"
  };

  public urls: any;
  public isAdmin: boolean = false;
  public isPointOfContact: boolean = false;
  public isModerator: boolean = false;
  public groups: Group[] = [];
  public logoName: string;
  public logoTitle: string;

  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _resourceService: ResourceService,
    private _session: SessionService,
    private _router: Router,
    private _appConfigService: AppConfigService,
    private _oidcSecurityService: OidcSecurityService
  ) {
    this.search.typeLabel = $localize`Tout`;
  }

  public ngOnInit() {
    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    let appConfig = this._appConfigService.getAppConfig();
    this.logoName = appConfig.logoName;
    this.logoTitle = appConfig.title;
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Définit le type de recherche à faire
   * @param type - Type de recherche à faire
   * @param typeLabel - Label du type de recherche
   */
  public setSearchType(type: string, typeLabel: string) {
    this.search.type = type;
    this.search.typeLabel = typeLabel;
  }

  /**
   * Redirige vers la page de recherche avec les paramètres donnés
   */
  public doSearch() {
    let searchData = new SearchData();
    searchData.text = this.search.text;
    searchData.searchProjects = false;
    searchData.searchDatas = false;
    searchData.searchLinks = false;
    switch (this.search.type) {
      case 'project': searchData.searchProjects = true;
      	searchData.typologie = "étude";
        break;
      case 'data': searchData.searchDatas = true;
      	searchData.typologie = "donnée";
        break;
      case 'link': searchData.searchLinks = true;
      	searchData.typologie = "lien";
        break;
      default:
        searchData.searchProjects = true;
        searchData.searchDatas = true;
        searchData.searchLinks = true;
      	searchData.typologie = '';
    }
    this.session.searchData = searchData;
    this._router.navigate(['/resources'])
      .then(r => {
        if (!r) {
          this._resourceService.doExternalSearch(searchData);
        }
      });
  }

  /**
   * Déconnecte l'utilisateur et redirige vers la page de connexion
   */
  public logout(): void {
    this._oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log("[HEADER] Log out : "+ result));
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackById(item) {
    return item.id;
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User) {
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let pointOfContactRole = roles.find(role => role.name == Constants.ROLE_REFERENT);
    if(!!pointOfContactRole) this.isPointOfContact = user.userRoles.findIndex(userRole => userRole.roleId==pointOfContactRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
  }

}
