import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { Constants } from "src/app/constants";
import { Project } from 'src/app/models';
import { LoaderService, ProjectService, SessionService, TableFilterService, UtilsService } from 'src/app/services';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import * as _ from "lodash";

@Component({
  templateUrl: './public-projects.component.html'
})
export class PublicProjectsComponent implements OnInit, OnDestroy {
  /**
   * Liste des dépôts (i.e "études" dans Cupidon)
   */
  public projects: Project[] = [];

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name'], filter: "" }
  };

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _projectService: ProjectService,
    private _filterService: TableFilterService,
    private _loader: LoaderService,
    private _utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this._subs.add(this._projectService.projects$.subscribe(projects => this._initProjects(projects)));

    this._getProjects();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Initialise la liste des dépôts (i.e "études" dans Cupidon)
   * @param projects - dépôts reçues du serveur
   */
  private _initProjects(projects) {
    //Tri le tableau en fonction de la date de dernière modification de la plus récente à la plus ancienne
    this.projects = _.orderBy(projects, 'publicationDate','desc');
    this._loader.hide();
  }

  /**
   * Demande la liste des dépôts (i.e "études" dans Cupidon)
   */
  private _getProjects() {
    this._loader.show();
    this._projectService.getPublishedProjects();
  }
}
