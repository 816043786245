import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { SessionService } from "src/app/services";

@Component({
  templateUrl: './landpage.component.html'
})
export class LandpageComponent {

  constructor(
    private _router: Router,
    private _oidcSecurityService: OidcSecurityService,
    private _session: SessionService
  ) { }

  onBtnSubmit() {
    if (!!this._session.currentUser){
      this._router.navigate(["/my-projects"]);
    }
    else {
      this._oidcSecurityService.authorize('main');
    }
  }

  onBtnSearch() {
    this._router.navigate([!!this._session.currentUser ? "/resources" : "/public/search"]);
  }

  onBtnHelp() {
    this._router.navigate([!!this._session.currentUser ? "/help" : "/public/help"]);
  }
}
