import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { SessionService } from "src/app/services";
import { LoaderService, UserService } from "../../../services";
import { Role, UserRole } from "../../../models";

@Component({
  templateUrl: './account.component.html'
})
export class AccountComponent {

  /**
   * Liste des Roles (pour la liste à cocher)
   */
  public allRoles: Role[] = [];

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _userService: UserService,
    public session: SessionService,
    private _loader: LoaderService
  ) {}

  ngOnInit() {
    this._subs.add(this._userService.roles$.subscribe(roles => this._initAllRoles(roles)));
    this._loader.show();
    this._userService.getRoles();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayRoleName(userRole:UserRole) {
    return this.allRoles.find(r => r.id==userRole.roleId)?.name;
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackById(i, item) {
    return item.id;
  }

  /**
   * Initialise la liste des différents rôles disponibles
   * @param roles : la liste des rôles
   */
  private _initAllRoles(roles:Role[]) {
    this.allRoles = roles.sort((a,b) => a.id - b.id);
    this._loader.hide();
  }

}