import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {SuiviActivity} from "../models/suivi-activity.model";
import {HttpClient} from "@angular/common/http";
import {Uris} from "../constants";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SuiviActivityService {

  /**
   * Source de la liste des activités
   */
  private _activitySource = new Subject<SuiviActivity[]>();

  /**
   * Observable qui renvoie la liste de activités
   */
  public activities$ = this._activitySource.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Appel http pour récupérer las activités
   */
  public getActivities() {
    this._http.get<SuiviActivity[]>(Uris.ACTIVITY)
      .pipe(
        map(activities => activities.map(a => new SuiviActivity().deserialize(a)))
      )
      .subscribe(activities => this._activitySource.next(activities));
  }

}
