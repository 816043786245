import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { UserService } from "src/app/services";
import { Role, Thematic } from "src/app/models";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './manage-role-modal.component.html'
})
export class ManageRoleModalAdminComponent implements OnInit, OnDestroy {
  @Input() currentRoles: Role[] = [];

  public selectedRole: Role = null;

  public roles: Role[] = [];

  public thematics: Role[] = [];
  
  private _subs = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private _userService: UserService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.roles$.subscribe(roles => this._initRoles(roles)));

    this._userService.getRoles();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public save(roleForm: NgForm) {
    if (roleForm.valid) {
      this.modal.close(this.selectedRole);
    }
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param group 
   */
  public trackById(i, group) {
    return group.id;
  }

  private _initRoles(roles: Role[]) {
    this.roles = _.filter(roles, g => !_.find(this.currentRoles, { id: g.id }));
  }
}