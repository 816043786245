import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {Observable, of, Subject, throwError} from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Link, Project } from '../models';
import { Uris } from '../constants';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Summary } from '../models/summary.model';
import {MailService} from "./mail.service";

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  /**
   * Source de la liste des liens
   */
  private linksSource = new Subject<Link[]>();

  /**
   * Source pour un lien unique
   */
  private linkSource = new Subject<Link>();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de liens
   */
  public links$ = this.linksSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un lien unique
   */
  public link$ = this.linkSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _router: Router,
    private _mail: MailService
  ) { }

  /**
   * Demande la récupération des liens dont le user est propriétaire
   */
  public getUserLinks(): void {
    this._http.get<Link[]>(Uris.LINKS + '?permission=readonly')
      .pipe(
        map(links => links.map(l => new Link().deserialize(l))),
        switchMap(links => {
          return this._http.post<Summary[]>(Uris.PROJECTS+'/summaries/csv', links.map(l=>l.projectId))
            .pipe(
              map(projects => {
                _.each(links, link => {
                  let project = _.find(projects, { identifier : link.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    link.project = projectObject;
                  }
                });
                return links;
              })
            )
        })
      ).subscribe(
        links => this.linksSource.next(links)
      );
  }


  public getLinksByStatus(status:string): void {
    this._http.get<Link[]>(Uris.LINKS + "findByStatus/"+status)
      .pipe(
        map(links => links.map(l => new Link().deserialize(l))),
        switchMap(links => {
          return this._http.post<Summary[]>(Uris.PROJECTS+'/summaries/csv', links.map(l=>l.projectId))
            .pipe(
              map(projects => {
                _.each(links, link => {
                  let project = _.find(projects, { identifier : link.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    link.project = projectObject;
                  }
                });
                return links;
              })
            )
        })
      ).subscribe(
        links => this.linksSource.next(links)
      );
  }

  /**
   * Demande la récupération des liens publiés
   */
  public getPublishedLinks(): void {
    this._http.get<Link[]>(Uris.PUBLIC_LINKS)
      .pipe(
        map(links => links.map(l => new Link().deserialize(l))),
        switchMap(links => {
          return this._http.post<Summary[]>(Uris.PUBLIC_PROJECTS+'/summaries/csv', links.map(l=>l.projectId))
            .pipe(
              map(projects => {
                _.each(links, link => {
                  let project = _.find(projects, { identifier : link.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    link.project = projectObject;
                  }
                });
                return links;
              })
            )
        })
      ).subscribe(
        links => this.linksSource.next(links)
      );
  }

  /**
   * Demande la récupération d'un lien précis
   * @param projectId - identifiant de l'étude parente
   * @param id - identifiant du lien
   * @param language - Language à utiliser pour charger la donnée
   */
  public getLink(projectId: string, id: string, language: string = "fre"): void {
    if (id === null || id === undefined) {
      let newLink = new Link();
      newLink.name = $localize`Nouvelle ressource`;
      this.linkSource.next(newLink);
    } else {
      const headers = {
        headers: new HttpHeaders({
          "Accept-Language": language
        })
      };
      let call = this._http.get<Link>(Uris.LINKS + id, headers);
      if (projectId) {
        call = this._http.get<Link>(`${Uris.PROJECTS}${projectId}/links/${id}`, headers);
      }
      call
        .pipe(
          map(link => new Link().deserialize(link))
        )
        .subscribe(
          link => this.linkSource.next(link),
          error => {
            if (error.status === 403 || error.status === 404) {
              this._router.navigate(['/my-links']);
            }
          }
        );
    }
  }

  /**
   * Demande la récupération d'un lien précis
   * @param id - identifiant du lien
   * @param language - Language à utiliser pour charger la donnée
   */
   public getPublicLink(id: string, language: string = "fre"): void {
    const headers = {
      headers: new HttpHeaders({
        "Accept-Language": language
      })
    };
    this._http.get<Link>(Uris.PUBLIC_LINKS + id, headers)
      .pipe(
        map(link => new Link().deserialize(link))
      )
      .subscribe(
        link => this.linkSource.next(link)
      );
  }

  /**
   * Enregistre un lien
   * @param link - lien à enregistrer
   */
  public saveLink(link: Link,askPublication:boolean): Observable<any> {
    let obs;

    // Création des headers HTTP
    const headers = new HttpHeaders({
      'Accept-Language': link.language,
    });

    if (link.id) {
      obs = this._http.put<any>(`${Uris.PROJECTS}${link.projectId}/links/${link.id}` + (askPublication ? '/full' : ''), link.serialize(), { headers: headers });
    } else {
      obs = this._http.post<any>(`${Uris.PROJECTS}${link.projectId}/links/` + (askPublication ? 'full' : ''), link.serialize(), { headers: headers });
    }
    return obs
      .pipe(
        catchError(error => {
          if (error.status === 403 || error.status === 404) {
            this._router.navigate(['/my-links']);
          }

          return throwError(error);
        }),
        switchMap((result: any) => {
            if (askPublication)
              this._mail.sendMailPublishMetadata(result.id);
          return of(result);
          }
        ),
        switchMap(result => this._session.getUserPermissionsObs(result))
      );
  }

  /**
   * Publier un lien
   * @param link - lien à publier
   */
   public publishLink(link: Link): Observable<Date> {
    return this._http.put<any>(`${Uris.LINKS}${link.id}/publish`, null)
      .pipe(
        map(o => o[link.id]),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Supprimer un lien
   * @param link - lien à supprimer
   */
  public deleteLink(link: Link): Observable<any> {
    return this._http.delete<any>(`${Uris.PROJECTS}${link.projectId}/links/${link.id}`)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
