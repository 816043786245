import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { Uris } from "../constants";
import { Organization } from "../models";

@Injectable({
    providedIn: 'root',
  })
  export class RorService {
  
    constructor(
      private _http: HttpClient
    ) { }

    public searchObs(searchText:string):Observable<Organization[]> {
        return this._http.post<Organization[]>(Uris.ROR_SEARCH, encodeURIComponent(searchText));
    }
  }