<label>
  <label-info [metadata]="metadata"></label-info>
  {{metadata.label}}
</label>
<div class="bbox-container">
  <div class="row little-form-group">
    <div class="col">
      <label for="bbox-min-x" i18n>X min</label>
      <input id="bbox-min-x" type="number" name="bbox-min-x" [(ngModel)]="value.minX" #minX="ngModel"
        class="form-control" (ngModelChange)="onChange()" [readonly]="isReadOnly || metadata.readOnly">
      <div class="alert alert-danger" *ngIf="minX.invalid">
        <p *ngIf="minX.errors.required" i18n>Champ requis</p>
        <p *ngIf="minX.errors.invalidBbox" i18n>Emprise invalide</p>
      </div>
    </div>
    <div class="col">
      <label for="bbox-min-y" i18n>Y min</label>
      <input id="bbox-min-y" type="number" name="bbox-min-y" [(ngModel)]="value.minY" #minY="ngModel"
        class="form-control" (ngModelChange)="onChange()" [readonly]="isReadOnly || metadata.readOnly">
      <div class="alert alert-danger" *ngIf="minY.invalid">
        <p *ngIf="minY.errors.required" i18n>Champ requis</p>
        <p *ngIf="minY.errors.invalidBbox" i18n>Emprise invalide</p>
      </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col">
      <label for="bbox-max-x" i18n>X max</label>
      <input id="bbox-max-x" type="number" name="bbox-max-x" [(ngModel)]="value.maxX" #maxX="ngModel"
        class="form-control" (ngModelChange)="onChange()" [readonly]="isReadOnly || metadata.readOnly">
      <div class="alert alert-danger" *ngIf="maxX.invalid">
        <p *ngIf="maxX.errors.required" i18n>Champ requis</p>
        <p *ngIf="maxX.errors.invalidBbox" i18n>Emprise invalide</p>
      </div>
    </div>
    <div class="col">
      <label for="bbox-max-y" i18n>Y max</label>
      <input id="bbox-max-y" type="number" name="bbox-max-y" [(ngModel)]="value.maxY" #maxY="ngModel"
        class="form-control" (ngModelChange)="onChange()" [readonly]="isReadOnly || metadata.readOnly">
      <div class="alert alert-danger" *ngIf="maxY.invalid">
        <p *ngIf="maxY.errors.required" i18n>Champ requis</p>
        <p *ngIf="maxY.errors.invalidBbox" i18n>Emprise invalide</p>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-default btn-block" (click)="openMap()"
    [disabled]="isReadOnly || metadata.readOnly">
    <em class="fa fa-map-marker-alt"></em>
    <ng-container i18n>Sélectionner sur la carte</ng-container>
  </button>
</div>
