import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DataService, LoaderService, MetadataService, ProjectService, RightPanelAnimation, SessionService, UtilsService } from '../../../services';
import {Project, Data, Role, User, OnlineResource, Contact} from '../../../models';
import { Constants, Uris } from '../../../constants';
import {ConfirmModalComponent, DataValidatorModalComponent} from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { StatusService } from '../../../services/status.service';
import { Status } from '../../../models/status.model';
import { Extent } from 'src/app/models/extent.model';
import { FormListEvent, RegistryService } from 'src/app/services/registry.service';


@Component({
  templateUrl: './data-detail.component.html',
  animations: RightPanelAnimation
})
export class DataDetailComponent implements OnInit, OnDestroy {
  public FILE_RESOURCE_PROTOCOL = Constants.FILE_RESOURCE_PROTOCOL;
  public DOI_RESOURCE_PROTOCOL = Constants.DOI_RESOURCE_PROTOCOL;

  /**
   * Indique si l'utilisateur est le propriétaire du jeu de données
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur peut modifier jeu de données
   */
  public isEditor: boolean = false;

  /**
   * Indique si l'utilisateur peut consulter le jeu de données
   */
  public isReadOnly: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur ?
   */
  public isModerator: boolean = false;

  /**
   * Donnée affichée
   */
  public data: Data;

  /**
   * Identifiant de la donnée en cours d'édition
   */
  public dataId: string;

  /**
   * Booléen qualifiant si le jeu de données auquel est associé le JDD est publié ou non
   */
  public projectIsPublished:boolean = false;

  /**
   * Booléen décrivant si le nom du jeu de données doit être affiché en entier ou non.
   */
  public showNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom du dépôt associée au jeu de données doit être affiché en entier ou non.
   */
  public showProjectNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom du jeu de données dépasse le nombre de caractère affichable.
   */
  public tooLongTitle: boolean = false;

  /**
   * Saving URL params
   */
   public params: ParamMap = null;

  /**
   * Dépôt (i.e "étude" dans Cupidon) à laquelle appartient la donnée
   */
  public project: Project;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Base d'url vers le dépôt (i.e "étude" dans Cupidon)
   */
  public projectPath: string;

  /**
   * Liste des langues disponibles pour la metadata affichée
   */
  public availableLanguages = [];

  /**
   * Langue par défaut de la metadata
   */
  public language = Constants.defaultLanguage;

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Date de libération du JDD.
   */
  public releasedDate: Date|null = null;

  /**
   * Flag "Données téléchargeables"
   */
  public filesDownloadable = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Est-on dans le contexte d'un dépôt (i.e "étude" dans Cupidon) ?
   */
  private _isInProject: boolean = false;

  /**
   * Gestion des formats de citation
   */
  public citationFormats: string[] = [];
  public citationFormat: string;
  public citation: string;

  /**
   * Expressions régulières des dates
   */
  public localeCalendarRegExps = Constants.localeCalendarRegExps;

  /**
   * Stockage des IDs des thématiques pour le changement de langue
   */
  public thematicsIdentifiersStorage: string[] = [];

  /**
   * Stockage des IDs des mots-clés pour le changement de langue
   */
  public keywordsIdentifiersStorage: string[] = [];

  /*
  * Les contacts de type point de contact
   */
  private pointOfContacts: Contact[];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _metadataService: MetadataService,
    private _dataService: DataService,
    private _projectService: ProjectService,
    private _statusService: StatusService,
    private _session: SessionService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _toastr: ToastrService,
    private _modalService: NgbModal,
    private _tracker: Angulartics2,
    private _registryService: RegistryService,
    private _elementRef: ElementRef
  ) { }

  public ngOnInit() {
    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._dataService.data$.subscribe(data => this._initData(data)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._statusService.statusByUri$.subscribe(status => this._initStatus(status)));
    this._subs.add(this._statusService.statusUpdate$.subscribe(status => this._updateStatus(status)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateLexiques(event)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => {
      this.params = _.clone(params);
      this._initDataFromParams(params);
    }));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayName():string {
    if(this.data==null) return "";
    if(!this.showNameWithEllipsis) return this.data.name ? this.data.name : this.data.defaultName;
    return this.data.name ?
      (this.data.name && this.data.name.length>=100) ? this.data.name.substring(0,100)+"..." : this.data.name :
      (this.data.defaultName && this.data.defaultName.length>=100) ? this.data.defaultName.substring(0,100)+"..." : this.data.defaultName;
  }

  public displayProjectName():string {
    let _project = this.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    }
    else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }

  public updateNameDisplay(event:any) {
    event.preventDefault();
    this.showNameWithEllipsis = !this.showNameWithEllipsis;
  }

  public updateProjectNameDisplay(event:any) {
    event.preventDefault();
    this.showProjectNameWithEllipsis = !this.showProjectNameWithEllipsis;
  }

  /**
   * Supprimme la donnée
   */
  public deleteData(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un jeu de données`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce jeu de données : '${this.data.defaultName}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._dataService.deleteData(this.data)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de jeu de données",
            properties: {
              category: this.data.name
            }
          });
          this._toastr.success($localize`Le jeu de données '${this.data.name}' a été supprimé avec succès`);
          let returnRoute = '../../';
          if (this._isInProject) {
            returnRoute += '../../../';
          }
          this._router.navigate([returnRoute], { relativeTo: this._route });
          this._loader.hide();
        }, error => console.error(error));
    }, () => null);
  }

  public askPublication():void {
    let invalidData = this._checkAllFields();
    if (invalidData.length > 0){
      const modalRef = this._modalService.open(DataValidatorModalComponent, { windowClass: "confirm-modal" });
      modalRef.componentInstance.title = $localize`Demande de publication d'un jeu de données`;
      modalRef.componentInstance.message = $localize`La valeur et/ou le format de ces champs n'est pas correcte : `;
      modalRef.componentInstance.invalidData = invalidData;
    }else{
      const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" });
      modalRef.componentInstance.title = $localize`Demande de publication d'un jeu de données`;
      modalRef.componentInstance.message = $localize`Voulez-vous vraiment demander la publication ce jeu de données : "${this.data.name}" ?`;
      modalRef.componentInstance.confirmClass = "btn-success";
      modalRef.componentInstance.confirmText = $localize`Publier`;
      modalRef.result.then(() => {
        this._loader.show();
        this._statusService.askPublication(this.data,this.language);
      });
    }
  }

  /**
   * Publie une data
   */
  public publishData(): void {
    this._metadataService.isPublished(this.data.projectId).subscribe((isProjectPublished: boolean) => {
      const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
      modalRef.componentInstance.title = $localize`Publication d'un jeu de données`;
      modalRef.componentInstance.message = $localize`Voulez-vous vraiment publier ce jeu de données : '${this.data.name}' ?`;
      modalRef.componentInstance.confirmClass = "btn-danger";
      modalRef.componentInstance.confirmText = $localize`Publier`;

      modalRef.result.then(() => {
        this._loader.show();
        this._dataService.publishData(this.data)
          .subscribe((publicationDate: Date) => {
            this._tracker.eventTrack.next({
              action: "Publication de jeu de données",
              properties: {
                category: this.data.name
              }
            });
            this._toastr.success($localize`Le jeu de données '${this.data.name}' a été publié avec succès`);
            this.data.published = true;
            this.data.publicationDate = publicationDate;
            this._loader.hide();
          }, error => console.error(error));
      }, () => null);
    });
  }

  public createDoi(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Demande de DOI`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment demander un DOI pour ce jeu de données : '${this.data.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Demander`;

    modalRef.result.then(() => {
      this._loader.show();
      this._dataService.createDoi(this.data)
        .subscribe((doiStatus: any) => {
          this._tracker.eventTrack.next({
            action: "Demande de DOI",
            properties: {
              category: this.data.name
            }
          });

          if (!doiStatus.doiConditionDataCiteFormatIsValid) {
            this._toastr.error($localize`Une erreur liée au contenu de la métadonnée s'est produite. (Erreur technique: doiConditionDataCiteFormatIsValid)`, null, Constants.toastrErrorOptions);
          } else if (!doiStatus.doiConditionAPIConfigured) {
            this._toastr.error($localize`Une erreur liée à la configuration du service de DOI s'est produite. (Erreur technique: doiConditionAPIConfigured)`, null, Constants.toastrErrorOptions);
          } else if (!doiStatus.doiConditionStandardSupportDoiCreation) {
            this._toastr.error($localize`Une erreur liée à la configuration du service de DOI s'est produite. (Erreur technique: doiConditionStandardSupportDoiCreation)`, null, Constants.toastrErrorOptions);
          } else if (!doiStatus.doiConditionRecordIsPublic) {
            this._toastr.error($localize`Une erreur s'est produite, la métadonnée doit être publiée. (Erreur technique: doiConditionRecordIsPublic)`, null, Constants.toastrErrorOptions);
          } else {
            this._toastr.success($localize`La demande de DOI pour le jeu de données '${this.data.name}' a été effectuée avec succès`);
            this.data.doi = doiStatus.doi;
            this._loader.hide();
          }
        });
    }, () => null);
  }

  /**
   * Changement de la langue de la metadata
   */
  public onLanguageChange() {
    this._loader.show();
    this._dataService.getData(this.data.projectId, this.data.id, this.language);
  }

  /**
   * Téléchargement du JDD à un format donné
   * @param format - Le format de téléchargement
   */
  public downloadMetadata(format:string) {
    this._metadataService.downloadMetadata(this.data.id,format,this.language).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.data.name + "." + format);
    });
  }

  /**
   * Indique si l'utilisateur peut télécharger les JDD
   */
  private canDownloadDatasetsFiles() {
    this._dataService.canDownloadFiles(this.data.id).subscribe(result => {
      this.filesDownloadable = result;
    });
  }

  /**
   * Télécharge un fichier
   * @param fileName Nom du fichier
   */
  public downloadFile(fileName:string) {
    this._dataService.downloadFile(this.data.id, fileName).subscribe(blob => {
      this._utils.launchDownloadFile(blob, fileName);
    });
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initDataFromParams(params: any) {
    this._loader.show();
    let projectId = null;
    if (params.length === 2) {
      projectId = params[params.length - 2];
    }
    let dataId = params[params.length - 1];
    if (params.length > 1) {
      this._isInProject = true;
    }
    this.isOwner = this._session.hasRight(dataId, 'owner');
    this.isEditor = this._session.hasRight(dataId, 'editor');
    this.isReadOnly = this._session.hasRight(dataId, 'readonly');
    this._dataService.getData(projectId, dataId, this.language);
    this.dataId = dataId;
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
  }

  /**
   * Affiche le lien reçu
   * @param data - Lien issu du serveur
   */
  private _initData(data: Data): void {
    this.data = data;
    this.tooLongTitle = (!!this.data.name && this.data.name.length >= 100) || (!!this.data.defaultName && this.data.defaultName.length >= 100);
    this.showNameWithEllipsis = this.tooLongTitle;
    this.availableLanguages = [];
    _.forEach(Constants.languages, languageValue => {
      // Si la donnée possède le langage
      if (this.data.languagesAvailable.indexOf(languageValue.value) > -1) {
        // Alors on ajoute le langage dans les langages disponibles de la metadata
        this.availableLanguages.push(languageValue);
      }
    });
    // Si la langue par défaut n'est pas présente alors on prend la première langue disponible
    let hasCurrentLanguage = _.map(this.availableLanguages, 'value').indexOf(this.language) > -1;
    if (!hasCurrentLanguage) {
      this.language = this.availableLanguages[0].value;
      // Si le langage utilisé n'est pas celui par défaut, alors un reload est nécessaire
      this._initDataFromParams(this.params);
    }
    this.locale = Constants.languageToLocale[this.language];

    if(!!this.data.releasedDate) {
      let format = this.localeCalendarFormats[this.locale].formatMoment;
      this.releasedDate = moment(this.data.releasedDate,format).toDate();
    }

    this.data.freeKeywords = this.data.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME);
    if(this.data.projectId!==null && this.data.projectId!=="" && this.data.projectId!=="null") this._projectService.getProject(data.projectId, false, this.language);
    this._generateContacts();
    this.canDownloadDatasetsFiles();
    this._statusService.getStatusByMetadataUuid(this.data);

    this._metadataService.getPublicCitationFormats(data.id).subscribe((formats: string[]) => {
      this.citationFormats = formats;
      if (this.citationFormats !== null && this.citationFormats.length > 0) {
        this.citationFormat = this.citationFormats[0];
        this.onCitationFormatChange(this.citationFormat);
      }
    });

    this.loadRegistreSoft();

    if(this.data.projectId===null || this.data.projectId==="" || this.data.projectId==="null") {
      this._loader.hide();
    }
  }

  private _initStatus(status:Status) {
    this.data.status = status;
  }

  private _updateStatus(status:Status) {
    this.data.status = status;
  }

  /**
   * Affiche le dépôt (i.e "étude" dans Cupidon) du lien
   * @param project - Projet du lien, issu du serveur
   */
  private _initProject(project: Project): void {
    this.project = project;
    this.showProjectNameWithEllipsis = ((this.project.name!=null && this.project.name.length>=100) || this.project.defaultName.length>=100);
    if (this._session.hasRight(this.project.id, 'owner')) {
      this.projectPath = '/my-projects';
    } else {
      this.projectPath = '/projects';
    }
    this._metadataService.isPublished(this.data.projectId).subscribe((isProjectPublished: boolean) => {
      this.projectIsPublished = isProjectPublished;
    });
    this._loader.hide();
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.data.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.data.contacts, c => c.role !== 'pointOfContact');
    this.data.contacts = firstContacts.concat(othersContacts);
    this.pointOfContacts = firstContacts;

    _.each(Constants.contactTypes, role => {
      _.each(role.labels , l => {
        if(l.language==this.language) this.contactsRolesNames[role.value] = l.label;
      });
    });
  }

  /**
   * Rechargement de la citation
   */
  public reloadCitation() {
      this._metadataService.getPublicCitation(this.data.id, this.citationFormat, this.language).subscribe((citation: string) => {
        this.citation = citation
      });
  }

  /**
  * Changement du format de citation
  */
  public onCitationFormatChange(format: string) {
  this.citationFormat = format;
  this.reloadCitation();
  }

  /**
  * Copie la citation dans le presse-papier
  */
  public copyCitationToClipboard() {
  if(!navigator.clipboard) return;
  let citationContainer = this._elementRef.nativeElement.querySelector("#citation-container");
  let text:string = citationContainer.innerHTML;
  navigator.clipboard.writeText(text);
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User):void {
    this.isOwner = this._session.hasRight(this.dataId,'owner');
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isOwner && !this.isEditor && !this.isReadOnly && !this.isAdmin && !this.isModerator) this._router.navigate(["/home"]);
    this._loader.hide();
  }

  /**
   * Vérifie que les champs obligatoires  sont renseignés avant de pouvoir publier
   */
  private _checkAllFields():any[] {
    let invalidFields = [], formatRegExp = this.localeCalendarRegExps[this.locale],
    format = this.localeCalendarFormats[this.locale].formatMoment;

    // Description
    this._metadataService.checkField(invalidFields, this.data.description, 'Résumé / Abstract');

    // Thematics
    this._metadataService.checkFieldMultiple(invalidFields, this.data.thematics, 'Thématiques / Thematics');

    // Keywords
    this._metadataService.checkFieldMultiple(invalidFields, this.data.freeKeywords, 'Mots-clés / Keywords');

    // Files
    let filesToUpload = [];
    _.each(this.data.files, (file: OnlineResource) => {
      filesToUpload.push({
        label: file.name,
        existent: true,
        url: file.url
      });
    });

    if (!filesToUpload || filesToUpload.length === 0 || !filesToUpload.find(file => !file.deleted)) {
      invalidFields.push("Fichiers / Files");

    } else if (!!filesToUpload && !!filesToUpload.find(file => !file.deleted && !!file.file && !!file.file?.size && file.file?.size > Constants.FILE_SIZE_LIMIT_VALUE)) {
      invalidFields.push("Fichiers / Files");
    }


    // Legal constraints
    this._metadataService.checkField(invalidFields, this.data.legalConstraints, 'Contraintes d\'utilisation / Legal constraints');

    // Embargo
    if (this.data.embargo === null) {
      invalidFields.push("Embargo sur la donnée / Data embargo");
    }

    // Released date
    if (!!this.data.embargo) {
      if (!!this.data.releasedDate) {
        if (!formatRegExp.test(this.data.releasedDate))
          invalidFields.push("Date de libération du JDD / Data release date");
        else {
          let creationDate = (!!this.data.creationDate) ? moment(this.data.creationDate, format).toDate() : null;
          let releasedDate = (!!this.data.releasedDate) ? moment(this.data.releasedDate, format).toDate() : null;

          if (!!creationDate && !!releasedDate && releasedDate.getTime() < creationDate.getTime())
            invalidFields.push("Date de libération du JDD / Data release date");
        }
      }else{
          invalidFields.push("Date de libération du JDD / Data release date");
      }
    }

    // Spatial extents
    this._metadataService.checkFieldMultiple(invalidFields, this.data.extents, 'Emprise / Extent');

    _.each(this.data.extents, (extent: Extent) => {
      this._metadataService.checkField(invalidFields, extent.westBoundLongitude, 'Longitude ouest / West bound longitude');
      this._metadataService.checkField(invalidFields, extent.eastBoundLongitude, 'Longitude est / East bound longitude');
      this._metadataService.checkField(invalidFields, extent.northBoundLatitude, 'Latitude nord / North bound latitude ');
      this._metadataService.checkField(invalidFields, extent.southBoundLatitude, 'Latitude sud / South bound latitude');
    });

    // Lineage
    this._metadataService.checkField(invalidFields, this.data.lineage, 'Traçabilité (origine) / Lineage (origin)');

    /* Associated resources */
    _.each(this.data.onlineResources, (resource: OnlineResource) => {
      this._metadataService.checkField(invalidFields, resource.url, 'Url / Uri');
      this._metadataService.checkFieldPattern(invalidFields, resource.url, 'Url / Uri', Constants.regExps.urIHttpOrHttps);
      //this._metadataService.checkField(invalidFields, resource.type, 'Type de ressource / Resource type');
      this._metadataService.checkField(invalidFields, resource.name, 'Nom / Name');
    });
    /* End Associated resources */

    return invalidFields;
  }

  private loadRegistreSoft(){
    this.thematicsIdentifiersStorage = this.data.thematics!=null ? _.cloneDeep(this.data.thematics).map(t => t.id) : [];
    this.keywordsIdentifiersStorage = this.data.freeKeywords!=null ? _.cloneDeep(this.data.freeKeywords).map(k => k.id) : [];
    this.data.thematics = [];
    this._reloadRegistries();
  };

  /**
   * Recharge les registres suite à un changment de langue
   */
  private _reloadRegistries() {
    this._registryService.getLexiques("dataPoles",this.language, null, null);
    this._registryService.getLexiques("thematics",this.language, null, null);
    this._registryService.getLexiques("keywords", this.language, null, null, "");
  }

  /**
   * Met à jour les thématics et les keywords
   * @param event - Événement reçu du service FormListService
   */
  private _updateLexiques(event: FormListEvent): void {
    switch (event.type) {
      case 'thematics':
        if(this.data!=undefined && this.data!=null) {
          _.each(event.datas , thematic => {
            if(this.thematicsIdentifiersStorage.includes(thematic.id) && this.data.thematics.filter(t=>t.id==thematic.id).length==0) this.data.thematics.push(thematic);
          });
        }
        break;
      case 'keywords' :
        if (!!this.data) {
          _.each(event.datas, d => {
            let keyword = this.data.freeKeywords.find(k => k.id == d.id);
            if (!!keyword) {
              keyword.label = d.label;
            }
          });
        }
        break;
    }
  }

  goToDuplication() {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" });
    modalRef.componentInstance.title = $localize`Duplication d'un jeu de données`;
    modalRef.componentInstance.message = $localize`La fonction dupliquer ne se fait qu'avec les informations dans la langue affichée à l'écran`;
    modalRef.componentInstance.confirmClass = "btn-success";
    modalRef.componentInstance.confirmText = $localize`OK`;
    modalRef.result.then(() => {
      let destination = !!this.data.projectId ? `/projects/${this.data.projectId}/datas/${this.data.id}/duplicate` :
        `my-data/${this.data.id}/duplicate`;
      this._router.navigate([destination], { relativeTo: this._route, queryParams: { lang: `${this.language}` }});
    });
  }

  /**
   * Télécharge un zip contenant tous les fichiers du jeu de données
   */
  public downloadZipFiles() {
    const fileNames = this.data.files.map(file => file.name);
    this._dataService.downloadZipFile(this.data.id, fileNames).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.data.id+".zip");
    });
  }
}
