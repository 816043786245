import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: './data-validator-modal.component.html'
})
export class DataValidatorModalComponent implements OnInit {
  /**
   * Titre de la popin
   */
  @Input() title;

  /**
   * Message de la popin
   */
  @Input() message;

  @Input() invalidData;


  constructor(
    public modal: NgbActiveModal,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {

  }

  closeWindow() {
    let button = this.elementRef.nativeElement.querySelector("#close-button");
    button.click();
  }

}
