export class Constants {

  public static defaultLanguage = "fre";

  public static languageMapping = {
    "fre": "fr",
    "eng": "en"
  };

  public static METADATA_DATA_TYPE = "donnée";
  public static METADATA_LINK_TYPE = "lien";
  public static METADATA_PROJECT_TYPE = "étude";

  public static linkPattern = "^https?:\/\/\\S*";

  public static ORGANISATION_NAME = "DATATERRA";

  public static linkTypes = [
    { fre : "Code source", eng : "Source code" },
    { fre : "DMP" , eng : "DMP" },
    { fre : "Référence bibliographique" , eng : "Bibliographic reference" },
    { fre : "Site Web projet" , eng : "Project website" }
  ];

  public static linkTypesi18n = {
    fr : {
      0 : "Code source",
      1 : "Plan de Gestion de Données",
      2 : "Référence bibliographique",
      3 : "Site Web du projet"
    },
    en : {
		  0 : "Source code",
    	1 : "Data Management Plan",
      2 : "Bibliographic reference",
      3 : "Project website"
		}
	};

  public static dataTypes = [
    "Données de simulation",
    "Données d'observation",
    "Données de campagne",
    "Résultats d'enquête",
    "Données expérimentales",
    "Données longues traines"
  ];

  public static executionTimes = [
    "Moins d'une minute",
    "Moins de 5 minutes",
    "Moins d'une heure",
    "Au-delà d'une heure"
  ];

  public static spatialRepresentationTypes = [
    "grid",
    "textTable",
    "tin",
    "vector"
  ];

  public static dataOnlineResourceProtocols = [
    "API d'accès au JDD",
    "Code source en accès ouvert",
    "DOI jeu de données lié",
    "IGSN",
    "Publication en accès ouvert",
    "Service de téléchargement",
    "Service Web cartographique",
    "Site Web projet"
  ];

  public static keywordsSuggestions = {
    eng : [
      { id : "keyword-1" , label : "Body wave" },
      { id : "keyword-2" , label : "Catalog" },
      { id : "keyword-3" , label : "Compressional waves" },
      { id : "keyword-4" , label : "Engineering seismology" },
      { id : "keyword-5" , label : "Earthquake engineering" },
      { id : "keyword-6" , label : "Earthquake location" },
      { id : "keyword-7" , label : "Earthquake swarm" },
      { id : "keyword-8" , label : "Fault" },
      { id : "keyword-9" , label : "Fault plane" },
      { id : "keyword-10" , label : "Focal mechanism" },
      { id : "keyword-11" , label : "Ground based observations" },
      { id : "keyword-12" , label : "Ground motion" },
      { id : "keyword-13" , label : "Ground stations" },
      { id : "keyword-14" , label : "Ground velocity map" },
      { id : "keyword-15" , label : "Hypocenter" },
      { id : "keyword-16" , label : "Local earthquake" },
      { id : "keyword-17" , label : "Phase" },
      { id : "keyword-18" , label : "Rayleigh waves" },
      { id : "keyword-19" , label : "S-wave velocity" },
      { id : "keyword-20" , label : "Seismic background" },
      { id : "keyword-21" , label : "Seismic event" },
      { id : "keyword-22" , label : "Seismic hazard" },
      { id : "keyword-23" , label : "Seismic imaging" },
      { id : "keyword-24" , label : "Seismic station" },
      { id : "keyword-25" , label : "Seismic wave" },
      { id : "keyword-26" , label : "Seismicity" },
      { id : "keyword-27" , label : "Seismometer" },
      { id : "keyword-28" , label : "Signal processing" },
      { id : "keyword-29" , label : "Shear waves" },
      { id : "keyword-30" , label : "Surface waves" },
      { id : "keyword-31" , label : "Tectonics" },
      { id : "keyword-32" , label : "Tomographic model" },
      { id : "keyword-33" , label : "Velocimeter" },
      { id : "keyword-24" , label : "1D velocity model"}
    ],
    fre : [
      { id : "keyword-1" , label : "Ondes de volume" },
      { id : "keyword-2" , label : "Catalogue" },
      { id : "keyword-3" , label : "Ondes P" },
      { id : "keyword-4" , label : "Ingénierie sismologique" },
      { id : "keyword-5" , label : "Ingénierie parasismique" },
      { id : "keyword-6" , label : "Localisation d'un séisme" },
      { id : "keyword-7" , label : "Essaim de séismes" },
      { id : "keyword-8" , label : "Faille" },
      { id : "keyword-9" , label : "Plan de faille" },
      { id : "keyword-10" , label : "Mécanisme au foyer" },
      { id : "keyword-11" , label : "Mouvement du sol" },
      { id : "keyword-12" , label : "Observations au sol" },
      { id : "keyword-13" , label : "Stations au sol" },
      { id : "keyword-14" , label : "Carte de vitesse de groupe" },
      { id : "keyword-15" , label : "Hypocentre" },
      { id : "keyword-16" , label : "Séisme local" },
      { id : "keyword-17" , label : "Phase" },
      { id : "keyword-18" , label : "Ondes de Rayleigh" },
      { id : "keyword-19" , label : "Vitesse des ondes S" },
      { id : "keyword-20" , label : "Bruit de fond sismique" },
      { id : "keyword-21" , label : "Événement sismique" },
      { id : "keyword-22" , label : "Risque sismique" },
      { id : "keyword-23" , label : "Imagerie sismique" },
      { id : "keyword-24" , label : "Station sismique" },
      { id : "keyword-25" , label : "Onde sismique" },
      { id : "keyword-26" , label : "Sismicité" },
      { id : "keyword-27" , label : "Sismomètre" },
      { id : "keyword-28" , label : "Traitement du signal" },
      { id : "keyword-29" , label : "Ondes S" },
      { id : "keyword-30" , label : "Ondes de surface" },
      { id : "keyword-31" , label : "Tectonique" },
      { id : "keyword-32" , label : "Modèle tomographique" },
      { id : "keyword-33" , label : "Vélocimètre" },
      { id : "keyword-24" , label : "Modèle de vitesse 1D"}
    ]
  };

  public static projectCategories = [
    "Projet scientifique",
    "Projet de thèses",
    "Projet de campagne",
    "Projet de structure",
    "Projet de service",
    "Projet de logiciel",
    "Projet ANR",
    "Projet H2020"
  ];

  /*public static useConstraints = [
    "CC BY 4.0",
    "CC BY-SA 4.0",
    "CC BY-NC 4.0",
    "CC BY-NC-SA 4.0",
    "CC BY-ND 4.0",
    "CC BY-NC-ND 4.0",
    "Licence Ouverte 2.0 (Etalab)"
  ];*/

  public static contactTypes = [
    {value:"resourceProvider" , labels:[{language:"fre",label:"Fournisseur de la ressource"} , {language:"eng",label:"Resource provider"}]},
    {value:"custodian" , labels:[{language:"fre",label:"Dépositaire"} , {language:"eng",label:"Custodian"}]},
    {value:"owner" , labels:[{language:"fre",label:"Propriétaire"} , {language:"eng",label:"Owner"}]},
    {value:"user" , labels:[{language:"fre",label:"Utilisateur"} , {language:"eng",label:"User"}]},
    {value:"distributor" , labels:[{language:"fre",label:"Distributeur"} , {language:"eng",label:"Distributor"}]},
    {value:"originator" , labels:[{language:"fre",label:"Organisateur"} , {language:"eng",label:"Originator"}]},
    {value:"pointOfContact" , labels:[{language:"fre",label:"Point de contact"} , {language:"eng",label:"Point of contact"}]},
    {value:"principalInvestigator" , labels:[{language:"fre",label:"Auteur principal de la recherche"} , {language:"eng",label:"Principal investigator"}]},
    {value:"processor" , labels:[{language:"fre",label:"Auteur du traitement"} , {language:"eng",label:"Processor"}]},
    {value:"publisher" , labels:[{language:"fre",label:"Responsable de la publication"} , {language:"eng",label:"Publisher"}]},
    {value:"author" , labels:[{language:"fre",label:"Auteur"} , {language:"eng",label:"Author"}]},
    {value:"sponsor" , labels:[{language:"fre",label:"Sponsor"} , {language:"eng",label:"Sponsor"}]},
    {value:"coAuthor" , labels:[{language:"fre",label:"Co-auteur"} , {language:"eng",label:"Co-author"}]},
    {value:"collaborator" , labels:[{language:"fre",label:"Collaborateur"} , {language:"eng",label:"Collaborator"}]},
    {value:"editor" , labels:[{language:"fre",label:"Éditeur"} , {language:"eng",label:"Editor"}]},
    {value:"mediator" , labels:[{language:"fre",label:"Médiateur"} , {language:"eng",label:"Mediator"}]},
    {value:"rightsHolder" , labels:[{language:"fre",label:"Détenteur des droits"} , {language:"eng",label:"Rights holder"}]},
    {value:"contributor" , labels:[{language:"fre",label:"Contributeur"} , {language:"eng",label:"Contributor"}]},
    {value:"funder" , labels:[{language:"fre",label:"Investisseur"} , {language:"eng",label:"Funder"}]},
    {value:"stakeholder" , labels:[{language:"fre",label:"Partie prenante"} , {language:"eng",label:"Stakeholder"}]}
  ];

  public static identifiersOrigins = [
    "HAL",
    "ORCID",
    "Publons / WebOfScience",
    "Scopus",
    "ArXiv",
    "IDRef"
  ];

  public static localeCalendarConfig = {
    en: {
      firstDayOfWeek: 0,
      clear: 'Clear',
      dateFormat: 'yy-mm-dd'
    },
    fr: {
      firstDayOfWeek: 1,
      clear: 'Vider',
      dateFormat: 'yy-mm-dd'
    }
  }

  public static localeCalendarRegExps = {
    fr : /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    en : /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
  }

  public static localeCalendarFormats = {
    fr : { label: "aaaa-mm-jj", format: "yyyy-MM-dd", formatMoment: "YYYY-MM-DD", formatWithTime: "yyyy-MM-dd HH:mm" },
    en : { label: "yyyy-mm-dd", format: "yyyy-MM-dd", formatMoment: "YYYY-MM-DD", formatWithTime: "yyyy-MM-dd HH:mm" }
  }

  public static languageToLocale = { fre : "fr", eng : "en" };

  public static dateFormatSpring:string = "YYYY-MM-DD";

  public static searchDestinations = [
    { label: "France métropolitaine", extent: [-4.965820, 42.261049, 8.305664, 51.645294] },
    { label: "Martinique", extent: [-61.2291951, 14.3886707, -60.8097044, 14.8787327] },
    { label: "Guadeloupe", extent: [-62.018039, 15.644411, -60.7919656, 16.714] },
    { label: "Guyane", extent: [-54.6026945, 2.1109401, -51.5693643, 5.978] },
    { label: "Mayotte", extent: [44.7436676, -13.2732554, 45.5070347, -12.379027] },
    { label: "Réunion", extent: [55.0032808, -21.5904458, 56.0507905, -20.672764] }
  ];

  public static languages = [
    { value: 'eng', label: 'English', flag:'🇬🇧'},
    { value: 'fre', label: 'Français', flag:'🇫🇷'}
  ];

  public static FILE_SIZE_LIMIT_VALUE = 50000000000;
  public static FILE_SIZE_LIMIT = 50000;
  public static DATA_POLE_KEYWORD_NAME = "dataPole";
  public static TYPOLOGIE_KEYWORD_NAME = "typologie";
  public static EXECUTION_TIME_KEYWORD_NAME = "executionTime";
  public static THEMATICS_KEYWORD_NAME = "thematic";
  public static KEYWORD_KEYWORD_NAME = "keyword";
  public static CODE_VERSION_KEYWORD_NAME = "version";
  public static FILE_RESOURCE_CODE = "download";
  public static FILE_RESOURCE_PROTOCOL = "WWW:LINK-1.0-http--link";
  public static DOI_RESOURCE_PROTOCOL = "DOI";

  public static DEFAULT_MAP_CONFIG = {
    "name": "defaultMap",
    "projection": "EPSG:4326",
    "projections": [
      {
        "name": "EPSG:4326",
        "label": "Longitude/latitude"
      }
    ],
    "startZoom": 7,
    "minZoom": null,
    "maxZoom": null,
    "startCenter": [2.4609, 46.6061],
    "startExtent": null,
    "backgroundLayers": [
      {
        "url": "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        "label": "Fond géographique",
        "defaultLayer": true,
        "params": {
          "LAYERS": "MONDE_MOD1_FR"
        }
      }
    ],
    "defaultLayers": [],
    "destinations": [],
    "controls": {
      "layerSwitcher": false,
      "legend": false,
      "srsSelector": false,
      "backgroundSelector": false,
      "goToDestination": false,
      "measureTools": false,
      "exportable": false,
      "territoriesDestinations": false
    }
  };

  public static DESCRIBED_EXTENT_MAP_CONFIG = {
    "name": "describedExtentMap",
    "projection": "EPSG:4326",
    "projections": [
      {
        "name": "EPSG:4326",
        "label": "Longitude/latitude"
      }
    ],
    "startZoom": 7,
    "minZoom": null,
    "maxZoom": null,
    "startCenter": [2.4609, 46.6061],
    "startExtent": null,
    "backgroundLayers": [
      {
        "url": "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        "label": "Fond géographique",
        "defaultLayer": true,
        "params": {
          "LAYERS": "MONDE_MOD1_FR"
        }
      },
      {
        "url": "http://mapsref.brgm.fr/wxs/cartocom/monde",
        "label": "Fond géo et villes",
        "defaultLayer": false,
        "params": {
          "LAYERS": "Monde",
          "FORMAT": "jpeg"
        }
      }
    ],
    "defaultLayers": [],
    "destinations": [],
    "controls": {
      "layerSwitcher": true,
      "legend": false,
      "srsSelector": false,
      "backgroundSelector": true,
      "goToDestination": true,
      "measureTools": false,
      "exportable": false,
      "territoriesDestinations": false
    }
  };

  public static errorOptions = {
    bothDates: { incorrect: true, bothDates: true },
    format: { incorrect: true, format: true },
    pattern: { incorrect: true, pattern: true },
    range: { incorrect: true, range: true },
    required: { incorrect: true, required: true },
    size: { incorrect: true, size: true }
  };

  public static regExps = {
    name: /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'\-\s]+$/,
    mail: /^[a-zA-Z0-9]+(?:[\._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[\._-][a-zA-Z0-9]+)+$/,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    urIHttpOrHttps: /(http|https)?:\/\/(\S+)/
  };

  public static toastrErrorOptions = {
    disableTimeOut: true,
    closeButton: true
  };

  public static ROLE_ADMIN = "Administrateur";
  public static ROLE_REFERENT = "Référent";
  public static ROLE_MODERATOR = "Modérateur";

  public static ROLE_ADMIN_ID = 1;
  public static ROLE_REFERENT_ID = 2;
  public static ROLE_MODERATOR_ID = 3;

  public static STANDARDS_ISO_TC211 = "https://standards.iso.org/iso/19115/resources/Codelists/cat/codelists.html";

  public static SITE_FILES_FORMAT = "https://facile.cines.fr/";

  public static ONTOLOGIES_BY_IR = {
    common: {
      ontologies: ['EASYDATA_KW']
    },
    aeris: {
      pole: 'https://terra-vocabulary.org/ncl/DataTerraRepositoryFairIncubator/InfraRecherche/c_0a3d49be',
      ontologies: ['ACTRIS_VOCAB', 'AER_PFM', 'AER_PRM', 'AER_PJT', 'AER_INS']
    },
    formater: {
      pole: 'https://terra-vocabulary.org/ncl/DataTerraRepositoryFairIncubator/InfraRecherche/c_0b54e086',
      ontologies: ['FMT_FOI', 'FMT_VAR', 'FMT_NET', 'FMT_INT', 'FMT_PRO', 'FMT_PTF', 'FMT_DT']
    },
    theia: {
      pole: 'https://terra-vocabulary.org/ncl/DataTerraRepositoryFairIncubator/InfraRecherche/c_ee5f0bc7',
      ontologies: ['TOZ']
    }
  }
}
