export class License {
  /**
   * ID
   */
  licenseId: string = "";

  /**
   * URI
   */
  reference: string = "";

}
