import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _timeout = null;
  private _loaderSource = new Subject<boolean>();
  private _menuWidthChangeSource = new Subject<boolean>();

  public loader$ = this._loaderSource.asObservable();
  public menuWidthChange$ = this._menuWidthChangeSource.asObservable();

  public show(): void {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      this._loaderSource.next(true);
    }, 500);
  }

  public hide(): void {
    this._loaderSource.next(false);
    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  public changeMenuWidth(collapsed: boolean) {
    this._menuWidthChangeSource.next(collapsed);
  }
}
