import * as _ from 'lodash';

export class DynamicFacetedSearchOptions {

  /**
   * Options sélectionnées
   */
  options: {label:string,type:string}[] = [];

  /**
   * Modèle à envoyer au back
   */
  private _jsonModel: any;

  /**
   * Sérialise l'objet pour la requête serveur
   */
  public serialize(): any {
    this._jsonModel = {
      options: this.options
    };
    return this._jsonModel;
  }

}
