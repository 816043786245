<section id="resources" class="h-100 w-100">
  <div class="h-100 w-100" id="map" #mapContainer>
    <autocomplete *ngIf="map" [map]="map"></autocomplete>

    <div *ngIf="destinations && destinations.length > 0" class="ol-unselectable ol-control destination-selector map-control-selector">
      <div class="d-flex align-items-center">
        <label for="destination-selector"><ng-container i18n>Aller à</ng-container>: </label>
        <select id="destination-selector" class="form-control ml-2"
          name="destination-selector" [(ngModel)]="currentDestinationExtent"
          (ngModelChange)="chooseDestination(currentDestinationExtent)">
          <option [ngValue]="null" i18n>Aller directement à...</option>
          <option *ngFor="let dest of destinations" [ngValue]="dest.extent">{{dest.label}}</option>
        </select>
      </div>
    </div>
  </div>


  <div class="right-panel p-0" [ngClass]="{opened: displayFilters, wide: (displayFilters && displayTable && displayTableWide)}">
    <button type="button"
      class="toggle-btn btn btn-icon"
      i18n-title title="Afficher/cacher les filtres"
      (click)="displayFilters = !displayFilters">
      <em class="fa fa-caret-left"></em>
    </button>
    <form class="search-filters h-100 d-flex flex-column" (ngSubmit)="search()" #editForm="ngForm">
      <h2 i18n>Recherche de ressources</h2>
      <p class="search-message">La sélection se fera sur l'intersection globale de l'ensemble des filtres.</p>
      <button type="button" name="close" class="btn btn-icon fa fa-times" i18n-title title="Fermer le panel de recherche" (click)="displayFilters = !displayFilters"></button>
      <div class="h-100 search-filters-content">
        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-text" i18n>Recherche</label>
          <div class="col">
            <input type="text" id="search-text" class="form-control search-text" name="searchText" [(ngModel)]="searchData.text">
          </div>
        </div>

        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 pr-3 mb-0" i18n>Types de métadonnées</label>
          <div class="col">
            <div class="form-check">
              <input type="radio" id="typologie-{{facetedSearch.typologies.length}}" class="form-check-input typologie-input" name="typologie" value="" (change)="updateTypologies(null)" checked="true"/>
              <label class="form-check-label round-check" for="typologie-{{facetedSearch.typologies.length}}" i18n>
                <i class="far fa-lg" [ngClass]="!projectChecked && !dataChecked && !linkChecked ? 'fa-check-circle' : 'fa-circle'"></i>&nbsp;
                Toutes ({{totalMetadata}})</label>
            </div>
            <ng-container *ngFor="let typologie of facetedSearch.typologies ; let index=index">
              <div *ngIf="typologie!=undefined && typologie!=null && ['étude','donnée','lien'].indexOf(typologie.label) > -1" class="form-check">
                <input type="radio" id="typologie-{{index}}" class="form-check-input typologie-input" name="typologie" value="{{typologie.label}}" (change)="updateTypologies(typologie)"/>
                <label class="form-check-label round-check" for="typologie-{{index}}" i18n>
                  <i class="far fa-lg" [ngClass]="projectChecked || dataChecked || linkChecked ? 'fa-check-circle' : 'fa-circle'"></i>&nbsp;
                  <ng-container *ngIf="typologie.label=='étude'">Dépôts</ng-container>
                  <ng-container *ngIf="typologie.label=='donnée'">Jeux de données</ng-container>
                  <ng-container *ngIf="typologie.label=='lien'">Ressources</ng-container>
                  ({{typologie.count}})
                </label>
              </div>
            </ng-container>
          </div>
        </div>

        <div *ngIf="projectChecked" class="form-group column no-gutters align-items-start">
          <div class="row no-gutters align-items-start">
            <label for="data-pole-keywords" class="col-4 pr-3 mb-0">
              <em class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Il est possible de choisir plusieurs pôles de données au sein d'une liste prédéfinie. Il faut saisir au moins une lettre pour voir apparaître une liste de suggestions."
                placement="top" container="body"></em>
              <ng-container i18n>IR/Pôles de données / <em>RI/Data poles</em></ng-container>
            </label>
            <div class="col">
              <ng-container *ngFor="let datapole of facetedSearch.dataPoles ; let index=index">
                <ng-container *ngIf="showAllDataPoles">
                  <div class="form-check">
                    <input type="checkbox" id="pole-{{index}}" class="form-check-input data-pole-input" name="pole-{{index}}" value="{{datapole.label}}" (change)="updateDataPoles($event,datapole)">
                    <label class="form-check-label" for="pole-{{index}}" i18n>
                      <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.dataPoles, datapole) ? 'fa-check-square' : 'fa-square'"></i>
                      &nbsp;{{datapole.label.split(" : ")[0]}} ({{datapole.count}})</label>
                  </div>
                </ng-container>
                <ng-container *ngIf="!showAllDataPoles">
                  <div class="form-check" [ngClass]="{'hidden':index>=5}">
                    <input type="checkbox" id="pole-{{index}}" class="form-check-input data-pole-input" name="pole-{{index}}" value="{{datapole.label}}" (change)="updateDataPoles($event,datapole)">
                    <label class="form-check-label" for="pole-{{index}}" i18n>
                      <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.dataPoles, datapole) ? 'fa-check-square' : 'fa-square'"></i>
                      &nbsp;{{datapole.label.split(" : ")[0]}} ({{datapole.count}})</label>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="facetedSearch.dataPoles.length > 5">
                <button *ngIf="!showAllDataPoles" (click)="updateDataPolesDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
                <button *ngIf="showAllDataPoles" (click)="updateDataPolesDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="dataChecked" class="form-group column no-gutters align-items-start">
          <div class="row no-gutters align-items-start">
            <label for="data-pole-keywords" class="col-4 pr-3 mb-0">
              <ng-container i18n>Types de représentations spatiales / <i>Spatial representations types</i></ng-container>
            </label>
            <div class="col">
              <ng-container *ngFor="let spatialRepresentationType of facetedSearch.spatialRepresentationsTypes ; let index=index">
                <ng-container *ngIf="showAllSpatialRepresentationsTypes">
                  <div class="form-check">
                    <input type="radio" id="spatial-representation-type-{{index}}" class="form-check-input spatial-representation-type-input" name="spatial-representation-type" value="{{spatialRepresentationType.label}}" (change)="updateSpatialRepresentationType(spatialRepresentationType)">
                    <label class="form-check-label round-check" for="spatial-representation-type-{{index}}" i18n>
                      <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.spatialRepresentationType, spatialRepresentationType) ? 'fa-check-square' : 'fa-square'"></i>
                      &nbsp;{{spatialRepresentationType.label.split(" : ")[0]}} ({{spatialRepresentationType.count}})</label>
                  </div>
                </ng-container>
                <ng-container *ngIf="!showAllSpatialRepresentationsTypes">
                  <div class="form-check" [ngClass]="{'hidden':index>=5}">
                    <input type="radio" id="spatial-representation-type-{{index}}" class="form-check-input spatial-representation-type-input" name="spatial-representation-type" value="{{spatialRepresentationType.label}}" (change)="updateSpatialRepresentationType(spatialRepresentationType)">
                    <label class="form-check-label round-check" for="spatial-representation-type-{{index}}" i18n>
                      <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.spatialRepresentationType, spatialRepresentationType) ? 'fa-check-square' : 'fa-square'"></i>
                      &nbsp;{{spatialRepresentationType.label.split(" : ")[0]}} ({{spatialRepresentationType.count}})</label>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="facetedSearch.spatialRepresentationsTypes.length > 5">
                <button *ngIf="!showAllSpatialRepresentationsTypes" (click)="updateSpatialRepresentationsTypesDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
                <button *ngIf="showAllSpatialRepresentationsTypes" (click)="updateSpatialRepresentationsTypesDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="linkChecked" class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-link-type" i18n>Type de ressources</label>
          <div class="col">
            <ng-container *ngFor="let linkType of facetedSearch.linkTypes ; let index=index">
              <ng-container *ngIf="showAllLinkTypes">
                <div class="form-check">
                  <input type="radio" id="link-type-{{index}}" class="form-check-input link-type-input" name="link-type-{{index}}" value="{{linkType.label}}" (change)="updateLinkType(linkType)">
                  <label class="form-check-label round-check" for="link-type-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.linkType, linkType) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{linkType.label.split(" : ")[0]}} ({{linkType.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllLinkTypes">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="radio" id="link-type-{{index}}" class="form-check-input link-type-input" name="link-type-{{index}}" value="{{linkType.label}}" (change)="updateLinkType(linkType)">
                  <label class="form-check-label round-check" for="link-type-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.linkType, linkType) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{linkType.label.split(" : ")[0]}} ({{linkType.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.linkTypes.length > 5">
              <button *ngIf="!showAllLinkTypes" (click)="updateLinkTypeDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllLinkTypes" (click)="updateLinkTypeDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 pr-3 mb-0" for="search-thematic">Thématiques</label>
          <div class="col">
            <ng-container *ngFor="let thematic of facetedSearch.thematics; let index=index">
              <ng-container *ngIf="showAllThematics">
                <div class="form-check">
                  <input type="checkbox" id="thematic-{{index}}" class="form-check-input thematic-input" name="thematic-{{index}}" value="{{thematic.label}}" (change)="updateThematics($event,thematic)">
                  <label class="form-check-label" for="thematic-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.thematics, thematic) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{thematic.label}} ({{thematic.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllThematics">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="checkbox" id="thematic-{{index}}" class="form-check-input thematic-input" name="thematic-{{index}}" value="{{thematic.label}}" (change)="updateThematics($event,thematic)">
                  <label class="form-check-label" for="thematic-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.thematics, thematic) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{thematic.label}} ({{thematic.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.thematics.length > 5">
              <button *ngIf="!showAllThematics" (click)="updateThematicsDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllThematics" (click)="updateThematicsDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 pr-3 mb-0" for="search-keyword">Mots-clés</label>
          <div class="col">
            <ng-container *ngFor="let keyword of facetedSearch.keywords; let index=index">
              <ng-container *ngIf="showAllKeywords">
                <div class="form-check">
                  <input type="checkbox" id="keyword-{{index}}" class="form-check-input keyword-input" name="keyword-{{index}}" value="{{keyword.label}}" (change)="updateKeywords($event,keyword)">
                  <label class="form-check-label" for="keyword-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.keywords, keyword) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{keyword.label}} ({{keyword.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllKeywords">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="checkbox" id="keyword-{{index}}" class="form-check-input keyword-input" name="keyword-{{index}}" value="{{keyword.label}}" (change)="updateKeywords($event,keyword)">
                  <label class="form-check-label" for="keyword-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.keywords, keyword) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{keyword.label}} ({{keyword.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.keywords.length > 5">
              <button *ngIf="!showAllKeywords" (click)="updateKeywordsDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllKeywords" (click)="updateKeywordsDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 pr-3 mb-0" for="search-organization">Organisations</label>
          <div class="col">
            <ng-container *ngFor="let organization of facetedSearch.organizations; let index=index">
              <ng-container *ngIf="showAllOrganizations">
                <div class="form-check">
                  <input type="checkbox" id="organization-{{index}}" class="form-check-input organization-input" name="organization-{{index}}" value="{{organization.label}}" (change)="updateOrganizations($event, organization)">
                  <label class="form-check-label" for="organization-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]="checkFromList(this.searchData.organizations, organization) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{organization.label}} ({{organization.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllOrganizations">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="checkbox" id="organization-{{index}}" class="form-check-input organization-input" name="organization-{{index}}" value="{{organization.label}}" (change)="updateOrganizations($event, organization)">
                  <label class="form-check-label" for="organization-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]="checkFromList(this.searchData.organizations, organization) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{organization.label}} ({{organization.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.organizations.length > 5">
              <button *ngIf="!showAllKeywords" (click)="updateOrganizationsDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllKeywords" (click)="updateOrganizationsDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>

        <!-- <div class="form-group row no-gutters align-items-start" *ngIf="searchData.typologie!='lien'">
          <label class="col-4 pr-3 mb-0" for="search-point-of-contact">Points de contact de la ressource</label>
          <div class="col">
            <ng-container *ngFor="let pointOfContact of facetedSearch.pointsOfContactOrgForResource; let index=index">
              <ng-container *ngIf="showAllPointsOfContact">
                <div class="form-check">
                  <input type="checkbox" id="point-of-contact-{{index}}" class="form-check-input point-of-contact-input" name="point-of-contact-{{index}}" value="{{pointOfContact.label}}" (change)="updatePointsOfContact($event,pointOfContact)">
                  <label class="form-check-label round-check" for="point-of-contact-{{index}}" i18n>{{pointOfContact.label}} ({{pointOfContact.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllPointsOfContact">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="checkbox" id="point-of-contact-{{index}}" class="form-check-input point-of-contact-input" name="point-of-contact-{{index}}" value="{{pointOfContact.label}}" (change)="updatePointsOfContact($event,pointOfContact)">
                  <label class="form-check-label round-check" for="point-of-contact-{{index}}" i18n>{{pointOfContact.label}} ({{pointOfContact.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.pointsOfContactOrgForResource.length > 5">
              <button *ngIf="!showAllPointsOfContact" (click)="updatePointsOfContactDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllPointsOfContact" (click)="updatePointsOfContactDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>-->

        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 pr-3 mb-0" for="search-point-of-contact">Année de création</label>
          <div class="col">
            <ng-container *ngFor="let creationYear of facetedSearch.creationYears; let index=index">
              <ng-container *ngIf="showAllCreationYears">
                <div class="form-check">
                  <input type="checkbox" id="creation-year-{{index}}" class="form-check-input creation-year-input" name="creation-year-{{index}}" value="{{creationYear.label}}" (change)="updateCreationYear($event, creationYear)">
                  <label class="form-check-label" for="creation-year-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.creationYear, creationYear)  ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{creationYear.label}} ({{creationYear.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllCreationYears">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="checkbox" id="creation-year-{{index}}" class="form-check-input creation-year-input" name="creation-year-{{index}}" value="{{creationYear.label}}" (change)="updateCreationYear($event, creationYear)">
                  <label class="form-check-label" for="creation-year-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.creationYear, creationYear)  ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{creationYear.label}} ({{creationYear.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.creationYears.length > 5">
              <button *ngIf="!showAllCreationYears" (click)="updateCreationYearsDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllCreationYears" (click)="updateCreationYearsDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 pr-3 mb-0" for="search-point-of-contact">Année de publication</label>
          <div class="col">
            <ng-container *ngFor="let publicationYear of facetedSearch.publicationYears; let index=index">
              <ng-container *ngIf="showAllPublicationYears">
                <div class="form-check">
                  <input type="checkbox" id="publication-year-{{index}}" class="form-check-input publication-year-input" name="publication-year-{{index}}" value="{{publicationYear.label}}" (change)="updatePublicationYear($event, publicationYear)">
                  <label class="form-check-label" for="publication-year-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.publicationYear, publicationYear) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{publicationYear.label}} ({{publicationYear.count}})</label>
                </div>
              </ng-container>
              <ng-container *ngIf="!showAllPublicationYears">
                <div class="form-check" [ngClass]="{'hidden':index>=5}">
                  <input type="checkbox" id="publication-year-{{index}}" class="form-check-input publication-year-input" name="publication-year-{{index}}" value="{{publicationYear.label}}" (change)="updatePublicationYear($event, publicationYear)">
                  <label class="form-check-label" for="publication-year-{{index}}" i18n>
                    <i class="far fa-lg" [ngClass]=" checkFromElement(this.searchData.publicationYear, publicationYear) ? 'fa-check-square' : 'fa-square'"></i>
                    &nbsp;{{publicationYear.label}} ({{publicationYear.count}})</label>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="facetedSearch.publicationYears.length > 5">
              <button *ngIf="!showAllPublicationYears" (click)="updatePublicationYearsDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
              <button *ngIf="showAllPublicationYears" (click)="updatePublicationYearsDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
            </ng-container>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-start" *ngIf="!isPublic">
          <label class="col-4 pr-3 mb-0" for="search-point-of-contact">Statut</label>
          <div class="col">
            <div class="form-check">
              <input type="checkbox" id="status-0" class="form-check-input status-input" name="status-0" value="0" (change)="updateStatus($event, 0)">
              <label class="form-check-label" for="status-0" i18n>
                <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.status, 0) ? 'fa-check-square' : 'fa-square'"></i>
                &nbsp;En cours de rédaction</label>
            </div>
            <div class="form-check">
              <input type="checkbox" id="status-1" class="form-check-input status-input" name="status-1" value="1" (change)="updateStatus($event, 1)">
              <label class="form-check-label" for="status-1" i18n>
                <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.status, 1) ? 'fa-check-square' : 'fa-square'"></i>
                &nbsp;Publication demandée</label>
            </div>
            <div class="form-check">
              <input type="checkbox" id="status-2" class="form-check-input status-input" name="status-2" value="2" (change)="updateStatus($event, 2)">
              <label class="form-check-label" for="status-2" i18n>
                <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.status, 2) ? 'fa-check-square' : 'fa-square'"></i>
                &nbsp;Publication acceptée</label>
            </div>
            <div class="form-check">
              <input type="checkbox" id="status-3" class="form-check-input status-input" name="status-3" value="3" (change)="updateStatus($event, 3)">
              <label class="form-check-label" for="status-3" i18n>
                <i class="far fa-lg" [ngClass]=" checkFromList(this.searchData.status, 3) ? 'fa-check-square' : 'fa-square'"></i>
                &nbsp;Publication refusée</label>
            </div>
          </div>
        </div>

        <fieldset class="date-form-group form-group">
          <legend i18n>Étendue temporelle / <em>Time extent</em></legend>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-time-extent-start" class="col-4 mb-0">
              <ng-container i18n>Début / <em>Beginning</em></ng-container>
            </label>
            <div class="col">
              <p-calendar id="data-time-extent-start" name="data-time-extent-start"
                [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentStartField="ngModel"
                [(ngModel)]="searchData.temporalExtentStart" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                (onBlur)="checkTemporalExtentDates(timeExtentStartField, 'start', false)" (onSelect)="checkTemporalExtentDates(timeExtentStartField, 'start', true)"
                [keepInvalid]="true">
              </p-calendar>
              <div class="alert alert-danger" *ngIf="!editForm.submitted && timeExtentStartField.invalid">
                <p *ngIf="timeExtentStartField.control.errors?.format" i18n>La date de début doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="timeExtentStartField.control.errors?.rangeEnd" i18n>La date de début doit être inférieure ou égale à la date de fin</p>
                <p *ngIf="timeExtentStartField.control.errors?.rangeToday" i18n>La date de début doit être inférieure ou égale à la date du jour</p>
              </div>
              <div class="alert alert-danger" *ngIf="editForm.submitted && timeExtentStartField.invalid">
                <p *ngIf="timeExtentStartField.errors?.missing" i18n>Veuillez fournir les 2 dates ou aucune</p>
                <p *ngIf="timeExtentStartField.errors?.rangeEnd" i18n>La date de début doit être inférieure ou égale à la date de fin</p>
                <p *ngIf="timeExtentStartField.errors?.rangeToday" i18n>La date de début doit être inférieure ou égale à la date du jour</p>
                <p *ngIf="timeExtentStartField.errors?.format" i18n>La date de début doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <label for="data-time-extent-end" class="col-4 mb-0">
              <ng-container i18n>Fin / <em>Ending</em></ng-container>
            </label>
            <div class="col">
              <p-calendar id="data-time-extent-end" name="data-time-extent-end"
                [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentEndField="ngModel"
                [(ngModel)]="searchData.temporalExtentEnd" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                (onBlur)="checkTemporalExtentDates(timeExtentEndField, 'end', false)" (onSelect)="checkTemporalExtentDates(timeExtentEndField, 'end', true)"
                [keepInvalid]="true">
              </p-calendar>
              <div class="alert alert-danger" *ngIf="!editForm.submitted && timeExtentEndField.invalid">
                <p *ngIf="timeExtentEndField.control.errors?.format" i18n>La date de fin doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="timeExtentEndField.control.errors?.rangeToday" i18n>La date de fin doit être inférieure ou égale à la date du jour</p>
              </div>
              <div class="alert alert-danger" *ngIf="editForm.submitted && timeExtentEndField.invalid">
                <p *ngIf="timeExtentEndField.errors?.missing" i18n>Veuillez fournir les 2 dates ou aucune</p>
                <p *ngIf="timeExtentEndField.errors?.rangeToday" i18n>La date de fin doit être inférieure ou égale à la date du jour</p>
                <p *ngIf="timeExtentEndField.errors?.format" i18n>La date de fin doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 mb-0 pr-3">
            <ng-container i18n>Emprise</ng-container>
            <button type="button" class="btn btn-primary btn-block mt-1 extent-btn" (click)="chooseExtent()"
              i18n-title title="Choisir l'emprise sur la carte" [disabled]="currentlyExtentChoose">
              <em class="fas fa-map-marked-alt mr-2"></em>
              <ng-container i18n>Choisir</ng-container>
            </button>
          </label>
          <div class="col">
            <div class="extent-backdrop" [ngClass]="{active: currentlyExtentChoose}">
              <p i18n>
                Pour sélectionner l'emprise :<br/>
                PC : Ctrl + cliquer-glisser<br/>
                Mac : Cmd + cliquer-déposer
              </p>
            </div>
            <button *ngIf="searchData.extent" type="button" class="btn btn-clear-extent" (click)="resetExtent()"
              i18n-title title="Effacer l'emprise"  i18n-title>
              <em>&times;</em>
            </button>
            <div class="rosace">
              <div class="west" *ngIf="searchData.extent">{{searchData.extent[0]}}</div>
              <div class="north" *ngIf="searchData.extent">{{searchData.extent[1]}}</div>
              <div class="east" *ngIf="searchData.extent">{{searchData.extent[2]}}</div>
              <div class="south" *ngIf="searchData.extent">{{searchData.extent[3]}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-3">
        <button type="button" class="btn btn-default btn-block mr-2" (click)="resetSearch()" i18n>Réinitialiser</button>
        <button type="submit" class="btn btn-primary btn-block ml-2 mt-0" i18n>Rechercher</button>
      </div>

    </form>
    <div class="results-table d-flex flex-column" [ngClass]="{opened: displayTable}">
      <h2 i18n>Résultats de la recherche</h2>
      <button type="button" name="close" class="btn btn-icon fa fa-times"
       i18n-title title="Fermer les résultats de la recherche" (click)="toggleDisplayTable(false)"></button>
      <div class="d-flex pl-4 align-items-end mb-3">
        <button type="button" class="btn btn-default btn-sm wide-btn" (click)="displayTableWide = !displayTableWide">
          <em class="fa fa-arrow-left"></em>
          <em class="fa fa-arrow-right"></em>
          <ng-container *ngIf="!displayTableWide" i18n>Agrandir le tableau</ng-container>
          <ng-container *ngIf="displayTableWide" i18n>Réduire le tableau</ng-container>
        </button>
        <vigirisks-table-counter>
          <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
            <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucun résultat</p>
            <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} résultats</p>
          </ng-template>
        </vigirisks-table-counter>
      </div>
      <div class="results-table-content h-100">
        <div class="data-table">
          <vigirisks-fitrable-table [rows]="resources" #dataTable>
            <ng-template head-content>
              <tr>
                <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
                <th class="date" sortable-column="lastUpdate"><span i18n>Dernière modification</span></th>
                <th class="actions text-center">&nbsp;</th>
                <th class="actions text-center">&nbsp;</th>
              </tr>
            </ng-template>
            <ng-template body-content let-resource>
              <tr (mouseover)="highlightResourceFeatures(resource, true)"
                (mouseout)="highlightResourceFeatures(resource, false)"
                [ngClass]="{hovered: resource.hovered}">
                <td class="name">
                  <em class="fa" title="{{resource.typeLabel}}"
                    [ngClass]="{
                      'fa-link': resource.type == 'link',
                      'fa-file-alt': resource.type == 'data',
                      'fa-folder-open': resource.type == 'project'
                    }"></em>
                  <a [routerLink]="[resource.url]">
                    {{resource.name}}
                  </a>
                </td>
                <td class="text-center">{{resource.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
                <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
                  <button type="button" class="btn btn-icon far fa-eye" i18n-title title="Voir plus d'informations" ngbDropdownToggle></button>
                  <div class="resource-details" aria-labelledby="row-dropdown" ngbDropdownMenu>
                    <dl>
                      <dt i18n>Contact</dt>
                      <dd>{{resource.sourceObject.ownerEmail}} <ng-container *ngIf="resource.sourceObject.ownerOrganisation">({{resource.sourceObject.ownerOrganisation}})</ng-container> </dd>
                      <ng-container *ngIf="resource.sourceObject.project">
                        <dt i18n>Dépôt</dt>
                        <dd>
                          <a [routerLink]="session.hasRight(resource.sourceObject.project.id, 'owner') ? ['/my-projects', resource.sourceObject.project.id] : (session.hasRight(resource.sourceObject.project.id, 'readonly') ? ['/projects', resource.sourceObject.project.id] : ['/public/search/metadata', resource.sourceObject.project.id])">
                            {{resource.sourceObject.project.name}}
                          </a>
                        </dd>
                      </ng-container>
                      <ng-container *ngIf="resource.sourceObject.typeName">
                        <dt i18n>Type</dt>
                        <dd>{{resource.sourceObject.typeName}}</dd>
                      </ng-container>
                    </dl>
                    <ng-container *ngIf="resource.extents.length > 0">
                      <button type="button" class="btn btn-icon project-extent" i18n-title title="Centrer sur l'emprise" (click)="goToExtent(resource.sourceObject)"> <em class="fa fa-expand"></em> </button>
                    </ng-container>
                  </div>
                </td>
                <td class="text-center btn-icon-container" container="body" placement="bottom-right">
                  <div class="" ngbDropdown #myDrop="ngbDropdown">
                    <button type="button" id="dropdown-resource-{{resource.id}}" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownAnchor (focus)="myDrop.open()"></button>
                    <div ngbDropdownMenu aria-labelledby="dropdown-resource-{{resource.id}}">
                      <a class="dropdown-item" [routerLink]="[resource.url]" i18n>Consulter</a>
                      <a *ngIf="(resource.type === 'link' && session.hasRight(resource.sourceObject.projectId, 'editor')) ||
                        (resource.type === 'data' && !resource.published && session.hasRight(resource.sourceObject.projectId, 'editor')) ||
                        (resource.type === 'project' && !resource.published && session.hasRight(resource.id, 'editor'))"
                        class="dropdown-item" [routerLink]="[resource.url, 'edit']" i18n>Modifier</a>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </vigirisks-fitrable-table>
        </div>
      </div>
      <div class="d-flex mb-3">
        <vigirisks-pagination class="mx-auto" [maxSize]="5"></vigirisks-pagination>
      </div>
    </div>
  </div>
</section>
