import { ContactIdentifier } from "./contact-identifier.model";
import { EntityMapper } from "./entity-mapper.model";
import { Organization } from "./organization.model";

export class Contact extends EntityMapper {
  /**
   * Nom de la personne
   */
  individualLastName: string = "";

  /**
   * Prénom de la personne
   */
  individualFirstName: string = "";

  /**
   * Organisation
   */
  organisation: Organization = null;

  /**
   * Mail de contact
   */
  email: string = "";

  /**
   * Rôle pour la ressource
   */
  role: string = "user";

  /**
   * Liste des identifiants du contact
   */
  contactIdentifiers: ContactIdentifier[] = [];

  constructor() {
    super();

    this._mapperDefs = [
      { front: "individualLastName", back: "individualLastName" },
      { front: "individualFirstName", back: "individualFirstName" },
      { front: "organisation", back: "organisation" },
      { front: "email", back: "email" },
      { front: "role", back: "role" },
      { front: "contactIdentifiers", class: ContactIdentifier }
    ];
  }
}