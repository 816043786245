import { Constants } from "../constants";
import * as moment from 'moment';

export class SearchData {
  /**
   * Texte à rechercher
   */
  text: string = "";

  /**
   * Rechercher dans les dépôts (i.e "études" dans Cupidon) ?
   */
  searchProjects: boolean = true;

  /**
   * Rechercher dans les données ?
   */
  searchDatas: boolean = true;

  /**
   * Rechercher dans les liens ?
   */
  searchLinks: boolean = true;

  /**
   * Type de donnée à chercher
   */
  //dataType: string = null;

  /**
   * Type de lien à chercher
   */
  linkType: string = "";

  /**
   * Id du user propriétaire des ressources recherchées
   */
  ownerId: string = "";

  /**
   * Emprise dans laquelle chercher
   */
  extent: [number, number, number, number] = null;

  /**
   * Type de métadonnées
   */
  typologie: string = "";

  /**
   * Mots-clés
   */
  keywords: string[] = [];

  /**
   * Types de représentations spatiales
   */
  spatialRepresentationType:string = "";

  /**
   * Thématiques
   */
  thematics: string[] = [];

  /**
   * Pôles de données
   */
  dataPoles: {label:string,shortLabel:string}[] = [];

  /**
   * Organisations
   */
  organizations: string[] = [];

  /**
   * Points de contact des ressources
   */
  pointsOfContactOrgForResource: string[] = [];

  /**
   * Années de création
   */
  creationYear: string = "";

  /**
   * Années de publication
   */
  publicationYear: string = "";

  /**
   * Statuts
   */
  status:string[] = [];

  /**
   * Date de début
   */
  temporalExtentStart?: Date = null;

  /**
   * Date de fin
   */
  temporalExtentEnd?: Date = null;

  /**
   * Modèle à envoyer au back
   */
  private _jsonModel: any;

  /**
   * Sérialise l'objet pour la requête serveur
   */
  public serialize(language:string): any {
    let metadataTypes = []

    if(this.typologie==="étude") {
      metadataTypes.push({
        type: 'étude',
        subType: null
      });
    }
    else if(this.typologie==="lien") {
      metadataTypes.push({
        type: 'lien',
        subType: this.linkType
      });
    }
    else if(this.typologie==="donnée") {
      metadataTypes.push({
        type: 'donnée',
        //subType: this.dataType
        subType: null
      });
    }

    let extent = null;
    if(this.extent) {
      extent = {
        eastBoundLongitude: this.extent[0],
        northBoundLatitude: this.extent[1],
        westBoundLongitude: this.extent[2],
        southBoundLatitude: this.extent[3]
      };
    }

    let locale = Constants.languageToLocale[language]
    let format = Constants.localeCalendarFormats[locale].format;
    
    this._jsonModel = {
      extent: extent,
      freeText: (this.text!==null && this.text!=="") ? this.text : "",
      metadataType: metadataTypes,
      owner: this.ownerId,
      maxRecords: 100,
      startPosition: 1,
      keywords: this.keywords,
      thematics: this.thematics,
      dataPoles: this.dataPoles.map(d => d.label),
      organizations: this.organizations,
      spatialRepresentationType: this.spatialRepresentationType,
      pointsOfContactOrgForResource: this.pointsOfContactOrgForResource,
      creationYears: this.creationYear,
      publicationYears: this.publicationYear,
      status: this.status,
      dateFrom : (this.temporalExtentStart==null) ? null : moment(this.temporalExtentStart,format).format(format),
      dateTo : (this.temporalExtentEnd==null) ? null : moment(this.temporalExtentEnd,format).format(format)
    };

    return this._jsonModel;
  }
}
