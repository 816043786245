<section id="links" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="mr-auto white-with-border" i18n>Ressources publiées</h1>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter white-with-border" *ngIf="totalResults === 0" i18n>Aucune ressource</p>
          <p class="list-counter white-with-border" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} ressources</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2 white-with-border" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="links" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name"><span i18n>Titre</span></th>
            <th class="project" sortable-column="project.name"><span i18n>Dépôt</span></th>
            <th class="type" sortable-column="typeName"><span i18n>Type</span></th>
            <th class="creator" sortable-column="creator">Déposé par</th>
            <th class="last-update" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
            <th class="url" sortable-column="source"><span i18n>Url</span></th>
          </tr>
        </ng-template>
        <ng-template body-content let-link>
          <tr>
            <td><a [routerLink]="['./metadata', link.id]">{{link.defaultName}}</a></td>
            <td>
              <a *ngIf="link.project" class="neutral-link"
                [routerLink]="['/public/projects/metadata', link.projectId]">
                {{link.project.defaultName}}
              </a>
            </td>
            <td>{{link.typeName}}</td>
            <td>{{ link.creator }}</td>
            <td>{{link.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
            <td class="url"><a [href]="link.url" [title]="link.url" target="_blank">{{link.url}}</a></td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
