import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // <== add the imports!
import {NgbAccordionModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DragulaModule} from 'ng2-dragula';
import {Ng5SliderModule} from 'ng5-slider';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {ToastrModule} from 'ngx-toastr';
import {Angulartics2Module} from 'angulartics2';

import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import {FileUploadModule} from 'primeng/fileupload';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';

import {FiltrableTableModule} from './modules/filtrableTable/app.module';

import {AppRoutes} from './app.routing';

import {AppComponent} from './app.component';
import {HttpRequestInterceptor} from './interceptors/http-request.interceptor';
import {FileSizePipe} from './pipes/file-size.pipe';
import {ContactPipe} from './pipes/contact.pipe';
import {SanitizeHtmlPipe} from './pipes/sanitize-html.pipe';
import {SanitizeImagePipe} from './pipes/sanitize-image.pipe';
import {I18nYesNoPipe} from './pipes/i18n-yes-no.pipe';
import {
  DatasComponent,
  LinksComponent,
  ProjectsComponent,
  PublicDatasComponent,
  PublicLinksComponent,
  PublicProjectsComponent
} from './components/lists';
import {AdminGuard, AuthGuard, NeedSaveGuard} from './guards';
import {
  BboxMapModalComponent,
  ConfirmModalComponent,
  DataValidatorModalComponent,
  RightsModalComponent
} from './components/modals';
import {
  AutocompleteComponent,
  BreadcrumbComponent,
  HeaderComponent,
  LoaderComponent,
  MainLayoutComponent,
  MapComponent,
  MenuComponent,
  ProgressBarComponent,
  PublicHeaderComponent,
  PublicMainLayoutComponent,
  PublicMenuComponent,
  SimpleMapComponent
} from './components/layout';
import {
  AccountComponent,
  DataDetailComponent,
  ErrorPageComponent,
  HelpComponent,
  LandpageComponent,
  LinkDetailComponent,
  ProjectDetailComponent,
  PublicLinkDetailComponent,
  ResourcesComponent
} from './components/pages';
import {DataEditComponent, LinkEditComponent, ProjectEditComponent,} from './components/forms';
import {
  BboxComponent,
  CheckboxComponent,
  CoordComponent,
  DescribedExtentComponent,
  DropFilesComponent,
  FileComponent,
  InfoComponent,
  LabelInfoMetadataComponent,
  ListComponent,
  MetadatasComponent,
  MultipleCoordComponent,
  MultipleCoordUniqueComponent,
  NumberComponent,
  TextComponent,
  TextMultilineComponent
} from './components/forms/form-parts';

import {GroupsAdminComponent} from './components/admin/lists';
import {RecordsAdminComponent} from './components/admin/records/layout/records.component';
import {PendingRecordsComponent} from './components/admin/records/lists/pending/pending-records.component';
import {AcceptedRecordsComponent} from './components/admin/records/lists/accepted/accepted-records.component';
import {RefusedRecordsComponent} from './components/admin/records/lists/refused/refused-records.component';
import {AdminLayoutComponent} from './components/admin/layout';
import {GroupEditAdminComponent, GroupManageAdminComponent} from './components/admin/forms';
import {
  AddUserModalAdminComponent,
  GroupChoiceModalAdminComponent,
  ManageRoleModalAdminComponent,
  UserNameModalAdminComponent
} from './components/admin/modals';
import {RoleManagementAdminComponent, UserSearchAdminComponent} from './components/admin/pages';

import {AutofocusDirective} from './directives';
import {
  PublicMetadataDetailComponent
} from './components/pages/public/public-metadata-detail/public-metadata-detail.component';
import {
  PublicProjectDetailComponent
} from './components/pages/public/public-project-detail/public-project-detail.component';
import {PublicDataDetailComponent} from './components/pages/public/public-data-detail/public-data-detail.component';
import {RoleEditAdminComponent} from './components/admin/forms/role-edit/role-edit.component';
import {RolesAdminComponent} from './components/admin/lists/roles/roles.component';

import {
  AbstractSecurityStorage,
  AuthModule,
  LogLevel,
  StsConfigHttpLoader,
  StsConfigLoader
} from "angular-auth-oidc-client";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppConfigService, LocalStorageService} from './services';
import {PublicFooterComponent} from './components/layout/public/public-footer/public-footer.component';
import {EditorModule} from "primeng/editor";
import {SuiviActivityComponent} from "./components/admin/lists/activity/suivi-activity.component";
import {Keycloak} from "./models";
import {map} from "rxjs/operators";
import {ServiceComponent} from "./components/pages/service/service.component";
import { AnnotatorModalComponent } from './components/modals/annotator/annotator-modal.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

// oblige de la garder ici car il ne peux pas appeler la class Util
//Issue #392 : Locale fixée à anglais pour les autres langues autre que français
function getLocaleFromNavigator(): string {
  const navigatorLocale = navigator.language;
  if (navigatorLocale != null) {
    const localeNavigator = navigatorLocale.substring(0, 2);
    return localeNavigator != 'fr' ? 'en' : localeNavigator;
  }
}

// use the require method provided by webpack
declare const require;

const interceptors = { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true };

export const authFactory = (appConfigService: AppConfigService) => {
  const config = appConfigService.getKeycloakUrl().pipe(
    map((keycloack: Keycloak) => {
      return {
        configId: 'main',
        authority: keycloack.loginUrl,
        redirectUrl: window.location.origin + '/#/public/home',
        postLogoutRedirectUri: window.location.origin + '/#/public/home',
        clientId: keycloack.resource,
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        silentRenewUrl: window.location.origin + '/silent-renew.html',
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        logLevel: LogLevel.Warn
      };
    })
  );
  return new StsConfigHttpLoader(config);
};


@NgModule({
  declarations: [
    AppComponent,
    ContactPipe,
    FileSizePipe,
    SanitizeHtmlPipe,
    SanitizeImagePipe,
    I18nYesNoPipe,
    MainLayoutComponent,
    HeaderComponent,
    MenuComponent,
    PublicMainLayoutComponent,
    PublicHeaderComponent,
    PublicMenuComponent,
    PublicFooterComponent,
    BreadcrumbComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    ProjectEditComponent,
    PublicProjectsComponent,
    DatasComponent,
    DataDetailComponent,
    DataEditComponent,
    PublicDatasComponent,
    LinksComponent,
    LinkDetailComponent,
    LinkEditComponent,
    PublicLinksComponent,
    ResourcesComponent,
    HelpComponent,
    ErrorPageComponent,
    LoaderComponent,
    MetadatasComponent,
    LabelInfoMetadataComponent,
    NumberComponent,
    TextComponent,
    TextMultilineComponent,
    ListComponent,
    CheckboxComponent,
    FileComponent,
    DropFilesComponent,
    BboxMapModalComponent,
    BboxComponent,
    CoordComponent,
    InfoComponent,
    MultipleCoordComponent,
    MultipleCoordUniqueComponent,
    AutocompleteComponent,
    MapComponent,
    SimpleMapComponent,
    DescribedExtentComponent,
    ConfirmModalComponent,
    RightsModalComponent,
    DataValidatorModalComponent,
    AdminLayoutComponent,
    GroupsAdminComponent,
    RolesAdminComponent,
    RoleManagementAdminComponent,
    RoleEditAdminComponent,
    RecordsAdminComponent,
    PendingRecordsComponent,
    AcceptedRecordsComponent,
    RefusedRecordsComponent,
    GroupManageAdminComponent,
    GroupEditAdminComponent,
    UserSearchAdminComponent,
    RoleManagementAdminComponent,
    UserNameModalAdminComponent,
    GroupChoiceModalAdminComponent,
    AddUserModalAdminComponent,
    ManageRoleModalAdminComponent,
    AccountComponent,
    AutofocusDirective,
    PublicMetadataDetailComponent,
    PublicLinkDetailComponent,
    PublicProjectDetailComponent,
    PublicDataDetailComponent,
    LandpageComponent,
    ProgressBarComponent,
    SuiviActivityComponent,
    ServiceComponent,
    AnnotatorModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbAccordionModule,
    NgbDropdownModule,
    FiltrableTableModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true,
      }
    }),
    DragulaModule.forRoot(),
    Ng5SliderModule,
    AutoCompleteModule,
    CalendarModule,
    FileUploadModule,
    InputSwitchModule,
    DropdownModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      progressBar: true,
      extendedTimeOut: 2000
    }),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [AppConfigService],
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    ProgressBarModule,
    EditorModule
  ],
  providers: [
    Title,
    { provide: LOCALE_ID, useValue: getLocaleFromNavigator() },
    { provide: TRANSLATIONS, useValue: require(`raw-loader!../locale/messages.` + getLocaleFromNavigator() + `.xlf`) },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
    AuthGuard,
    NeedSaveGuard,
    AdminGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    interceptors,
    { provide: AbstractSecurityStorage, useClass: LocalStorageService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
