import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Subject, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Uris } from '../constants';
import { Resource, Link, SearchData, Project, Data, EntityMetadata } from '../models';
import { RightsModalComponent } from '../components/modals/rights/rights-modal.component';
import { LoaderService } from './loader.service';
import { SessionService } from './session.service';
import { FacetedSearch } from '../models/faceted-search.model';
import { DynamicFacetedSearchOptions } from '../models/dynamic-faceted-search-options.model';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  /**
   * Source utilisée lors de la récupération d'une liste de resources
   */
  private _resourcesSource = new Subject<Resource[]>();

  /**
   * Source utilisée au retour d'une édition des droits d'une resource
   */
  private _editRightsSource = new Subject<any>();

  /**
   * Source utilisée lors que le header effectue une recherche alors qu'on est dans la page recherche
   */
  private _externalSearch = new Subject<SearchData>();

  private _facetedSearch = new Subject<FacetedSearch>();

  /**
   * Observable appelé à chaque récupération d'une liste de resource
   */
  public resources$ = this._resourcesSource.asObservable();

  /**
   * Observable appelé à chaque édition des droits d'une resource
   */
  public editRights$ = this._editRightsSource.asObservable();

  /**
   * Observable appelé à chaque recherche du header dans le cadre de la page recherche
   */
  public externalSearch$ = this._externalSearch.asObservable();

  public facetedSearch$ = this._facetedSearch.asObservable();

  constructor(
    private _http: HttpClient,
    private _toastr: ToastrService,
    private _modalService: NgbModal,
    private _loader: LoaderService,
    private _session: SessionService
  ) { }

  /**
   * Demande la recherche des resources metadata selon
   * @param searchData - critères de recherche
   */
  public searchResources(searchData: SearchData,language:string="fre"): void {
    let resourceUri = Uris.RESOURCES, projectUri = Uris.PROJECTS;
    if (!this._session.currentUser) {
      resourceUri = Uris.PUBLIC_RESOURCES;
      projectUri = Uris.PUBLIC_PROJECTS;
    }
    this._http.post<any>(resourceUri, searchData.serialize(language))
      .pipe(
        map(result => {
          result = result || {};
          result.metadataProjects = result.metadataProjects || [];
          result.metadataDatas = result.metadataDatas || [];
          result.metadataLinks = result.metadataLinks || [];
          return result;
        }),
        map(result => {
          result.metadataProjects = result.metadataProjects.map(p => new Project().deserialize(p));
          result.metadataDatas = result.metadataDatas.map(p => new Data().deserialize(p));
          result.metadataLinks = result.metadataLinks.map(p => new Link().deserialize(p));
          return result;
        }),
        switchMap(result => {
          let params:HttpParams = new HttpParams();
          let datasProjectsIdentifiers = result.metadataDatas.map(d => d.projectId);
          let linksProjectsIdentifiers = result.metadataLinks.map(l => l.projectId);
          let projectsIdentifiers = [];
          for(let id of datasProjectsIdentifiers)projectsIdentifiers.push(id);
          for(let id of linksProjectsIdentifiers) projectsIdentifiers.push(id);
          let projectsIdentifiersWithoutDuplicates = projectsIdentifiers.filter((item,index) => projectsIdentifiers.indexOf(item) === index);
          params = params.append("projectsIdentifiers",projectsIdentifiersWithoutDuplicates.join(","));
          return this._http.get<Project[]>(projectUri, {params:params})
            .pipe(
              map(projects => projects.map(p => new Project().deserialize(p))),
              map(projects => {
                _.each(result.metadataDatas, data => {
                  data.project = _.find(projects, { id: data.projectId });
                });
                _.each(result.metadataLinks, link => {
                  link.project = _.find(projects, { id: link.projectId });
                });
                return result;
              })
            )
        })
      )
      .subscribe(result => {

        let resources: Resource[] = [];

        _.each(result.metadataProjects, (project: Project) => {
          let url = '/public/search/metadata/' + project.id;
          if (!!this._session.currentUser && this._session.hasRight(project.id, 'readonly')) {
            url = '/projects/' + project.id
            if (this._session.hasRight(project.id, 'owner')) {
              url = '/my-projects/' + project.id;
            }
          }
          resources.push(new Resource().deserialize({
            id: project.id,
            type: 'project',
            typeLabel: 'Dépôt',
            lastUpdate: project.lastUpdate,
            name: project.name,
            sourceObject: project,
            extents: project.extents || [],
            url: url,
            published: project.published
          }));
        });

        _.each(result.metadataDatas, (data: Data) => {
          let url = '/public/search/metadata/' + data.id;
          if (!!this._session.currentUser && this._session.hasRight(data.projectId, 'readonly')) {
            url = "/projects/" + data.projectId + "/datas/" + data.id;
            if (this._session.hasRight(data.projectId, 'owner')) {
              url = '/my-data/' + data.id;
            }
          }
          resources.push(new Resource().deserialize({
            id: data.id,
            type: 'data',
            typeLabel: 'Jeu de données',
            lastUpdate: data.lastUpdate,
            name: data.name,
            sourceObject: data,
            extents: data.extents || [],
            url: url,
            published: data.published
          }));
        });

        _.each(result.metadataLinks, (link: Link) => {
          let url = '/public/search/metadata/' + link.id;
          if (!!this._session.currentUser && this._session.hasRight(link.projectId, 'readonly')) {
            url = "/projects/" + link.projectId + "/links/" + link.id;
            if (this._session.hasRight(link.projectId, 'owner')) {
              url = '/my-links/' + link.id;
            }
          }
          resources.push(new Resource().deserialize({
            id: link.id,
            type: 'link',
            typeLabel: 'Ressource',
            lastUpdate: link.lastUpdate,
            name: link.name,
            sourceObject: link,
            extents: link.extents || [],
            url: url
          }));
        });

        if (result.numberOfRecordsMatched > resources.length) {
          this._toastr.info(
            $localize`Pour des raisons de performance, seules ${resources.length} ressources sont affichées sur ${result.numberOfRecordsMatched} trouvées. Veuillez affiner vos critères de recherche.`,
            null,
            { timeOut: 0, closeButton: true }
          )
        }

        this._resourcesSource.next(_.sortBy(resources, ['name']));
      });
  }

  /**
   * Récupère les options de la recherche à facette.
   * @param options : les options actuellement cochées par l'utilisateur, utilisées pour mettre à jour dynamiquement la recherche.
   */
  public getSearchOptions(options:DynamicFacetedSearchOptions) {
    this._http.post<FacetedSearch>((!this._session.currentUser ? Uris.PUBLIC_RESOURCES : Uris.RESOURCES) + "searchOptions", options.serialize()).subscribe(result => {
      this._facetedSearch.next(result);
    });
  }

  /**
   * Ouvre la modale d'édition des droits d'une métadonnée
   * @param itemId - ID de la métadonnée (uri)
   * @param itemTitle - Nom de la métadonnée
   * @param itemType - Type de métadonnée (project, link ou data)
   */
  public editResourceRights(item: EntityMetadata, itemType: string) {
    let initialPermissions = {
      individualPermissions: _.cloneDeep(item.individualPermissions),
      groupPermissions: _.cloneDeep(item.groupPermissions)
    };
    const modalRef = this._modalService.open(RightsModalComponent, { backdrop: "static", size: "lg", windowClass: "confirm-modal rights-modal" });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.itemType = itemType;

    modalRef.result.then(result => {
      // save le résultat
      let calls = [];

      //// droits individuels

      // suppressions
      _.each(initialPermissions.individualPermissions, permission => {
        let currentPermission = _.find(result.individualPermissions, { uriMetadata: permission.uriMetadata, userId: permission.userId });
        if (!currentPermission) { // suppression
          calls.push(this._http.delete<any>(Uris.USERS + permission.userId + '/permissions?uriMetadata=' + encodeURIComponent(permission.uriMetadata)));
        }
      });

      // ajouts et éditions
      _.each(result.individualPermissions, permission => {
        let initialPermission = _.find(initialPermissions.individualPermissions, { uriMetadata: permission.uriMetadata, userId: permission.userId });
        if (initialPermission) {
          if (initialPermission.code !== permission.code) { // édition
            calls.push(this._http.put<any>(Uris.USERS + permission.userId +
              '/permissions?code=' + permission.code + '&uriMetadata=' + encodeURIComponent(permission.uriMetadata), {}));
          }
        } else { // ajout
          calls.push(this._http.post<any>(Uris.USERS + permission.userId +
            '/permissions?code=' + permission.code + '&uriMetadata=' + encodeURIComponent(permission.uriMetadata), {}));
        }
      });

      //// droits de groupe

      // suppressions
      _.each(initialPermissions.groupPermissions, permission => {
        let currentPermission = _.find(result.groupPermissions, p => {
          return p.uriMetadata === permission.uriMetadata && p.group.id === permission.group.id;
        });
        if (!currentPermission) { // suppression
          calls.push(this._http.delete<any>(Uris.GROUPS + permission.group.id + '/permissions?uriMetadata=' + encodeURIComponent(permission.uriMetadata)));
        }
      });

      // ajouts et éditions
      _.each(result.groupPermissions, permission => {
        let initialPermission = _.find(initialPermissions.groupPermissions, p => {
          return p.uriMetadata === permission.uriMetadata && p.group.id === permission.group.id;
        });
        if (initialPermission) {
          if (initialPermission.code !== permission.code) { // édition
            calls.push(this._http.put<any>(Uris.GROUPS + permission.group.id +
              '/permissions?code=' + permission.code + '&uriMetadata=' + encodeURIComponent(permission.uriMetadata), {}));
          }
        } else { // ajout
          calls.push(this._http.post<any>(Uris.GROUPS + permission.group.id +
            '/permissions?code=' + permission.code + '&uriMetadata=' + encodeURIComponent(permission.uriMetadata), {}));
        }
      });

      if (calls.length > 0) {
        this._loader.show();
        forkJoin(calls)
          .pipe(switchMap(() => this._session.getUserPermissionsObs(null)))
          .subscribe(() => {
            this._editRightsSource.next(result);
            this._toastr.success($localize`Les permissions de '${item.name}' ont été modifiées avec succès`);
            this._loader.hide();
          }, error => {
            this._editRightsSource.next(false);
          });
      } else {
        this._editRightsSource.next(false);
        this._toastr.success($localize`Les permissions de '${item.name}' ont été modifiées avec succès`);
      }

    }, () => null);
  }

  /**
   * Force une recherche pour le composant concerné
   * @param searchData Recherche à effectuer
   */
  public doExternalSearch(searchData: SearchData) {
    this._externalSearch.next(searchData);
  }
}
