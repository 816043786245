import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Constants } from "../../../constants";
import { AppConfigService, LoaderService, SessionService, UtilsService } from '../../../services';
import { AppVersion, Role, User } from '../../../models';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  public collapsed: boolean = true;

  public isAdmin:boolean = false;

  public isModerator:boolean = false;

  public appVersion = null;
  public buildDate: Date = null;

  private _subs: Subscription = new Subscription();

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  constructor(
    public _session: SessionService,
    private _loaderService: LoaderService,
    private _router: Router,
    private _appConfigService: AppConfigService,
    private _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._appConfigService.getAppVersion().subscribe((appVersion: AppVersion) => {
      this.appVersion = appVersion.version;
      this.buildDate = appVersion.buildDate;
    });
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public toggleMenu() {
    this.collapsed = !this.collapsed;
    this._loaderService.changeMenuWidth(this.collapsed);
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User) {
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
  }

}
