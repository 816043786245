import { EntityMetadata } from './entity-metadata.model';
import { Constants } from '../constants/app.constants';
import { Project } from './project.model';

export class Link extends EntityMetadata {
  /**
   * Identifiant du dépôt (i.e "étude" dans Cupidon) lié
   */
  projectId: string = null;

  /**
   * Dépôt (i.e "étude" dans Cupidon) lié
   */
  project: Project = null;

  /**
   * URL du lien
   */
  url: string = "";

  /**
   * Nom du type de lien
   */
  typeName: string = null;

  /**
   * Indique si la metadata est publiée
   */
  published = false;

  /**
   * Date de publication de la donnée d'origine
   */
  publicationDate: Date = null;

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "projectId", back: "parentIdentifier" },
      { front: "url", back: "onlineResources.0.link" },
      { front: "publicationDate", class: Date }
    ]);
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    this.typeName = this.getUniqueClassifiedKeyword(Constants.TYPOLOGIE_KEYWORD_NAME);

    return this;
  }
}
