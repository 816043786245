import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { Constants } from "src/app/constants";

@Component({
  templateUrl: './add-user-modal.component.html'
})
export class AddUserModalAdminComponent {
  public user: string = "";
  public emails: string[] = [];
  @Input() title: string = "";
  public patternMail = Constants.regExps.mail;

  constructor(
    public modal: NgbActiveModal
  ) { }


  public save(addUserForm: NgForm) {
    if(addUserForm.valid) {
      this.modal.close(this.user);
    }
  }
}