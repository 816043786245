// @ts-nocheck
import { Control } from 'ol/control';

export class ZoomToBBOXControl extends Control {
  constructor(optOptions = null) {
    let options = optOptions || {};
    let element = document.createElement('div');
    element.className = 'ol-control ol-unselectable ol-zoom-to-bbox-control';
    let button = document.createElement('button');
    button.innerHTML = options.label || "E";
    button.title = options.tipLabel || "Go to maximal extension";

    var goToExtend = () => {
      let map = this.getMap();
      let layers = map.getLayers();
      let center = [0,0];
      let view = map.getView();

      for (var i = 0; i<layers.getLength(); i++) {
        if(layers.item(i).get('idx')=='dataDraw'){
          let geom = layers.item(i).getSource().getFeatures()[0].getGeometry();
          if(geom.getType() === 'Point') {
            center = geom.getCoordinates();
            view.setZoom(5);
            view.setCenter(center);
          } else {
            let extentToZoom = geom.getExtent();
            view.fit(extentToZoom, {padding: [50,50,50,50]});
          }
          break;
        }
      }

    };

    button.addEventListener('click', goToExtend, false);
    button.addEventListener('touchstart', goToExtend, false);

    element.appendChild(button);

    super({
      element: element,
      target: options.target
    });
  }

  getMap() { // set to avoid error in compiler
    return super.getMap();
  }
}

export class CustomActionButtonControl extends Control {
  constructor(optOptions = null) {
    let options = optOptions || {};
    let element = document.createElement('div');
    element.className = 'ol-control ol-unselectable';
    if(options.class) {
      element.className += ' ' + options.class;
    }
    let button = document.createElement('button');
    button.innerHTML = options.label || "A";
    button.title = options.tipLabel || "Button action";

    if(typeof options.onClick == "function") {
      button.addEventListener('click', options.onClick, false);
      button.addEventListener('touchstart', options.onClick, false);
    }

    element.appendChild(button);

    super({
      element: element,
      target: options.target
    });
  }

  getMap() { // set to avoid error in compiler
    return super.getMap();
  }

}
