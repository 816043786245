<form #roleForm="ngForm" class="edit-fields-form" [ngClass]="{'ng-submitted': roleForm.submitted}"
  (ngSubmit)="save(roleForm)">
  <div class="modal-header">
    <h2 class="modal-title" i18n>Ajouter un rôle à l'utilisateur</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="confirm-modal">
    <div class="d-flex align-items-center">
      <label for="manage-role" class="mb-0 mr-3 nowrap" i18n>Rôle</label>
      <select name="manage-role" id="manage-role" class="form-control" [(ngModel)]="selectedRole" required>
        <option [ngValue]="null" i18n>-- Choisir un rôle --</option>
        <option *ngFor="let role of roles; trackBy: trackById" [ngValue]="role">{{role.name}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" class="btn btn-primary ml-auto" i18n>Enregistrer</button>
  </div>
</form>