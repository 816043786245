<section id="links" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto white-with-border" i18n>Mes ressources</h1>
    <div class="title-button-container">
      <a [routerLink]="['./new']" class="btn btn-primary">
        <em class="fa fa-plus"></em>
        <ng-container i18n>Référencer une nouvelle ressource</ng-container>
      </a>
    </div>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter white-with-border" *ngIf="totalResults === 0" i18n>Aucune ressource</p>
          <p class="list-counter white-with-border" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} ressources</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2 white-with-border" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="links" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name"><span i18n>Titre</span></th>
            <th class="project" sortable-column="project.name"><span i18n>Dépôt</span></th>
            <th class="type" sortable-column="typeName"><span i18n>Type</span></th>
            <th class="creator" sortable-column="creator">Déposé par</th>
            <th class="last-update text-center" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
            <th class="url" sortable-column="source"><span i18n>Url</span></th>
            <th class="status" sortable-column="status.value" sort-direction="desc">Statut</th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-link>
          <tr>
            <td>
              <a [routerLink]="[session.hasRight(link.projectId, 'readonly') ? '/my-projects' : '/projects', link.projectId, 'links', link.id]">
                {{displayName(link)}}
              </a>
            </td>
            <td>
              <a class="neutral-link"
                [routerLink]="[session.hasRight(link.projectId, 'owner') ? '/my-projects' : '/projects', link.projectId]">
                {{displayProjectName(link)}}
              </a>
            </td>
            <td>{{link.typeName}}</td>
            <td>{{ link.creator }}</td>
            <td class="text-center">{{link.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
            <td class="url"><a [href]="link.url" [title]="link.url" target="_blank">{{link.url}}</a></td>
            <td>
              <ng-container *ngIf="link?.status?.value == 'PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
              <ng-container *ngIf="link?.status?.value == 'PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
              <ng-container *ngIf="link?.status?.value == 'ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
              <ng-container *ngIf="link?.status?.value == 'REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
            </td>
            <td class="text-center btn-icon-container" container="body" placement="bottom-right">
              <div class="" ngbDropdown #myDrop="ngbDropdown">
                <button type="button" id="dropdown-link-{{link.id}}" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownAnchor (focus)="myDrop.open()"></button>
                <div ngbDropdownMenu aria-labelledby="dropdown-link-{{link.id}}">
                  <a class="dropdown-item" [routerLink]="['/my-projects',link.projectId,'links',link.id]" i18n>Consulter</a>
                  <a *ngIf="(session.hasRight(link.projectId, 'owner') || session.hasRight(link.projectId, 'editor')) && !link.published" class="dropdown-item" [routerLink]="['./', link.id, 'edit']" i18n>Modifier</a>
                  <a *ngIf="session.hasRight(link.projectId, 'owner') && !link.published" class="dropdown-item item-danger" (click)="deleteLink(link)" i18n>Supprimer</a>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
