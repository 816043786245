import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, LoaderService } from "src/app/services";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ConfirmModalComponent } from "src/app/components/modals";
import { Role, UserRole } from "../../../../models";
import { Router } from "@angular/router";
import { SessionService } from "../../../../services";
import { Constants } from "src/app/constants";

@Component({
  templateUrl: './roles.component.html'
})
export class RolesAdminComponent implements OnInit, OnDestroy {

  /**
   * Liste des Roles (pour la liste à cocher)
   */
  public allRoles: Role[] = [];
  
  /**
   * Liste complète des groupes
   */
  public userRoles: UserRole[] = [];

  public adminRole: Role|undefined;

  public pointOfContactRole: Role|undefined;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _router: Router,
    private _userService: UserService,
    private _session: SessionService,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.roles$.subscribe(roles => this._initAllRoles(roles)));
    this._subs.add(this._userService.userRoles$.subscribe(userRoles => this._initUserRoles(userRoles)));
    this._loader.show();
    this._userService.getRoles();
  }


  ngOnDestroy() {
    this._subs.unsubscribe();
  }


  public displayRoleName(userRole:UserRole) {
    return this.allRoles.find(r => r.id==userRole.roleId)?.name;
  }


  /**
   * Demande confirmation et supprime le rôle attribué à l'utilisateur
   * @param group - groupe à supprimer
   */
  public deleteUserRole(userRole: UserRole) {
    let roleId = userRole.roleId;
    let chosenRole = this.allRoles.find(r => r.id==roleId);
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Retrait d'un rôle`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment retirer le rôle ${chosenRole?.name} à ${userRole.mail} ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Retirer le rôle`;
    modalRef.result.then(() => {
      this._loader.show();
      this._userService.deleteUserRole(userRole)
        .subscribe(() => {
          this._toastr.success($localize`Le rôle ${chosenRole?.name} a été retiré à ${userRole.mail} avec succès.`);
          this._userService.getUserRoles();
        });
    }, () => null);
  }


  public canEditOrDelete(userRole:UserRole):boolean {
    if(!this._session.currentUser) return false;
    if(this.allRoles.length==0) return false;
    let isAdmin = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==this.adminRole?.id)!=-1;
    if(isAdmin) return true;
    let isPointOfContact = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==this.pointOfContactRole?.id)!=-1;
    if(!isPointOfContact) return false;
    let userPointOfContactRole:UserRole = this._session.currentUser.userRoles.find(userRole => userRole.roleId==this.pointOfContactRole?.id);
    for(let thematic of userRole.thematics) {
      if(userPointOfContactRole.thematics.find(t=>t.label==thematic.label)!=undefined) return true;
    }
    return false;
  }


  /**
   * Initialise la liste des différents rôles disponibles
   * @param roles : la liste des rôles
   */
  private _initAllRoles(roles:Role[]) {
    this.allRoles = roles.sort((a,b) => a.id - b.id);
    this.adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    let isAdmin = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==this.adminRole?.id)!=-1;
    this.pointOfContactRole = roles.find(role => role.name == Constants.ROLE_REFERENT);
    let isPointOfContact = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==this.pointOfContactRole?.id)!=-1;
    if(!isAdmin && !isPointOfContact) this._router.navigate(["/home"]);
    this._userService.getUserRoles();
    this._loader.hide();
  }

  /**
   * Initialise les groupes reçus
   * @param groups - groupes reçus du serveur
   */
  private _initUserRoles(userRoles: UserRole[]) {
    this.userRoles = userRoles;
    for(let userRole of this.userRoles) {
      userRole.thematics.sort((a,b)=>a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
    }
    this._loader.hide();
  }


}