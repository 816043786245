import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { Link } from 'src/app/models';
import { LinkService, LoaderService, SessionService, TableFilterService, UtilsService } from 'src/app/services';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from 'src/app/constants';
import * as _ from "lodash";

@Component({
  templateUrl: './public-links.component.html'
})
export class PublicLinksComponent implements OnInit, OnDestroy {
  /**
   * Liste des liens
   */
  public links: Link[] = [];

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  // Langue
  public locale: string;

  // Format des dates en fonction des langues
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _filterService: TableFilterService,
    private _linkService: LinkService,
    private _loader: LoaderService,
    private _utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this._subs.add(this._linkService.links$.subscribe(links => this._initLinks(links)));

    this._loader.show();
    this._linkService.getPublishedLinks();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);

    this.locale = this._utilsService.getLocaleFromNavigator();
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayName(_link : Link):string {
    if(_link==null) return "";
    if(_link.name != null && _link.name != "") {
      if(_link.name.length >= 195) return _link.name.substring(0,195)+" ...";
      else return _link.name;
    } else {
      if(_link.defaultName != null && _link.defaultName != "") {
        if(_link.defaultName.length >= 195) return _link.defaultName.substring(0,195)+" ...";
        else return _link.defaultName;
      }
      else return "";
    }
  }

  public displayProjectName(_link : Link):string {
    let _project = _link.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    } else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }
  }


  /**
   * Initialise la liste des liens
   * @param links - liens reçus du serveur
   */
  private _initLinks(links) {
	links.forEach(l => l.typeName = l.classifiedKeywords.find(ckw => Constants.TYPOLOGIE_KEYWORD_NAME === ckw.typeCodeValue).keywordsWithLink[0].label );
    //Tri le tableau en fonction de la date de dernière modification de la plus récente à la plus ancienne
    this.links = _.orderBy(links, 'publicationDate','desc');
    this._loader.hide();
  }
}
