import * as _ from 'lodash';

import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { Observable, combineLatest, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Uris } from '../constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Registry } from 'src/app/models/registry.model';
import { LoaderService } from './loader.service';
import { EntityMapper } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private _http: HttpClient,
    private _loader: LoaderService
  ) { }

  /**
   * Renvoie un observable abonné à tous les changements de paramètres de la route
   * @param route - route du composant appelant
   */
  public getAllRouteParams(route): Observable<string[]> {
    let calls: Observable<Params>[] = [];
    _.each(route.pathFromRoot, (r: ActivatedRoute, i: number) => {
      calls.push(r.params);
    });

    return combineLatest<Params>(calls)
      .pipe(
        map(params => {
          params = _.filter(params, p => _.keys(p).length > 0);
          params = params.map(p => p.id);
          return _.uniq(params);
        })
      );
  }

  /**
   * Récupère la locale du navigateur
   * Issue #392 : Locale fixée à anglais pour les autres langues autre que français
   */
  public getLocaleFromNavigator(): string {
    const navigatorLocale = navigator.language;
    if (navigatorLocale != null) {
      const localeNavigator = navigatorLocale.substring(0, 2);
      return localeNavigator != 'fr' ? 'en' : localeNavigator;
    }
  }

  /**
   * Récupère une image par le proxy et en fait un data/image
   * https://medium.com/techinpieces/blobs-with-http-post-and-angular-5-a-short-story-993084811af4
   * @param url Url de l'image
   */
  public getImageDataFromProxy(url: string): Observable<string> {
    let resultSource = new Subject<any>();
    if (!url) {
      return of("");
    }
    if (1 == 1) {
      return of(url);
    } else { // TODO : à activer quand le proxy pourra remonter des images
      this._http.get(Uris.PROXY + '?url=' + encodeURIComponent(url), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }),
        responseType: 'blob' as 'json'
      }).subscribe((image: Blob) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          resultSource.next(reader.result);
        }, false);
        if (image) {
          reader.readAsDataURL(image);
        }
      }, error => {
        resultSource.next(null);
      });
    }

    return resultSource.asObservable();
  }

  public isNullOrUndefined(value) {
    return value === undefined || value === null;
  }

  /**
   * Définit une opacité pour une couleur hexadécimale
   * @param hex Couleur en hexa
   * @param opacity Opacité voulue (en pourcentage)
   */
  public fadeHexColor(hex: string, opacity: number) {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  }

  /**
   * Recherche un terme dans la liste de termes
   * @param list Liste de termes
   * @param term Terme à rechercher
   */
  public searchKeyword(list: string[], term: string, exlusionList: Registry[] = []): string[] {
    let result: string[] = _.filter(list, t => exlusionList.indexOf(t) < 0 && this._containsStr(term, t));

    return this._sortByProximityOfTerm(term, result);
  }

  /**
   * Recherche une string dans un texte, sans sensibilité à la casse ou aux accents
   * @param search String recherchée
   * @param text Texte dans lequel faire la recherche
   */
   public containsStr(search: string, text: string) {
    search = this._removeAccents(search.toLocaleLowerCase()).replace(/[_,.-]/g, " ");
    text = this._removeAccents(text.toLocaleLowerCase()).replace(/[_,.-]/g, " ");

    return text.indexOf(search.toString()) >= 0;
  }

  /**
   * Remplace les lettres accentuées par leur équivalent non accentuée
   * @param text - Texte à traiter
   */
  private _removeAccents(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * Recherche une string dans un texte, sans sensibilité à la casse ou aux accents
   * @param search String recherchée
   * @param text Texte dans lequel faire la recherche
   */
  private _containsStr(search: string, text: string) {
    search = this._removeAccents(search.toLocaleLowerCase()).replace(/[_,.-]/g, " ");
    text = this._removeAccents(text.toLocaleLowerCase()).replace(/[_,.-]/g, " ");

    return text.indexOf(search.toString()) >= 0;
  }

  /**
   * Trie une liste de termes par rapport à leur proximité avec un terme donné
   * @param term Terme de proximité
   * @param list Liste de termes
   */
  private _sortByProximityOfTerm(term: string, list: string[]): string[] {
    let proximityList: string[] = [];
    let alphaList: string[] = [];

    term = term.toLowerCase();

    _.each(list, (item) => {
      if (this._strStartsWith(term, item)) {
        proximityList.push(item);
      } else {
        alphaList.push(item);
      }
    });

    return proximityList.concat(alphaList);
  }

  /**
   * Vérifie si un texte commence par un terme
   * @param search Terme à chercher
   * @param text Texte dans lequel chercher
   */
  private _strStartsWith(search: string, text: string): boolean {
    return text.toLowerCase().slice(0, search.length) === search;
  }

  /**
   * Lance le téléchargement d'un fichier
   * @param blob Contenu du fichier
   * @param fileName Nom du fichier
   */
  public launchDownloadFile(blob:Blob, fileName:string) {
    const link = window.document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
    this._loader.hide();
  }

  /**
   * Exécute une API en mode GET, récupère le résultat JSON et l'associe à un model
   * @param type Classe du model
   * @param api API
   * @returns Observable résultat de l'appel à l'API
   */
  public deserializeFromGetApi<T extends EntityMapper>(type: { new(): T ; }, api:string):Observable<T> {
    return this._http.get<T>(api)
      .pipe(
        map(t => new type().deserialize(t))
      );
  }
}
