import { EntityMapper } from "./entity-mapper.model";
import { Group } from "./group.model";

export class Permission extends EntityMapper {
  /**
   * Identifiant unique de la permission
   */
  id: number = 0;

  /**
   * Métadonnée concernée par la permission
   */
  uriMetadata: string = "";

  /**
   * Niveau de la permission (owner, editor ou readonly)
   */
  code: string = "";

  /**
   * Utilisateur ayant la permission, null si c'est un groupe
   */
  userId: string = null;

  /**
   * Groupe ayant la permission, null si c'est un utilisateur
   */
  group: Group = null;

  constructor() {
    super();

    this._mapperDefs = [
      { front: "code", back: "permissionCode" },
      { front: "group", class: Group }
    ];
  }
}