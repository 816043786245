import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable, Subject, concat, of, throwError } from 'rxjs';
import { catchError, last, map, switchMap, tap, toArray } from 'rxjs/operators';

import { Data, Project, OnlineResource, FileToUpload } from '../models';
import { Summary } from '../models/summary.model';
import { Constants, Uris } from '../constants';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProgressBarService } from './progress-bar.service';
import {MailService} from "./mail.service";

@Injectable({
  providedIn: 'root',
})
export class DataService {
  /**
   * Source de la liste des données
   */
  private datasSource = new Subject<Data[]>();

  /**
   * Source d'une donnée unique
   */
  private dataSource = new Subject<Data>();

  /**
   * Cache des données pour les inputs file
   */
  private _dataCache: Data[] = [];

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de données
   */
  public datas$ = this.datasSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'une donnée unique
   */
  public data$ = this.dataSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _router: Router,
    private _progressbar: ProgressBarService,
    private _mail: MailService
  ) { }

  /**
   * Demande la récupération des données dont le user est propriétaire
   */
  public getUserDatas(language:string="fre"): void {
    this._http.get<Data[]>(Uris.DATAS + '?permission=readonly')
      .pipe(
        map(datas => datas.map(l => new Data().deserialize(l,language))),
        switchMap(datas => {
          return this._http.post<Summary[]>(Uris.PROJECTS+'summaries/csv', datas.map(d=>d.projectId))
            .pipe(
              map(projects => {
                _.each(datas, data => {
                  let project = _.find(projects, { identifier : data.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    data.project = projectObject;
                  }
                });
                return datas;
              })
            )
        })
      ).subscribe(
        datas => this.datasSource.next(datas)
      );
  }

  /**
   * Demande la récupération des données publiées
   */
  public getPublishedDatas(language:string="fre"): void {
    this._http.get<Data[]>(Uris.PUBLIC_DATAS)
      .pipe(
        map(datas => datas.map(l => new Data().deserialize(l,language))),
        switchMap(datas => {
          return this._http.post<Summary[]>(Uris.PUBLIC_PROJECTS+'summaries/csv', datas.map(d=>d.projectId))
            .pipe(
              map(projects => {
                _.each(datas, data => {
                  let project = _.find(projects, { identifier : data.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    data.project = projectObject;
                  }
                });
                return datas;
              })
            )
        })
      ).subscribe(
        datas => this.datasSource.next(datas)
      );
  }

  /**
   * Demande la récupération de toutes les données auxquelles le user a accès
   * @param needCache Faut-il mettre le résultat en cache ?
   */
  public getAllDatas(needCache: boolean = false,language="fre") {
    this._http.get<Data[]>(Uris.DATAS)
      .pipe(
        map(datas => datas.map(l => new Data().deserialize(l,language))),
        switchMap(datas => {
          return this._http.post<Summary[]>(Uris.PROJECTS+'summaries/csv', datas.map(d=>d.projectId))
            .pipe(
              map(projects => {
                _.each(datas, data => {
                  let project = _.find(projects, { identifier : data.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    data.project = projectObject;
                  }
                });
                return datas;
              })
            )
        }),
        tap(datas => {
          if (needCache) {
            this._dataCache = _.cloneDeep(datas);
          }
        })
      ).subscribe(
        datas => this.datasSource.next(datas)
      );
  }


  public getDatasByStatus(status:string,needCache: boolean = false,language:string="fre"): void {
    this._http.get<Data[]>(Uris.DATAS+"findByStatus/"+status)
      .pipe(
        map(datas => datas.map(l => new Data().deserialize(l,language))),
        switchMap(datas => {
          return this._http.post<Summary[]>(Uris.PROJECTS, datas.map(d=>d.projectId))
            .pipe(
              map(projects => {
                _.each(datas, data => {
                  let project = _.find(projects, { identifier : data.projectId });
                  if(project!=undefined && project!=null) {
                    let projectObject = new Project();
                    projectObject.id = project.identifier;
                    projectObject.name = project.title;
                    projectObject.defaultName = project.title;
                    data.project = projectObject;
                  }
                });
                return datas;
              })
            )
        }),
        tap(datas => {
          if (needCache) {
            this._dataCache = _.cloneDeep(datas);
          }
        })
      ).subscribe(
        datas => this.datasSource.next(datas)
      );
  }

  /**
   * Renvoie les données conservées en cache
   */
  public getCachedData(): Data[] {
    return _.cloneDeep(this._dataCache);
  }

  /**
   * Demande la récupération d'une donnée précis
   * @param projectId - identifiant du dépôt (i.e "étude" dans Cupidon) parent
   * @param id - identifiant de la donnée
   * @param language - Language à utiliser pour charger la donnée
   */
  public getData(projectId: string, id: string, language: string = "fre"): void {
    if (id === null || id === undefined) {
      let newData = new Data();
      newData.name = $localize`Nouveau jeu de données`;
      this.dataSource.next(newData);
    } else {
      const headers = {
        headers: new HttpHeaders({
          "Accept-Language": language
        })
      };
      let call = this._http.get<Data>(`${Uris.DATAS}${id}`, headers);
      if (projectId) {
        call = this._http.get<Data>(`${Uris.PROJECTS}${projectId}/datasets/${id}`, headers);
      }
      call
        .pipe(
          map(data => new Data().deserialize(data,language)),
          map((data: Data) => {
            // Réordonner les online resources pour mettre les liens bibliographiques en premier
            let alphaResources: OnlineResource[] = [];
            let otherResources: OnlineResource[] = [];

            _.each(data.onlineResources, (ol: OnlineResource) => {
              if (ol.protocol === "Lien bibliographique") {
                alphaResources.push(ol);
              } else {
                otherResources.push(ol);
              }
            });

            data.onlineResources = alphaResources.concat(otherResources);
            return data;
          }),
        )
        .subscribe(
          data => this.dataSource.next(data),
          error => {
            if (error.status === 403 || error.status === 404) {
              this._router.navigate(['/my-data']);
            }
          }
        );
    }
  }

  /**
   * Demande la récupération d'une donnée précis
   * @param id - identifiant de la donnée
   * @param language - Language à utiliser pour charger la donnée
   */
  public getPublicData(id: string, language: string = "fre"): void {
    const headers = {
      headers: new HttpHeaders({
        "Accept-Language": language
      })
    };
    this._http.get<Data>(Uris.PUBLIC_DATAS + id, headers)
      .pipe(
        map(data => new Data().deserialize(data,language)),
        map((data: Data) => {
          // Réordonner les online resources pour mettre les liens bibliographiques en premier
          let alphaResources: OnlineResource[] = [];
          let otherResources: OnlineResource[] = [];

          _.each(data.onlineResources, (ol: OnlineResource) => {
            if (ol.protocol === "Lien bibliographique") {
              alphaResources.push(ol);
            } else {
              otherResources.push(ol);
            }
          });

          data.onlineResources = alphaResources.concat(otherResources);

          return data;
        }),
      )
      .subscribe(
        data => this.dataSource.next(data)
      );
  }

  /**
   * Enregistre une donnée
   * @param dataForm - donnée à enregistrer
   * @param language - Language utilisée pour remplir la metadata
   */
  public saveData(dataForm: Data, files?: FileToUpload[], askPublication:boolean=false, getNewData: boolean = false): Observable<any> {
    let data = _.cloneDeep(dataForm);
    delete data.owner;
    delete data.status;

    let locale = Constants.languageToLocale[data.language];
    let format = Constants.localeCalendarFormats[locale].formatMoment;
    if(data.creationDate!=null && data.creationDate!="") {
      data.creationDate = moment(data.creationDate,format).format("YYYY-MM-DD");
    } else data.creationDate = null;
    if(data.releasedDate!=null && data.releasedDate!="") {
      data.releasedDate = moment(data.releasedDate,format).format("YYYY-MM-DD");
    } else data.releasedDate = null;
    if(data.temporalExtentStart!=null && data.temporalExtentStart!="") {
      data.temporalExtentStart = moment(data.temporalExtentStart,format).format("YYYY-MM-DD");
    } else data.temporalExtentStart = null;
    if(data.temporalExtentEnd!=null && data.temporalExtentEnd!="") {
      data.temporalExtentEnd = moment(data.temporalExtentEnd,format).format("YYYY-MM-DD");
    } else data.temporalExtentEnd = null;
    if(data.project!==null) {
      if(data.project.temporalExtentStart!=null && data.project.temporalExtentStart!="") {
        data.project.temporalExtentStart = moment(data.project.temporalExtentStart,format).format("YYYY-MM-DD");
      } else data.project.temporalExtentStart = null;
      if(data.project.temporalExtentEnd!=null && data.project.temporalExtentEnd!="") {
        data.project.temporalExtentEnd = moment(data.project.temporalExtentEnd,format).format("YYYY-MM-DD");
      } else data.project.temporalExtentEnd = null;
    }

    // Création des headers HTTP
    const headers = new HttpHeaders({
      'Accept-Language': data.language
    });

    let obs;
    if (data.id) {
      obs = this._http.put<any>(`${Uris.PROJECTS}${data.projectId}/datasets/${data.id}` + (askPublication ? '/full' : ''), data.serialize(), { headers: headers });
    } else {
      obs = this._http.post<any>(`${Uris.PROJECTS}${data.projectId}/datasets` + (askPublication ? '/full' : ''), data.serialize(), { headers: headers });
    }

    return obs
      .pipe(
        catchError(error => {
          if (error.status === 403 || error.status === 404) {
            this._router.navigate(['/my-data']);
          }

          return throwError(error);
        }),
        tap((result: any) => dataForm.id = result.id),
        switchMap((result: any) => {
          if (askPublication)
            this._mail.sendMailPublishMetadata(result.id);
          if (files) {
            let addedFiles: File[] = [];
            let deletedFiles: string[] = [];
            _.each(files, (file: FileToUpload) => {
              if (!file.existent && file.file) {
                addedFiles.push(file.file);
              }
              if (file.existent && file.deleted) {
                deletedFiles.push(file.label);
              }
            });

            let deleteCall: Observable<any> = of(null);
            let addCalls: Observable<any>[] = [];

            if (deletedFiles.length > 0) {
              const headers = new HttpHeaders({"Accept-Language" : data.language});
              deleteCall = this._http.post<any>(`${Uris.DATAS}${result.id}/files/delete`, deletedFiles, { headers: headers })
                .pipe(
                  catchError(error => {
                    return throwError(error);
                  })
                );
            }

            if (addedFiles.length > 0) {
              let iterFile = 1;
              addedFiles.forEach(file => {

                const headers = new HttpHeaders({
                  "Accept-Language": data.language,
                  "Content-Type": "application/octet-stream",
                  "Content-Disposition": "attachment;filename=" + file.name,
                  "Content-Length": file.size.toString()
                });

                addCalls.push(this._http.post<any>(`${Uris.DATAS}${result.id}/files`, file, { headers: headers,reportProgress: true,
                  observe: 'events' })
                  .pipe(
                    tap((event: HttpEvent<any>) => {
                      switch (event.type) {
                          case HttpEventType.Sent:
                              this._progressbar.show(addedFiles.length, iterFile, file.name, false);
                              iterFile++;
                              break;
                          case HttpEventType.DownloadProgress:
                          case HttpEventType.UploadProgress:
                              if (event.total) {
                                  const progress = Math.round(event.loaded / event.total * 100);
                                  this._progressbar.updateProgressValue(progress);
                              }
                              break;
                          case HttpEventType.Response:
                                if (!!event.body) this._progressbar.hide();
                        } // fin du switch
                      }
                    ),
                    last(),
                    catchError(error => {
                      this._progressbar.hide();
                      return throwError(error);
                    })
                  )
                );
              });
            }

            this._loader.hide();

            return deleteCall.pipe(
              tap(deleteResult => {
                if (deleteResult && _.isArray(deleteResult)) {
                  let notDeleted = [];
                  _.each(deleteResult, r => {
                    if (!r.deleted) {
                      notDeleted.push(r.fileName);
                    }
                  })
                  if (notDeleted.length > 0) {
                    this._toastr.warning(
                      $localize`Suite à une erreur, les fichiers suivants n'ont pas été correctement supprimés : ${notDeleted.join(', ')}`
                    );
                  }
                }
              }),
              switchMap(() => concat(...addCalls)),
              toArray(),
              tap(addResults => {
                if (addResults && _.isArray(addResults)) {
                  let notAdded = [];
                  let renamed = [];
                  _.each(addResults, r => {
                    if (!r.body[0].uploaded) {
                      notAdded.push(r.body[0].originalFileName);
                    } else if (r.body[0].fileName !== r.body[0].originalFileName) {
                      renamed.push(r.body[0].originalFileName + ' -> ' + r.body[0].fileName);
                    }
                  });
                  if (notAdded.length > 0) {
                    this._toastr.warning(
                      $localize`Suite à une erreur, les fichiers suivants n'ont pas été correctement ajoutés : ${notAdded.join(', ')}`
                    );
                  }
                  if (renamed.length > 0) {
                    this._toastr.info(
                      $localize`En raison de doublons de noms, les fichiers suivants ont été renommés : ${renamed.join(', ')}`
                    );
                  }
                }
              }),
              map(() => result)
            );
          }
          return of(result);
        }),
        switchMap(result => this._session.getUserPermissionsObs(result)),
        switchMap((result: any) => {
          if (getNewData) {
            return this._http.get<Data>(`${Uris.PROJECTS}${result.projectId}/datasets/${result.id}`)
              .pipe(
                catchError(error => {
                  return throwError(error);
                }),
                map(d => new Data().deserialize(d,d.language))
              )
          }
          return of(result);
        }),
        catchError(error => {
          this._progressbar.hide();
          return throwError(error);
        })
      );
  }

  /**
   * Supprimer une donnée
   * @param data - donnée à supprimer
   */
  public deleteData(data: Data): Observable<any> {
    return this._http.delete<any>(`${Uris.PROJECTS}${data.projectId}/datasets/${data.id}`)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Publier une donnée
   * @param data - donnée à publier
   */
  public publishData(data: Data): Observable<Date> {
    return this._http.put<any>(`${Uris.DATAS}${data.id}/publish`, null)
      .pipe(
        map(o => o[data.id]),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Supprimer une donnée
   * @param data - donnée à supprimer
   */
  public createDoi(data: Data): Observable<any> {
    return this._http.put<any>(`${Uris.DATAS}${data.id}/doi`, null)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Télécharge un fichier
   * @param id Identifiant du jeu de données
   * @param fileName Nom du fichier
   * @returns
   */
  public downloadFile(id: string, fileName: string, indexFile?: number, totalFiles?: number): Observable<Blob> {
    return this.downloadFileCommon(Uris.DATAS, id, fileName, indexFile, totalFiles);
  }

  /**
   * Télécharge un fichier (via un lien public)
   * @param id Identifiant du jeu de données
   * @param fileName Nom du fichier
   * @returns
   */
  public downloadPublicFile(id: string, fileName: string, indexFile?: number, totalFiles?: number): Observable<Blob> {
    return this.downloadFileCommon(Uris.PUBLIC_DATAS, id, fileName, indexFile, totalFiles);
  }

  private downloadFileCommon(uri: string, id: string, fileName: string, indexFile?: number, totalFiles?: number): Observable<Blob> {
    return this._progressbar.launchDownload(`${uri}${id}/files/${fileName}`, fileName, indexFile, totalFiles);
  }

  public downloadPublicZipFile(id: string, fileNames: string[]): Observable<Blob> {
    return this.downloadZipCommon(Uris.PUBLIC_DATAS, id, fileNames);
  }

  public downloadZipFile(id: string, fileNames: string[]): Observable<Blob> {
    return this.downloadZipCommon(Uris.DATAS, id, fileNames);
  }

  private downloadZipCommon(uri: string, id: string, fileNames: string[]): Observable<Blob> {
    return this._progressbar.launchDownloadZip(`${uri}${id}/files/zip`, id, fileNames);
  }

  public canDownloadFiles(id:string): Observable<boolean> {
    return this._http.get<boolean>(`${Uris.DATAS}${id}/canDownloadFiles`)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
