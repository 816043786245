// @ts-nocheck
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Uris } from '../constants';

import { MapConfig, Destination } from '../models';

import WMSCapabilities from 'ol/format/WMSCapabilities';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private maps: MapConfig[] = [];
  private capabilitiesFormatter: WMSCapabilities = new WMSCapabilities({ version: "1.3.0" });

  private capabilitiesSource = new Subject<any>();
  private geonamesSource = new Subject<any>();
  private geonamesBoundingBoxSource = new Subject<any>();
  private geonamesBoundingBoxSourceMode2 = new Subject<any>();
  private legendImagesSource = new Subject<any>();

  public capabilities$ = this.capabilitiesSource.asObservable();
  public geonames$ = this.geonamesSource.asObservable();
  public geonamesBoundingBox$ = this.geonamesBoundingBoxSource.asObservable();
  public geonamesBoundingBoxMode2$ = this.geonamesBoundingBoxSourceMode2.asObservable();
  public legendImages$ = this.legendImagesSource.asObservable();

  private _sessionMapStorage: any = {};


  constructor(
    private _http: HttpClient,
    private _utils: UtilsService
  ) { }

  public initMaps(): void {
    this._http.get<MapConfig[]>(Uris.MAPS)
      .pipe(
        map(datas => datas.map(m => new MapConfig().deserialize(m)))
      )
      .subscribe(maps => this.maps = maps);
  }

  public getMapConfig(mapName: string): MapConfig {
    return _.find(this.maps, { name: mapName }) || null;
  }

  public getBgLayerNumber(mapName: string): number {
    let mapConfig = this.getMapConfig(mapName);
    if (mapConfig) {
      return mapConfig.backgroundLayers.length;
    }
    return 0;
  }

  public getMapProjections(mapName: string): string[] {
    let mapConfig = this.getMapConfig(mapName);
    if (mapConfig) {
      return mapConfig.projections;
    }
    return [];
  }

  public saveSessionMap(projectId: number, workflowId: number, sessionMap: any) {
    if (!this._sessionMapStorage[projectId]) {
      this._sessionMapStorage[projectId] = {};
    }
    this._sessionMapStorage[projectId][workflowId] = _.clone(sessionMap);
  }

  public getSessionMap(projectId: number, workflowId: number): any {
    if (this._sessionMapStorage[projectId] && this._sessionMapStorage[projectId][workflowId]) {
      return this._sessionMapStorage[projectId][workflowId];
    }
    return null;
  }

  /**
   * Récupère les informations à afficher d'une couche
   * @param layer Couche demandée
   */
  public getLayerCapabilities(layer: any): void {
    let url = layer.url;
    if (url.indexOf('?') < 0) {
      url += "?";
    }
    url += "service=WMS&request=GetCapabilities";
    this._http.get(Uris.PROXY + '?url=' + encodeURIComponent(url), { responseType: 'text' })
      .pipe(
        map(result => this.capabilitiesFormatter.read(result))
      ).subscribe(result => {
        let layerCapabilities = null;
        if (result.Capability.Layer.Name === layer.name) {
          layerCapabilities = result.Capability.Layer;
        } else {
          layerCapabilities = this.getLayerFromCapabilities(result.Capability.Layer.Layer, layer.name);
        }
        let contact: any = null;
        if (result.Service.ContactInformation && _.keys(result.Service.ContactInformation).length > 0) {
          contact = {
            person: result.Service.ContactInformation.ContactPersonPrimary.ContactPerson,
            organization: result.Service.ContactInformation.ContactPersonPrimary.ContactOrganization,
            email: result.Service.ContactInformation.ContactElectronicMailAddress
          };
          if (result.Service.ContactInformation.ContactAddress) {
            contact.address = result.Service.ContactInformation.ContactAddress.Address;
            contact.postCode = result.Service.ContactInformation.ContactAddress.PostCode;
            contact.city = result.Service.ContactInformation.ContactAddress.City;
            contact.country = result.Service.ContactInformation.ContactAddress.Country;
          }
        }

        // récupération de la légende via proxy
        let legendUrl = layerCapabilities.Style[0].LegendURL ? layerCapabilities.Style[0].LegendURL[0].OnlineResource : null;
        let legendObservable = legendUrl ? this._utils.getImageDataFromProxy(legendUrl) : of(null);

        legendObservable.subscribe(legendDataImage => {
          this.capabilitiesSource.next({
            idx: layer.idx,
            serviceTitle: layerCapabilities.Title,
            abstract: layerCapabilities.Abstract,
            extent: layerCapabilities.EX_GeographicBoundingBox,
            visibility: this.getLayerScaleVisibility(layer.olLayer),
            legendUrl: legendDataImage,
            supportedProjections: layerCapabilities.CRS,
            contact: contact
          });
        });

      });
  }

  public searchGeonames(searchText: string, mode:string, language?:string): void {
    this._http.get<any[]>(Uris.GEONAMES_SEARCH, {
      params : {
        nameStartsWith: searchText.toLowerCase(),
        lang: language,
        maxRows: mode=="country" ? 1000 : 100,
        mode: mode
      }
    }).pipe(
      map(results => {
        let res = [];
        _.each(results, e => {
          if (e.type !== "cycleway") {
            let name = e.toponymName + (e.countryName!=null ? " ("+e.countryName+")" : "");
            if(mode=="country") name = e.countryName!=null ? e.countryName : "";
            if (!_.find(res, { name: name })) {
              res.push(new Destination().deserialize({
                geonameId: e.geonameId,
                label: name,
                extent: [e.lng,e.lat,e.lng,e.lat]
              }));
            }
          }
        });
        return res;
      })
    ).subscribe(result => {
      let formatedResult = [];
      _.each(result,r=>{
        formatedResult.push({ geonameId:r.geonameId, label:r.label , extent:r.extent });
      });
      result = _.sortBy(formatedResult, data => data.label.toLowerCase());
      this.geonamesSource.next(result)
    });
  }

  /**
   * Récupère la bounding box d'un item Geonames.
   * @param geonameId l'ID de l'item au sein de Geonames.
   * @param index la position de l'emprise associée dans la liste des emprises.
   * @param language langage
   */
  public getItemBoundingBox(geonameId:string, index:number, language?:string) {
    this._http.get<any[]>(Uris.GEONAMES_BOUNDING_BOX + geonameId, {
      params : {
        lang: language
      }
    }).subscribe(boundingBox => {
      this.geonamesBoundingBoxSource.next({ index: index, boundingBox: boundingBox });
    });
  }


  /**
   * Récupère la bounding box d'un item Geonames.
   * @param geonameId l'ID de l'item au sein de Geonames.
   * @param language langage
   */
  public getItemBoundingBoxMode2(geonameId:string, label: string, language?:string) {
    this._http.get<any[]>(Uris.GEONAMES_BOUNDING_BOX + geonameId, {
      params : {
        lang: language
      }
    }).subscribe(boundingBox => {
      this.geonamesBoundingBoxSourceMode2.next({ label: label, geonameId: geonameId, boundingBox: boundingBox });
    });
  }

  // TODO : à refaire quand le proxy pourra récupérer des images
  public getLegendImages(layers): void {
    let calls = [];

    _.each(layers, layer => {
      calls.push(this.loadImage(layer));
    });

    forkJoin(calls)
      .pipe(
        map(images => {
          let finalImages = [];
          _.each(images, image => {
            let c = document.createElement('canvas');
            c.width = image.img.naturalWidth;
            c.height = image.img.naturalHeight;

            c.getContext('2d').drawImage(image.img, 0, 0);

            finalImages.push({
              title: image.title,
              width: c.width,
              height: c.height,
              image: c.toDataURL('image/png')
            });
          });

          return finalImages;
        })
      )
      .subscribe(res => this.legendImagesSource.next(res));
  }

  private loadImage(layer) {
    return new Observable(observer => {
      let img = new Image();
      img.crossOrigin = "anonymous";
      img.src = layer.infos.legendUrl;
      img.onload = () => {
        observer.next({
          title: layer.title,
          img: img
        });
        observer.complete();
      }
      img.onerror = err => {
        observer.error(err);
      }
    });
  }

  private getLayerScaleVisibility(layer) {
    //Display Scale visibility
    let minScale = Number(layer.get("minScale"));
    let maxScale = Number(layer.get("maxScale"));
    if (minScale && maxScale) {
      var scaleToDisplay = "";
      if (minScale || maxScale) {
        if (!maxScale) {
          scaleToDisplay = "Scale < 1/" + minScale;
        } else {
          scaleToDisplay = "1/" + maxScale + "< Scale";
          if (minScale) {
            scaleToDisplay += '<1/' + minScale;
          }
        }
      }
      return scaleToDisplay || null;
    } else {
      return null;
    }
  }

  private getLayerFromCapabilities(layerList, layerName) {
    if (!layerList) {
      return null;
    }
    let foundLayer = null;
    for (let i = 0, len = layerList.length; i < len; ++i) {
      if (layerList[i].Name === layerName) {
        foundLayer = layerList[i];
        break;
      } else {
        let layer = this.getLayerFromCapabilities(layerList[i].Layer, layerName);
        if (layer) {
          foundLayer = layer;
          break;
        }
      }
    }
    return foundLayer;
  }
}
