import { EntityMapper } from "./entity-mapper.model";
import { User } from "./user.model";
import { Role } from "./role.model";
import { Thematic } from "./thematic.model";

/**
 * Groupe de droits d'accès aux métadonnées
 */
export class UserRole extends EntityMapper {

  /**
   * Identifiant de l'entité
   */
  public id: string | null = null;

  /**
   * Identité de l'utilisateur
   */
  public mail: string | null = null;
  
  /**
   * Rôle de l'utilisateur
   */
  public roleId: number | null = null;
  
  /**
   * Liste des thématiques associées au rôle de l'utilisateur (peut-être null en fonction du rôle)
   */
  public thematics: Thematic[] = [];
  
}