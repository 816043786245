import * as _ from 'lodash';

import { Component, Input } from "@angular/core";
import { ControlValueAccessor, Validator, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Constants } from "../../../../constants";
import { FileToUpload } from '../../../../models';
import { LoaderService, ProgressBarService, UtilsService } from 'src/app/services';

@Component({
  selector: 'drop-files',
  templateUrl: './drop-files.component.html',
  host: {
    class: 'd-block'
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DropFilesComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: DropFilesComponent, multi: true }
  ]
})
export class DropFilesComponent implements ControlValueAccessor, Validator {
  @Input() multiple: boolean = false;
  @Input() sizeLimit: number = Constants.FILE_SIZE_LIMIT;
  @Input() inputId: string;
  @Input() datasetFilesDirectory: string;
  @Input() filesDownloadable: boolean = true;

  public files: FileToUpload[] = [];
  public dropState: string;

  constructor(
    private _progressbar: ProgressBarService,
    private _utils: UtilsService,
    private _loader: LoaderService
  ) { }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  /**
   * Met à jour la liste des fichiers après ajout
   * @param files Fichiers ajoutés
   */
  public onFilesChange(files: FileList | File[]) {
    if (!this.multiple) {
      _.each(this.files, (file: FileToUpload) => {
        this.deleteFile(file);
      });
    }
    _.each(files, (file: File) => {
      this.files.push({
        label: file.name,
        file: file
      });
    });
    this.onChangeCb(this.files);
  }

  /**
   * Télécharge un fichier
   * @param file Fichier
   */
  public downloadFile(file:FileToUpload) {
    this._loader.show();
    let url = file.url;
    if (url && this.filesDownloadable){
      url = url.replace("public-api", "api");
      this._progressbar.launchDownload(url, file.label).subscribe(blob => {
        this._utils.launchDownloadFile(blob, file.label);
      });
    }else
      this._loader.hide();
  }

  /**
   * Tagge un fichier comme "à supprimer"
   */
  public deleteFile(file: FileToUpload) {
    if (file.existent) {
      file.deleted = true;
    } else {
      this.files.splice(this.files.indexOf(file), 1);
    }
    this.onChangeCb(this.files);
  }

  /**
   * Ajoute les fichiers droppés dans la zone
   * @param event
   */
  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files.length > 0) {
      if (this.multiple) {
        this.onFilesChange(event.dataTransfer.files);
      } else {
        this.onFilesChange([event.dataTransfer.files[0]]);
      }
    }
    this.dropState = "";
  }

  /**
   * Modifie la classe au survol (en drag) de la zone
   * @param event
   */
  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropState = "hover";
  }

  /**
   * Modifie la classe en quittant le survol (en drag) de la zone
   * @param event
   */
  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropState = "";
  }

  // control methods
  writeValue(values: FileToUpload[]) {
    if (!values || !_.isArray(values)) {
      values = [];
    }
    let files = _.filter(this.files, f => !f.existent);

    this.files = values.concat(files);
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations: any = {};

    _.each(this.files, (file: FileToUpload) => {
      file.invalid = false;
      if (file.file && (file.file.size / 1024 / 1024) >= this.sizeLimit) {
        file.invalid = true;
        validations.size = true;
      }
    });

    if (_.keys(validations).length > 0) {
      return validations;
    }

    return null;
  }
}
