import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, throwError } from "rxjs";
import { ToastrService } from 'ngx-toastr';

import { Project, Destination, Contact, Organization, Role, User } from '../../../../app/models';
import { ProjectService, MapService, LoaderService, MetadataService, PrimeNGUtilsService, RorService, SessionService } from '../../../services';
import { Constants, Uris } from '../../../constants';
import { FormListEvent, RegistryService } from '../../../services/registry.service';
import { Angulartics2 } from 'angulartics2';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';

import { DescribedExtentComponent } from '../form-parts/described-extent/described-extent.component';
import { Extent } from '../../../models/extent.model';
import { Registry } from '../../../models/registry.model';
import { StatusService } from '../../../services/status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../../modals';
import { Status } from '../../../models/status.model';
import {DataPole} from "../../../models/data-pole.model";

@Component({
  templateUrl: './project-edit.component.html'
})
export class ProjectEditComponent implements OnInit, OnDestroy {

  @ViewChild('nameField') nameField;

  @ViewChild('descField') descField;

  @ViewChild('dataPolesField') dataPolesField;

  @ViewChild('thematicsField') thematicsField;

  @ViewChild('keywordsField') keywordsField;

  @ViewChild('spaceExtentField') spaceExtentField;

  @ViewChildren('westBoundLongitudeField') westBoundLongitudeFields;

  @ViewChildren('eastBoundLongitudeField') eastBoundLongitudeFields;

  @ViewChildren('northBoundLatitudeField') northBoundLatitudeFields;

  @ViewChildren('southBoundLatitudeField') southBoundLatitudeFields;

  @ViewChildren('contactLastNameField') contactLastNameFields;

  @ViewChildren('contactFirstNameField') contactFirstNameFields;

  @ViewChildren('contactEmailField') contactEmailFields;

  @ViewChildren('contactRoleField') contactRoleFields;

  @ViewChildren('contactOrganisationField') contactOrganisationFields;

  @ViewChild('dataPolesAutocomplete') dataPolesAutocomplete;

  @ViewChild('thematicsAutocomplete') thematicsAutocomplete;

  @ViewChild('keywordsAutocomplete') keywordsAutocomplete;

  @ViewChild('timeExtentStartField') timeExtentStartField;

  @ViewChild('timeExtentEndField') timeExtentEndField;

  @ViewChild(DescribedExtentComponent) describedExtentComponent:DescribedExtentComponent;

  /**
   * Indique si l'utilisateur est le propriétaire du dépôt (i.e "étude" dans Cupidon)
   */
  public isOwner: boolean = false;

  /**
   * Indique si l'utilisateur peut modifier le dépôt (i.e "étude" dans Cupidon)
   */
  public isEditor: boolean = false;

  /**
   * Indique si l'utilisateur est administrateur
   */
  public isAdmin: boolean = false;

  /**
   * Indique si l'utilisateur est modérateur
   */
  public isModerator: boolean = false;

  /**
   * Dépôt (i.e "étude" dans Cupidon) en cours d'édition
   */
  public project: Project;

  /**
   * Identifiant du dépôt en cours d'édition
   */
  public projectId: string;

  /**
   * Est-ce un nouveau dépôt (i.e "étude" dans Cupidon) ?
   */
  public isNew: boolean = true;

  /**
   * Liste des geonames de l'autocomplétion
   */
  public geonames: Destination[] = [];

  /**
   * Thématiques filtrées
   */
  public filteredThematics: string[] = [];

  /**
   * Liste des catégories
   */
  public projectCategories: string[] = [];

  /**
   * Liste des types de contact
   */
  public contactTypes: { label: string, value: string }[] = [];

  /**
   * Est-il possible de supprimer un contact "pointOfContact" ?
   */
  public canDeletePointOfContact: boolean = false;

  /**
   * Objet geoname actuellement sélectionné
   */
  //public chosenGeoname: Destination;
  public chosenGeoname: {label:string};

  /**
   * Types de description des emprises géographiques
   */
  public extentsDescriptionTypes:string[] = [];

  /**
   * Liste des mots-clés au format string
   */
  public keywords: Registry[] = [];

  /**
   * Nom du pôle de données
   */
  public dataPoles: Registry[] = [];


  public completedDataPole: DataPole[] = [];

  /**
   * Langage de la metadata
   */
   public language = "fre";

   /**
    * Langue actuelle
    */
   public locale: string = "fr";

   /**
    * Langue précédemment sélectionnée
    */
   public previousLocale:string = "fr";

   /**
    * Configuration des langues
    */
   public localeCalendarConfig = Constants.localeCalendarConfig;

   /**
    * Expressions régulières des langues
    */
   public localeCalendarRegExps = Constants.localeCalendarRegExps;

   /**
    * Format des dates en fonction des langues
    */
   public localeCalendarFormats = Constants.localeCalendarFormats;

   /**
    * Traduction language -> locale
    */
   public languageToLocale = Constants.languageToLocale;

   /**
    * Liste des langues proposées par l'application
    */
   public availableLanguages = Constants.languages;

  /**
  * Liste des pôles de données pour l'auto-complétion
  */
  public dataPolesSuggestions: SelectItem[] = [];

  /**
   * Liste de âges de début des dropdown
   */
  public thematicsSuggestions: SelectItem[] = [];

  /**
   * Liste des mots-clés pour l'auto-complétion
   */
  public keywordsSuggestions: SelectItem[] = [];

  /**
   * Stockage des IDs des pôles de données pour le changement de langue
   */
  public dataPolesIdentifiersStorage: string[] = [];

  /**
   * Stockage des IDs des thématiques pour le changement de langue
   */
  public thematicsIdentifiersStorage: string[] = [];

  /**
   * Stockage des IDs des mots-clés pour le changement de langue
   */
  public keywordsIdentifiersStorage: string[] = [];

  /**
   * URL vers le registre des pôles de données
   */
  public dataPolesRegistryUrl: string = Uris.DATA_POLES_REGISTRY_URL;

  /**
   * URL vers le registre des thématiques
   */
  public thematicsRegistryUrl: string = Uris.THEMATICS_REGISTRY_URL;

  /**
   * URL vers le registre des mots-clés
   */
  public keywordsRegistryUrl: string = Uris.KEYWORDS_REGISTRY_URL;

  /**
   * Liste des organisations pour l'autocomplétion
   */
  public organizationResults: Organization[] = [];

  /**
   * Booléen qualifiant si le formulaire a été soumis au moins une fois
   */
  public formSubmitted:boolean = false;

  /**
   * Dépôt (i.e "étude" dans Cupidon) avant modification
   */
  private _initialProject: Project;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Ancienne valeur du champ Rôle d'un contact
   */
  private oldValueRole:string;

  /**
   * String pour info-bulle de l'organisation d'un contact
   */
  tooltipRorSite = "ROR (" + Uris.ROR_SITE+ ")";

  /**
   * String pour info-bulle du rôle d'un contact
   */
  siteStandards = Constants.STANDARDS_ISO_TC211;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _metadataService: MetadataService,
    private _projectService: ProjectService,
    private _statusService: StatusService,
    private _mapService: MapService,
    private _toastr: ToastrService,
    private _loader: LoaderService,
    private _session: SessionService,
    private _registryService: RegistryService,
    private _modalService: NgbModal,
    private _tracker: Angulartics2,
    private _elementRef: ElementRef,
    private _primeNGUtilsService: PrimeNGUtilsService,
    private _rorService: RorService
  ) { }

  ngOnInit() {

    this._subs.add(this._session.currentUser$.subscribe(user => this._checkRoles(user)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._mapService.geonames$.subscribe(geonames => this._updateGeonames(geonames)));
    this._subs.add(this._mapService.geonamesBoundingBox$.subscribe(result => this._updateBoundingBox(result.boundingBox, result.index)));
    this._subs.add(this._route.parent.paramMap.subscribe(params => this._initProjectFromParams(params)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));
    this._subs.add(this._statusService.statusByUri$.subscribe(status => this._initStatus(status)));
    this._subs.add(this._statusService.statusUpdate$.subscribe(status => this._router.navigate(["/records/pending"])));

    this.projectCategories = Constants.projectCategories;
    this.contactTypes = [];
    for(let i:number=0 ; i< Constants.contactTypes.length ; i++) {
      let elt = Constants.contactTypes[i];
      let value:string = elt.value;
      for(let l of elt.labels) {
        if(l.language==this.language) {
          let label:string = l.label;
          this.contactTypes.push({label:label,value:value});
          break;
        }
      }
    }
    this.contactTypes.sort((a,b) => a.label.localeCompare(b.label));

    this._route.queryParams
      .subscribe(params => {
        if(params.lang!=null) this.language = params.lang;
      }
    );

  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Lance la requête pour l'autocomplétion des geonames
   * @param event - event primeng
   */
  public searchGeonames(event: any, index:number) {
    let formattedLanguage = "";
    if (this.language == "fre") formattedLanguage = "fr";
    else if (this.language == "eng") formattedLanguage = "en";

    this._mapService.searchGeonames(event.query, this.extentsDescriptionTypes[index], formattedLanguage);
  }

  /**
   * Lorsque l'on sélectionne un item Geonames, on envoie une requête
   * pour récupérer sa bounding box.
   * @param item : l'item sélectionné.
   * @param index : la position de l'emprise concernée dans la liste des emprises.
   */
  public geonamesItemSelected(item:Destination, index:number) {
    this.project.extents[index].geonameId = item.geonameId;
    this.project.extents[index].description = { label: item.label };

    let formattedLanguage = "";
    if (this.language == "fre") formattedLanguage = "fr";
    else if (this.language == "eng") formattedLanguage = "en";

    this._mapService.getItemBoundingBox(item.geonameId, index, formattedLanguage);
  }

  /**
   * Demande du dépôt (i.e "étude" dans Cupidon) à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initProjectFromParams(params: any) {
    this._loader.show();
    this._route.queryParams
      .subscribe(parameters => {
        if(parameters.lang!=null) this.language = parameters.lang;
        this._projectService.getProject(params.get('id'), true, this.language);
        this.isNew = (params.get('id') === null); // No 'id' param => route my-data/new   'id' param => route my-data/{id}/edit
      }
    );
    this.projectId = params.get('id');
    if(!this._session.currentUser) this._session.getCurrentUser();
    else this._checkRoles(this._session.currentUser);
  }

  /**
   * Clone le dépôt (i.e "étude" dans Cupidon) reçu du serveur pour en faire un objet éditable
   * @param project - Dépôt issu du serveur
   */
  private _initProject(project: Project) {
    this._initialProject = _.cloneDeep(project);
    if (this.isNew) {
      this._initialProject.name = "";

      let firstContact = new Contact();
      firstContact.individualLastName = this._session.currentUser.lastName;
      firstContact.individualFirstName = this._session.currentUser.firstName;
      firstContact.email = this._session.currentUser.email;
      firstContact.role = "pointOfContact";
      this._initialProject.contacts.push(firstContact);
    }
    this.project = _.cloneDeep(this._initialProject);

    this.project.extents = [];
    _.each(project.extents , extent => {
      let newExtent = new Extent();
      newExtent.geonameId = extent.geonameId;
      newExtent.description = extent.description;
      newExtent.westBoundLongitude = extent.westBoundLongitude;
      newExtent.eastBoundLongitude = extent.eastBoundLongitude;
      newExtent.northBoundLatitude = extent.northBoundLatitude;
      newExtent.southBoundLatitude = extent.southBoundLatitude;
      this.project.extents.push(newExtent);
    });

    if(!this.isNew) {
      this.keywords = this.project.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME);
      this.dataPoles = this.project.getClassifiedKeywordsWithLink(Constants.DATA_POLE_KEYWORD_NAME);
    } else {
      this.keywords = [];
      this.dataPoles = [];
    }
    if(!this.isNew) this._statusService.getStatusByMetadataUuid(this.project);

    this._registryService.getLexiques("dataPoles",this.language, null, null);
    setTimeout(()=> {
    this.updateDataPole();
    },100);

    this._loader.hide();

    this._primeNGUtilsService.setLanguage(this.locale);
  }

  /**
   * Initialise le statut de la ressource
   * @param status
   */
  private _initStatus(status:Status) {
    this.project.status = status;
  }

  /**
   * Met à jour la liste des suggestions Geonames.
   * @param geonames
   */
  private _updateGeonames(geonames:Destination[]) {
    this.geonames = geonames;
  }

  /**
   * Met à jour la bounding box d'une emprise.
   * @param boundingBox : la bounding box de l'emprise.
   * @param index : la position de l'emprise dans la liste des emprises.
   * @returns
   */
  private _updateBoundingBox(boundingBox:Extent, index:number) {
    if (!this.project.extents || this.project.extents.length < index + 1) return;

    this.project.extents[index].westBoundLongitude = boundingBox.westBoundLongitude;
    this.project.extents[index].eastBoundLongitude = boundingBox.eastBoundLongitude;
    this.project.extents[index].northBoundLatitude = boundingBox.northBoundLatitude;
    this.project.extents[index].southBoundLatitude = boundingBox.southBoundLatitude;

    if (!!boundingBox.westBoundLongitude && !!boundingBox.eastBoundLongitude && !!boundingBox.northBoundLatitude && !!boundingBox.southBoundLatitude) {
      this.describedExtentComponent.updateBBoxes();
      this.describedExtentComponent.onChangeCb(this.project.extents);
    }
  }


  /**
   * Enregistre le dépôt (i.e "étude" dans Cupidon) et reviens à la liste des dépôts
   */
  public save(askPublication:boolean, afterSaveOk?: (value: any) => void, afterSaveKo?: (error: any) => void) {
    this._loader.show();
    if (this.keywords) {
      this.project.freeKeywords = this.keywords;
    } else {
      this.project.freeKeywords = [];
    }
    if (this.dataPoles) {
      let dataPolesEntities:DataPole[] = [];
      _.each(this.dataPoles , dataPole => {
        let dataPoleEntity = new DataPole();
        dataPoleEntity.id = dataPole.id;
        dataPoleEntity.label = dataPole.label;
        dataPoleEntity.subRegistry = dataPole.subRegistry;
        dataPolesEntities.push(dataPoleEntity);
      });
      this.project.addClassifiedKeywordsWithLink(Constants.DATA_POLE_KEYWORD_NAME, dataPolesEntities, true);
    } else {
      this.project.removeClassifiedKeywords(Constants.DATA_POLE_KEYWORD_NAME);
    }
    this.project.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME, this.project.thematics, true);
    this.project.addClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME, this.keywords, true);

    this.project.language = this.language;

    let project = _.cloneDeep(this.project);
    delete project.owner;
    delete project.status;

    if (!afterSaveOk) {
      afterSaveOk = result => {
        this._tracker.eventTrack.next({
          action: this.isNew ? "Ajout d'un dépôt" : "Modification d'un dépôt",
          properties: {
            category: this.project.name
          }
        });
        this._loader.hide();
        if (result && result.id) {
          if (this.isNew) {
            this._toastr.success($localize`Le dépôt ${this.project.name} a été créé avec succès`);
            this._session.setInitPageParams({ id: result.id, type: 'project' });
          } else {
            this._toastr.success($localize`Le dépôt ${this.project.name} a été modifié avec succès`);

            if (this.thematicsField.control.dirty)
              this._toastr.info(
                $localize`La fiche dépôt a été modifiée, assurez-vous de vérifier et de modifier les fiches Jeux de données associées au dépôt avant de demander leur publication`,
                null,
                Constants.toastrErrorOptions
              );
          }
          let routeToNavigate = this.isNew ? `../${result.id}` : '../';
          this._router.navigate([routeToNavigate], { relativeTo: this._route });
        }
      };
    }

    if (!afterSaveKo) afterSaveKo = error => console.error(error);

    this._projectService.saveProject(project,askPublication).subscribe(afterSaveOk, afterSaveKo);
  }


  public saveDraft(event:any):void {
    event.preventDefault();
    this.formSubmitted = true;
    if (this._checkMinimalFields()) this.save(false);
  }


  public saveAndAskPublication(event:any):void {
    event.preventDefault();
    this.formSubmitted = true;
    if (this._checkAllFields()) this.save(true);
  }


  /**
   * Met à jour une date du formulaire en fonction du nouveau format imposé par la langue choisie.
   * @param date la valeur de la date en tant que chaîne de caractères.
   * @returns la langue au nouveau format.
   */
  public changeDateFormat(date:string, oldLocale:string, newLocale:string):string {
    if (!date) return "";

    let oldFormat = this.localeCalendarFormats[oldLocale].formatMoment;
    let newFormat = this.localeCalendarFormats[newLocale].formatMoment;
    if (!oldFormat || !newFormat) return "";

    return moment(date, oldFormat).format(newFormat);
  }

  /**
   * Ajoute une emprise
   */
  public addExtent() {
    let extent = new Extent();
    extent.westBoundLongitude = null;
    extent.eastBoundLongitude = null;
    extent.northBoundLatitude = null;
    extent.southBoundLatitude = null;
    this.project.extents.push(extent);
    this.describedExtentComponent.onChangeCb(this.project.extents);
    this.extentsDescriptionTypes.push("place");
  }

  /**
   * Supprime une emprise
   */
  public removeExtent(index: number) {
    this.project.extents.splice(index, 1);
    this.describedExtentComponent.updateBBoxes();
    this.describedExtentComponent.onChangeCb(this.project.extents);
  }

  /**
   * Ajoute un contact
   */
  public addContact() {
    this.project.contacts.push(new Contact());
  }

  /**
   * Supprime un contact
   * @param index emplacement de l'objet
   */
  public removeContact(index: number) {
    this.project.contacts.splice(index, 1);
    this.checkPointsOfContacts(null);
  }

  /**
   * Vérifie si on peut encore supprimer des "pointOfContact"
   * @param contact Contact
   */
  public checkPointsOfContacts(contact:Contact) {
    let pointOfContacts = _.filter(this.project.contacts, { role: "pointOfContact" });
    this.canDeletePointOfContact = (pointOfContacts.length > 1);

    if (!!contact && (this.oldValueRole === 'funder' || contact.role === 'funder')) this.clearOrganization(contact);
  }

  /**
   * Stockage de l'actuelle valeur du champ Rôle d'un contact (pour gérer la réinitialisation du champ Organisation si besoin)
   * @param contact Contact
   */
  public onFocusRole(contact:Contact) {
    this.oldValueRole = !!contact ? contact.role : null;
  }

  /**
   * Lance la recherche des organisations (via ROR)
   * @param event Evénement contenant la saisie
   * @param contact Contact
   */
  public searchOrganization(event, contact:Contact) {
    if (!!event && !!event.query && event.query.length >= 3) {
      this._rorService.searchObs(event.query).subscribe(results => this.organizationResults = results);
    } else this.organizationResults = [];
  }

  /**
   * Réinitialise l'organisation du contact
   * @param contact Contact
   */
  public clearOrganization(contact:Contact) {
    if (!!contact) {
      contact.organisation = null;
    }
  }

  /**
   * Accepte la publication de la métadonnée.
   * @param event : the click event of the button, prevented to avoid submitting the form.
   */
  public acceptPublication(event:any):void {
    event.preventDefault();

    if (this._checkAllFields()) {
      const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
      modalRef.componentInstance.title = $localize`Validation de la publication d'un dépôt`;
      modalRef.componentInstance.message = $localize`Voulez-vous vraiment accepter la publication du dépôt "${this.project.name}"?`;
      modalRef.componentInstance.confirmClass = "btn-success";
      modalRef.componentInstance.confirmText = $localize`Accepter la publication`;
      modalRef.result.then(() => {
        this._loader.show();

        this.save(
          true,
          () => this._statusService.acceptPublication(this.project, this.language),
          (error: any) => throwError(error)
        );
      });
    }
  }


  /**
   * Refuse la publication de la métadonnée.
   * @param event : the click event of the button, prevented to avoid submitting the form.
   */
  public refusePublication(event:any):void {
    event.preventDefault();
    const modalRef = this._modalService.open(ConfirmModalComponent, {windowClass:"confirm-modal"});
    modalRef.componentInstance.title = $localize`Refuser de la publication d'un jeu de données`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment refuser la publication du jeu de données "${this.project.name}"?`;
    modalRef.componentInstance.inputLabel = $localize`Message précisant l'objet du refus`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Refuser la publication`;
    modalRef.result.then((message:string) => {
      this._loader.show();
      this._statusService.refusePublication(this.project, message);
    });
  }


  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackByValue(item) {
    return item.value;
  }


  /**
   * Vérifie qu'une date est présente et au bon format.
   * @param field
   * @returns
   */
  public checkDate(field:any) {
    let formatRegExp = this.localeCalendarRegExps[this.locale];
    if(!field.control.value) {
      field.control.setErrors({required:true});
      return;
    }
    if(!formatRegExp.test(field.control.value)) field.control.setErrors({format : true});
  }


  /**
   * Vérifie que les valeurs de l'étendu temporelle sont présentes, au bon format et avec les bonnes valeurs.
   * @param field
   * @returns
   */
  public checkTemporalExtentDates(field:any, type:string, selectedInCalendar:boolean) {
    this.timeExtentStartField.control.setErrors(null);
    this.timeExtentEndField.control.setErrors(null);

    if (!selectedInCalendar) {
      // Check if value has the right format
      let formatRegExp = this.localeCalendarRegExps[this.locale];
      if (!!field && !formatRegExp.test(field.control.value)) {
        field.control.setErrors({ format: true });
        return;
      }
    }
    // Check if start < end
    let format = this.localeCalendarFormats[this.locale].formatMoment;
    let startDate:Date|null = type == "start" ? moment(field.control.value, format).toDate() :
                                              (!!this.project.temporalExtentStart ? moment(this.project.temporalExtentStart, format).toDate() : null);
    let endDate:Date|null = type == "end" ? moment(field.control.value, format).toDate() :
                                          (!!this.project.temporalExtentEnd ? moment(this.project.temporalExtentEnd, format).toDate() : null);
    let today = new Date();

    // Check if start <= today
    if (!!startDate && startDate.getTime() > today.getTime()) this.timeExtentStartField.control.setErrors({ rangeToday: true });

    // Check if end <= today
    if (!!endDate && endDate.getTime() > today.getTime()) this.timeExtentEndField.control.setErrors({ rangeToday: true });

    // Check if start <= end
    if (!!startDate && !!endDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors({ rangeEnd: true });
  }


  public updateExtentsDescriptionTypes(value:string,index:number) {
    this.extentsDescriptionTypes[index] = value;
  }

  /**
    * Changement de la langue de la metadata
    */
  public onLanguageChange() {
    this.dataPolesIdentifiersStorage = !!this.dataPoles ? _.cloneDeep(this.dataPoles).map(d => d.id) : [];
    this.thematicsIdentifiersStorage = !!this.project.thematics ? _.cloneDeep(this.project.thematics).map(t => t.id) : [];
    this.keywordsIdentifiersStorage = !!this.keywords ?  _.cloneDeep(this.keywords).map(k => k.id) : [];

    // On ne recharge pas s'il s'agit d'une nouvelle donnée
    this.dataPoles = [];
    this.keywords = [];
    this.project.thematics = [];
    if (!this.isNew) {
      this._loader.show();
      this._projectService.getProject(this.project.id, true, this.language);
    }else{
      this._reloadRegistries();
    }


    let oldLocale = this.locale;
    this.locale = this.languageToLocale[this.language];
    let newLocale = this.locale;

    this.project.temporalExtentStart = this.changeDateFormat(this.project.temporalExtentStart, oldLocale, newLocale);
    this.project.temporalExtentEnd = this.changeDateFormat(this.project.temporalExtentEnd, oldLocale, newLocale);

    this.contactTypes = [];
    for (let i:number = 0; i < Constants.contactTypes.length; i++) {
      let elt = Constants.contactTypes[i];
      let value:string = elt.value;

      for (let l of elt.labels) {
        if (l.language == this.language) {
          let label:string = l.label;
          this.contactTypes.push({ label:label, value:value });
          break;
        }
      }
    }
    this.contactTypes.sort((a,b) => a.label.localeCompare(b.label));

    this._primeNGUtilsService.setLanguage(this.locale);
  }

  /**
   * Ferme la pop-up demandant de choisir une langue
   */
  public closeLanguagePopUp(event?:any) {
    if(event!=null) event.preventDefault();
    let popUp = this._elementRef.nativeElement.querySelector("#language-pop-up");
    popUp.classList.add("hidden");
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   * @param type - Type de donnée à autocompléter
   */
  public formListAutocomplete(event, type: string): any {
    switch (type) {
      case 'categories':
      case 'dataPoles':
        this._registryService.getLexiques(type, this.language, null, null, event!=null ? event.query : "", {});
        break;
      case 'keywords':
      case 'thematics':
        this._registryService.getLexiques(type, this.language, this.completedDataPole, null, event!=null ? event.query : "", {});
        break;
    }
    // Uncomment code below to activate minimal length of keywords autocomplete
    /* if(type=="keywords" && event.query.length<3) {
      let autocompleteElt = this._elementRef.nativeElement.querySelector("#data-keywords-autocomplete");
      if(autocompleteElt!=null) autocompleteElt.classList.add("hide-load-icon");
      return;
    } else {
      let autocompleteElt = this._elementRef.nativeElement.querySelector("#data-keywords-autocomplete");
      if(autocompleteElt!=null) autocompleteElt.classList.remove("hide-load-icon");
    }
    this._registryService.getLexiques(type, this.language, event!=null ? event.query : "", {}); */
  }

  /**
   * Affiche la liste complète des suggestions d'une liste quand un élément
   * de type p-autocomplete reçoit le focus
   * @param autocomplete Element p-autocomplete du DOM ayant reçu le focus
   * @param type type de liste de propositions
   */
  public autocompleteFocus(autocomplete,type:string) {
    switch(type) {
      case "thematics":
        autocomplete._suggestions = this.thematicsSuggestions;
        break;
      case "dataPoles":
        autocomplete._suggestions = this.dataPolesSuggestions;
        break;
      case "keywords":
        autocomplete._suggestions = this.keywordsSuggestions;
        break;
    }
    if(autocomplete._suggestions.length > 0) autocomplete.show();
  }

  public autocompleteSelected(autocomplete,type:string) {
    this.autocompleteFocus(autocomplete,type);
  }

  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case 'dataPoles':
        this.dataPolesSuggestions = event.datas;
        if(this.project!=undefined && this.project!=null) {
          _.each(this.dataPolesSuggestions , dataPole => {
            _.each(this.dataPoles , dp => {
              if (dp.id == dataPole.id)
                dp.subRegistry = dataPole.subRegistry
            });
            if(this.dataPolesIdentifiersStorage.includes(dataPole.id) && this.dataPoles.filter(d=>d.id==dataPole.id).length==0)
              this.dataPoles.push(dataPole);
          });
        }
        break;
      case 'thematics':
        this.thematicsSuggestions = event.datas;
        if(this.project!=undefined && this.project!=null) {
          _.each(this.thematicsSuggestions , thematic => {
            if(this.thematicsIdentifiersStorage.includes(thematic.id) && this.project.thematics.filter(t=>t.id==thematic.id).length==0) this.project.thematics.push(thematic);
          });
        }
        break;
      case 'keywords' :
        this.keywordsSuggestions = event.datas;
        if(this.project!=undefined && this.project!=null) {
          _.each(this.keywordsSuggestions , keyword => {
            if(this.keywordsIdentifiersStorage.includes(keyword.id) && !this.keywords.includes(keyword) && this.keywords.filter(t=>t.id==keyword.id).length==0)
              this.keywords.push(keyword);
          });
        }
        break;
      case 'geonames':
        this._updateGeonames(event.datas);
        break;
    }
  }

  /**
   * Recharge les registres suite à un changment de langue
   */
  private _reloadRegistries() {
    this._registryService.getLexiques("dataPoles",this.language, null, null);
    this._registryService.getLexiques("thematics",this.language, this.completedDataPole, null, "");
    this._registryService.getLexiques("keywords", this.language, this.completedDataPole, null, "");
  }


  /**
   * Vérifie que l'utilisateur connecté a l'autorisation d'accéder à la page. Redirige sinon.
   * @param roles : la liste des rôles affectés à l'utilisateur.
   */
  private _checkRoles(user:User):void {
    if(this.isNew) return;
    this.isOwner = this._session.hasRight(this.projectId,'owner');
    this.isEditor = this._session.hasRight(this.projectId,'editor');
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    if(!!adminRole) this.isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    let moderatorRole = roles.find(role => role.name == Constants.ROLE_MODERATOR);
    if(!!moderatorRole) this.isModerator = user.userRoles.findIndex(userRole => userRole.roleId==moderatorRole.id)!=-1;
    if(!this.isOwner && !this.isEditor && !this.isAdmin && !this.isModerator) this._router.navigate(["/home"]);
    this._loader.hide();
  }

  /**
   * Vérifie que les champs strictement nécessaires sont présents.
   * @returns
   */
  private _checkMinimalFields():boolean {
    let invalidFields:HTMLElement[] = this._checkCommonFields();

    // Scroll de l'écran vers la première erreur
    if (invalidFields.length > 0) invalidFields[0].scrollIntoView({ behavior: "smooth" });

    return invalidFields.length === 0;
  }

  private _checkAllFields():boolean {
    let invalidFields:HTMLElement[] = this._checkCommonFields();
    let formatRegExp = this.localeCalendarRegExps[this.locale], format = this.localeCalendarFormats[this.locale].format;

    // Description
    this._metadataService.addFieldRequiredError(invalidFields, this.descField.control, this.project.description, 'project-description');

    // Data poles
    this._metadataService.addFieldMultiRequiredError(invalidFields, this.dataPolesField.control, this.dataPoles, 'project-data-poles-autocomplete');

    // Thematics
    this._metadataService.addFieldMultiRequiredError(invalidFields, this.thematicsField.control, this.project.thematics, 'project-thematics-autocomplete');

    // Keywords
    this._metadataService.addFieldMultiRequiredError(invalidFields, this.keywordsField.control, this.keywords, 'project-keywords-autocomplete');

    // Spatial extents
    this._metadataService.addFieldEachRequiredError(invalidFields, this.westBoundLongitudeFields._results, 'project-west-bound-longitude-');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.eastBoundLongitudeFields._results, 'project-east-bound-longitude-');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.northBoundLatitudeFields._results, 'project-north-bound-latitude-');

    this._metadataService.addFieldEachRequiredError(invalidFields, this.southBoundLatitudeFields._results, 'project-south-bound-latitude-');

    /* Contacts */
    // Contacts last names
    this._metadataService.addFieldEachPatternError(invalidFields, this.contactLastNameFields._results, 'contact-lastname-', Constants.regExps.name);

    // Contacts first names
    this._metadataService.addFieldEachPatternError(invalidFields, this.contactFirstNameFields._results, 'contact-firstname-', Constants.regExps.name);

    // Contacts mails
    this._metadataService.addFieldEachPatternError(invalidFields, this.contactEmailFields._results, 'contact-email-', Constants.regExps.mail);

    // Contacts organisations
    this._metadataService.addFieldEachRequiredError(invalidFields, this.contactOrganisationFields._results, 'contact-organisation-');

    // Contacts roles
    this._metadataService.addFieldEachRequiredError(invalidFields, this.contactRoleFields._results, 'contact-role-');
    /* End Contacts */

    // Time extent start
    if (!!this.project.temporalExtentStart) {
      if (!formatRegExp.test(this.project.temporalExtentStart)) this.timeExtentStartField.control.setErrors(Constants.errorOptions.format);
      else {
        if (!this.project.temporalExtentEnd) this.timeExtentEndField.control.setErrors(Constants.errorOptions.bothDates);
        else {
          let startDate = (!!this.project.temporalExtentStart) ? moment(this.project.temporalExtentStart, format).toDate() : null;
          let endDate = (!!this.project.temporalExtentEnd) ? moment(this.project.temporalExtentEnd, format).toDate() : null;

          if(!!startDate) {
            if (!!endDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors(Constants.errorOptions.range);
          } else if (!endDate) this.timeExtentEndField.control.setErrors(Constants.errorOptions.bothDates);
        }
      }
    }

    let timeExtentStartElt:HTMLElement|null = document.getElementById('project-time-extent-start');
    if (!!timeExtentStartElt && (!!this.timeExtentStartField.control.errors || !!this.timeExtentEndField.control.errors)) invalidFields.push(timeExtentStartElt);

    // Time extent end
    if (!!this.project.temporalExtentEnd) {
      if (!formatRegExp.test(this.project.temporalExtentEnd)) this.timeExtentEndField.control.setErrors(Constants.errorOptions.format);
      else {
        if (!this.project.temporalExtentStart) this.timeExtentStartField.control.setErrors(Constants.errorOptions.bothDates);
        else {
          let startDate = (!!this.project.temporalExtentStart) ? moment(this.project.temporalExtentStart, format).toDate() : null;
          let endDate = (!!this.project.temporalExtentEnd) ? moment(this.project.temporalExtentEnd, format).toDate() : null;

          if (!!endDate) {
            if (!!startDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors(Constants.errorOptions.range);
          } else if(!startDate) this.timeExtentStartField.control.setErrors(Constants.errorOptions.bothDates);
        }
      }
    }

    let timeExtentEndElt:HTMLElement|null = document.getElementById('project-time-extent-end');
    if (!!timeExtentEndElt && !!this.timeExtentEndField.control.errors) invalidFields.push(timeExtentEndElt);

    if (!!timeExtentStartElt && !this.timeExtentStartField.control.errors && !!timeExtentEndElt && !this.timeExtentEndField.control.errors) {
      this.checkTemporalExtentDates(null, null, false);

      if (!!this.timeExtentStartField.control.errors) invalidFields.push(timeExtentStartElt);
      if (!!this.timeExtentEndField.control.errors) invalidFields.push(timeExtentEndElt);
    }

    // Scroll de l'écran vers la première erreur
    if (invalidFields.length > 0) invalidFields[0].scrollIntoView({ behavior: "smooth" });

    return invalidFields.length === 0;
  }

  private _checkCommonFields():HTMLElement[] {
    let invalidFields:HTMLElement[] = [], reinitErrors = field => field.control.setErrors(null);

    // Reinit all field errors
    this.nameField.control.setErrors(null);

    // Name
    this._metadataService.addFieldRequiredError(invalidFields, this.nameField.control, this.project.name, 'project-name');

    this.timeExtentStartField.control.setErrors(null);
    this.timeExtentEndField.control.setErrors(null);
    this.descField.control.setErrors(null);
    this.dataPolesField.control.setErrors(null);
    this.thematicsField.control.setErrors(null);
    this.keywordsField.control.setErrors(null);

    this.westBoundLongitudeFields._results.forEach(reinitErrors);
    this.eastBoundLongitudeFields._results.forEach(reinitErrors);
    this.northBoundLatitudeFields._results.forEach(reinitErrors);
    this.southBoundLatitudeFields._results.forEach(reinitErrors);

    this.contactLastNameFields._results.forEach(reinitErrors);
    this.contactFirstNameFields._results.forEach(reinitErrors);
    this.contactEmailFields._results.forEach(reinitErrors);
    this.contactOrganisationFields._results.forEach(reinitErrors);
    this.contactRoleFields._results.forEach(reinitErrors);

    let roleFields = this.contactRoleFields._results;
    for (let i:number = 0; i < roleFields.length; i++) {
      if (roleFields[i].model === "pointOfContact"){
        // last name
        if (this.contactLastNameFields && this.contactLastNameFields._results && this.contactLastNameFields._results.length > i && this.contactLastNameFields._results[i]){
          let lastNameField = this.contactLastNameFields._results[i];
          this._metadataService.addFieldPatternError(invalidFields, lastNameField.control, lastNameField.model, 'contact-lastname-'+ i, Constants.regExps.name);
        }
        // first name
        if (this.contactFirstNameFields && this.contactFirstNameFields._results && this.contactFirstNameFields._results.length > i && this.contactFirstNameFields._results[i]){
          let firstNameField = this.contactFirstNameFields._results[i];
          this._metadataService.addFieldPatternError(invalidFields, firstNameField.control, firstNameField.model, 'contact-firstname-'+ i, Constants.regExps.name);
        }
        // mail
        if (this.contactEmailFields && this.contactEmailFields._results && this.contactEmailFields._results.length > i && this.contactEmailFields._results[i]){
          let emailField = this.contactEmailFields._results[i];
          this._metadataService.addFieldPatternError(invalidFields, emailField.control, emailField.model, 'contact-email-'+ i, Constants.regExps.mail);
        }
        // organisation
        if (this.contactOrganisationFields && this.contactOrganisationFields._results && this.contactOrganisationFields._results.length > i && this.contactOrganisationFields._results[i]){
          let organisationField = this.contactOrganisationFields._results[i];
          this._metadataService.addFieldRequiredError(invalidFields, organisationField.control, organisationField.model, 'contact-organisation-'+ i);
        }
        // role
        let pointOfContactRoleField = this.contactRoleFields._results[i];
        this._metadataService.addFieldRequiredError(invalidFields, pointOfContactRoleField.control, pointOfContactRoleField.model, 'contact-role-'+ i);
      }
    }

    return invalidFields;
  }

  updateDataPole() {
    this.completedDataPole = [];
    _.each(this.dataPoles , dataPole => {
      if(this.completedDataPole.filter(t=>t.id==dataPole.id).length==0){
        if (dataPole.subRegistry != null)
          this.completedDataPole.push(dataPole);
        }
      _.each(dataPole.subRegistry , subReg => {
        let dataPoleEntity = new DataPole();
        dataPoleEntity.id = subReg.id;
        dataPoleEntity.label = subReg.label;
        dataPoleEntity.subRegistry = subReg.subRegistry;
        if(this.completedDataPole.filter(t=>t.id==subReg.id).length==0)
          this.completedDataPole.push(dataPoleEntity);
      });
    });
    this.thematicsIdentifiersStorage = !!this.project.thematics ? _.cloneDeep(this.project.thematics).map(t => t.id) : [];
    this.keywordsIdentifiersStorage = !!this.keywords ?  _.cloneDeep(this.keywords).map(k => k.id) : [];
    this.project.thematics = [];
    this.keywords = [];

    this._registryService.getLexiques("thematics",this.language, this.completedDataPole, null, "");
    this._registryService.getLexiques("keywords", this.language, this.completedDataPole, null, "");
  }
}
