import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';

import { LinkService, SessionService, LoaderService, ProjectService, UtilsService } from 'src/app/services';
import { Link, Project } from 'src/app/models';
import { Constants } from 'src/app/constants';
import { MetadataService } from '../../../../services';
import { Registry } from 'src/app/models/registry.model';

@Component({
  selector: 'app-public-link-detail',
  templateUrl: './public-link-detail.component.html',
  styleUrls: ['./public-link-detail.component.scss'],
})
export class PublicLinkDetailComponent implements OnInit, OnDestroy {

  /**
   * Lien affiché
   */
  public link: Link;
  
  /**
   * Booléen décrivant si le nom du jeu de données doit être affiché en entier ou non.
   */
  public showNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom l'étude associée au jeu de données doit être affiché en entier ou non.
   */
  public showProjectNameWithEllipsis: boolean = false;

  /**
   * Booléen décrivant si le nom du jeu de données dépasse le nombre de caractère affichable.
   */
  public tooLongTitle: boolean = false;

  /**
   * Dépôt (i.e "étude" dans Cupidon) auquel appartient le lien
   */
  public project: Project;
 
  /**
   * Saving URL params
   */
  public params: ParamMap = null;

  /**
   * Base d'url vers le dépôt (i.e "étude" dans Cupidon)
   */
  public projectPath: string;

  /**
   * Booléen indiquant si le projet est publié ou non
   */
  public projectIsPublished:boolean = false;

  /**
   * Booléen indiquant si on a fini ou non de vérifier la publication du dépôt
   */
  public projectPublicationChecked:boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Liste des langues disponibles pour la metadata affichée
   */
  public availableLanguages = [];

  /**
   * Langue par défaut de la metadata
   */
  public language = Constants.defaultLanguage;
  
  /**
   * Type de la ressource
   */
   public ressourceType : Registry = new Registry();

  constructor(
    private _route: ActivatedRoute,
    private _linkService: LinkService,
    private _projectService: ProjectService,
    private _metadataService: MetadataService,
    public session: SessionService,
    private _loader: LoaderService,
    private _utils: UtilsService
  ) { }

  public ngOnInit() {
    this._subs.add(this._linkService.link$.subscribe(link => this._initLink(link)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => {
      this.params = _.clone(params);
      this._initLinkFromParams(params);
    }));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public displayName():string {
    if(this.link==null) return "";
    if(!this.showNameWithEllipsis) return this.link.name ? this.link.name : this.link.defaultName;
    return this.link.name ? 
      (this.link.name && this.link.name.length>=100) ? this.link.name.substring(0,100)+"..." : this.link.name :
      (this.link.defaultName && this.link.defaultName.length>=100) ? this.link.defaultName.substring(0,100)+"..." : this.link.defaultName;
  }
  
  public displayProjectName():string {
    let _project = this.project
    if(_project==null) return "";
    if(_project.name != null && _project.name != "") {
      if(_project.name.length >= 195) return _project.name.substring(0,195)+" ...";
      else return _project.name;
    } 
    else {
      if(_project.defaultName != null && _project.defaultName != "") {
        if(_project.defaultName.length >= 195) return _project.defaultName.substring(0,195)+" ...";
        else return _project.defaultName;
      }
      else return "";
    }

  }

  public updateNameDisplay(event:any) {
    event.preventDefault();
    this.showNameWithEllipsis = !this.showNameWithEllipsis;
  }

  public updateProjectNameDisplay(event:any) {
    event.preventDefault();
    this.showProjectNameWithEllipsis = !this.showProjectNameWithEllipsis;
  }

  /**
   * Changement du langage de la fiche, on recharge la metadata
   */
  public onLanguageChange() {
    this._loader.show();
    this._linkService.getPublicLink(this.link.id, this.language);
  }

  /**
   * Téléchargement du lien à un format donné
   * @param format - Le format de téléchargement
   */
  public downloadMetadata(format:string) {
    this._metadataService.downloadPublicMetadata(this.link.id,format,this.language).subscribe(blob => {
      this._utils.launchDownloadFile(blob, this.link.name + "." + format);
    });
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initLinkFromParams(params: any): void {
    this._loader.show();
    let linkId = params[params.length - 1];
    this._linkService.getPublicLink(linkId);
  }

  /**
   * Affiche le lien reçu
   * @param link - Lien issu du serveur
   */
  private _initLink(link: Link): void {
    this.link = link;
    this.ressourceType = this.link.classifiedKeywords.find(ckw => Constants.TYPOLOGIE_KEYWORD_NAME === ckw.typeCodeValue).keywordsWithLink[0];
    this.tooLongTitle = (!!this.link.name && this.link.name.length >= 100) || (!!this.link.defaultName && this.link.defaultName.length >= 100);
    this.showNameWithEllipsis = this.tooLongTitle;
    this.availableLanguages = [];
    _.forEach(Constants.languages, languageValue => {
      // Si la donnée possède le langage
      if (this.link.languagesAvailable.indexOf(languageValue.value) > -1) {
        // Alors on ajoute le langage dans les langages disponibles de la metadata
        this.availableLanguages.push(languageValue);
      }
    });
    // Si la langue par défaut n'est pas présente alors on prend la première langue disponible
    let hasCurrentLanguage = _.map(this.availableLanguages, 'value').indexOf(this.language) > -1;
    if (!hasCurrentLanguage) {
      this.language = this.availableLanguages[0].value;
      // Si le langage utilisé n'est pas celui par défaut, alors un reload est nécessaire
      this._initLinkFromParams(this.params);
    }

    this._metadataService.isPublished(this.link.projectId).subscribe((isProjectPublished: boolean) => {
      this.projectPublicationChecked = true;
      this.projectIsPublished = isProjectPublished;
      if(this.projectIsPublished) {
        this._projectService.getPublicProject(link.projectId, true, this.language);
      }
    });

    this._loader.hide();
  }


  /**
   * Affiche le dépôt (i.e "étude" dans Cupidon) du lien
   * @param project - Projet du lien, issu du serveur
   */
  private _initProject(project: Project): void {
    this.project = project;
    this.showProjectNameWithEllipsis = ((this.project.name!=null && this.project.name.length>=100) || this.project.defaultName.length>=100);
    this.projectPath = '/public/metadata';
    this._loader.hide();
  }
}
