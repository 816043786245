<div class="modal-body" id="map-modal">
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <map-component *ngIf="metadata && metadata.mapConfig" [mapConfig]="metadata.mapConfig" [options]="options"
    (onDataChange)="onDataMapChange($event)" locale="{{locale}}"></map-component>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
  <button type="button" class="btn btn-primary ml-auto" (click)="modal.close(shadowData)" i18n>Appliquer</button>
</div>
