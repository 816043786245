import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants, Uris } from '../constants';
import { map } from 'rxjs/operators/map';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { MetadataGroup } from '../models/metadata-group.model';
import { FormControl } from '@angular/forms';
import { EntityMetadata } from '../models';

/**
 * Metadata service /!\ à ne pas confondre avec les metadatas de geonetwork
 * Le terme "metadata" est ici utilisé pour définir les champs automatiquement gérés des boites de process d'un workflow
 */
@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  /**
   * Source d'une liste de métadonnées
   */
   private _metadataSource = new Subject<{type:string,content:MetadataGroup[]}>();

   /**
    * Observable qui envoie un event à chaque récupération d'un étude unique
    */
   public metadata$ = this._metadataSource.asObservable();

   constructor(
     private _http: HttpClient,
     private _loader: LoaderService,
     private _session: SessionService
   ) { }

  /**
   * Convertit une liste de metadatas en paramètres d'exécution
   * @param metadatas - Liste des metadatas à convertir
   */
  public convertMetadataToParams(metadatas: any[]): any {
    let params = {};

    _.each(metadatas, metadata => {
      let metadataName = metadata.execParamName || metadata.name;
      switch (metadata.type) {
        case "bbox":
          let bboxParam = [
            metadata.value.minX,
            metadata.value.minY,
            metadata.value.maxX,
            metadata.value.maxY
          ];
          params[metadataName] = bboxParam.join(',');
          break;
        case "coord":
          params[metadataName] = metadata.value.x + "," + metadata.value.y;
          break;
        case "coord_mult":
          let coords = [];
          _.each(metadata.values, val => {
            coords.push(val.x + "," + val.y);
          });
          params[metadataName] = coords.join(';');
          break;
        default:
          params[metadataName] = metadata.value;
      }
    });

    return params;
  }

  /**
   * Applique les données de paramètres d'exécution dans les metadatas
   * @param params - paramètres d'exécutions
   * @param metadatas - metadatas à modifier
   */
  public convertParamsToMetadatas(params: any, metadatas: any[]) {
    _.each(_.keys(params), key => {
      if (params.hasOwnProperty(key)) {
        let metadata = _.find(metadatas, m => m.execParamName === key || m.name === key);
        if (metadata) {
          switch (metadata.type) {
            case "bbox":
              let bbox = params[key].split(',').map(Number);
              if (bbox.length === 4) {
                metadata.value.minX = bbox[0];
                metadata.value.minY = bbox[1];
                metadata.value.maxX = bbox[2];
                metadata.value.maxY = bbox[3];
              }
              break;
            case "coord":
              let coords = params[key].split(',').map(Number);
              if (coords.length === 2) {
                metadata.value.x = coords[0];
                metadata.value.y = coords[1];
              }
              break;
            case "coord_mult":
              let coordsMult = params[key].split(';');
              metadata.values = [];
              _.each(coordsMult, c => {
                let coordinates = c.split(',').map(Number);
                if (coordinates.length === 2) {
                  metadata.values.push({ x: coordinates[0], y: coordinates[1] });
                }
              });
              break;
            default:
              metadata.value = params[key];
              break;
          }
        }
      }
    });
  }

  public updateDependentFields(changedField, fields) {
    if (changedField.dependingFields) {
      _.each(changedField.dependingFields, dependingField => {
        let fieldToUpdate = _.find(fields, { name: dependingField.fieldName });
        if (fieldToUpdate) {
          if (dependingField.updateMethod === "hideOnValue") {
            this._updateFieldHidden(fieldToUpdate, changedField.value, dependingField.hiddenValueList);
          } else {
            if (this[dependingField.updateMethod]) {
              let params = this.generateParams(dependingField, fields);
              this[dependingField.updateMethod](fieldToUpdate, params);
            }
          }
        }
      });
    }
  }

  public clone(metadatas) {
    let clone;
    if (_.isArray(metadatas)) {
      clone = [];
      _.each(metadatas, metadata => {
        clone.push(this.clone(metadata));
      });
    } else if (_.isObject(metadatas)) {
      clone = {};
      _.each(_.keys(metadatas), key => {
        clone[key] = this.clone(metadatas[key]);
      });
    } else {
      clone = metadatas;
    }
    return clone;
  }

  public getMetadataByStatus(status:string): void {
    this._http.get<MetadataGroup[]>(Uris.STATUS+'findByStatus/'+status)
      .pipe(
        map(metadata => metadata.map(m => new MetadataGroup().deserialize(m)))
      )
      .subscribe(
        metadata => {
          /* _.each(metadata, m => {
            m.type = status;
          }); */
          let result = ({type:status , content:metadata});
          this._metadataSource.next(result);
        }
      );
  }

  public deleteMetadataGroup(metadataGroup:MetadataGroup,status:string) {
    this._http.delete<any>(Uris.PROJECTS+metadataGroup.project.identifier+'/deleteMetadataGroup/'+status)
      .subscribe(
        metadata => {
          let result = ({type:status , content:metadata});
          this._metadataSource.next(result);
          this._loader.hide();
        }
      );
  }

  /**
   * Indique si une metadata est publiée
   * @param metadataIdentifier Uuid de la metadata
   */
  public isPublished(metadataIdentifier: string): Observable<boolean> {
	  return this._http.get<Map<string, boolean>>(`${Uris.PUBLIC_RESOURCES}${metadataIdentifier}/ispublished`)
      .pipe(
        map(o => o[metadataIdentifier])
      );
  }

  /**
   * Récupère la liste des formats de citation disponible pour une metadata
   * @param metadataIdentifier Uuid de la metadata
   */
  public getCitationFormats(metadataIdentifier: string): Observable<string[]> {
    return this._http.get<string[]>(`${Uris.RECORDS}${metadataIdentifier}/citation/formats`);
  }

  /**
   * Récupère la liste des formats de citation disponible pour une metadata
   * @param metadataIdentifier Uuid de la metadata
   */
  public getPublicCitationFormats(metadataIdentifier: string): Observable<string[]> {
    return this._http.get<string[]>(`${Uris.PUBLIC_RESOURCES}${metadataIdentifier}/citation/formats`);
  }

  /**
   * Récupère une citation d'une metadata selon un format
   * @param metadataIdentifier Uuid de la metadata
   * @param format Format de la citation
   * @param metadataLanguage Langue de la metadata
   */
  public getCitation(metadataIdentifier: string, format: string, metadataLanguage: string): Observable<string> {
    return this._http.get<Map<string, string>>(`${Uris.RECORDS}${metadataIdentifier}/citation?format=${format}&metadataLanguage=${metadataLanguage}`)
    .pipe(
      map(o => o['citation'])
    );
  }

   /**
   * Récupère une citation d'une metadata selon un format
   * @param metadataIdentifier Uuid de la metadata
   * @param format Format de la citation
   * @param metadataLanguage Langue de la metadata
   */
  public getPublicCitation(metadataIdentifier: string, format: string, metadataLanguage: string): Observable<string> {
    return this._http.get<Map<string, string>>(`${Uris.PUBLIC_RESOURCES}${metadataIdentifier}/citation?format=${format}&metadataLanguage=${metadataLanguage}`)
    .pipe(
      map(o => o['citation'])
    );
  }

  /**
   * Télécharge une métadonnée à un format donné
   * @param metadataIdentifier Uuid de la metadata
   * @param format Format de la citation
   * @param metadataLanguage Langue de la metadata
   */
  public downloadMetadata(metadataIdentifier: string, format: string, metadataLanguage: string) {
    return this.downloadMetadataCommon(Uris.RECORDS, metadataIdentifier, format, metadataLanguage);
  }

  /**
   * Télécharge une métadonnée publique à un format donné
   * @param metadataIdentifier Uuid de la metadata
   * @param format Format de la metadata
   * @param metadataLanguage Langue de la metadata
   */
  public downloadPublicMetadata(metadataIdentifier: string, format: string, metadataLanguage: string) {
    return this.downloadMetadataCommon(Uris.PUBLIC_RESOURCES, metadataIdentifier, format, metadataLanguage);
  }

  private downloadMetadataCommon(uri: string, metadataIdentifier: string, format: string, metadataLanguage: string): Observable<Blob> {
    this._loader.show();
    return this._http.get(`${uri}${metadataIdentifier}/download?format=${format}&language=${metadataLanguage}`, { responseType: 'blob' });
  }

  /**
   * Retourne le type d'une metadata
   * @param metadataIdentifier Uuid de la metadata
   * @returns Le type d'une metadata
   */
  public getMetadataType(metadataIdentifier: string): Observable<string> {
    return this._http.get<Map<string, string>>(`${Uris.PUBLIC_RESOURCES}${metadataIdentifier}/type`)
    .pipe(
      map(o => o[metadataIdentifier])
    );
  }

  private generateParams(fieldToUpdate, fields) {
    let obj = {};
    _.each(fieldToUpdate.params, param => {
      let paramField = _.find(fields, { name: param });
      if (paramField) {
        obj[param] = paramField.value;
      }
    });
    return obj;
  }

  // dynamics methods

  private _updateFieldHidden(fieldToUpdate: any, currentValue: any, matchList?: any[]) {
    if (!matchList) {
      return;
    }
    fieldToUpdate.hidden = (matchList.indexOf(currentValue) >= 0);
  }

  private toggleSrsCible(fieldToUpdate, params) {
    fieldToUpdate.readOnly = !params.hasProjection;
    fieldToUpdate.value = fieldToUpdate.readOnly ? 'none' : "EPSG:4326";
  }

  private toggleResolutionCible(fieldToUpdate, params) {
    fieldToUpdate.readOnly = !params.execInterpolation;
    if (fieldToUpdate.readOnly) {
      fieldToUpdate.baseMin = fieldToUpdate.min;
      fieldToUpdate.min = null;
    } else {
      fieldToUpdate.min = fieldToUpdate.baseMin || 1;
      delete fieldToUpdate.baseMin;
    }
    fieldToUpdate.value = fieldToUpdate.readOnly ? 0 : fieldToUpdate.min + 1;
  }

  private shakemapToggleField(fieldToUpdate, params) {
    let activationBool = false;
    if (params.mode_alea === 'exemple') {
      activationBool = true;
    }

    if (['shakemap_exemple', 'info_exemple'].indexOf(fieldToUpdate.name) >= 0) {
      fieldToUpdate.hidden = !activationBool;
      if (fieldToUpdate.type != 'info') {
        fieldToUpdate.execParam = activationBool;
      }
    }
    if (['uuid_geoazur', 'resolution_geoazur', 'info_geoazur'].indexOf(fieldToUpdate.name) >= 0) {
      fieldToUpdate.hidden = activationBool;
      if (fieldToUpdate.type != 'info') {
        fieldToUpdate.execParam = !activationBool;
      }
    }
  }

  /**
   * Ajoute une erreur required à chaque champ d'un tableau si besoin
   * @param invalidFields Liste des champs en erreur
   * @param fields Tableau
   * @param fieldElementId Idenifiant HTML du champ
   */
  public addFieldEachRequiredError(invalidFields:HTMLElement[], fields:any[], fieldElementId:string) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      this.addFieldRequiredError(invalidFields, field.control, field.model, fieldElementId + i);
    }
  }

  /**
   * Ajoute une erreur required à un champ si besoin
   * @param invalidFields Liste des champs en erreur
   * @param fieldControl Champ
   * @param fieldValue Valeur du champ
   * @param fieldElementId Idenifiant HTML du champ
   */
  public addFieldRequiredError(invalidFields:HTMLElement[], fieldControl:FormControl, fieldValue, fieldElementId:string) {
    fieldControl.setErrors(null);

    if (!fieldValue) {
      fieldControl.setErrors(Constants.errorOptions.required);
      let elt:HTMLElement|null = document.getElementById(fieldElementId);
      if (!!elt) invalidFields.push(elt);
    }
  }

  /**
   * Ajoute une erreur required à un champ multivalué si besoin
   * @param invalidFields Liste des champs en erreur
   * @param fieldControl Champ
   * @param fieldValue Valeur du champ
   * @param fieldElementId Idenifiant HTML du champ
   */
  public addFieldMultiRequiredError(invalidFields:HTMLElement[], fieldControl:FormControl, fieldValue, fieldElementId:string) {
    fieldControl.setErrors(null);

    if (!fieldValue || fieldValue.length === 0) {
      fieldControl.setErrors(Constants.errorOptions.required);
      let elt:HTMLElement|null = document.getElementById(fieldElementId);
      if (!!elt) invalidFields.push(elt);
    }
  }

  /**
   * Ajoute une erreur de format à un champ si besoin
   * @param invalidFields Liste des champs en erreur
   * @param fieldControl Champ
   * @param fieldValue Valeur du champ
   * @param fieldElementId Idenifiant HTML du champ
   * @param format Format sur lequel s'effectue le contrôle
   */
  public addFieldFormatError(invalidFields:HTMLElement[], fieldControl:FormControl, fieldValue, fieldElementId:string, format:RegExp) {
    this.addFieldFormatPatternError(invalidFields, fieldControl, fieldValue, fieldElementId, format, true);
  }

  /**
   * Ajoute une erreur de pattern à chaque champ d'un tableau si besoin
   * @param invalidFields Liste des champs en erreur
   * @param fields Tableau
   * @param fieldElementId Idenifiant HTML du champ
   * @param pattern Format sur lequel s'effectue le contrôle
   */
  public addFieldEachPatternError(invalidFields:HTMLElement[], fields:any[], fieldElementId:string, pattern:RegExp) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      this.addFieldPatternError(invalidFields, field.control, field.model, fieldElementId + i, pattern);
    }
  }

  /**
   * Ajoute une erreur de pattern à un champ si besoin
   * @param invalidFields Liste des champs en erreur
   * @param fieldControl Champ
   * @param fieldValue Valeur du champ
   * @param fieldElementId Idenifiant HTML du champ
   * @param pattern Format sur lequel s'effectue le contrôle
   */
  public addFieldPatternError(invalidFields:HTMLElement[], fieldControl:FormControl, fieldValue, fieldElementId:string, pattern:RegExp) {
    this.addFieldFormatPatternError(invalidFields, fieldControl, fieldValue, fieldElementId, pattern, false);
  }

  private addFieldFormatPatternError(invalidFields:HTMLElement[], fieldControl:FormControl, fieldValue, fieldElementId:string, format:RegExp, isFormat:boolean) {
    fieldControl.setErrors(null);

    if (!fieldValue) fieldControl.setErrors(Constants.errorOptions.required);
    else if (!format.test(fieldValue)) fieldControl.setErrors(isFormat ? Constants.errorOptions.format : Constants.errorOptions.pattern);

    let elt:HTMLElement|null = document.getElementById(fieldElementId);
    if (!!elt && !!fieldControl.errors) invalidFields.push(elt);
  }

  public checkField(invalidFields:any[], fieldValue, field) {

    if (!fieldValue)
      invalidFields.push(field);
  }

  public checkFieldMultiple(invalidFields:any[], fieldValue, field) {

    if (!fieldValue || fieldValue.length === 0)
      invalidFields.push(field);
  }


  public checkFieldPattern(invalidFields:any[], fieldValue, field, format:RegExp) {

    if (!fieldValue || !format.test(fieldValue))
      invalidFields.push(field);
  }

  public canModerate(metadata:EntityMetadata):boolean {
    if (this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId == Constants.ROLE_ADMIN_ID) != -1 ) return true;
    if (!!metadata && !!metadata.thematics && metadata.thematics.length > 0) {
      let metadataThematicsIds:string[] = metadata.thematics.map(t => t.id);
      return !!this._session.getCurrentUserThematicsIds().find(id => metadataThematicsIds.includes(id));
    }

    return false;
  }
}
