import { Constants } from 'src/app/constants';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, LoaderService } from "src/app/services";
import { Subscription } from "rxjs";
import { Thematic, Role} from "src/app/models";
import { ToastrService } from 'ngx-toastr';
import { FormListEvent, RegistryService } from '../../../../services/registry.service';
import { UserRole } from '../../../../models/user-role.model';
import { NgForm } from '@angular/forms';
import { SessionService, UtilsService } from '../../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../models';

@Component({
  templateUrl: 'role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditAdminComponent implements OnInit, OnDestroy {

  /**
   * Association entre un utilisateur, un rôle et éventuellement des thématiques.
   * Entité envoyée au back-end pour l'enregistrement.
   */
  public userRole: UserRole = new UserRole();

  /**
   * Utilisateur sélectionné
   */
  public selectedUser: string;

  /**
   * Liste d'autocomplétion des emails d'utilisateurs
   */
  public emails: string[] = [];

  /**
   * Liste des Roles (pour la liste à cocher)
   */
  public allRoles: Role[] = [];

  /**
   * Liste des Roles (pour la liste à cocher)
   */
  public roles: Role[] = [];

  /**
   * Rôle actuellement sélectionné
   */
  public currentRole: Role | null = null;

  /**
   * Rôle administrateur
   */
  public adminRole: Role | null = null;

  /**
   * Liste des thématiques (pour la liste à cocher)
   */
  public allThematics: Thematic[] = [];

  /**
   * Liste des thématiques pour laquelle l'utilisateur sélectionné à le rôle référent
   */
  public refThematics: Thematic[] = [];

  /**
   * Liste des thématiques pour laquelle l'utilisateur sélectionné à le rôle modérateur
   */
  public modThematics: Thematic[] = [];

  /**
   * Dans le cadre où l'utilisateur ne possède pas toutes les thématiques du UserRole édité,
   * liste des thématiques qu'il possède et peut donc modifier.
   */
  public updatableThematics: Thematic[] = [];

  /**
   * Dans le cadre où l'utilisateur ne possède pas toutes les thématiques du UserRole édité,
   * liste des thématiques qu'il ne possède pas et ne peut donc pas modifier.
   */
  public nonUpdatableThematics: Thematic[] = [];

  /**
   * Booléen indiquant si les attributs updatableThematics et nonUpdatableThematics de cette classe
   * ont été initialisés.
   */
  public thematicsSorted: boolean = false;

  /**
   * Booléen qualifiant si on crée un nouveau UserRole ou si l'on en modifie un existant
   */
  public isNew: boolean = true;

  /**
   * Route permettant de revenir à la page de la liste des UserRole
   */
  public cancelRoute: string = '../';

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _utils: UtilsService,
    private _userService: UserService,
    private _registryService: RegistryService,
    private _toastr: ToastrService,
    private _session: SessionService,
    private _loader: LoaderService
  ) { }


  ngOnInit() {
    this._subs.add(this._userService.userRole$.subscribe(userRole => this._initUserRole(userRole)));
    this._subs.add(this._userService.emails$.subscribe(emails => this._initEmails(emails)));
    this._subs.add(this._userService.roles$.subscribe(roles => this._initAllRoles(roles)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._initAllThematics(event)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initUserRoleFromParams(params)));
    this._userService.searchUsersObs();
  }


  ngOnDestroy() {
    this._subs.unsubscribe();
  }


  /**
   * Demande l'autocomplétion des emails d'utilisateurs
   * @param event - événement primeng
   */
  public autocompleteUsers(event: any) {
    this._userService.searchUsers(event.query);
  }


  /**
   * Demande l'autocomplétion des thématiques
   * @param event - Événement primeng contenant la requête d'autocomplétion
   * @param type - Type de donnée à autocompléter
   */
  public autocompleteThematics(event:any): void {
    this._registryService.getLexiques("thematics", "fre", null, null, event!=null ? event.query : "", {});// TODO : mettre en place this.language
  }


  /**
   * Affiche la liste des suggéstions de thématique lorsque l'on y met le focus
   * @param autocompleteThematicsField
   */
  public autocompleteThematicsFocus(autocompleteThematicsField:any): void {
    autocompleteThematicsField._suggestions = this.allThematics;
    if(autocompleteThematicsField._suggestions.length > 0) autocompleteThematicsField.show();
  }


  /**
   * Fonction permettant de supprimer les duplicatas dans la liste des thématiques
   * @param type Permet de savoir si l'input contient toutes les thématiques de l'utilisateur connecté
   */
  public removeDuplicates(type:string) {
    if(type==="all") {
      let thematicsWithoutDuplicate:Thematic[] = [];
      _.each(this.userRole.thematics , thematic => {
        if(thematicsWithoutDuplicate.findIndex(t=>t.id==thematic.id)==-1) thematicsWithoutDuplicate.push(thematic);
      });
      this.userRole.thematics = thematicsWithoutDuplicate;
    }
    else if(type=="updatable") {
      let thematicsWithoutDuplicate:Thematic[] = [];
      _.each(this.updatableThematics , thematic => {
        if(thematicsWithoutDuplicate.findIndex(t=>t.id==thematic.id)==-1) thematicsWithoutDuplicate.push(thematic);
      });
      this.updatableThematics = thematicsWithoutDuplicate;
    }
  }


  /**
   * Rafraîchit les thématiques de l'association rôle-utilisateur en cours de saisie
   * lorsqu'on choisit un rôle. Si celui-ci est administrateur, on vide la liste des
   * thématiques choisies.
   */
  public refreshThematics():void {
    if(this.userRole.roleId==this.adminRole.id) this.userRole.thematics = [];
    this.currentRole = this.allRoles.find(r => r.id==this.userRole.roleId);
  }


  /**
   * Charge les groupes de l'utilisateur
   * @param email Email de l'utilisateur sélectionné
   */
  public loadRoles(email: string) {
    if (email) this._userService.getUserRolesByEmail(email).subscribe(role => this.roles = _.sortBy(role, ['id']));
    else this.roles = [];
  }


  /**
   * Optimisation pour le ngFor
   * @param i
   * @param group
   */
  public trackById(i, group) {
    return group.id;
  }


  /**
   * Enregistrement du rôle et des éventuelles thématiques associées à un utilisateur.
   * @param editForm
   */
  public save(editForm: NgForm) {
    if (editForm.valid) {
      this._loader.show();
      if(this.nonUpdatableThematics.length>0) {
        let mergedThematics:Thematic[] = [];
        for(let thematic of this.updatableThematics) mergedThematics.push(thematic);
        for(let thematic of this.nonUpdatableThematics) mergedThematics.push(thematic);
        this.userRole.thematics = mergedThematics;
      }
      this._userService.saveUserRole(this.userRole)
        .subscribe(result => {
          this._loader.hide();
          if (result && result.id) {
            this._toastr.success($localize`Le rôle ${this.currentRole?.name} a été affecté à ${this.userRole.mail} avec succès.`);
            if (this.isNew) this._session.setInitPageParams({ id: result.id, type: 'group' });
            this._router.navigate([this.cancelRoute], { relativeTo: this._route });
          }
        });
    }
  }


  /**
   * Initialise l'association rôle-utilisateur
   * @param userRole
   */
  private _initUserRole(userRole:UserRole):void {
    this.userRole = userRole;
    this._userService.getRoles();
    this._registryService.getLexiques("thematics","fre", null, null); // TODO : mettre en place this.language
    this._loader.hide();
  }


  /**
   * Demande la récupération du groupe
   * @param params - paramètres de l'url
   */
  private _initUserRoleFromParams(params) {
    if (params && params.length > 0) {
      this._loader.show();
      this.isNew = (params[0] === 'new');
      if (this.isNew) {
        this.cancelRoute += '../';
      }
      this._userService.getUserRoleById(params[0]);
    }
  }


  /**
   * Initialise la liste des mails
   * @param event
   */
  private _initEmails(emails:string[]):void {
    this.emails = emails;
    this._loader.hide();
  }


  /**
   * Initialise la liste des différents rôles disponibles
   * @param roles : la liste des rôles
   */
  private _initAllRoles(roles:Role[]) {
    this.adminRole = roles.find(role => role.name == Constants.ROLE_ADMIN);
    let isAdmin = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==this.adminRole.id)!=-1;
    let pointOfContactRole:Role = roles.find(role => role.name == Constants.ROLE_REFERENT);
    let isPointOfContact = this._session.currentUser.userRoles.findIndex(userRole => userRole.roleId==pointOfContactRole.id)!=-1;
    if(!isAdmin && !isPointOfContact) this._router.navigate(["/home"]);
    this.allRoles = roles.filter(role => {
                          if(isAdmin) return true;
                          return role.name == Constants.ROLE_MODERATOR;
                        })
                        .sort((a,b) => a.id - b.id);
    this.currentRole = this.allRoles.find(r => r.id==this.userRole.roleId);
    this._loader.hide();
  }


  /**
   * Initialise les thématiques renvoyées par le registre
   * @param event
   */
  private _initAllThematics(event:FormListEvent):void {
    this.allThematics = [];
    let user:User = this._session.currentUser;
    let roles:Role[] = this._session.allRoles;
    let adminRole = roles.find(role => role.name== Constants.ROLE_ADMIN);
    let isAdmin = false;
    if(!!adminRole) isAdmin = user.userRoles.findIndex(userRole => userRole.roleId==adminRole.id)!=-1;
    if(isAdmin) this.allThematics = event.datas;
    else {
      let pointOfContactRole = roles.find(role => role.name == Constants.ROLE_REFERENT);
      if(!pointOfContactRole) return;
      let userPointOfContactRole = user.userRoles.find(userRole=>userRole.roleId==pointOfContactRole.id);
      if(!userPointOfContactRole) return;
      for(let thematic of event.datas) {
        if(userPointOfContactRole.thematics.findIndex(t=>t.id==thematic.id)!=-1) {
          this.allThematics.push(thematic);
        }
      }
      if(!this.thematicsSorted) {
        for(let thematic of this.userRole.thematics) {
          if(userPointOfContactRole.thematics.findIndex(t=>t.id==thematic.id)!=-1) this.updatableThematics.push(thematic);
          else this.nonUpdatableThematics.push(thematic);
        }
      }
      this.thematicsSorted = true;
    }
    this._loader.hide();
  }


  private _thematicsWithoutDuplicates() {
    let thematicsWithoutDuplicate:Thematic[] = [];
    _.each(this.userRole.thematics , thematic => {
      if(thematicsWithoutDuplicate.findIndex(t=>t.id==thematic.id)==-1) thematicsWithoutDuplicate.push(thematic);
    });
    return thematicsWithoutDuplicate;
  }


}
