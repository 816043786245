import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Uris } from "../constants";
import { Mail } from "../models";

@Injectable({
  providedIn: 'root',
})
export class MailService {

  constructor(
    private _http: HttpClient
  ) { }

  public sendMailPublishMetadata(metadataId:string) {
    this.sendMailMetadata(Uris.MAIL_PUBLISH, { metadataId: metadataId, motifRejet: null });
  }
  public sendMailAcceptMetadata(metadataId:string) {
    this.sendMailMetadata(Uris.MAIL_ACCEPT, { metadataId: metadataId, motifRejet: null });
  }

  public sendMailRefuseMetadata(metadataId:string, motifRejet:string) {
    this.sendMailMetadata(Uris.MAIL_REFUSE, { metadataId: metadataId, motifRejet: motifRejet });
  }

  private sendMailMetadata(url:string, mail:Mail) {
    this._http.post<boolean>(url, mail).subscribe();
  }
}
