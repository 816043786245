import {Component, OnInit, OnDestroy, Input, EventEmitter, Output} from '@angular/core';

import { Subscription } from 'rxjs';

import Map from 'ol/Map';
import { transformExtent } from 'ol/proj';
import {MapService, UtilsService} from 'src/app/services';
import {Destination} from "../../../models";



@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  public searchText: string = "";
  public searchResults: any[] = [];

  private searchTimeout = null;
  private sub: Subscription = new Subscription();
  @Input() private map: Map;

  @Output() destinationChangeEvent = new EventEmitter<Destination>();

  /**
   * Langue actuelle
   */
  @Input() locale: string;

  public extentDestinationType:string = "place";

  constructor(private mapService: MapService, private _utils: UtilsService) { }

  public ngOnInit() {
    this.locale = this._utils.getLocaleFromNavigator();
    this.sub.add(this.mapService.geonames$.subscribe(result => this.searchResults = result));
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public emitEvent(value : Destination) {
    this.destinationChangeEvent.emit(value);
  }

  public goToDestination(destination) {
    if (this.map) {
      let view = this.map.getView();
      let extent = transformExtent(destination.extent, 'EPSG:4326', view.getProjection());
      view.fit(extent, { padding: [20, 20, 20, 20] });
      view.setZoom(10);
      this.searchResults = [];
      this.emitEvent(destination);
    }
  }

  public updateSearchList() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.mapService.searchGeonames(this.searchText,this.extentDestinationType, this.locale);
      this.searchTimeout = null;
    }, 500);
  }

  public resetSearch() {
    this.searchText = "";
    this.searchResults = [];
  }

  updateExtentDestinationType(value: string) {
    this.extentDestinationType = value;
  }
}
