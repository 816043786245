import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressBarService } from 'src/app/services';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html'
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  
  private _subs: Subscription = new Subscription();

  public visible: boolean = false;
  public totalFiles: number;
  public currentFile: number;
  public nameFile: string;
  public progressValue: number = 0;
  public isDownload: boolean = false;

  constructor(private _progressBar: ProgressBarService) { }

  public ngOnInit() {
    this._subs.add(this._progressBar.isProgressBarVisible$.subscribe( visible => this.visible = visible));
    this._subs.add(this._progressBar.totalFiles$.subscribe( tot => this.totalFiles = tot ));
    this._subs.add(this._progressBar.currentFile$.subscribe( current => this.currentFile = current ));
    this._subs.add(this._progressBar.nameFile$.subscribe( name => this.nameFile = name ));
    this._subs.add(this._progressBar.progressValue$.subscribe( val => this.progressValue = val ));
    this._subs.add(this._progressBar.isDownloadValue$.subscribe( val => this.isDownload = val ));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
