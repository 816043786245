import * as _ from 'lodash';
import * as moment from 'moment';

import { Data } from './data.model';
import { Link } from './link.model';
import { EntityMetadata } from './entity-metadata.model';
import { Constants } from '../constants';
import { Registry } from './registry.model';
import {License} from "./license.model";

/**
 * Entité des dépôts (i.e "étude" dans Cupidon)
 */
export class Project extends EntityMetadata {

  /**
   * Indique si la metadata est publiée
   */
  published = false;

  /**
   * Contraintes d'utilisation
   */
  legalConstraints: License = {
    licenseId:"CC-BY-4.0",
    reference:"https://spdx.org/licenses/CC-BY-4.0.html"
  };

  /**
   * Liste des données du dépôt (i.e "étude" dans Cupidon)
   */
  datas: Data[] = [];

  /**
   * Liste des liens du dépôt (i.e "étude" dans Cupidon)
   */
  links: Link[] = [];

  /**
   * Traçabilité
   */
  lineage: string = "";

  /**
   * Pôle de données
   */
  dataPoles: Registry[] = [];

  /**
   * Début de l'étendue temporelle
   */
  temporalExtentStart: string = null;

  /**
   * Fin de l'étendue temporelle
   */
  temporalExtentEnd: string = null;

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "lineage", back: "dataQualityLineage" },
      { front: "links", class: Link },
      { front: "datas", class: Data }
    ]);
  }

  public serialize() {
    super.serialize();
    this._jsonModel.temporalExtent = {
      description : "",
      start : this.temporalExtentStart,
      end : this.temporalExtentEnd
    };
    return this._jsonModel;
  }

  public deserialize(json: any, language="fre"): this {
    super.deserialize(json);

    this.dataPoles = this.getClassifiedKeywordsWithLink(Constants.DATA_POLE_KEYWORD_NAME);
    this.thematics = this.getClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME);
    this.freeKeywords = this.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME);

    let locale = Constants.languageToLocale[language];
    let format = Constants.localeCalendarFormats[locale].formatMoment;

    if(json.temporalExtent!=null && json.temporalExtent.start!=null) {
      let temporalExtentStart:Date = new Date(json.temporalExtent.start);
      let momentObject = moment(temporalExtentStart,format);
      this.temporalExtentStart = momentObject.format(format);
    }

    if(json.temporalExtent!=null && json.temporalExtent.end!=null) {
      let temporalExtentEnd:Date = new Date(json.temporalExtent.end);
      let momentObject = moment(temporalExtentEnd,format);
      this.temporalExtentEnd = momentObject.format(format);
    }

    return this;
  }

  /**
   * Récupère la locale du navigateur
   */
  private getLocaleFromNavigator(): string {
    return !!navigator.language ? navigator.language.substring(0, 2) || 'fr' : 'fr';
  }
}
