<div class="modal-header">
  <h2 class="modal-title">{{title}}</h2>
  <button id="close-button" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" id="confirm-modal">
  <p class="mb-0 nl2br">{{message}}</p>
  <br *ngIf="!!inputLabel"/>
  <span class="row no-gutters align-items-center" *ngIf="!!inputLabel">
    <label class="col-4">{{inputLabel}} *</label>
    <div class="col">
      <p-editor [style]="{'height':'320px'}" class="form-control" name="input" [(ngModel)]="input">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-size">
              <option value="small">Petit</option>
              <option selected></option>
              <option value="large">Sous-titre</option>
              <option value="huge">Titre</option>
            </select>
          </span>
          <span class="ql-formats">
            <select class="ql-font"></select>
          </span>
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="strike"></button>
          </span>
          <span class="ql-formats">
            <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
              <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)"></option>
              <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)"></option>
              <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)"></option>
              <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)"></option>
              <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)"></option>
              <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)"></option>
              <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
              <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)"></option>
              <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)"></option>
              <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)"></option>
              <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)"></option>
              <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)"></option>
              <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)"></option>
              <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)"></option>
              <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)"></option>
              <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)"></option>
              <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)"></option>
              <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)"></option>
              <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)"></option>
              <option value="rgb(194, 133, 255)" label="rgb(194, 133, 255)"></option>
              <option value="rgb(136, 136, 136)" label="rgb(136, 136, 136)"></option>
              <option value="rgb(161, 0, 0)" label="rgb(161, 0, 0)"></option>
              <option value="rgb(178, 107, 0)" label="rgb(178, 107, 0)"></option>
              <option value="rgb(178, 178, 0)" label="rgb(178, 178, 0)"></option>
              <option value="rgb(0, 97, 0)" label="rgb(0, 97, 0)"></option>
              <option value="rgb(0, 71, 178)" label="rgb(0, 71, 178)"></option>
              <option value="rgb(107, 36, 178)" label="rgb(107, 36, 178)"></option>
              <option value="rgb(68, 68, 68)" label="rgb(68, 68, 68)"></option>
              <option value="rgb(92, 0, 0)" label="rgb(92, 0, 0)"></option>
              <option value="rgb(102, 61, 0)" label="rgb(102, 61, 0)"></option>
              <option value="rgb(102, 102, 0)" label="rgb(102, 102, 0)"></option>
              <option value="rgb(0, 55, 0)" label="rgb(0, 55, 0)"></option>
              <option value="rgb(0, 41, 102)" label="rgb(0, 41, 102)"></option>
              <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
            </select>
            <span class="ql-format-separator"></span>
            <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
              <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)"></option>
              <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)"></option>
              <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)"></option>
              <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)"></option>
              <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)"></option>
              <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)"></option>
              <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
              <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)"></option>
              <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)"></option>
              <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)"></option>
              <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)"></option>
              <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)"></option>
              <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)"></option>
              <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)"></option>
              <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)"></option>
              <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)"></option>
              <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)"></option>
              <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)"></option>
              <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)"></option>
              <option value="rgb(194, 133, 255)" label="rgb(194, 133, 255)"></option>
              <option value="rgb(136, 136, 136)" label="rgb(136, 136, 136)"></option>
              <option value="rgb(161, 0, 0)" label="rgb(161, 0, 0)"></option>
              <option value="rgb(178, 107, 0)" label="rgb(178, 107, 0)"></option>
              <option value="rgb(178, 178, 0)" label="rgb(178, 178, 0)"></option>
              <option value="rgb(0, 97, 0)" label="rgb(0, 97, 0)"></option>
              <option value="rgb(0, 71, 178)" label="rgb(0, 71, 178)"></option>
              <option value="rgb(107, 36, 178)" label="rgb(107, 36, 178)"></option>
              <option value="rgb(68, 68, 68)" label="rgb(68, 68, 68)"></option>
              <option value="rgb(92, 0, 0)" label="rgb(92, 0, 0)"></option>
              <option value="rgb(102, 61, 0)" label="rgb(102, 61, 0)"></option>
              <option value="rgb(102, 102, 0)" label="rgb(102, 102, 0)"></option>
              <option value="rgb(0, 55, 0)" label="rgb(0, 55, 0)"></option>
              <option value="rgb(0, 41, 102)" label="rgb(0, 41, 102)"></option>
              <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button type="button" class="ql-code-block" aria-label="code-block"></button>
            <button type="button" class="ql-link" aria-label="Link"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <select title="Text Alignment" class="ql-align" >
              <option selected>Gauche</option>
              <option value="center" label="Center"></option>
              <option value="right" label="Right"></option>
              <option value="justify" label="Justify"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button type="button" class="ql-clean" aria-label="clean"></button>
          </span>
        </ng-template>
      </p-editor>
      <div class="alert alert-danger" *ngIf="!!inputInvalid">
        <p>Champ requis</p>
      </div>
    </div>
  </span>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-default"
    (click)="modal.dismiss('cancel')" i18n>Annuler</button>
  <button *ngIf="cancelAndQuitText"
    type="button" class="btn ml-auto mr-3" [ngClass]="cancelAndQuitClass"
    (click)="modal.close('cancelAndQuit')">{{cancelAndQuitText}}</button>
  <button type="button" class="btn {{confirmClass}}" [ngClass]="{'ml-auto': !cancelAndQuitText}"
    (click)="!!inputLabel ? save() : modal.close('confirm')">{{confirmText}}</button>
</div>
