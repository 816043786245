import {Component} from "@angular/core";

@Component({
  templateUrl: './service.component.html'
})
export class ServiceComponent {

  cswUrl: string = "https://www.easydata.earth/api/csw";
}

