import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Group } from "src/app/models";
import { UserService, UtilsService, LoaderService, SessionService } from "src/app/services";
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: 'group-edit.component.html'
})
export class GroupEditAdminComponent implements OnInit, OnDestroy {
  /**
   * Groupe à afficher
   */
  public group: Group;

  public isNew: boolean = true;

  public cancelRoute: string = '../';

  public isActive: boolean = true;

  private _initialGroup: Group;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _utils: UtilsService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _session: SessionService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.group$.subscribe(group => this._initGroup(group)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initGroupFromParams(params)));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Demande l'enregistrement du groupe
   * @param editForm - objet angular formulaire
   */
  public save(editForm: NgForm) {
    if (editForm.valid) {
      this._loader.show();
      this.group.status = this.isActive ? 'alive' : 'closed';
      this._userService.saveGroup(this.group)
        .subscribe(result => {
          this._loader.hide();
          if (result && result.id) {
            if (this.isNew) {
              this._toastr.success($localize`Le groupe ${this.group.name} a été créé avec succès`);
              this._session.setInitPageParams({ id: result.id, type: 'group' });
            } else {
              this._toastr.success($localize`Le groupe ${this.group.name} a été modifié avec succès`);
            }
            this._router.navigate([this.cancelRoute], { relativeTo: this._route });
          }
        });
    }
  }

  /**
   * Initialise le groupe à afficher
   * @param group - groupe reçu du serveur
   */
  private _initGroup(group: Group) {
    this._initialGroup = _.cloneDeep(group);
    if (this.isNew) {
      this._initialGroup.name = "";
    }
    this.group = _.cloneDeep(this._initialGroup);
    this.isActive = (this.group.status === 'alive');
    this._loader.hide();
  }

  /**
   * Demande la récupération du groupe
   * @param params - paramètres de l'url
   */
  private _initGroupFromParams(params) {
    if (params && params.length > 0) {
      this._loader.show();
      this.isNew = (params[0] === 'new');
      if (this.isNew) {
        this.cancelRoute += '../';
      }
      this._userService.getGroup(params[0]);
    }
  }
}