import { EntityMapper } from "./entity-mapper.model";

export class AppVersion extends EntityMapper {

  /**
   * Version de l'application
   */
  version: string = "";

  /**
   * Date de la version
   */
  buildDate: Date = null;
  
}