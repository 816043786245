<section id="admin-groups" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Rôles des utilisateurs</h1>
    <a [routerLink]="['./new/edit']" class="btn btn-primary">
      <em class="fa fa-plus"></em>
      <ng-container i18n>Associer un rôle à un utilisateur</ng-container>
    </a>
  </div>
  <div class="page-content d-flex flex-column h-100">
    <div class="data-table">
      <vigirisks-fitrable-table [rows]="userRoles" #dataTable>
        <ng-template head-content>
          <tr>
            <th sortable-column="mail" sort-direction="asc"><span i18n>Utilisateur</span></th>
            <th class="text-center" sortable-column="roleId"><span i18n>Rôle</span></th>
            <th class="text-center"><span i18n>Thématiques</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-userRole>
          <tr>
            <td><a [routerLink]="['./', userRole.id]">{{userRole.mail}}</a></td>
            <td class="text-center">{{displayRoleName(userRole)}}</td>
            <td class="text-center">
              <ng-container *ngFor="let thematic of userRole.thematics ; last as isLast">
                <a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a>{{isLast ? '' : ','}}
              </ng-container>
            </td>
            <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle *ngIf="canEditOrDelete(userRole)"></button>
              <div aria-labelledby="row-dropdown" *ngIf="canEditOrDelete(userRole)" ngbDropdownMenu>
                <a class="dropdown-item" [routerLink]="['./', userRole.id, 'edit']" i18n>Modifier</a>
                <a class="dropdown-item item-danger" (click)="deleteUserRole(userRole)" i18n>Supprimer</a>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
