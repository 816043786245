<div class="p-4 h-100">
    <div class="admin-layout h-100 d-flex flex-column">
      <nav class="navbar admin-nav px-3 py-0 justify-content-start">
        <span class="navbar-brand mr-4">
          <em class="fa fa-table mr-1"></em>
          <ng-container i18n>Modération des fiches</ng-container>
        </span>
        <ul class="navbar-nav flex-row">
          <li class="nav-item">
            <a class="nav-link" routerLink="./pending" routerLinkActive="active" i18n>Fiches à valider</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="./accepted" routerLinkActive="active" i18n>Fiches acceptées</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="./refused" routerLinkActive="active" i18n>Fiches refusées</a>
          </li>
        </ul>
      </nav>
      <div class="h-100 w-100 admin-page">
        <router-outlet></router-outlet>
      </div>
    </div>
</div>
