<section id="edit-link" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="white-with-border">
      <ng-container *ngIf="isNew" i18n>Référencer une nouvelle ressource</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier une ressource</ng-container>
    </h1>
  </div>
  <div class="page-content">
    <form *ngIf="link" class="edit-form edit-fields-form custom-submit" [ngClass]="{'ng-submitted': editForm.submitted}"
      #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="form-group row no-gutters align-items-center">
            <label for="language" class="mr-3 mb-0">
              <ng-container i18n>Langue utilisée / <em>Language</em></ng-container>
            </label>
            <div>
              <select id="language" class="form-control" name="language" [(ngModel)]="language" (change)="onLanguageChange()" (click)="closeLanguagePopUp()">
                <option *ngFor="let availableLanguage of availableLanguages" [value]="availableLanguage.value">{{ availableLanguage.flag }}&nbsp;{{ availableLanguage.label }}</option>
              </select>
              <div id="language-pop-up" class="pop-up-container">
                <div class="arrow-up"></div>
                <div class="pop-up-content">
                  <div class="pop-up-header">
                    <div><strong>Choix de la langue</strong></div>
                    <div><button class="pop-up-close-button" (click)="closeLanguagePopUp($event)">&times;</button></div>
                  </div>
                  <div class="pop-up-body">
                    Avant de créer la métadonnée, veuillez renseigner la langue utilisée.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="form-group row no-gutters align-items-center">
            <label for="link-name" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Le titre doit être court et explicite" placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The title should be short and explicit" placement="top" container="body"></em>
              <ng-container i18n>Titre / <em>Title</em></ng-container> *
            </label>
            <div class="col">
              <input type="text" id="link-name" class="form-control" name="link-name" [(ngModel)]="link.name"
                #nameField="ngModel">
              <div class="alert alert-danger" *ngIf="formSubmitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="link-description" class="col-4 mr-3 mb-0 mt-1" i18n>
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Le résumé décrit la ressource associée au dépôt" placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The Abstract describes the resource associated with the repository" placement="top" container="body"></em>
              Résumé / <em>Abstract</em>
            </label>
            <div class="col">
              <textarea name="link-description" class="form-control" id="link-description"
                [(ngModel)]="link.description" #descField="ngModel"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-project" class="col-4 mr-3 mb-0">
              <ng-container i18n>Dépôt / <em>Repository</em></ng-container> *
            </label>
            <div class="col">
              <select id="link-project" class="form-control" name="link-project" [(ngModel)]="link.projectId" (change)="updateProject()"
                #projectField="ngModel" [disabled]="isInProject">
                <option *ngIf="locale == 'fr'" [ngValue]="null" i18n>-- Choisir un dépôt --</option>
                <option *ngIf="locale == 'en'" [ngValue]="null" i18n>-- Select a repository --</option>
                <option *ngFor="let project of projects; trackBy: trackById" [ngValue]="project.id">
                  <ng-container *ngIf="project!=null && project.name!=null">{{project.name}}</ng-container>
                  <ng-container *ngIf="project!=null && project.name==null">{{project.defaultName}}</ng-container>
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="formSubmitted && projectField.invalid">
                <p *ngIf="projectField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-type" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Ce champ permet de préciser le type de ressource renseigné : un site web du projet associé, une référence bibliographique (publication en accès ouvert ou preprint accessible de préférence dans des revues publiques ou dans HAL), un code source (forge logicielle en open source ou code archivé dans Software Heritage ayant permis d’obtenir les données), un plan de gestion de données réalisé dans le cadre d’un projet ou d’un établissement." placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="This field allows you to specify the type of resource entered: a website of the associated project, a bibliographic reference (open access publication or preprint accessible preferably in public journals or in HAL), a source code (open source software forge or code archived in Software Heritage which made it possible to obtain the data), a data management plan carried out as part of a project or establishment." placement="top" container="body"></em>
              <ng-container i18n>Type de ressource / <em>Resource type</em></ng-container> *
            </label>
            <div class="col">
              <select id="link-type" class="form-control" name="link-type" [(ngModel)]="linkType"
                #subTypeField="ngModel">
                <option *ngIf="locale == 'fr'" [ngValue]="null" i18n>-- Choisir un type de ressource --</option>
                <option *ngIf="locale == 'en'" [ngValue]="null" i18n>-- Select a ressource type --</option>
                <option *ngFor="let subType of subTypes | keyvalue" [ngValue]="subType.key">{{subType.value}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="formSubmitted && subTypeField.invalid">
                <p *ngIf="subTypeField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-url" class="col-4 mr-3 mb-0">
              <ng-container i18n>Url / <em>Uri</em></ng-container> *
            </label>
            <div class="col">
              <input type="text" id="link-url" class="form-control" name="link-url" [(ngModel)]="link.url" [pattern]="linkPattern" #urlField="ngModel">
              <div class="alert alert-danger" *ngIf="formSubmitted && urlField.invalid">
                <p *ngIf="urlField.errors.required" i18n>Champ requis</p>
                <p *ngIf="urlField.errors.pattern" i18n>L'URL doit commencer par http ou https</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="d-flex">
        <a [routerLink]="cancelRoute" class="btn btn-default">
          <ng-container i18n *ngIf="locale=='fr'">Annuler</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Cancel</ng-container>
        </a>
        <button (click)="saveDraft($event)" class="btn btn-primary ml-auto" *ngIf="!!isNew || link?.status?.value == 'PROGRESS'">
          <ng-container i18n *ngIf="locale=='fr'">Sauvegarder</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Save</ng-container>
        </button>
        <button (click)="saveAndAskPublication($event)" [ngClass]="!!isNew || link?.status?.value == 'PROGRESS' ? 'btn btn-primary ml-3' : 'btn btn-primary ml-auto'" *ngIf="!_metadataService.canModerate(link.project) || ((isNew || (!!link && !!link.status && link.status.value == 'PROGRESS')) && _metadataService.canModerate(link.project))">
          <ng-container i18n *ngIf="locale=='fr'">Publier</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Publish</ng-container>
        </button>
        <button (click)="saveAndAskPublication($event)" [ngClass]="!!isNew || link?.status?.value == 'PROGRESS' ? 'btn btn-primary ml-3' : 'btn btn-primary ml-auto'" *ngIf="!isNew && !!link && !!link.status && (link.status.value == 'PENDING' || link.status.value == 'REFUSED') && _metadataService.canModerate(link.project)">
          <ng-container i18n *ngIf="locale=='fr'">Sauvegarder les modifications</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Save changes</ng-container>
        </button>
        <button class="btn btn-success ml-3" (click)="acceptPublication($event)" *ngIf="!isNew && !!link && !!link.status && (link.status.value == 'PENDING' || link.status.value == 'REFUSED') && _metadataService.canModerate(link.project)">
          <em class="fas fa-check-circle"></em>
          <ng-container i18n *ngIf="locale=='fr'">Accepter la publication</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Accept publication</ng-container>
        </button>
        <button class="btn btn-danger normal-padding ml-3" (click)="refusePublication($event)" *ngIf="!isNew && !!link && !!link.status && link.status.value == 'PENDING' && _metadataService.canModerate(link.project)">
          <em class="fas fa-times-circle"></em>
          <ng-container i18n *ngIf="locale=='fr'">Refuser la publication</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Refuse publication</ng-container>
        </button>
      </div>
    </form>
  </div>
</section>
