import { Directive, TemplateRef, ContentChild } from '@angular/core';
import { SortableColumnDirective } from './sortable-column.directive';

@Directive({
  selector: '[head-content]'
})
export class HeadContentTemplateDirective {
  @ContentChild(SortableColumnDirective, /* TODO: add static flag */ {}) sortDir: SortableColumnDirective;

  constructor(public tpl: TemplateRef<any>) { }
}
