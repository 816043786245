<section id="data-edit" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="white-with-border">
      <ng-container *ngIf="isNew" i18n>Déposer un nouveau jeu de données</ng-container>
      <ng-container *ngIf="isDuplication" i18n>Dupliquer le jeu de données</ng-container>
      <ng-container *ngIf="!isNew && !isDuplication" i18n>Modifier le jeu de données</ng-container>
    </h1>
  </div>
  <div id="page-content" class="page-content" *ngIf="data">
    <form *ngIf="data" class="edit-form edit-fields-form custom-submit" [ngClass]="{'ng-submitted': editForm.submitted}"
      #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="form-group row no-gutters align-items-center">
            <label for="language" class="col-4 mr-3 mb-0">
              <ng-container i18n>Langue utilisée / <em>Language</em></ng-container> *
            </label>
            <div>
              <select id="language" class="form-control" name="language" [(ngModel)]="language" (change)="onLanguageChange()" (click)="closeLanguagePopUp()" #languageField="ngModel" required>
                <option *ngFor="let availableLanguage of availableLanguages" [value]="availableLanguage.value">{{ availableLanguage.flag }}&nbsp;{{ availableLanguage.label }}</option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && languageField.invalid">
                <p *ngIf="languageField.errors.required" i18n>Champ requis</p>
              </div>
              <div id="language-pop-up" class="pop-up-container">
                <div class="arrow-up"></div>
                <div class="pop-up-content">
                  <div class="pop-up-header">
                    <div><strong>Choix de la langue</strong></div>
                    <div><button class="pop-up-close-button" (click)="closeLanguagePopUp($event)">&times;</button></div>
                  </div>
                  <div class="pop-up-body">
                    Avant de créer la métadonnée, veuillez renseigner la langue utilisée.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6">
          <h2>Informations générales / <em>General informations</em></h2>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-name" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Le titre doit être court et explicite. Il peut se composer, par exemple, du type de données déposé, localisation, période temporelle, projet." placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The title should be short and explicit. It can consist, for example, of the type of data deposited, location, time period, project." placement="top" container="body"></em>
              <ng-container i18n>Titre / <em>Title</em> *</ng-container>
            </label>
            <div class="col">
              <input type="text" id="data-name" class="form-control" name="data-name" [(ngModel)]="data.name"
                #nameField="ngModel">
              <div class="alert alert-danger" *ngIf="formSubmitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-description" class="col-4 mr-3 mb-0 mt-1" i18n>
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Le résumé décrit les objectifs et le contenu du jeu de données déposé. Ce champ ne doit pas contenir des informations techniques, celles-ci doivent être intégrées dans la champ « traçabilité »" placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The summary describes the objectives and content of the submitted dataset. This field must not contain technical information, this must be integrated into the “Lineage” field" placement="top" container="body"></em>
              Résumé / <em>Abstract</em> *
            </label>
            <div class="col">
              <textarea name="data-description" class="form-control" id="data-description"
                [(ngModel)]="data.description" #descField="ngModel"></textarea>
              <div class="alert alert-danger" *ngIf="formSubmitted && descField.invalid">
                <p *ngIf="descField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-project" class="col-4 mr-3 mb-0">
              <ng-container i18n>Dépôt / <em>Repository</em></ng-container> *
            </label>
            <div class="col">
              <select id="data-project" class="form-control" name="data-project" [(ngModel)]="data.projectId"
                #projectField="ngModel" [disabled]="isInProject" (change)="updateProject()">
                <option *ngIf="locale == 'fr'" [ngValue]="null" i18n>-- Choisir un dépôt --</option>
                <option *ngIf="locale == 'en'" [ngValue]="null" i18n>-- Select a repository --</option>
                <option *ngFor="let project of projects; trackBy: trackById" [ngValue]="project.id">{{displayProjectName(project,true)}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="formSubmitted && projectField.invalid">
                <p *ngIf="projectField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group column no-gutters align-items-center">
            <div class="row no-gutters align-items-start thematics-section">
              <label for="data-thematics" class="col-4 mr-3 mb-0 mt-2">
                <ng-container i18n>Thématiques / <em>Thematics</em> *</ng-container><br/>
                <a href="{{thematicsRegistryUrl}}" target="_blank">Registre des thématiques</a>
              </label>
              <div class="col">
                <p-autoComplete id="data-thematics-autocomplete" [(ngModel)]="data.thematics" [multiple]="true" inputId="data-thematics" #thematicsAutocomplete
                  (onFocus)="autocompleteFocus(thematicsAutocomplete,'thematics')" (onSelect)="autocompleteSelected('thematics')" [dropdown]="true"
                  emptyMessage="Aucune thématique trouvée" name="data-thematics" [suggestions]="thematicsSuggestions" field="label" styleClass="form-control w-100 dropdown"
                  (completeMethod)="formListAutocomplete($event, 'thematics')" #thematicsField="ngModel" [ngModelOptions]="{standalone: true}" forceSelection="true">
                </p-autoComplete>
                <div class="alert alert-danger not-absolute" *ngIf="formSubmitted && thematicsField.invalid">
                  <p *ngIf="thematicsField.errors.required" i18n>Merci de choisir les thématiques parmi la liste proposée</p>
                </div>
                <div *ngIf="hasProjectThematic && data.project!=null" class="form-legend-container">
                  <div class="form-legend-row">
                    <span class="form-legend-box darkblue"></span>
                    <span>
                      <ng-container *ngIf="locale=='fr'">Thématique choisi dans ce formulaire</ng-container>
                      <ng-container *ngIf="locale=='en'">Thematic chosen in this form</ng-container>
                    </span>
                  </div>
                  <div class="form-legend-row">
                    <span class="form-legend-box lightblue"></span>
                    <span>
                      <ng-container *ngIf="locale=='fr'">Thématique importé du dépôt</ng-container>
                      <ng-container *ngIf="locale=='en'">Thematic imported from repository</ng-container>
                      "{{displayProjectName(data.project)}}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start keywords-section">
            <label for="data-keywords" class="col-4 mr-3 mb-0">
              <em *ngIf="locale=='fr'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La suggestion de mots-clefs permet à partir du résumé renseigné de proposer des mots-clefs issus de vocabulaires liés à la thématique considérée et favoriser ainsi la découverte du jeu de données" placement="top" container="body"></em>
              <em *ngIf="locale=='en'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The suggestion of keywords allows, from the completed abstract, to propose keywords from vocabularies linked to the theme considered and thus promote the discovery of the dataset" placement="top" container="body"></em>
              <ng-container i18n>Mots-clés / <em>Keywords</em> *</ng-container><br/>
              <a href="{{keywordsRegistryUrl}}" target="_blank">Registre des mots-clés</a>
              <button type="button" class="btn badge badge-primary" (click)="launchAnnotatorSearch()">
                <ng-container *ngIf="locale=='fr'">Suggestion de mots-clés additionnels</ng-container>
                <ng-container *ngIf="locale=='en'">Suggestion of additional keywords</ng-container>
              </button>
              <a href="https://earthportal.eu/" target="_blank">EarthPortal</a>
            </label>
            <div class="col">
              <p-autoComplete id="data-keywords-autocomplete" [(ngModel)]="keywords" #keywordsField="ngModel" [multiple]="true" inputId="data-keywords" #keywordsAutocomplete
                (onFocus)="autocompleteFocus(keywordsAutocomplete,'keywords')" (onSelect)="autocompleteSelected('keywords')" [dropdown]="true"
                emptyMessage="Aucun mot-clé trouvé" (minQueryLength)="3" name="project-thematics" [suggestions]="keywordsSuggestions" field="label" styleClass="form-control w-100 dropdown"
                (completeMethod)="formListAutocomplete($event, 'keywords')" [ngModelOptions]="{standalone: true}" forceSelection="true">
              </p-autoComplete>
              <div class="alert alert-danger not-absolute" *ngIf="formSubmitted && keywordsField.invalid">
                <p *ngIf="keywordsField.errors.required" i18n>Merci de choisir les mots-clés parmi la liste proposée</p>
              </div>
              <div *ngIf="hasProjectKeyword && data.project!=null" class="form-legend-container">
                <div class="form-legend-row">
                  <span class="form-legend-box darkblue"></span>
                  <span>
                    <ng-container *ngIf="locale=='fr'">Mot-clé choisi dans ce formulaire</ng-container>
                    <ng-container *ngIf="locale=='en'">Keyword chosen in this form</ng-container>
                  </span>
                </div>
                <div class="form-legend-row">
                  <span class="form-legend-box lightblue"></span>
                  <span>
                    <ng-container *ngIf="locale=='fr'">Mot-clé importé du dépôt</ng-container>
                    <ng-container *ngIf="locale=='en'">Keyword imported from repository</ng-container>
                    "{{displayProjectName(data.project)}}"</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters">
            <label for="data-files" class="col-4 mr-3 mb-0 mt-1" i18n>
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Pour le format des fichiers, Il est recommandé de choisir un des formats de la liste suivante: {{ listFilesFormat }}" placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="For the file format, it is recommended to choose one of the formats from the following list: {{ listFilesFormat }}" placement="top" container="body"></em>
              Fichiers / <em>Files</em> *
            </label>
            <div class="col">
              <drop-files id="data-files-drop-zone" inputId="data-files" [(ngModel)]="filesToUpload" #dataFilesFied="ngModel" name="data-files"
                [multiple]="true" [datasetFilesDirectory]="datasetsFilePath + data.id + '/files'" [filesDownloadable]="filesDownloadable">
              </drop-files>
              <div class="alert alert-danger" *ngIf="formSubmitted && dataFilesFied.invalid">
                <p *ngIf="dataFilesFied.errors.required" i18n>Champ requis</p>
                <p *ngIf="dataFilesFied.errors.size" i18n>Un ou plusieurs fichiers sont trop lourds</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-creation-date" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="La date de création correspond à la date à laquelle le jeu de données a été créé. Elle doit être inférieure ou égale à la date du jour"
                placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="The creation date is the date the dataset was created. It must be less than or equal to today's date"
                placement="top" container="body"></em>
              <ng-container i18n>Date de création / <em>Creation date</em></ng-container> *
            </label>
            <div class="col">
              <p-calendar id="data-creation-date" name="data-creation-date" styleClass="d-flex align-items-stretch"
                [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                inputStyleClass="form-control" [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body"
                [(ngModel)]="data.creationDate" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true" #creationDateField="ngModel"
                (onBlur)="checkCreationDate(false, false)" (onSelect)="checkCreationDate(true, false)"
                [keepInvalid]="true"></p-calendar>
              <div class="alert alert-danger" *ngIf="!formSubmitted && creationDateField.invalid">
                <p *ngIf="creationDateField.control.errors?.required || creationDateField.control.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="creationDateField.control.errors?.format" i18n>La date de création doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="creationDateField.control.errors?.rangeToday" i18n>La date de création doit être inférieure ou égale à la date du jour</p>
              </div>
              <div class="alert alert-danger" *ngIf="formSubmitted && creationDateField.invalid">
                <p *ngIf="creationDateField.errors?.required || creationDateField.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="creationDateField.errors?.format" i18n>La date de création doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="creationDateField.errors?.rangeToday" i18n>La date de création doit être inférieure ou égale à la date du jour</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-constraints" class="col-4 mr-3 mb-0 mt-1">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="La licence choisie encadrera les droits et obligations devant être respectés pour toute réutilisation de vos données. Selon la nature de vos données et le contexte dans lequel elles ont été acquises, certaines licences peuvent être recommandées ou imposées. N’hésitez pas à vous référer au cadre juridique de votre établissement, de votre projet de recherche ou de la revue dans laquelle vous souhaitez publier."
                placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="The license chosen will govern the rights and obligations that must be respected for any reuse of your data. Depending on the nature of your data and the context in which it was acquired, certain licenses may be recommended or imposed. Do not hesitate to refer to the legal framework of your establishment, your research project or the journal in which you wish to publish."
                placement="top" container="body"></em>
              <ng-container i18n>Contraintes d'utilisation / <em>Legal constraints</em></ng-container> *
            </label>
            <div class="col">
              <select id="data-constraints" class="form-control" name="data-constraints" (change)="updateLicense()"
                [(ngModel)]="licenseId" #legalConstraintsField="ngModel">
                <option *ngFor="let useConstraint of useConstraints" [ngValue]="useConstraint.licenseId">{{useConstraint.licenseId}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="formSubmitted && legalConstraintsField.invalid">
                <p *ngIf="legalConstraintsField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters align-items-center">
            <label for="data-embargo" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Permet la mise des données sous embargo. La date de libération à laquelle prend fin cet embargo ne peut pas excéder deux ans après la date de création." placement="top" container="body"
                placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Allows to put an embargo on the data. The liberation date on which the embargo takes end cannot exceed two years after the creation date." placement="top" container="body"
                placement="top" container="body"></em>
              <ng-container i18n>Embargo sur la donnée / <em>Data embargo</em></ng-container> *
            </label>
            <div class="col">
              <p-inputSwitch inputId="data-embargo" name="embargo" (onChange)="changeEmbargoStatus()" [(ngModel)]="data.embargo" #embargoField="ngModel"></p-inputSwitch>
              <div class="alert alert-danger" *ngIf="formSubmitted && legalConstraintsField.invalid">
                <p *ngIf="legalConstraintsField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-access-rights" class="col-4 mr-3 mb-0">
              <ng-container i18n>Droits d'accès / <em>Access rights</em></ng-container>
            </label>
            <div class="col">
              <span *ngIf="data.embargo">Embargo</span>
              <span *ngIf="!data.embargo">Open Access</span>
            </div>
          </div>

          <div class="form-group row no-gutters align-items-center" *ngIf="data.embargo">
            <label for="data-released-date" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Date à laquelle l'embargo sur les données prend fin. Elle ne peut pas excéder deux ans après la date de création." placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Date on which the embargo on data takes end. It cannot exceed two years after the creation date." placement="top" container="body"></em>
              <ng-container i18n>Date de libération du jeu de données / <em>Data release date</em></ng-container>
            </label>
            <div class="col">
              <p-calendar id="data-released-date" name="data-released-date" styleClass="d-flex align-items-stretch"
                [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                inputStyleClass="form-control" [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #releasedDateField="ngModel"
                [(ngModel)]="data.releasedDate" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                (onBlur)="checkReleasedDate(false)" (onSelect)="checkReleasedDate(true)"
                [keepInvalid]="true"></p-calendar>
              <div *ngIf="minReleasedDate">
                <ng-container i18n *ngIf="locale=='fr'">Date de libération minimale : {{displayMinReleasedDate()}}</ng-container>
                <ng-container i18n *ngIf="locale=='en'">Maximum release date : {{displayMinReleasedDate()}}</ng-container>
              </div>
              <div *ngIf="maxReleasedDate">
                <ng-container i18n *ngIf="locale=='fr'">Date de libération maximale : {{displayMaxReleasedDate()}}</ng-container>
                <ng-container i18n *ngIf="locale=='en'">Maximum release date : {{displayMaxReleasedDate()}}</ng-container>
              </div>
              <div class="alert alert-danger" *ngIf="!formSubmitted && releasedDateField.invalid">
                <p *ngIf="releasedDateField.control.errors?.format" i18n>La date de libération doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="releasedDateField.control.errors?.range" i18n>La date de libération doit être supérieure ou égale à la date de création</p>
                <p *ngIf="releasedDateField.control.errors?.rangeMax" i18n>La date de libération doit être inférieure ou égale à la date de libération maximale</p>
              </div>
              <div class="alert alert-danger" *ngIf="formSubmitted && releasedDateField.invalid">
                <p *ngIf="releasedDateField.errors?.format" i18n>La date de libération doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                <p *ngIf="releasedDateField.errors?.range" i18n>La date de libération doit être supérieure ou égale à la date de création</p>
                <p *ngIf="releasedDateField.errors?.rangeMax" i18n>La date de libération doit être inférieure ou égale à la date de libération maximale</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-6">
          <h2>Informations spatio-temporelles / <em>Spatio-temporal informations</em></h2>
          <fieldset class="form-group">
            <legend i18n>Emprises / <em>Extents</em> *</legend>
            <div class="form-group row no-gutters align-items-start">
              <label for="data-extent" class="col-4 mb-0 mt-1" i18n>Emprise / <em>Extent</em> *</label>
              <div class="col">
                <described-extent name="data-extent" id="data-extent" [(ngModel)]="data.extents" #extentChildComponent #spaceExtentField="ngModel" locale="{{locale}}"></described-extent>
                <div class="alert alert-danger" *ngIf="formSubmitted && spaceExtentField.invalid">
                  <p *ngIf="spaceExtentField.errors.required" i18n>Champ requis</p>
                </div>
              </div>
            </div>
            <fieldset *ngFor="let extent of data.extents; let index = index" class="form-group with-close-btn">
              <legend>
                <ng-container *ngIf="extent.description==null || extent.description==''">Emprise {{index+1}}</ng-container>
                <ng-container *ngIf="extent.description!=null">{{extent.description.label}}</ng-container>
              </legend>
              <button type="button" class="btn btn-close" (click)="removeExtent(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <div class="description-column col-4 mr-3 mb-0">
                  <label for="'project-extent-'+index+'-description'" i18n>Description / <em>Description</em></label>
                  <div class="row description-type-row">
                    <div class="description-type-elt">
                      <input type="radio" id="place-description-{{index}}" name="description-type-{{index}}" value="place" (change)="updateExtentsDescriptionTypes('place',index)"/>
                      <label for="place-description-{{index}}">
                        <ng-container *ngIf="locale=='fr'">Lieu</ng-container>
                        <ng-container *ngIf="locale=='en'">Place</ng-container>
                      </label>
                    </div>
                    <div class="description-type-elt">
                      <input type="radio" id="country-description-{{index}}" name="description-type-{{index}}" value="country" (change)="updateExtentsDescriptionTypes('country',index)"/>
                      <label for="country-description-{{index}}">
                        <ng-container *ngIf="locale=='fr'">Pays</ng-container>
                        <ng-container *ngIf="locale=='en'">Country</ng-container>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p-autoComplete [(ngModel)]="extent.description" id="project-extent-description" [multiple]="false" inputId="'project-extent-'+index+'-description'"
                    name="project-extent-description" [suggestions]="geonames" field="label" (completeMethod)="searchGeonames($event,index)" (onSelect)="geonamesItemSelected($event,index)"
                    styleClass="w-100" inputStyleClass="form-control" [ngModelOptions]="{standalone: true}" forceSelection="true">
                  </p-autoComplete>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'data-west-bound-longitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La longitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Longitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Longitude ouest / <em>West bound longitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'data-west-bound-longitude-'+index" class="form-control" [name]="'data-west-bound-longitude-'+index"
                  [(ngModel)]="extent.westBoundLongitude" #westBoundLongitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(data.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && westBoundLongitudeField.invalid">
                    <p *ngIf="westBoundLongitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'data-east-bound-longitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La longitude doit être comprise entre -180 et 180 degrés."
                  placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Longitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>                  <ng-container i18n>Longitude est / <em>East bound longitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'data-east-bound-longitude-'+index" class="form-control" [name]="'data-east-bound-longitude-'+index"
                  [(ngModel)]="extent.eastBoundLongitude" #eastBoundLongitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(data.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && eastBoundLongitudeField.invalid">
                    <p *ngIf="eastBoundLongitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'data-north-bound-latitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La latitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Latitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Latitude nord / <em>North bound latitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'data-north-bound-latitude-'+index" class="form-control" [name]="'data-north-bound-latitude-'+index"
                  [(ngModel)]="extent.northBoundLatitude" #northBoundLatitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(data.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && northBoundLatitudeField.invalid">
                    <p *ngIf="northBoundLatitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'data-south-bound-latitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La latitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Latitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Latitude sud / <em>South bound latitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'data-south-bound-latitude-'+index" class="form-control" [name]="'data-south-bound-latitude-'+index"
                  [(ngModel)]="extent.southBoundLatitude" #southBoundLatitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(data.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && southBoundLatitudeField.invalid">
                    <p *ngIf="southBoundLatitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
            </fieldset>
            <button type="button" class="btn btn-add" (click)="addExtent()">
              <em class="fa fa-plus mr-2"></em>
              <ng-container i18n *ngIf="locale=='fr'">Ajouter une emprise</ng-container>
              <ng-container i18n *ngIf="locale=='en'">Add extent</ng-container>
            </button>
          </fieldset>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-spatial-type" class="col-4 mr-3 mb-0">
              <ng-container i18n>Type de représentation spatiale / <em>Spatial resolution type</em></ng-container>
            </label>
            <div class="col">
              <select id="data-spatial-type" class="form-control" name="data-spatial-type"
                [(ngModel)]="data.spatialRepresentationType" #representationTypeField="ngModel">
                <option *ngIf="locale=='fr'" [ngValue]="null" i18n>-- Choisir une représentation --</option>
                <option *ngIf="locale=='en'" [ngValue]="null" i18n>-- Select a resolution type --</option>
                <option *ngFor="let spatialType of spatialTypes" [ngValue]="spatialType">{{spatialType}}</option>
              </select>
            </div>
          </div>
          <!-- <div class="form-group row no-gutters align-items-center">
            <label for="data-resolution" class="col-4 mr-3 mb-0">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="La résolution spatiale est obligatoire lorsque la représentation spatiale choisie est &quot;vector&quot;, &quot;grid&quot; ou &quot;tin&quot;."
                placement="top" container="body"></i>
              <ng-container i18n>Résolution spatiale / <i>Spatial resolution</i></ng-container><span *ngIf="['vector','grid','tin'].indexOf(data.spatialRepresentationType) > -1"> *</span>
            </label>
            <div class="col">
              <input type="number" id="data-resolution" class="form-control" name="data-resolution"
                [(ngModel)]="data.spatialResolution" #spatialResolutionField="ngModel"
                [required]="['vector','grid','tin'].indexOf(data.spatialRepresentationType) > -1 || null">
              <div class="alert alert-danger" *ngIf="editForm.submitted && spatialResolutionField.invalid">
                <p *ngIf="spatialResolutionField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div> -->
          <fieldset class="form-group">
            <legend i18n>Étendue temporelle / <em>Time extent</em> *</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="data-time-extent-start" class="col-4 mb-0">
                <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="La date de début doit suivre le format {{localeCalendarFormats[locale].label}} et être inférieure ou égale à la date de fin de l'étendue temporelle."
                  placement="top" container="body"></em>
                <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="Beginning date must follow the format {{localeCalendarFormats[locale].label}} and be less than or equal to temporal extent end."
                  placement="top" container="body"></em>
                <ng-container i18n>Début / <em>Beginning</em></ng-container> *
              </label>
              <div class="col">
                <p-calendar id="data-time-extent-start" name="data-time-extent-start"
                  [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                  styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                  [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentStartField="ngModel"
                  [(ngModel)]="data.temporalExtentStart" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                  (onBlur)="checkTemporalExtentDates(timeExtentStartField, 'start', false)" (onSelect)="checkTemporalExtentDates(timeExtentStartField, 'start', true)"
                  [keepInvalid]="true">
                </p-calendar>
                <div class="alert alert-danger" *ngIf="!formSubmitted && timeExtentStartField.invalid">
                  <p *ngIf="timeExtentStartField.control.errors?.required || timeExtentStartField.control.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.control.errors?.format" i18n>La date de début doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.control.errors?.rangeEnd" i18n>La date de début doit être inférieure ou égale à la date de fin</p>
                  <p *ngIf="timeExtentStartField.control.errors?.rangeToday" i18n>La date de début doit être inférieure ou égale à la date du jour</p>
                  <p *ngIf="timeExtentStartField.control.errors?.rangeCreation" i18n>La date de début doit être inférieure ou égale à la date de création</p>
                </div>
                <div class="alert alert-danger" *ngIf="formSubmitted && timeExtentStartField.invalid">
                  <p *ngIf="timeExtentStartField.errors?.required || timeExtentStartField.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.errors?.bothDates" i18n>Veuillez fournir les 2 dates ou aucune</p>
                  <p *ngIf="timeExtentStartField.errors?.rangeEnd" i18n>La date de début doit être inférieure ou égale à la date de fin</p>
                  <p *ngIf="timeExtentStartField.errors?.rangeToday" i18n>La date de début doit être inférieure ou égale à la date du jour</p>
                  <p *ngIf="timeExtentStartField.errors?.rangeCreation" i18n>La date de début doit être inférieure ou égale à la date de création</p>
                  <p *ngIf="timeExtentStartField.errors?.format" i18n>La date de début doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                </div>
              </div>
            </div>
            <div class="row no-gutters align-items-center">
              <label for="data-time-extent-end" class="col-4 mb-0">
                <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="La date de fin d'étendue temporelle doit respecter le format {{localeCalendarFormats[locale].label}}. La date de fin doit être inférieure ou égale à la date du jour"
                  placement="top" container="body"></em>
                <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="The time extent end date must follow the format {{localeCalendarFormats[locale].label}}. End date must be less than or equal to today's date."
                  placement="top" container="body"></em>
                <ng-container i18n>Fin / <em>Ending</em></ng-container> *
              </label>
              <div class="col">
                <p-calendar id="data-time-extent-end" name="data-time-extent-end"
                  [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                  styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                  [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentEndField="ngModel"
                  [(ngModel)]="data.temporalExtentEnd" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                  (onBlur)="checkTemporalExtentDates(timeExtentEndField, 'end', false)" (onSelect)="checkTemporalExtentDates(timeExtentEndField, 'end', true)"
                  [keepInvalid]="true">
                </p-calendar>
                <div class="alert alert-danger" *ngIf="!formSubmitted && timeExtentEndField.invalid">
                  <p *ngIf="timeExtentEndField.control.errors?.required || timeExtentEndField.control.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.control.errors?.format" i18n>La date de fin doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.control.errors?.rangeToday" i18n>La date de fin doit être inférieure ou égale à la date du jour</p>
                  <p *ngIf="timeExtentEndField.control.errors?.rangeCreation" i18n>La date de fin doit être inférieure ou égale à la date de création</p>
                </div>
                <div class="alert alert-danger" *ngIf="formSubmitted && timeExtentEndField.invalid">
                  <p *ngIf="timeExtentEndField.errors?.required || timeExtentEndField.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.errors?.bothDates" i18n>Veuillez fournir les 2 dates ou aucune</p>
                  <p *ngIf="timeExtentEndField.errors?.rangeToday" i18n>La date de fin doit être inférieure ou égale à la date du jour</p>
                  <p *ngIf="timeExtentEndField.errors?.rangeCreation" i18n>La date de fin doit être inférieure ou égale à la date de création</p>
                  <p *ngIf="timeExtentEndField.errors?.format" i18n>La date de fin doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-lineage" class="col-4 mr-3 mb-0 mt-1">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Description des sources et processus de production fournissant la provenance du jeu de données."
                placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Description of the sources and production processes providing the provenance of the dataset."
                placement="top" container="body"></em>
              <ng-container i18n>Traçabilité (origine) / <em>Lineage (origin)</em></ng-container> *
            </label>
            <div class="col">
              <textarea name="data-lineage" class="form-control" id="data-lineage"
                [(ngModel)]="data.lineage" #lineageField="ngModel"></textarea>
              <div class="alert alert-danger" *ngIf="formSubmitted && lineageField.invalid">
                <p *ngIf="lineageField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="form-separator">
      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>
          <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Liste des contacts associés au jeu de données décrit par la fiche de métadonnées. Renseigner à minima un contact dont le rôle est point de contact et un contact dont le rôle est auteur"
            placement="top" container="body"></em>
          <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="List of contacts associated with the dataset described by the metadata record. Enter at least one contact whose role is point of contact and one contact whose role is author"
            placement="top" container="body"></em>
          Contacts / <em>Contacts</em>
        </h2>
        <button type="button" class="btn btn-add ml-5" (click)="addContact()">
          <em class="fa fa-plus mr-2"></em>
          <ng-container i18n *ngIf="locale=='fr'">Ajouter un contact</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Add contact</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let contact of data.contacts; let index = index">
          <div class="col-12 col-xl-6">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Contact / <em>Contact</em></legend>
              <button type="button" *ngIf="index >= 2"
                class="btn btn-close" (click)="removeContact(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-lastname-' + index" class="col-4 mb-0">
                  <ng-container i18n>Nom / <em>Last name</em></ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-lastname-' + index" class="form-control"
                    [name]="'contact-lastname-' + index" [(ngModel)]="contact.individualLastName" #contactLastNameField="ngModel"
                    pattern="^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'\-\s]+$">
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactLastNameField.invalid">
                    <p *ngIf="contactLastNameField.errors.required" i18n>Champ requis</p>
                    <p *ngIf="contactLastNameField.errors?.pattern">Caractères autorisés : lettres, tirets et apostrophes.</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-firstname-' + index" class="col-4 mb-0">
                  <ng-container i18n>Prénom / <em>First name</em></ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-firstname-' + index" class="form-control"
                    [name]="'contact-firstname-' + index" [(ngModel)]="contact.individualFirstName" #contactFirstNameField="ngModel"
                    pattern="^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'\-\s]+$">
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactFirstNameField.invalid">
                    <p *ngIf="contactFirstNameField.errors.required" i18n>Champ requis</p>
                    <p *ngIf="contactFirstNameField.errors?.pattern">Caractères autorisés : lettres, tirets et apostrophes.</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-email-' + index" class="col-4 mb-0">
                  <ng-container i18n>Email / <em>Email</em></ng-container> *
                </label>
                <div class="col">
                  <input type="email" [id]="'contact-email-' + index" class="form-control"
                    [name]="'contact-email-' + index" [(ngModel)]="contact.email" #contactEmailField="ngModel"
                    pattern="^[a-zA-Z0-9]+(?:[\._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[\._-][a-zA-Z0-9]+)+$">
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactEmailField.invalid">
                    <p *ngIf="contactEmailField.errors.required" i18n>Champ requis</p>
                    <p *ngIf="contactEmailField.errors?.pattern" i18n>Veuillez saisir une adresse mail valide</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-organisation-' + index" class="col-4 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="L'organisation est recherchée dans le registre {{tooltipRorSite}}, si l'organisme n'est pas retrouvé, indiquer l'ID ROR correspondant."
                      placement="top" container="body">
                  </em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="The organization is searched in the register {{tooltipRorSite}}, if the organism is not found, indicate the corresponding ROR ID."
                      placement="top" container="body">
                  </em>
                  <ng-container i18n>Organisation / <em>Organization</em></ng-container> *
                </label>
                <div class="col">
                  <p-autoComplete inputId="'contact-organisation-' + index" [id]="'contact-organisation-' + index" name="'contact-organisation-' + index"
                    (minQueryLength)="3" [suggestions]="organizationResults" field="name" (completeMethod)="searchOrganization($event, contact)"
                    (onClear)="clearOrganization(contact)" styleClass="w-100" inputStyleClass="form-control" forceSelection="true"
                    [(ngModel)]="contact.organisation" #contactOrganisationField="ngModel" [ngModelOptions]="{standalone: true}">
                  </p-autoComplete>
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactOrganisationField.invalid">
                    <p *ngIf="contactOrganisationField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-role-' + index" class="col-4 mb-0">
                  <em *ngIf="language=='fre'"  class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="Les définitions des rôles utilisés dans EaSy Data sont disponibles sur la page suivante: {{ siteStandards }}"
                      placement="top" container="body">
                  </em>
                  <em *ngIf="language=='eng'"  class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="The definitions of the roles used in EaSy Data are available on the following page: {{ siteStandards }}"
                      placement="top" container="body">
                  </em>
                  <ng-container i18n>Rôle / <em>Role</em></ng-container> *
                </label>
                <div class="col">
                  <select [id]="'contact-role-' + index" class="form-control" [name]="'contact-role-' + index"
                    [(ngModel)]="contact.role" #contactRoleField="ngModel" (change)="checkPointsOfContacts(contact)"
                    (focus)="onFocusRole(contact)" [disabled]="index < 2">
                    <option *ngFor="let role of contactTypes; trackBy: trackByValue" [ngValue]="role.value">{{role.label}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactRoleField.invalid">
                    <p *ngIf="contactRoleField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <div class="d-flex mb-5 align-items-center">
                  <h2 class="form-title my-0" i18n>Identifiants / <em>Identifiers</em></h2>
                  <button type="button" class="btn btn-add ml-5" (click)="addIdentifierToContact(contact)">
                    <em class="fa fa-plus mr-2"></em>
                    <ng-container i18n *ngIf="locale=='fr'">Ajouter un identifiant</ng-container>
                    <ng-container i18n *ngIf="locale=='en'">Add identifier</ng-container>
                  </button>
                </div>
                <ng-container *ngFor="let contactIdentifier of contact.contactIdentifiers; let identifierIndex = index">
                  <div class="col-12">
                    <fieldset class="form-group with-close-btn">
                      <legend i18n>Identifiant / <em>Identifier</em></legend>
                      <button type="button" class="btn btn-close" (click)="removeIdentifierFromContact(contact, identifierIndex)">
                        <span>&times;</span>
                      </button>
                      <div class="form-group row no-gutters align-items-center">
                        <label [for]="'contact-identifier-type-' + index + '-' + identifierIndex" class="col-4 mb-0">
                          <ng-container i18n>Type de l'identifiant / <em>Identifier origin</em></ng-container> *
                        </label>
                        <div class="col">
                          <select type="text" [id]="'contact-identifier-type-' + index + '-' + identifierIndex" class="form-control"
                            [name]="'contact-identifier-type-' + index + '-' + identifierIndex" [(ngModel)]="contactIdentifier.identifierType" #contactIdentifierTypeField="ngModel">
                            <option *ngFor="let origin of identifiersOrigins">{{ origin }}</option>
                          </select>
                          <div class="alert alert-danger" *ngIf="formSubmitted && contactIdentifierTypeField.invalid">
                            <p *ngIf="contactIdentifierTypeField.errors.required" i18n>Champ requis</p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row no-gutters align-items-center">
                        <label [for]="'contact-identifier-' + index + '-' + identifierIndex" class="col-4 mb-0">
                          <ng-container i18n>Identifiant / <em>Identifier</em></ng-container> *
                        </label>
                        <div class="col">
                          <input type="text" [id]="'contact-identifier-' + index + '-' + identifierIndex" class="form-control"
                            [name]="'contact-identifier-' + index + '-' + identifierIndex" [(ngModel)]="contactIdentifier.identifier" #contactIdentifierField="ngModel">
                          <div class="alert alert-danger" *ngIf="formSubmitted && contactIdentifierField.invalid">
                            <p *ngIf="contactIdentifierField.errors.required" i18n>Champ requis</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </ng-container>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>

      <hr class="form-separator">

      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>
          <em class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Renseigner toute ressource liée au jeu de données déposé disponible en accès ouvert : Publication, site web projet, code source, API d’accès au jeu de données, IGSN, service associés (service web cartographique, service de téléchargement), DOI du ou des jeux de données source ou associés."
              placement="top" container="body"></em>
          Ressources du jeu de données et services associés / <em>Associated datasets and services</em>
        </h2>
        <button type="button" class="btn btn-add ml-5" (click)="addOnlineResource()">
          <em class="fa fa-plus mr-2"></em>
          <ng-container i18n *ngIf="locale=='fr'">Ajouter une ressource ou un service</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Add ressource or service</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let resource of data.onlineResources; let index = index">
          <div class="col-12 col-xl-6" *ngIf="resource.type !== FILE_RESOURCE_CODE">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Ressource ou service / <em>Resource or service</em></legend>
              <button type="button" class="btn btn-close" (click)="removeOnlineResource(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'data-or-url-' + index" class="col-4 mb-0">
                  <ng-container i18n>Url / <em>Uri</em></ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'data-or-url-' + index" class="form-control" [name]="'data-or-url-' + index"
                    [(ngModel)]="resource.url" #dataUrlField="ngModel">
                  <div class="alert alert-danger" *ngIf="formSubmitted && dataUrlField.invalid">
                    <p *ngIf="dataUrlField.errors.required" i18n>Champ requis</p>
                    <p *ngIf="dataUrlField.errors?.pattern">Veuillez saisir une url valide</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'data-or-protocol-' + index" class="col-4 mb-0">
                  <ng-container i18n>Type de ressource / <em>Resource type</em></ng-container> *
                </label>
                <div class="col">
                  <select [id]="'data-or-protocol-' + index" class="form-control" [name]="'data-or-protocol-' + index"
                    [(ngModel)]="resource.protocol" #dataUrlProtocolField="ngModel">
                    <option *ngIf="locale=='fr'" [ngValue]="''" i18n>-- Choisir un type de ressource --</option>
                    <option *ngIf="locale=='en'" [ngValue]="''" i18n>-- Select a resource type --</option>
                    <option *ngFor="let protocol of protocols" [ngValue]="protocol">{{protocol}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="formSubmitted && dataUrlProtocolField.invalid">
                    <p *ngIf="dataUrlProtocolField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'data-or-name-' + index" class="col-4 mb-0" i18n>Nom / <em>Name</em> *</label>
                <div class="col">
                  <input type="text" [id]="'data-or-name-' + index" class="form-control"
                    [name]="'data-or-name-' + index" [(ngModel)]="resource.name" #dataUrlNameField="ngModel"/>
                  <div class="alert alert-danger" *ngIf="formSubmitted && dataUrlNameField.invalid">
                    <p *ngIf="dataUrlNameField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters align-items-start">
                <label [for]="'data-or-desc-' + index" class="col-4 mb-0 mt-1" i18n>Description / <em>Description</em></label>
                <div class="col">
                  <textarea [name]="'data-or-desc-' + index" class="form-control" [id]="'data-or-desc-' + index"
                    [(ngModel)]="resource.description"></textarea>
                </div>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>
      <div class="d-flex">
        <a [routerLink]="cancelRoute" class="btn btn-default">
          <ng-container i18n *ngIf="locale=='fr'">Annuler</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Cancel</ng-container>
        </a>
        <button (click)="saveDraft($event)" class="btn btn-primary ml-auto" *ngIf="!!isNew || data?.status?.value == 'PROGRESS'">
          <ng-container i18n *ngIf="locale=='fr'">Sauvegarder</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Save</ng-container>
        </button>
        <button (click)="saveAndAskPublication($event)" [ngClass]="!!isNew || data?.status?.value == 'PROGRESS' ? 'btn btn-primary ml-3' : 'btn btn-primary ml-auto'" *ngIf="!_metadataService.canModerate(data) || ((isNew || (!!data && !!data.status && data.status.value == 'PROGRESS')) && _metadataService.canModerate(data))">
          <ng-container i18n *ngIf="locale=='fr'">Publier et demander un DOI</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Publish and ask for DOI</ng-container>
        </button>
        <button (click)="saveAndAskPublication($event)" [ngClass]="!!isNew || data?.status?.value == 'PROGRESS' ? 'btn btn-primary ml-3' : 'btn btn-primary ml-auto'" *ngIf="!isNew && !!data && !!data.status && (data.status.value == 'PENDING' || data.status.value == 'REFUSED') && _metadataService.canModerate(data)">
          <ng-container i18n *ngIf="locale=='fr'">Sauvegarder les modifications</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Save changes</ng-container>
        </button>
        <button class="btn btn-success ml-3" (click)="acceptPublication($event)" *ngIf="!isNew && !!data && !!data.status && (data.status.value == 'PENDING' || data.status.value == 'REFUSED') && _metadataService.canModerate(data)">
          <em class="fas fa-check-circle"></em>
          <ng-container i18n *ngIf="locale=='fr'">Accepter la publication</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Accept publication</ng-container>
        </button>
        <button class="btn btn-danger normal-padding ml-3" (click)="refusePublication($event)" *ngIf="!isNew && !!data && !!data.status && data.status.value == 'PENDING'  && _metadataService.canModerate(data)">
          <em class="fas fa-times-circle"></em>
          <ng-container i18n *ngIf="locale=='fr'">Refuser la publication</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Refuse publication</ng-container>
        </button>
      </div>
    </form>
  </div>
</section>
