<div class="modal-header">
  <h2 class="modal-title">{{title}}</h2>
  <button id="close-button" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" id="confirm-modal">
  <p class="mb-0 nl2br">{{message}}</p><br>
  <ul *ngFor="let field of invalidData">
    <li style="color: red">{{field}}</li>
  </ul>
  </div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-default"
    (click)="modal.dismiss('cancel')" i18n>OK</button>
</div>
