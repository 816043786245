import { EntityMapper } from "./entity-mapper.model";

/**
 * Suivi des activités importantes de easy data
 */
export class SuiviActivity extends EntityMapper {
  /**
   * ID de l'activité
   */
  id: number = null;

  /**
   * Metadata concerné
   */
  metadataUuid: string = "";

  /**
   * action de l'activité
   */
  value: string = "";

  /**
   * Date de l'activité
   */
  actionDate: string = "";

  /**
   * acteur de l'activité
   */
  actor: string = "";
}
