<section id="project-edit" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="white-with-border">
      <ng-container *ngIf="isNew" i18n>Créer un dépôt</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier le dépôt</ng-container>
    </h1>
  </div>
  <div class="page-content">
    <form *ngIf="project" class="edit-form edit-fields-form custom-submit" [ngClass]="{'ng-submitted': editForm.submitted}"
      #editForm="ngForm" (ngSubmit)="save()" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="form-group row no-gutters align-items-center">
            <label for="language" class="col-4 mr-3 mb-0">
              <ng-container i18n>Langue utilisée / <em>Language</em></ng-container>
            </label>
            <div>
              <select id="language" class="form-control" name="language" [(ngModel)]="language" #languageField="ngModel" (change)="onLanguageChange()" (click)="closeLanguagePopUp()">
                <option *ngFor="let availableLanguage of availableLanguages" [value]="availableLanguage.value">{{ availableLanguage.flag }}&nbsp;{{ availableLanguage.label }}</option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && languageField.invalid">
                <p *ngIf="languageField.errors.required" i18n>Champ requis</p>
              </div>
              <div id="language-pop-up" class="pop-up-container">
                <div class="arrow-up"></div>
                <div class="pop-up-content">
                  <div class="pop-up-header">
                    <div><strong>Choix de la langue</strong></div>
                    <div><button class="pop-up-close-button" (click)="closeLanguagePopUp($event)">&times;</button></div>
                  </div>
                  <div class="pop-up-body">
                    Avant de créer la métadonnée, veuillez renseigner la langue utilisée.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6">
          <h2>Informations générales / <em>General informations</em></h2>
          <div class="form-group row no-gutters align-items-center">
            <label for="project-name" class="col-4 mr-3 mb-0">
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Le titre doit être court et explicite" placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The title should be short and explicit" placement="top" container="body"></em>
              <ng-container i18n>Titre / <em>Title</em> *</ng-container>
            </label>
            <div class="col">
              <input type="text" id="project-name" class="form-control" name="project-name" [(ngModel)]="project.name"
                #nameField="ngModel">
              <div class="alert alert-danger" *ngIf="formSubmitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="project-description" class="col-4 mr-3 mb-0 mt-1" i18n>
              <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Le résumé décrit les objectifs et le contenu du dépôt" placement="top" container="body"></em>
              <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="The Abstract describes the objectives and content of the repository" placement="top" container="body"></em>
              Résumé / <em>Abstract</em> *
            </label>
            <div class="col">
              <textarea name="project-description" class="form-control" id="project-description"
                [(ngModel)]="project.description" #descField="ngModel"></textarea>
              <div class="alert alert-danger" *ngIf="formSubmitted && descField.invalid">
                <p *ngIf="descField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group column no-gutters align-items-center">
            <div class="row no-gutters align-items-start">
              <label for="project-data-pole" class="col-4 mr-3 mb-0">
                <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="Il est possible de choisir plusieurs pôles de données au sein d'une liste prédéfinie venant d'un registre"
                  placement="top" container="body"></em>
                <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="It is possible to choose several data centers from a predefined list coming from a registry"
                  placement="top" container="body"></em>
                <ng-container i18n>IR/Pôles de données / <em>RI/Data poles</em> *</ng-container><br/>
                <a href="{{dataPolesRegistryUrl}}" target="_blank">Registre des pôles de données</a>
              </label>
              <div class="col">
                <p-autoComplete id="project-data-poles-autocomplete" [(ngModel)]="dataPoles" #dataPolesAutocomplete #dataPolesField="ngModel" [multiple]="true" inputId="project-data-poles"
                  (onFocus)="autocompleteFocus(dataPolesAutocomplete,'dataPoles')" (onSelect)="autocompleteSelected(dataPolesAutocomplete,'dataPoles')" emptyMessage="Aucun pôle de données trouvé"
                  name="project-data-pole" [suggestions]="dataPolesSuggestions" field="label" styleClass="form-control w-100 dropdown" [forceSelection]="false"
                  (completeMethod)="formListAutocomplete($event, 'dataPoles')" [ngModelOptions]="{standalone: true}" [dropdown]="true" (ngModelChange)="updateDataPole()">
                </p-autoComplete>
                <div class="alert alert-danger" *ngIf="formSubmitted && dataPolesField.invalid">
                  <p *ngIf="dataPolesField.errors.required" i18n>Champ requis</p>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="dataPoles!=null && dataPoles.length>0" class="row no-gutters">
              <label>Pôles de données sélectionnés :</label><br/>
              <div>
                <ul class="vertical-list">
                  <li *ngFor="let pole of dataPoles; let isLast=last"><a href="{{pole.id}}" target="_blank">{{pole.label}}</a></li>
                </ul>
              </div>
            </div> -->
          </div>
          <div class="form-group column no-gutters align-items-center">
            <div class="row no-gutters align-items-start">
              <label for="project-thematics" class="col-4 mr-3 mb-0 mt-2">
                <ng-container i18n>Thématiques / <em>Thematics</em> *</ng-container><br/>
                <a href="{{thematicsRegistryUrl}}" target="_blank">Registre des thématiques</a>
              </label>
              <div class="col">
                <p-autoComplete id="project-thematics-autocomplete" [(ngModel)]="project.thematics" #thematicsAutocomplete #thematicsField="ngModel" [multiple]="true" inputId="project-thematics"
                  (onFocus)="autocompleteFocus(thematicsAutocomplete,'thematics')" emptyMessage="Aucune thématique trouvée" [dropdown]="true"
                  name="project-thematics" [suggestions]="thematicsSuggestions" field="label" styleClass="form-control w-100 dropdown"
                  (completeMethod)="formListAutocomplete($event, 'thematics')" [ngModelOptions]="{standalone: true}" forceSelection="true">
                </p-autoComplete>
                <div class="alert alert-danger" *ngIf="formSubmitted && thematicsField.invalid">
                  <p *ngIf="thematicsField.errors.required" i18n>Merci de choisir les thématiques parmi la liste proposée</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group column no-gutters align-items-center">
            <div class="row no-gutters align-items-start">
              <label for="project-keywords" class="col-4 mr-3 mb-0">
                  <ng-container i18n>Mots-clés / <em>Keywords</em> *</ng-container><br/>
                  <a href="{{keywordsRegistryUrl}}" target="_blank">Registre des mots-clés</a>
              </label>
              <div class="col">
                <p-autoComplete id="project-keywords-autocomplete" [(ngModel)]="keywords" #keywordsAutocomplete #keywordsField="ngModel" [multiple]="true" inputId="project-keywords"
                  emptyMessage="Aucun mot-clé trouvé" (minQueryLength)="3" [dropdown]="true"
                  name="project-keywords" [suggestions]="keywordsSuggestions" field="label" styleClass="form-control w-100 dropdown" (onFocus)="autocompleteFocus(keywordsAutocomplete,'keywords')"
                  (completeMethod)="formListAutocomplete($event, 'keywords')" [ngModelOptions]="{standalone: true}" forceSelection="true">
                </p-autoComplete>
                <div class="alert alert-danger" *ngIf="formSubmitted && keywordsField.invalid">
                  <p *ngIf="keywordsField.errors.required" i18n>Merci de choisir les mots-clés parmi la liste proposée</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-6">
          <h2>Informations spatio-temporelles / <em>Spatio-temporal informations</em></h2>
          <fieldset class="form-group">
            <legend i18n>Emprises / <em>Extents</em></legend>
            <div class="form-group row no-gutters align-items-start">
              <label for="project-extent" class="col-4 mb-0 mt-1" i18n>Emprise / <em>Extent</em></label>
              <div class="col">
                <described-extent name="project-extent" id="project-extent" [(ngModel)]="project.extents" #spaceExtentField="ngModel" #extentChildComponent locale="{{locale}}"></described-extent>
              </div>
            </div>
            <fieldset *ngFor="let extent of project.extents; let index = index" class="form-group with-close-btn">
              <legend>
                <ng-container *ngIf="extent!=null && extent.description==null || extent.description==''">Emprise {{index+1}}</ng-container>
                <ng-container *ngIf="extent!=null && extent.description!=null">{{extent.description.label}}</ng-container>
              </legend>
              <button type="button" class="btn btn-close" (click)="removeExtent(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <div class="description-column col-4 mr-3 mb-0">
                  <label for="'project-extent-'+index+'-description'" i18n>Description / <em>Description</em></label>
                  <div class="row description-type-row">
                    <div class="description-type-elt">
                      <input type="radio" id="place-description-{{index}}" name="description-type-{{index}}" value="place" (change)="updateExtentsDescriptionTypes('place',index)"/>
                      <label for="place-description-{{index}}">
                        <ng-container *ngIf="locale=='fr'">Lieu</ng-container>
                        <ng-container *ngIf="locale=='en'">Place</ng-container>
                      </label>
                    </div>
                    <div class="description-type-elt">
                      <input type="radio" id="country-description-{{index}}" name="description-type-{{index}}" value="country" (change)="updateExtentsDescriptionTypes('country',index)"/>
                      <label for="country-description-{{index}}">
                        <ng-container *ngIf="locale=='fr'">Pays</ng-container>
                        <ng-container *ngIf="locale=='en'">Country</ng-container>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p-autoComplete [(ngModel)]="extent.description" id="project-extent-description" [multiple]="false" inputId="'project-extent-'+index+'-description'"
                    name="project-extent-description" [suggestions]="geonames" field="label" (completeMethod)="searchGeonames($event,index)" (onSelect)="geonamesItemSelected($event,index)"
                    styleClass="w-100" inputStyleClass="form-control" [ngModelOptions]="{standalone: true}" forceSelection="true">
                  </p-autoComplete>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'project-west-bound-longitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La longitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Longitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Longitude ouest / <em>West bound longitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'project-west-bound-longitude-'+index" class="form-control" [name]="'project-west-bound-longitude-'+index"
                  [(ngModel)]="extent.westBoundLongitude" #westBoundLongitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(project.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && westBoundLongitudeField.invalid">
                    <p *ngIf="westBoundLongitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'project-east-bound-longitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La longitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Longitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Longitude est / <em>East bound longitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'project-east-bound-longitude-'+index" class="form-control" [name]="'project-east-bound-longitude-'+index"
                  [(ngModel)]="extent.eastBoundLongitude" #eastBoundLongitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(project.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && eastBoundLongitudeField.invalid">
                    <p *ngIf="eastBoundLongitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'project-north-bound-latitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La latitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Latitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Latitude nord / <em>North bound latitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'project-north-bound-latitude-'+index" class="form-control" [name]="'project-north-bound-latitude-'+index"
                  [(ngModel)]="extent.northBoundLatitude" #northBoundLatitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(project.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && northBoundLatitudeField.invalid">
                    <p *ngIf="northBoundLatitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label for="'project-south-bound-latitude-'+index" class="col-4 mr-3 mb-0">
                  <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="La latitude doit être comprise entre -180 et 180 degrés."
                    placement="top" container="body"></em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Latitude must be included between -180 et 180 degrees."
                    placement="top" container="body"></em>
                  <ng-container i18n>Latitude sud / <em>South bound latitude</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="number" min="-180" max="180" [id]="'project-south-bound-latitude-'+index" class="form-control" [name]="'project-south-bound-latitude-'+index"
                  [(ngModel)]="extent.southBoundLatitude" #southBoundLatitudeField="ngModel" (change)="extentChildComponent.updateBBoxes(project.extents)">
                  <div class="alert alert-danger" *ngIf="formSubmitted && southBoundLatitudeField.invalid">
                    <p *ngIf="southBoundLatitudeField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
            </fieldset>
            <button type="button" class="btn btn-add" (click)="addExtent()">
              <em class="fa fa-plus mr-2"></em>
              <ng-container i18n *ngIf="locale=='fr'">Ajouter une emprise</ng-container>
              <ng-container i18n *ngIf="locale=='en'">Add extent</ng-container>
            </button>
          </fieldset>
          <fieldset class="form-group">
            <legend i18n>Étendue temporelle / <em>Time extent</em></legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="project-time-extent-start" class="col-4 mb-0">
                <em *ngIf="locale=='fr'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="La date de début doit suivre le format {{localeCalendarFormats[locale].label}} et être inférieure ou égale à la date de fin de l'étendue temporelle."
                  placement="top" container="body"></em>
                <em *ngIf="locale=='en'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="Beginning date must follow the format {{localeCalendarFormats[locale].label}} and be less than or equal to temporal extent end."
                  placement="top" container="body"></em>
                <ng-container i18n>Début / <em>Beginning</em></ng-container>
              </label>
              <div class="col">
                <p-calendar id="project-time-extent-start" name="project-time-extent-start"
                  [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                  styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                  [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentStartField="ngModel"
                  [(ngModel)]="project.temporalExtentStart" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                  (onBlur)="checkTemporalExtentDates(timeExtentStartField, 'start', false)" (onSelect)="checkTemporalExtentDates(timeExtentStartField, 'start', true)"
                  [keepInvalid]="true">
                </p-calendar>
                <div class="alert alert-danger" *ngIf="!formSubmitted && timeExtentStartField.invalid">
                  <p *ngIf="timeExtentStartField.control.errors?.required || timeExtentStartField.control.errors?.missing" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.control.errors?.format" i18n>La date de début doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.control.errors?.rangeToday" i18n>La date de début doit être inférieure ou égale à la date du jour</p>
                  <p *ngIf="timeExtentStartField.control.errors?.rangeEnd" i18n>La date de début doit être inférieure ou égale à la date de fin</p>
                </div>
                <div class="alert alert-danger" *ngIf="formSubmitted && timeExtentStartField.invalid">
                  <p *ngIf="timeExtentStartField.errors?.required" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.errors?.bothDates" i18n>Veuillez fournir les 2 dates ou aucune</p>
                  <p *ngIf="timeExtentStartField.errors?.format" i18n>La date de début doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentStartField.errors?.rangeToday" i18n>La date de début doit être inférieure ou égale à la date du jour</p>
                  <p *ngIf="timeExtentStartField.errors?.rangeEnd" i18n>La date de début doit être inférieure ou égale à la date de fin</p>
                </div>
              </div>
            </div>
            <div class="row no-gutters align-items-center">
              <label for="project-time-extent-end" class="col-4 mb-0">
                <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="Le format de la date de fin d'étendue temporelle doit respecter {{localeCalendarFormats[locale].label}}. La date de fin doit être inférieure ou égale à la date du jour."
                  placement="top" container="body"></em>
                <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                  ngbTooltip="Ending date of time extent must follow the format {{localeCalendarFormats[locale].label}}. It must be less than or equal to today."
                  placement="top" container="body"></em>
                <ng-container i18n>Fin / <em>Ending</em></ng-container>
              </label>
              <div class="col">
                <p-calendar id="project-time-extent-end" name="project-time-extent-end"
                  [showOnFocus]="false" [yearNavigator]="true" yearRange="1900:2100" dataType="string"
                  styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                  [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentEndField="ngModel"
                  [(ngModel)]="project.temporalExtentEnd" [firstDayOfWeek]="localeCalendarConfig[locale].firstDayOfWeek" [showIcon]="true"
                  (onBlur)="checkTemporalExtentDates(timeExtentEndField, 'end', false)" (onSelect)="checkTemporalExtentDates(timeExtentEndField, 'end', true)"
                  [keepInvalid]="true">
                </p-calendar>
                <div class="alert alert-danger" *ngIf="!formSubmitted && timeExtentEndField.invalid">
                  <p *ngIf="timeExtentEndField.control.errors?.required" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.control.errors?.format" i18n>La date de fin doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.control.errors?.rangeToday" i18n>La date de fin doit être inférieure ou égale à la date du jour</p>
                </div>
                <div class="alert alert-danger" *ngIf="formSubmitted && timeExtentEndField.invalid">
                  <p *ngIf="timeExtentEndField.errors?.required" i18n>Champ requis au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.errors?.bothDates" i18n>Veuillez fournir les 2 dates ou aucune</p>
                  <p *ngIf="timeExtentEndField.errors?.format" i18n>La date de fin doit être saisie au format {{localeCalendarFormats[locale].label}}</p>
                  <p *ngIf="timeExtentEndField.errors?.rangeToday" i18n>La date de fin doit être inférieure ou égale à la date du jour</p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

      </div>

      <hr class="form-separator">

      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>
          <em *ngIf="language=='fre'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Liste des contacts associés à la ressource décrite par la fiche de métadonnées. Renseigner à minima un contact dont le rôle est point de contact"
            placement="top" container="body"></em>
          <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="List of contacts associated with the resource described by the metadata record. Enter at least one contact whose role is point of contact"
            placement="top" container="body"></em>
          Contacts / <em>Contacts</em>
        </h2>
        <button type="button" class="btn btn-add ml-5" (click)="addContact()">
          <em class="fa fa-plus mr-2"></em>
          <ng-container i18n *ngIf="locale=='fr'">Ajouter un contact</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Add contact</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let contact of project.contacts; let index = index">
          <div class="col-12 col-xl-6">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Contact</legend>
              <button type="button" *ngIf="index >= 1"
                class="btn btn-close" (click)="removeContact(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-lastname-' + index" class="col-4 mb-0">
                  <ng-container i18n>Nom / <em>Last name</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-lastname-' + index" class="form-control"
                    [name]="'contact-lastname-' + index" [(ngModel)]="contact.individualLastName" #contactLastNameField="ngModel"
                    pattern="^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'\-\s]+$">
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactLastNameField.invalid">
                    <p *ngIf="contactLastNameField.errors.required" i18n>Champ requis</p>
                    <p *ngIf="contactLastNameField.errors?.pattern">Caractères autorisés : lettres, tirets et apostrophes.</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-firstname-' + index" class="col-4 mb-0">
                  <ng-container i18n>Prénom / <em>First name</em></ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-firstname-' + index" class="form-control"
                    [name]="'contact-firstname-' + index" [(ngModel)]="contact.individualFirstName" #contactFirstNameField="ngModel"
                    pattern="^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'\-\s]+$">
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactFirstNameField.invalid">
                    <p *ngIf="contactFirstNameField.errors.required" i18n>Champ requis</p>
                    <p *ngIf="contactFirstNameField.errors?.pattern">Caractères autorisés : lettres, tirets et apostrophes.</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-email-' + index" class="col-4 mb-0">
                  <ng-container i18n>Email / <em>Email</em> *</ng-container>
                </label>
                <div class="col">
                  <input type="email" [id]="'contact-email-' + index" class="form-control"
                    [name]="'contact-email-' + index" [(ngModel)]="contact.email" #contactEmailField="ngModel"
                    pattern="^[a-zA-Z0-9]+(?:[\._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[\._-][a-zA-Z0-9]+)+$">
                    <div class="alert alert-danger" *ngIf="formSubmitted && contactEmailField.invalid">
                      <p *ngIf="contactEmailField.errors.required" i18n>Champ requis</p>
                      <p *ngIf="contactEmailField.errors?.pattern" i18n>Veuillez saisir une adresse mail valide</p>
                    </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-organisation-' + index" class="col-4 mb-0">
                  <em *ngIf="language=='fre'"  class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="L'organisation est recherchée dans le registre {{tooltipRorSite}}, si l'organisme n'est pas retrouvé, indiquer l'ID ROR correspondant."
                      placement="top" container="body">
                  </em>
                  <em *ngIf="language=='eng'" class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="The organization is searched in the register {{tooltipRorSite}}, if the organism is not found, indicate the corresponding ROR ID."
                      placement="top" container="body">
                  </em>
                  <ng-container i18n>Organisation / <em>Organization</em> *</ng-container>
                </label>
                <div class="col">
                  <p-autoComplete inputId="'contact-organisation-' + index" [id]="'contact-organisation-' + index" name="'contact-organisation-' + index"
                    (minQueryLength)="3" [suggestions]="organizationResults" field="name" (completeMethod)="searchOrganization($event, contact)"
                    (onClear)="clearOrganization(contact)" styleClass="w-100" inputStyleClass="form-control" forceSelection="true"
                    [(ngModel)]="contact.organisation" #contactOrganisationField="ngModel" [ngModelOptions]="{standalone: true}">
                  </p-autoComplete>
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactOrganisationField.invalid">
                    <p *ngIf="contactOrganisationField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters align-items-center">
                <label [for]="'contact-role-' + index" class="col-4 mb-0">
                  <em *ngIf="language=='fre'"  class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="Les définitions des rôles utilisés dans EaSy Data sont disponibles sur la page suivante: {{ siteStandards }}"
                      placement="top" container="body">
                  </em>
                  <em *ngIf="language=='eng'"  class="fa fa-question-circle" i18n-ngbTooltip
                      ngbTooltip="The definitions of the roles used in EaSy Data are available on the following page: {{ siteStandards }}"
                      placement="top" container="body">
                  </em>
                  <ng-container i18n>Rôle / <em>Role</em> *</ng-container>
                </label>
                <div class="col">
                  <select [id]="'contact-role-' + index" class="form-control" [name]="'contact-role-' + index"
                    [(ngModel)]="contact.role" #contactRoleField="ngModel" (change)="checkPointsOfContacts(contact)"
                    (focus)="onFocusRole(contact)" [disabled]="index < 1">
                    <option *ngFor="let role of contactTypes; trackBy: trackByValue" [ngValue]="role.value">{{role.label}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="formSubmitted && contactRoleField.invalid">
                    <p *ngIf="contactRoleField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>
      <div class="d-flex">
        <a [routerLink]="isNew ? ['../../'] : ['../']" class="btn btn-default">
          <ng-container i18n *ngIf="locale=='fr'">Annuler</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Cancel</ng-container>
        </a>
        <button (click)="saveDraft($event)" class="btn btn-primary ml-auto" *ngIf="!!isNew || project?.status?.value == 'PROGRESS'">
          <ng-container i18n *ngIf="locale=='fr'">Sauvegarder</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Save</ng-container>
        </button>
        <button (click)="saveAndAskPublication($event)" [ngClass]="!!isNew || project?.status?.value == 'PROGRESS' ? 'btn btn-primary ml-3' : 'btn btn-primary ml-auto'" *ngIf="!_metadataService.canModerate(project) || ((isNew || (!!project && !!project.status && project.status.value == 'PROGRESS')) && _metadataService.canModerate(project))">
          <ng-container i18n *ngIf="locale=='fr'">Publier</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Publish</ng-container>
        </button>
        <button (click)="saveAndAskPublication($event)" [ngClass]="!!isNew || project?.status?.value == 'PROGRESS' ? 'btn btn-primary ml-3' : 'btn btn-primary ml-auto'" *ngIf="!isNew && !!project && !!project.status && (project.status.value == 'PENDING' || project.status.value == 'REFUSED') && _metadataService.canModerate(project)">
          <ng-container i18n *ngIf="locale=='fr'">Sauvegarder les modifications</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Save changes</ng-container>
        </button>
        <button class="btn btn-success ml-3" (click)="acceptPublication($event)" *ngIf="!isNew && !!project && !!project.status && (project.status.value == 'PENDING' || project.status.value == 'REFUSED') && _metadataService.canModerate(project)">
          <em class="fas fa-check-circle"></em>
          <ng-container i18n *ngIf="locale=='fr'">Accepter la publication</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Accept publication</ng-container>
        </button>
        <button class="btn btn-danger normal-padding ml-3" (click)="refusePublication($event)" *ngIf="!isNew && !!project && !!project.status && project.status.value == 'PENDING' && _metadataService.canModerate(project)">
          <em class="fas fa-times-circle"></em>
          <ng-container i18n *ngIf="locale=='fr'">Refuser la publication</ng-container>
          <ng-container i18n *ngIf="locale=='en'">Refuse publication</ng-container>
        </button>
      </div>
    </form>
  </div>
</section>
