<div class="destination-type">
  <div class="col-12">
    <div class="form-group">
      <input type="radio" id="place-description" name="destination-type" value="place" (change)="updateExtentDestinationType('place')" />
      <label for="place-description">
        <ng-container *ngIf="locale=='fr'">Lieu</ng-container>
        <ng-container *ngIf="locale=='en'">Place</ng-container>
      </label>
      <input type="radio" id="country-description" name="destination-type" value="country" (change)="updateExtentDestinationType('country')" />
      <label for="country-description">
        <ng-container *ngIf="locale=='fr'">Pays</ng-container>
        <ng-container *ngIf="locale=='en'">Country</ng-container>
      </label>
    </div>
  </div>
</div>
<div class="autocomplete ol-control">
  <div>
    <button type="button" class="btn btn-icon search-btn" (click)="updateSearchList()"> <em class="fa fa-search"></em> </button>
    <input type="text" [(ngModel)]="searchText" class="form-control" i18n-placeholder placeholder="Destination" (ngModelChange)="updateSearchList()" />
    <button *ngIf="searchText" type="button" class="btn btn-icon reset-btn" (click)="resetSearch()"> &times; </button>
    <div class="search-list" *ngIf="searchResults.length > 0">
      <ul>
        <li *ngFor="let destination of searchResults">
          <a (click)="goToDestination(destination)">{{destination.label}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
