<section id="datas" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="mr-auto white-with-border" i18n>Jeu de données publiés</h1>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter white-with-border" *ngIf="totalResults === 0" i18n>Aucun jeu de données</p>
          <p class="list-counter white-with-border" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} jeux de données</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2 white-with-border" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="datas" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="defaultName"><span i18n>Titre</span></th>
            <th class="project" sortable-column="project.defaultName"><span i18n>Dépôt</span></th>
            <th class="creator" sortable-column="creator"><span i18n>Déposé par</span></th>
            <th class="date text-center" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
          </tr>
        </ng-template>
        <ng-template body-content let-data>
          <tr>
            <td>
              <a [routerLink]="['./metadata', data.id]">
                {{displayName(data)}}
              </a>
            </td>
            <td>
              <a *ngIf="data.project" class="neutral-link"
                [routerLink]="['/public/projects/metadata', data.projectId]">
                {{displayProjectName(data)}}
              </a>
              <span *ngIf="!data.project">Aucun dépôt associé</span>
            </td>
            <td>{{ data.creator }}</td>
            <td class="text-center">{{data.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
