<section id="projects" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 class="white-with-border" i18n>Dépôts publiés</h1>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter white-with-border" *ngIf="totalResults === 0" i18n>Aucun dépôt</p>
          <p class="list-counter white-with-border" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} dépôts</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2 white-with-border" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="projects" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="defaultName"><span i18n>Titre</span></th>
            <th class="owner" sortable-column="ownerEmail"><span i18n>Contact</span></th>
            <th class="creator" sortable-column="creator"><span i18n>Déposé par</span></th>
            <th class="last-update text-center" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
          </tr>
        </ng-template>
        <ng-template body-content let-project>
          <tr>
            <td>
              <a [routerLink]="['./metadata', project.id]">{{project.defaultName}}</a>
            </td>
            <td>{{project.ownerEmail}}<ng-container *ngIf="project.ownerOrganisation"> ({{project.ownerOrganisation}})</ng-container></td>
            <td>{{project.creator}}</td>
            <td class="text-center">{{project.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
