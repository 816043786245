<label>
  <label-info [metadata]="metadata"></label-info>
  {{metadata.label}}
</label>
<div class="bbox-container">
  <div class="row form-group">
    <div class="col">
      <label for="coord-x" i18n>X</label>
      <input id="coord-x" type="number" name="coord-x" [(ngModel)]="value.x" #coordX="ngModel" (ngModelChange)="onChange()" class="form-control">
      <div class="alert alert-danger" *ngIf="coordX.invalid">
        <p *ngIf="coordX.errors.required" i18n>Champ requis</p>
        <p *ngIf="coordX.errors.invalidCoord" i18n>Coordonnée incomplète</p>
      </div>
    </div>
    <div class="col">
      <label for="coord-y" i18n>Y</label>
      <input id="coord-y" type="number" name="coord-y" [(ngModel)]="value.y" #coordY="ngModel" (ngModelChange)="onChange()" class="form-control">
      <div class="alert alert-danger" *ngIf="coordY.invalid">
        <p *ngIf="coordY.errors.required" i18n>Champ requis</p>
        <p *ngIf="coordY.errors.invalidCoord" i18n>Coordonnée incomplète</p>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-default btn-block" (click)="openMap()" [disabled]="isReadOnly || metadata.readOnly">
    <em class="fa fa-map-marker-alt"></em>
    <ng-container>Sélectionner sur la carte</ng-container>
  </button>
</div>
