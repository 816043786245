import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, LoaderService } from "src/app/services";
import { Subscription } from "rxjs";
import { Group } from "src/app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ConfirmModalComponent } from "src/app/components/modals";

@Component({
  templateUrl: './groups.component.html'
})
export class GroupsAdminComponent implements OnInit, OnDestroy {
  /**
   * Liste complète des groupes
   */
  public groups: Group[] = [];

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _userService: UserService,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.groups$.subscribe(groups => this._initGroups(groups)));
    this._loader.show();
    this._userService.getGroups();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Demande confirmation et supprime le groupe
   * @param group - groupe à supprimer
   */
  public deleteGroup(group: Group) {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un groupe`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce groupe : ${group.name} ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._userService.deleteGroup(group)
        .subscribe(() => {
          this._toastr.success($localize`Le groupe ${group.name} a été supprimé avec succès`);
          this._userService.getGroups();
        });
    }, () => null);

  }

  /**
   * Initialise les groupes reçus
   * @param groups - groupes reçus du serveur
   */
  private _initGroups(groups: Group[]) {
    this.groups = groups;
    this._loader.hide();
  }
}