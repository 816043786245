<section id="project-detail" class="d-flex flex-column h-100" *ngIf="project">

  <div class="d-flex align-items-start page-language-choice">
    <div class="form-group row no-gutters align-items-center">
      <label for="language" class="mr-3 mb-0 white-with-border">
        <ng-container i18n>Langue utilisée</ng-container>
      </label>
      <div class="col">
        <select id="language" class="form-control" name="language" [(ngModel)]="language" (change)="onLanguageChange()">
          <option *ngFor="let availableLanguage of availableLanguages" [value]="availableLanguage.value">{{ availableLanguage.flag }}&nbsp;{{ availableLanguage.label }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="page-header d-flex align-items-start mb-3">
    <div class="w-100 mr-5">
      <h1 class="white-with-border">
        <em *ngIf="isOwner && project.individualPermissions.length === 1 && project.groupPermissions.length === 0"
            i18n-ngbTooltip ngbTooltip="Privée : ce dépôt n'est visible que par moi" placement="top"
            class="fa fa-lock white-with-border"></em>
        <em *ngIf="!isOwner || project.individualPermissions.length > 1 || project.groupPermissions.length > 0"
            i18n-ngbTooltip ngbTooltip="Partagée : ce dépôt est ouvert à plusieurs utilisateurs ou groupes" placement="top"
            class="fa fa-user-friends white-with-border"></em>
        {{displayName()}}
        <br/>
        <button *ngIf="tooLongTitle && showNameWithEllipsis" (click)="updateNameDisplay($event)" class="show-more-or-less-btn">Voir plus</button>
        <button *ngIf="tooLongTitle && !showNameWithEllipsis" (click)="updateNameDisplay($event)" class="show-more-or-less-btn">Voir moins</button>
      </h1>
      <p class="w-50 description mb-3 white-with-border" *ngIf="project.owners && project.owners.length > 0">
        <strong i18n>Propriétaires :</strong>&nbsp;
        <ng-container *ngFor="let user of project.owners; let i = index;">
          <ng-container *ngIf="i > 0">, </ng-container>{{user}}
        </ng-container>
      </p>
    </div>
  </div>

  <div class="d-flex align-items-start page-citation-choice">
    <div class="form-group row no-gutters align-items-center">
      <div class="col-12 citation-nav-bar">
        <label class="mr-3 mb-0 white-with-border">
          <ng-container i18n>Télécharger les metadonnées</ng-container>
        </label>
        <span class="btn badge badge-primary" (click)="downloadMetadata('json')" i18n>JSON</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('pdf')" i18n>PDF</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('xml')" i18n>XML</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('datacite')" i18n>DATACITE</span>
        <span class="btn badge badge-primary" (click)="downloadMetadata('zip')" i18n>ZIP</span>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-start options-buttons-row">
    <a [routerLink]="['./edit']" [queryParams]="{ lang: language }" class="btn btn-default ml-3"
      *ngIf="(isEditor || _metadataService.canModerate(project)) && !!project && !!project.status && project.status.value != 'ACCEPTED'">
      <em class="fa fa-edit"></em>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <button type="button" class="btn btn-default ml-3"
      *ngIf="isAdmin || isOwner" (click)="openEditRightsModal()">
      <em class="fa fa-lock"></em>
      <ng-container i18n>Droits d'accès</ng-container>
    </button>
    <button class="btn btn-danger ml-3"
      (click)="deleteProject()" *ngIf="isOwner && !project.published">
      <em class="fa fa-trash-alt"></em>
      <ng-container i18n>Supprimer</ng-container>
    </button>
  </div>

  <div class="d-flex align-items-start publication-asked" *ngIf="!!project && !project.published && !!project.status && project.status.value == 'PENDING' && _metadataService.canModerate(project)">
    <div class="col">
      <h2 class="white-with-border">Publication demandée</h2>
      <div>
        <a [routerLink]="['./edit']" [queryParams]="{ lang: language }" class="btn btn-default">Vérifier le contenu de la fiche</a>
      </div>
    </div>
  </div>

  <div class="page-header-and-content">
    <div class="informations-page-content page-content d-flex flex-column">
      <div class="informations-subcontent subcontent">
        <nav>
          <div class=" h-100 nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link" [ngClass]="{ 'active': activeTab === 'info-tab' }" (click)="setActiveTab('info-tab')" i18n-title>Aperçu</a>
            <a class="nav-item nav-link" [ngClass]="{ 'active': activeTab === 'data-tab' }" (click)="setActiveTab('data-tab')" i18n-title>Données</a>
            <a class="nav-item nav-link" [ngClass]="{ 'active': activeTab === 'link-tab' }" (click)="setActiveTab('link-tab')" i18n-title>Ressources</a>
          </div>
        </nav>
        <div class="tab-content">
          <div class="tab-pane" [ngClass]="{ 'active': activeTab === 'info-tab' }">
              <div class="p-3 d-flex flex-column h-100 meta-detail">
                <dl class="mb-0 row">
                  <div class="col-12 col-xl-8">
                    <h2 i18n>Informations du dépôt</h2>
                    <div class="row">
                      <div class="col-6">
                        <div class="mb-2">
                          <dt class="d-inline" i18n>Statut / <em>Status</em> :</dt>&nbsp;
                          <dd class="d-inline" i18n>
                            <ng-container *ngIf="project?.status?.value=='PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
                            <ng-container *ngIf="project?.status?.value=='PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
                            <ng-container *ngIf="project?.status?.value=='ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
                            <ng-container *ngIf="project?.status?.value=='REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
                          </dd>
                        </div>
                        <div class="mb-2">
                          <dt class="d-inline" i18n>Mots-clés / <em>Keywords</em> :</dt>&nbsp;
                          <dd class="d-inline">
                            <ng-container *ngIf="project.freeKeywords!=null && project.freeKeywords.length>0">
                              <ul class="vertical-list">
                                <li *ngFor="let keyword of project.freeKeywords"><a href="{{keyword.id}}" target="_blank">{{keyword.label}}</a></li>
                              </ul>
                            </ng-container>
                            <ng-container *ngIf="project.freeKeywords==null || project.freeKeywords.length==0">Aucun mot-clé renseigné.</ng-container>
                          </dd>
                        </div>
                        <div class="mb-2">
                          <dt class="d-inline" i18n>Thématiques / <em>Thematics</em> :</dt>&nbsp;
                          <dd class="d-inline">
                            <ng-container *ngIf="project.thematics!=null && project.thematics.length>0">
                              <ul class="vertical-list">
                                <li *ngFor="let thematic of project.thematics"><a href="{{thematic.id}}" target="_blank">{{thematic.label}}</a></li>
                              </ul>
                            </ng-container>
                            <ng-container *ngIf="project.thematics==null || project.thematics.length==0">Aucune thématique renseignée.</ng-container>
                          </dd>
                        </div>
                        <div class="mb-2">
                          <dt class="d-inline" i18n>IR/Pôles de données / <em>RI/Data poles</em> :</dt>&nbsp;
                          <dd class="d-inline">
                            <ng-container *ngIf="project.dataPoles!=null && project.dataPoles.length>0">
                              <ul class="vertical-list">
                                <li *ngFor="let pole of project.dataPoles"><a href="{{pole.id}}" target="_blank">{{pole.label}}</a></li>
                              </ul>
                            </ng-container>
                            <ng-container *ngIf="project.dataPoles==null || project.dataPoles.length==0">Aucun pôle de données renseigné.</ng-container>
                          </dd>
                        </div>
                        <div class="mb-2">
                          <dt class="d-inline" i18n>
                            <em class="fa fa-question-circle" i18n-ngbTooltip
                              ngbTooltip="La date de dernière modification est au format {{localeCalendarFormats[locale].label}}."
                              placement="top" container="body"></em>
                            Dernière modification / <em>Last update</em> :
                          </dt>&nbsp;
                          <dd class="d-inline">{{project.lastUpdate | date: localeCalendarFormats[locale].format}}</dd>
                        </div>
                        <div class="mb-2">
                          <dt class="d-inline" i18n>
                            <em class="fa fa-question-circle" i18n-ngbTooltip
                              ngbTooltip="Les dates de l'étendue temporelle sont au format {{localeCalendarFormats[locale].label}}."
                              placement="top" container="body"></em>
                            Étendue temporelle / <em>Temporal extent</em> :
                          </dt>&nbsp;
                          <dd class="d-inline">
                            <ng-container *ngIf="project.temporalExtentStart && project.temporalExtentEnd">Du
                              {{project.temporalExtentStart | date: localeCalendarFormats[locale].format}}
                              au
                              {{project.temporalExtentEnd | date: localeCalendarFormats[locale].format}}
                            </ng-container>
                            <ng-container *ngIf="!project.temporalExtentStart || !project.temporalExtentEnd">Non renseignée.</ng-container>
                          </dd>
                        </div>
                        <dt class="accordion-title">
                          <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                            (click)="hideDescription = !hideDescription">
                            <span i18n>Résumé / <em>Abstract</em></span>
                            <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></em>
                          </button>
                        </dt>
                        <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{project.description}}</dd>
                      </div>
                      <div class="col-6">
                        <dt class="accordion-title">
                          <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                            (click)="hideContacts = !hideContacts">
                            <span i18n>Contacts / <em>Contacts</em></span>
                            <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContacts}"></em>
                          </button>
                        </dt>
                        <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContacts}">
                          <ul class="contacts">
                            <li *ngFor="let contact of project.contacts">
                              <em class="d-block">{{contactsRolesNames[contact.role]}}</em>
                              <span *ngIf="contact.individualLastName || contact.individualFirstName || contact.organisation">{{contact.individualLastName}} {{contact.individualFirstName}}
                                <ng-container *ngIf="!!contact.organisation?.name">({{contact.organisation.name}})</ng-container>
                              </span>
                              <a *ngIf="contact.email" class="d-block" [href]="'mailto:' + contact.email">{{contact.email}}</a>
                            </li>
                          </ul>
                        </dd>
                        <dt class="accordion-title">
                          <button type="button" class="btn btn-icon accordion-btn w-100 d-flex" (click)="hideLegals = !hideLegals">
                            <span i18n>Contraintes légales / <em>Legal constraints</em></span>
                            <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLegals}"></em>
                          </button>
                        </dt>
                        <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLegals}">
                          {{project.legalConstraints.licenseId}}
                        </dd>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <hr class="my-3 d-xl-none">
                    <h2>Emprises / <em>Extents</em></h2>
                    <dt>
                      <ol class="extents-list" *ngIf="project!=null && project.extents!=null && project.extents.length!=0">
                        <ng-container *ngFor="let extent of project.extents">
                          <li *ngIf="extent.description!=null && extent.description.label!=null && extent.description.label!=''">{{extent.description.label}}</li>
                        </ng-container>
                      </ol>
                    </dt>
                    <dd>
                      <simple-map [extents]="project.extents"></simple-map>
                    </dd>
                  </div>
                </dl>
              </div>
          </div>
          <div class="tab-pane" [ngClass]="{ 'active': activeTab === 'data-tab' }">
              <div class="p-3 d-flex flex-column h-100">
                <a [routerLink]="['./datas/new']" [queryParams]="{lang : language}" *ngIf="isEditor || isOwner" class="btn btn-primary add-btn">
                  <em class="fa fa-plus"></em>
                  <ng-container i18n>Déposer un nouveau jeu de données</ng-container>
                </a>
                <div class="d-flex align-items-center">
                  <vigirisks-table-counter>
                    <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
                      <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucune donnée</p>
                      <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} données</p>
                    </ng-template>
                  </vigirisks-table-counter>
                </div>
                <div class="data-table h-100 px-3">
                  <vigirisks-fitrable-table [itemsPerPage]="10" [rows]="project.datas">
                    <ng-template head-content>
                      <tr>
                        <th class="name" sortable-column="defaultName"><span i18n>Titre</span></th>
                        <th class="extentDescription" sortable-column="extentDescription"><span i18n>Emprise</span></th>
                        <th class="date text-center" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
                        <th class="status" sortable-column="status.value" sort-direction="desc">Statut</th>
                        <th class="actions text-center">&nbsp;</th>
                      </tr>
                    </ng-template>
                    <ng-template body-content let-data>
                      <tr>
                        <td><a [routerLink]="['./datas', data.id]">{{data.defaultName}}</a></td>
                        <td>{{data.extentDescription}}</td>
                        <td class="text-center">{{data.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
                        <td>
                          <ng-container *ngIf="data?.status?.value=='PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
                          <ng-container *ngIf="data?.status?.value=='PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
                          <ng-container *ngIf="data?.status?.value=='ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
                          <ng-container *ngIf="data?.status?.value=='REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
                        </td>
                        <td class="text-center btn-icon-container" container="body" placement="bottom-right">
                          <div class="" ngbDropdown #myDrop="ngbDropdown">
                            <button type="button" id="dropdown-data-{{data.id}}" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownAnchor (focus)="myDrop.open()"></button>
                            <div ngbDropdownMenu aria-labelledby="dropdown-data-{{data.id}}">
                              <a class="dropdown-item" [routerLink]="['./datas', data.id]" i18n>Consulter</a>
                              <a *ngIf="(isEditor || isOwner || isModerator || isAdmin) && !data.published" class="dropdown-item" [routerLink]="['./datas', data.id, 'edit']" i18n>Modifier</a>
                              <a *ngIf="data.files!=null && data.files.length>0" class="dropdown-item" target="_blank" (click)="downloadZipFiles(data)">
                                Télécharger
                                <em class="fa fa-external-link-alt ml-2"></em>
                              </a>
                              <a *ngIf="(isEditor || isOwner || isModerator || isAdmin) && !data.published" class="dropdown-item" [routerLink]="['./datas', data.id, 'duplicate']" i18n>Dupliquer</a>
                              <a *ngIf="(isOwner || isModerator || isAdmin) && !data.published" class="dropdown-item item-danger" (click)="deleteMetadata(data, 'data')" i18n>Supprimer</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </vigirisks-fitrable-table>
                </div>
                <div class="d-flex">
                  <vigirisks-pagination class="ml-auto mb-0" [maxSize]="5"></vigirisks-pagination>
                </div>
              </div>
          </div>
          <div class="tab-pane" [ngClass]="{ 'active': activeTab === 'link-tab' }">
              <div class="p-3 d-flex flex-column h-100">
                <a [routerLink]="['./links/new']" [queryParams]="{lang : language}" *ngIf="isEditor || isOwner"
                  class="btn btn-primary add-btn">
                  <em class="fa fa-plus"></em>
                  <ng-container i18n>Référencer une nouvelle ressource</ng-container>
                </a>
                <div class="d-flex align-items-center">
                  <vigirisks-table-counter>
                    <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
                      <p class="list-counter karamazov" *ngIf="totalResults === 0" i18n>Aucune ressource</p>
                      <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} ressources</p>
                    </ng-template>
                  </vigirisks-table-counter>
                </div>
                <div class="data-table h-100 px-3">
                  <vigirisks-fitrable-table [itemsPerPage]="10" [rows]="project.links">
                    <ng-template head-content>
                      <tr>
                        <th class="name" sortable-column="name"><span i18n>Titre</span></th>
                        <th class="type" sortable-column="typeName"><span i18n>Type</span></th>
                        <th class="last-update" sortable-column="lastUpdate" sort-direction="desc"><span i18n>Dernière modification</span></th>
                        <th class="url" sortable-column="url"><span i18n>Url</span></th>
                        <th class="status" sortable-column="status.value" sort-direction="desc">Statut</th>
                        <th class="actions text-center">&nbsp;</th>
                      </tr>
                    </ng-template>
                    <ng-template body-content let-link>
                      <tr>
                        <td><a [routerLink]="['./links', link.id]">{{link.name}}</a></td>
                        <td>{{link.typeName}}</td>
                        <td>{{link.lastUpdate | date: localeCalendarFormats[locale].format}}</td>
                        <td class="url"><a [href]="link.url" [title]="link.url" target="_blank">{{link.url}}</a></td>
                        <td>
                          <ng-container *ngIf="link?.status?.value=='PROGRESS'"><span class="status-progress">En cours de rédaction</span></ng-container>
                          <ng-container *ngIf="link?.status?.value=='PENDING'"><span class="status-pending">Publication demandée</span></ng-container>
                          <ng-container *ngIf="link?.status?.value=='ACCEPTED'"><span class="status-accepted">Publication acceptée</span></ng-container>
                          <ng-container *ngIf="link?.status?.value=='REFUSED'"><span class="status-refused">Publication refusée</span></ng-container>
                        </td>
                        <td class="text-center btn-icon-container" container="body" placement="bottom-right">
                          <div class="" ngbDropdown #myDrop="ngbDropdown">
                            <button type="button" id="dropdown-link-{{link.id}}" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownAnchor (focus)="myDrop.open()"></button>
                            <div ngbDropdownMenu aria-labelledby="dropdown-link-{{link.id}}">
                              <a class="dropdown-item" [routerLink]="['./links', link.id]" i18n>Consulter</a>
                              <a *ngIf="isEditor" class="dropdown-item" [routerLink]="['./links', link.id, 'edit']" i18n>Modifier</a>
                              <a *ngIf="isEditor" class="dropdown-item item-danger" (click)="deleteMetadata(link, 'link')" i18n>Supprimer</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </vigirisks-fitrable-table>
                </div>
                <div class="d-flex">
                  <vigirisks-pagination class="ml-auto mb-0" [maxSize]="5"></vigirisks-pagination>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="informations-page-content page-content d-flex flex-column">
      <div class="informations-subcontent subcontent">
          <div class="p-3 d-flex flex-column h-100 meta-detail">
              <dl class="mb-0 row">
                <div class="col-12 col-xl-12">
                  <h2 i18n>Informations Metadonnées</h2>
                  <div class="row">
                    <div class="col-4">
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideIdentifiant = !hideIdentifiant">
                          <span i18n>Identifiant / <em>Identifiant</em></span>
                          <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideIdentifiant}"></em>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideIdentifiant}">
                        <a class="d-block" [href]="project.uriMetadata">{{project.uriMetadata}}</a>
                      </dd>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Date de création / <em>Creation date</em> :</dt>&nbsp;
                        <dd class="d-inline">{{project.metadataCreationDate | date: localeCalendarFormats[locale].format}}</dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Date de mise à jour / <em>Update date</em> :</dt>&nbsp;
                        <dd class="d-inline" *ngIf="project.metadataRevisionDate">{{project.metadataRevisionDate | date: localeCalendarFormats[locale].format}}</dd>
                        <dd class="d-inline" *ngIf="!project.metadataRevisionDate">Pas encore modifiée</dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Date de publication / <em>Publication date</em> :</dt>&nbsp;
                        <dd class="d-inline" *ngIf="project.metadataPublicationDate">{{project.metadataPublicationDate | date: localeCalendarFormats[locale].format}}</dd>
                        <dd class="d-inline" *ngIf="!project.metadataPublicationDate">Pas encore publiée</dd>
                      </div>
                    </div>
                    <div class="col-4">
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideMetadataContacts = !hideMetadataContacts">
                          <span i18n>Contacts / <em>Contacts</em></span>
                          <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideMetadataContacts}"></em>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideMetadataContacts}">
                        <ul class="contacts">
                          <li *ngIf="project.dataterraContact">
                            <em class="d-block">{{contactsRolesNames[project.dataterraContact.role]}}</em>
                            <span *ngIf="project.dataterraContact.organisation.name">{{project.dataterraContact.organisation.name}}</span>
                            <a *ngIf="project.dataterraContact.email" class="d-block" [href]="'mailto:' + project.dataterraContact.email">{{project.dataterraContact.email}}</a>
                          </li>
                          <li *ngFor="let pointOfContact of pointOfContacts">
                            <em class="d-block">{{contactsRolesNames[pointOfContact.role]}}</em>
                            <span *ngIf="pointOfContact.individualLastName || pointOfContact.individualFirstName || pointOfContact.organisation.name">{{pointOfContact.individualLastName}} {{pointOfContact.individualFirstName}}
                              <ng-container *ngIf="!!pointOfContact.organisation?.name">({{pointOfContact.organisation.name}})</ng-container>
                      </span>
                            <a *ngIf="pointOfContact.email" class="d-block" [href]="'mailto:' + pointOfContact.email">{{pointOfContact.email}}</a>
                          </li>
                        </ul>
                      </dd>
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideMetadaLegals = !hideMetadaLegals">
                          <span i18n>Contraintes légales / <em>Legal constraints</em></span>
                          <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideMetadaLegals}"></em>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideMetadaLegals}">
                        {{project.metadataLegalConstraints.licenseId}}
                      </dd>
                    </div>
                    <div class="col-12 col-xl-4">
                      <hr class="my-3 d-xl-none">
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideContactsDistributor = !hideContactsDistributor">
                          <span i18n>Contacts de distribution / <em>Distributor Contacts</em></span>
                          <em class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContactsDistributor}"></em>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContactsDistributor}">
                        <ul class="contacts">
                          <em class="d-block">{{contactsRolesNames[project.distributorContact.role]}}</em>
                          <span *ngIf="project.distributorContact.organisation.name">{{project.distributorContact.organisation.name}}</span>
                          <a *ngIf="project.distributorContact.email" class="d-block" [href]="'mailto:' + project.distributorContact.email">{{project.distributorContact.email}}</a>
                        </ul>
                      </dd>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
      </div>
    </div>
  </div>
</section>
