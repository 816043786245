import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetadataService, UtilsService, LoaderService } from '../../../../services';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-public-metadata-detail',
  templateUrl: './public-metadata-detail.component.html',
  styleUrls: ['./public-metadata-detail.component.scss']
})
export class PublicMetadataDetailComponent implements OnInit {

  public metadataTypeStudy = Constants.METADATA_PROJECT_TYPE;
  public metadataTypeData = Constants.METADATA_DATA_TYPE;
  public metadataTypeLink = Constants.METADATA_LINK_TYPE;

  public type: string;
  public errorStatus: number;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _metadataService: MetadataService
  ) { }

  ngOnInit(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    this._loader.show();
    this._utils.getAllRouteParams(this._route).subscribe(params => {
      let metadataId = params[params.length - 1];
      this._metadataService.getMetadataType(metadataId).subscribe((type: string) => {
        this.type = type;
      });
    });
  }

}
