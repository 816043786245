<section id="admin-groups" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Groupes d'utilisateurs</h1>
    <a [routerLink]="['./new/edit']" class="btn btn-primary">
      <em class="fa fa-plus"></em>
      <ng-container i18n>Créer un nouveau groupe</ng-container>
    </a>
  </div>
  <div class="page-content d-flex flex-column h-100">
    <div class="data-table">
      <vigirisks-fitrable-table [rows]="groups" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Nom</span></th>
            <th class="status text-center" sortable-column="status"><span i18n>Statut</span></th>
            <th class="users text-center" sortable-column="users.length"><span i18n>Nombre d'utilisateurs</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-group>
          <tr>
            <td><a [routerLink]="['./', group.id]">{{group.name}}</a></td>
            <td class="text-center">
              <em class="mr-2 status-dot" [ngClass]="{active: group.status === 'alive'}"></em>
              <ng-container *ngIf="group.status === 'alive'" i18n>Actif</ng-container>
              <ng-container *ngIf="group.status !== 'alive'" i18n>Inactif</ng-container>
            </td>
            <td class="text-center">{{group.users.length}}</td>
            <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
              <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                <a class="dropdown-item" [routerLink]="['./', group.id]" i18n>Gérer</a>
                <a class="dropdown-item" [routerLink]="['./', group.id, 'edit']" i18n>Modifier</a>
                <a class="dropdown-item item-danger" (click)="deleteGroup(group)" i18n>Supprimer</a>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
