import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { Map, View, Feature } from 'ol';
import { Image as ImageLayer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource, ImageWMS } from 'ol/source';
import { defaults as defaultControls, MousePosition, ScaleLine } from 'ol/control';
import { DragBox } from 'ol/interaction';
import { Fill, Stroke, Style } from 'ol/style';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import { format } from 'ol/coordinate';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { extend as extendExtent } from 'ol/extent';

import { Resource, SearchData, Destination, EntityMetadata } from '../../../models';
import { ResourceService, TableFilterService, SessionService, LoaderService, UserService, CustomActionButtonControl } from '../../../services';
import { Constants } from '../../../constants';
import { Angulartics2 } from 'angulartics2';
import { FilterEvent, FilterService } from '../../../modules/filtrableTable/services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormListEvent, RegistryService } from '../../../services/registry.service';
import { SelectItem } from 'primeng/api';
import { SearchOption } from '../../../models/search-option.model';
import { FacetedSearch } from '../../../models/faceted-search.model';
import { DynamicFacetedSearchOptions } from '../../../models/dynamic-faceted-search-options.model';

@Component({
  templateUrl: './resources.component.html'
})
export class ResourcesComponent implements OnInit, OnDestroy {
  @ViewChild('timeExtentStartField') timeExtentStartField;
  @ViewChild('timeExtentEndField') timeExtentEndField;
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;

  /**
   * Liste initiale des résultats de la recherche
   */
  public resources: Resource[] = [];

  /**
   * Liste ordonnée et paginée des résultats de la recherche
   */
  public filteredResources: Resource[] = [];

  /**
   * Nombre de résultats par page
   */
  public itemsPerPage: number = 12;

  /**
   * Page de résultats actuelle
   */
  public currentPage: number = 1;

  /**
   * Est-on en train de sélectionner une emprise ?
   */
  public currentlyExtentChoose: boolean = false;

  /**
   * Liste des utilisateurs
   */
  public users: string[] = [];

  /**
   * Liste des types de données
   */
  public dataTypes = Constants.dataTypes;

  /**
   * Liste des types de liens
   */
  public linkTypes = Constants.linkTypes;

  /**
   * Liste des thématiques en dropdown
   */
  public thematicsSuggestions: SelectItem[] = [];

  /**
   * Liste des pôles de données en dropdown
   */
  public dataPolesSuggestions: SelectItem[] = [];

  /**
   * Booléen permettant d'afficher la totalité ou une partie des types de représentations spatiales
   */
  public showAllSpatialRepresentationsTypes: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des types de lien
   */
  public showAllLinkTypes: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des projets Opale
   */
  public showAllDataPoles: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des thématiques
   */
  public showAllThematics: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des mots-clés
   */
  public showAllKeywords: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des organisations
   */
  public showAllOrganizations: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des points de contact des ressources
   */
  // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
  //public showAllPointsOfContact: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des années de création
   */
  public showAllCreationYears: boolean = false;

  /**
   * Booléen permettant d'afficher la totalité ou une partie des années de publication
   */
  public showAllPublicationYears: boolean = false;

  /**
   * Date du jour, utilisée pour délimiter certains calendriers
   */
  public today:Date = new Date();

  /**
   * Nombre total de métadonnées
   */
  public totalMetadata: number = 0;

  /**
   * Objet formulaire
   */
  public searchData: SearchData = new SearchData();

  /**
   * Liste des options pour la recherche à facette
   */
  public facetedSearch: FacetedSearch = new FacetedSearch();

  /**
   * Liste des options de la recherche à facette actuellement sélectionnées, utilisées pour mettre celle-ci à jour dynamiquement.
   */
  public dynamicFacetedSearchOptions:DynamicFacetedSearchOptions = new DynamicFacetedSearchOptions();

  /**
   * Booléen renseignant si on a coché la checkbox des Dépôts
   */
  public projectChecked: boolean = false;

  /**
   * Booléen renseignant si on a coché la checkbox des Jeux de données
   */
  public dataChecked: boolean = false;

  /**
   * Booléen renseignant si on a coché la checkbox des Ressources
   */
  public linkChecked: boolean = false;

  /**
   * Afficher les résultats ?
   */
  public displayTable = false;

  /**
   * Agrandir le panel de résultats ?
   */
  public displayTableWide = false;

  /**
   * Afficher le panel de recherche ?
   */
  public displayFilters = true;

  /**
   * Liste des destinations du goto
   */
  public destinations: Destination[] = [];

  /**
   * Est-on en mode public ?
   */
  public isPublic: boolean = true;

  /**
   * Emprise de la destination actuellement choisie
   */
  public currentDestinationExtent: [number, number, number, number] = null;

  /**
   * Langue actuelle
   */
  public language: string = "fre";

  /**
   * Langue actuelle
   */
  public locale: string = "fr";

  /**
   * Configuration des langues
   */
  public localeCalendarConfig = Constants.localeCalendarConfig;

  /**
   * Expressions régulières des langues
   */
  public localeCalendarRegExps = Constants.localeCalendarRegExps;

  /**
   * Format des dates en fonction des langues
   */
  public localeCalendarFormats = Constants.localeCalendarFormats;

  /**
   * Toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Carte openlayers
   */
  public map: Map;

  private _resultsLayer: VectorLayer<any>;

  private _drawLayer: VectorLayer<any>;

  private _drawInteraction: DragBox;

  constructor(
    private _resourceService: ResourceService,
    private _tableFilterService: TableFilterService,
    public session: SessionService,
    private _userService: UserService,
    private _loader: LoaderService,
    private _tracker: Angulartics2,
    private _filterService: FilterService,
    private _registryService: RegistryService,
    private _elementRef: ElementRef,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._subs.add(this._resourceService.resources$.subscribe(resources => this._setResourcesListAndCounts(resources)));
    this._subs.add(this._userService.emails$.subscribe(users => this._setUserList(users)));
    this._subs.add(this._resourceService.externalSearch$.subscribe(searchData => this.search(searchData)));
    this._subs.add(this._resourceService.facetedSearch$.subscribe(facetedSearch => this._initFacetedSearch(facetedSearch)));
    this._subs.add(this._loader.menuWidthChange$.subscribe(() => setTimeout(() => this._redrawMap(), 300)));
    this._subs.add(this._filterService.tableFiltered$.subscribe(e => this.filterTable(e)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));

    this._registryService.getSuggestions("dataPoles", this.language, "");
    this._registryService.getSuggestions("thematics", this.language, "");
    this._resourceService.getSearchOptions(new DynamicFacetedSearchOptions());

    this.destinations = Constants.searchDestinations.map(d => new Destination().deserialize(d));

    if(!this._route.snapshot.parent.parent.routeConfig || this._route.snapshot.parent.parent.routeConfig.path !== 'public') {
      this.isPublic = false;
    }

    let sessionSearchData: SearchData;
    if(this.isPublic) {
      sessionSearchData = this.session.publicSearchData;
    } else {
      sessionSearchData = this.session.searchData;
    }

    if (sessionSearchData) {
      this.search(sessionSearchData);
    }
    this._initMap();
  }

  ngOnDestroy() {
    this._router.onSameUrlNavigation = 'ignore';
    this.resetSearch();
    this._subs.unsubscribe();
  }

  /**
   * Met à jour les données de filtre des résultats
   * @param event - Event du filtre de table
   */
  public filterTable(event: FilterEvent): void {
    this.currentPage = event.page;
    this.filteredResources = this._tableFilterService.filterTable(event, this.resources);
    this._updateResultsLayer();
  }

  /**
   * Lance la recherche
   */
  public search(searchData: SearchData = null): void {
    if (searchData) {
      this.searchData = _.cloneDeep(searchData);
    } else {
      if(this.isPublic) {
        this.session.publicSearchData = _.cloneDeep(this.searchData);
      } else {
        this.session.searchData = _.cloneDeep(this.searchData);
      }
    }
    this._loader.show();
    this._tracker.eventTrack.next({
      action: "Recherche",
      properties: {
        category: "Mot-clé: " + this.searchData.text
      }
    });
    this._resourceService.searchResources(this.searchData,this.language);
    this.toggleDisplayTable(true);
  }

  public autocompleteUsers(event: any): void {
    this._userService.searchUsers(event.query);
  }

  /**
   * Réinitialise la recherche
   */
  public resetSearch(): void {
    this.searchData = new SearchData();
    _.each(this._elementRef.nativeElement.querySelectorAll("input[type='radio']") , button => {
      button.checked = false;
    });
    _.each(this._elementRef.nativeElement.querySelectorAll("input[type='checkbox']") , checkbox => {
      checkbox.checked = false;
    });
    this.session.publicSearchData = null;
    this.session.searchData = null;
    this.resetExtent();
    this.updateTypologies(null);
  }

  public toggleDisplayTable(isDisplayed: boolean) {
    if (this._resultsLayer) {
      this._resultsLayer.setVisible(isDisplayed);
    }
    this.displayTable = isDisplayed;
  }


  /**
   * Vérifie que les valeurs de l'étendu temporelle sont présentes, au bon format et avec les bonnes valeurs.
   * @param field 
   * @returns 
   */
  public checkTemporalExtentDates(field:any,type:string,selectedInCalendar:boolean) {
    this.timeExtentStartField.control.setErrors(null);
    this.timeExtentEndField.control.setErrors(null);

    if (!selectedInCalendar) {
      if (!field || !field.control.value) return;

      // Check if value has the right format
      let formatRegExp = this.localeCalendarRegExps[this.locale];
      if (!formatRegExp.test(field.control.value)) {
        field.control.setErrors({ format: true });
        return;
      }
    }

    // Check if start < end
    let format = this.localeCalendarFormats[this.locale].formatMoment;
    let startDate:Date|null = type == "start" ? moment(field.control.value, format).toDate() : 
                                              (!!this.searchData.temporalExtentStart ? moment(this.searchData.temporalExtentStart, format).toDate() : null);
    let endDate:Date|null = type == "end" ? moment(field.control.value, format).toDate() : 
                                          (!!this.searchData.temporalExtentEnd ? moment(this.searchData.temporalExtentEnd, format).toDate() : null);
    let today = new Date();

    // Check if start <= today
    if (!!startDate && startDate.getTime() > today.getTime()) this.timeExtentStartField.control.setErrors({ rangeToday: true });

    // Check if end <= today
    if (!!endDate && endDate.getTime() > today.getTime()) this.timeExtentEndField.control.setErrors({ rangeToday: true });

    // Check if start <= end
    if (!!startDate && !!endDate && startDate.getTime() > endDate.getTime()) this.timeExtentStartField.control.setErrors({ rangeEnd: true });
  }
  

  /**
   * Définit et trie la liste des utilisateurs
   * @param users - Liste des utilisateurs
   */
  private _setUserList(users: string[]): void {
    users.sort();
    this.users = users;

    this._loader.hide();
  }

  /**
   * Met à jour les comptes des différents types de résultats
   * @param resources - Résultats de la recherche
   */
  private _setResourcesListAndCounts(resources: Resource[]): void {
    this.resources = resources;
    this.currentPage = 1;
    this.filterTable({
      page: 1,
      sortColumn: 'name',
      sortDirection: 'asc',
      collectionSize: resources.length,
      itemsPerPage: this.itemsPerPage
    });
    this._loader.hide();
    this.toggleDisplayTable(true);
  }

  // =============================================
  // ================== Carte ====================
  // =============================================

  /**
   * Zoome sur l'emprise d'un résultat
   * @param resource Objet d'origine du résultat
   */
  public goToExtent(resource: EntityMetadata): void {
    if (resource.extents.length > 0) {
      let finalExtent;
      _.each(resource.extents, extent => {
        let coordinates = [
          extent.westBoundLongitude,
          extent.northBoundLatitude,
          extent.eastBoundLongitude,
          extent.southBoundLatitude
        ];
        if (!finalExtent) {
          finalExtent = coordinates.slice();
        } else {
          extendExtent(finalExtent, coordinates);
        }
      });

      this.map.getView().fit(finalExtent, { padding: [15, 515, 15, 15] });
    }
  }

  /**
   * Active la sélection d'une emprise sur la carte
   */
  public chooseExtent(): void {
    if (this.map) {
      this.currentlyExtentChoose = true;
      this.map.addInteraction(this._drawInteraction);
    }
  }

  /**
   * Réinitialise l'emprise de recherche
   */
  public resetExtent(): void {
    this.searchData.extent = null;
    this._drawLayer.getSource().clear();
  }

  /**
   * Met en valeur une resource survolée dans le tableau des résultats
   * @param resource - Resource survolée
   * @param isHovered - A mettre en valeur ou non
   */
  public highlightResourceFeatures(resource: Resource, isHovered: boolean): void {
    resource.hovered = isHovered;
    this._resultsLayer.changed();
  }

  /**
   * Centre la carte sur la destination choisie dans le gazetteer
   * @param extent - emprise sur laquelle zoomer
   */
  public chooseDestination(extent: [number, number, number, number]): void {
    if (extent) {
      this.map.getView().fit(extent, { padding: [15, 515, 15, 15] });
      this.currentDestinationExtent = null;
    }
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   * @param type - Type de donnée à autocompléter
   */
  public formListAutocomplete(event, type: string): void {
    this._registryService.getSuggestions(type, this.language, event.query);
    //this._registryService.getLexiques(type, this.language, event.query, {});
  }

  /**
   * Affiche la liste complète des suggestions d'une liste quand un élément
   * de type p-autocomplete reçoit le focus
   * @param autocomplete Element p-autocomplete du DOM ayant reçu le focus
   * @param type type de liste de propositions
   */
  public autocompleteFocus(autocomplete,type:string) {
    switch(type) {
      case "dataPoles":
        autocomplete._suggestions = this.dataPolesSuggestions;
        break;
      case "categories":
      case "thematics":
        autocomplete._suggestions = this.thematicsSuggestions;
        break;
    }
    if(autocomplete._suggestions.length > 0) autocomplete.show();
  }


  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case "dataPoles":
        this.dataPolesSuggestions = event.datas;
        break;
      case "categories":
      case "thematics":
        this.thematicsSuggestions = event.datas;
        break;
    }
  }

  /**
   * Initialise la carte de la recherche
   */
  private _initMap(): void {
    this.map = new Map({
      target: this.mapContainer.nativeElement,
      controls: this._getControls(),
      layers: this._getInitialLayers(),
      view: new View({
        projection: 'EPSG:4326',
        center: [6.853376452701834, 46.159415444095494],
        zoom: 6
      })
    });
    this._initChooseExtentInteraction();
    this.map.on('pointermove', e => this._highlightHoveredFeaturesResources(e));

    setTimeout(() => this._redrawMap(), 100);
    setTimeout(() => this._redrawMap(), 500);
  }

  /**
   * Initialise les contrôles de la carte
   */
  private _getControls(): any {
    return defaultControls({
      zoomOptions: {
        zoomInTipLabel: 'Zoomer',
        zoomOutTipLabel: 'Dézoomer'
      },
      rotateOptions: {
        tipLabel: 'Réinitialiser la rotation'
      }
    }).extend([
      new ScaleLine(),
      new MousePosition({
        coordinateFormat: coordinate => format(coordinate, 'X:&nbsp;{x}&nbsp;Y:&nbsp;{y}', 4),
        projection: 'EPSG:4326',
        undefinedHTML: 'X:&nbsp;0&nbsp;Y:&nbsp;0'
      }),
      new CustomActionButtonControl({
        class: 'ol-zoom-extent',
        label: '<i class="fa fa-expand"></i>',
        tipLabel: $localize`Revenir à l'emprise initiale`,
        onClick: () => this.chooseDestination([-4.965820, 42.261049, 8.305664, 51.645294])
      })
    ]);
  }

  /**
   * Initialise les couches de la carte
   */
  private _getInitialLayers(): any[] {
    let layers = [];

    layers.push(new ImageLayer({
      source: new ImageWMS({
        ratio: 1,
        url: "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        params: {
          "LAYERS": "MONDE_MOD1_FR"
        }
      })
    }));

    this._resultsLayer = new VectorLayer({
      source: new VectorSource(),
      style: feat => {
        let resource = _.find(this.filteredResources, r => r.features.indexOf(feat) >= 0);
        let color = '#E36C04';
        let fill = 'rgba(255,255,255,0.3)';
        if (resource && resource.hovered) {
          color = 'blue';
          fill = 'rgba(255,255,255,0.6)';
        }

        return new Style({
          stroke: new Stroke({
            width: 2,
            color: color
          }),
          fill: new Fill({
            color: fill
          })
        });
      }
    });

    layers.push(this._resultsLayer);

    this._drawLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          width: 2,
          color: 'red'
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.3)'
        })
      })
    });

    layers.push(this._drawLayer);

    return layers;
  }

  /**
   * Initialise l'interaction de séleciton d'une emprise
   */
  private _initChooseExtentInteraction(): void {
    this._drawInteraction = new DragBox({
      condition: platformModifierKeyOnly
    });

    this._drawInteraction.on('boxend', e => this._updateChosenExtent(e.target));
  }

  /**
   * Met à jour la carte et l'emprise choisis sur la carte
   * @param feature - Feature openlayers envoyée par l'événement
   */
  private _updateChosenExtent(feature: any): void {
    let extent = feature.getGeometry().getExtent();

    this._drawLayer.getSource().clear();
    this._drawLayer.getSource().addFeature(new Feature({
      geometry: polygonFromExtent(extent)
    }));

    for (let i = 0; i < extent.length; i++) {
      extent[i] = Math.round(extent[i] * 1000000) / 1000000;
    }

    this.searchData.extent = extent;

    this.map.removeInteraction(this._drawInteraction);
    this.currentlyExtentChoose = false;
    this.displayFilters = true;
  }


  /**
   * Initialise/met à jour les options de la recherche à facette.
   * @param facetedSearch Les options de recherche à facette reçues depuis le back-end.
   */
  private _initFacetedSearch(facetedSearch:FacetedSearch) {
    this.facetedSearch = facetedSearch;
    let typologiesTmp = facetedSearch.typologies,
      fnCompare = (a:SearchOption, b:SearchOption) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

    // Sort typologies
    this.facetedSearch.typologies = [];
    this.facetedSearch.typologies.push(typologiesTmp.filter(t => t.label=="étude")[0]);
    this.facetedSearch.typologies.push(typologiesTmp.filter(t => t.label=="donnée")[0]);
    this.facetedSearch.typologies.push(typologiesTmp.filter(t => t.label=="lien")[0]);
    this.totalMetadata = 0;
    for (let i = 0; i < facetedSearch.typologies.length; i++) {
      let typologie = facetedSearch.typologies[i];
      if (!!typologie) this.totalMetadata += typologie.count;
    }

    // Sort spatial representations types
    this.facetedSearch.spatialRepresentationsTypes.sort(fnCompare);
    // Sort thematics
    this.facetedSearch.thematics.sort(fnCompare);
    // Sort keywords
    this.facetedSearch.keywords.sort(fnCompare);
    // Sort data poles
    this.facetedSearch.dataPoles.sort(fnCompare);
    // Sort organizations
    this.facetedSearch.organizations.sort(fnCompare);
    // Sort point of contacts organisations for resource
    //this.facetedSearch.pointsOfContactOrgForResource.sort(fnCompare);
    // Sort creation years
    this.facetedSearch.creationYears.sort(fnCompare);
    // Sort publication years
    this.facetedSearch.publicationYears.sort(fnCompare);

    // When the faceted search is dynamically refreshed, add the previously checked options
    setTimeout(()=>{
      let kebabCaseClasses = { typologie: "typologie", dataPole: "data-pole", thematic: "thematic",
                                keyword: "keyword", spatialRepresentationType: "spatial-representation-type", linkType: "link-type",
                                pointOfContact: "point-of-contact", creationYear: "creation-year", publicationYear: "publication-year",
                                organization: "organization" };
      for (let option of this.dynamicFacetedSearchOptions.options) {
        if (!option) continue;
        let inputClass:string = kebabCaseClasses[option.type];
        let inputElement:HTMLElement = this._elementRef.nativeElement.querySelector("input."+inputClass+"-input[value=\""+option.label+"\"]");
        if (!!inputElement) inputElement.setAttribute("checked", "true");
      }
    }, 300);    
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) un type de métadonnée que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param typologie : le type de métadonnée que l'on souhaite ajouter ou retirer.
   */
  public updateTypologies(typologie?:SearchOption) {
    if(typologie==null) {
      this.searchData.typologie="";
      this.projectChecked = false;
      this.dataChecked = false;
      this.linkChecked = false;
      // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
      // this.showAllPointsOfContact = true;
      this.searchData.dataPoles = [];
      this.searchData.spatialRepresentationType = "";
      this.searchData.linkType = "";
      this.dynamicFacetedSearchOptions.options = this._getCheckedOptions();
      this._resourceService.getSearchOptions(this.dynamicFacetedSearchOptions);
      return;
    }
    this.searchData.typologie = typologie.label;
    if(this.searchData.typologie=="étude") {
      this.projectChecked = true;
      this.dataChecked = false;
      this.linkChecked = false;
      this.searchData.spatialRepresentationType = "";
      this.searchData.linkType = "";
      // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
      // this.showAllPointsOfContact = true;
    }
    else if(this.searchData.typologie=="donnée") {
      this.projectChecked = false;
      this.dataChecked = true;
      this.linkChecked = false;
      this.searchData.dataPoles = [];
      this.searchData.linkType = "";
      // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
      // this.showAllPointsOfContact = true;
    }
    else if(this.searchData.typologie=="lien") {
      this.projectChecked = false;
      this.dataChecked = false;
      this.linkChecked = true;
      this.searchData.dataPoles = [];
      this.searchData.spatialRepresentationType = "";
      this.searchData.pointsOfContactOrgForResource = [];
      // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
      // this.showAllPointsOfContact = false;
    }

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) un type de représentation spatiale que
   * l'on vient de cocher ou décocher.
   * @param spatialRepresentationType : le type de représentation spatiale que l'on souhaite ajouter ou retirer.
   */
  public updateSpatialRepresentationType(spatialRepresentationType?:SearchOption) {
    this.searchData.spatialRepresentationType = !spatialRepresentationType ? "" : spatialRepresentationType.label;

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) un type de lien que
   * l'on vient de cocher ou décocher.
   * @param linkType : le type de lien que l'on souhaite ajouter ou retirer.
   */
  public updateLinkType(linkType?:SearchOption) {
    if (!linkType) {
      this.searchData.linkType = "";
      return;
    }

    if (this.searchData.typologie != "lien") return;

    this.searchData.linkType = linkType.label;

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) une thématique que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param thematic : la thématique que l'on souhaite ajouter ou retirer.
   */
  public updateThematics(event:any, thematic:SearchOption) {
    this.updateFieldArray(this.searchData.thematics, event.currentTarget.checked, thematic.label);

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) un mot-clé que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param keyword : le mot-clé que l'on souhaite ajouter ou retirer.
   */
  public updateKeywords(event:any, keyword:SearchOption) {
    this.updateFieldArray(this.searchData.keywords, event.currentTarget.checked, keyword.label);

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) un pôle de données que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param dataPole : le pôle de données que l'on souhaite ajouter ou retirer.
   */
  public updateDataPoles(event:any, dataPole:SearchOption) {
    let index = this.searchData.dataPoles.findIndex(d => d.label == dataPole.label);

    if (event.currentTarget.checked) {
      if (index == -1) this.searchData.dataPoles.push({ label: dataPole.label, shortLabel:dataPole.label.split(" : ")[0] });
    } else if (index > -1) this.searchData.dataPoles.splice(index, 1);

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) une organisation que
   * l'on vient de cocher ou décocher.
   * @param event l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param organization l'organisation que l'on souhaite ajouter ou retirer.
   */
  public updateOrganizations(event:any, organization:SearchOption) {
    this.updateFieldArray(this.searchData.organizations, event.currentTarget.checked, organization.label);

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) l'organisation d'un point de contact que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param pointOfContact : le point de contact que l'on souhaite ajouter ou retirer.
   */
  // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
  /*
  public updatePointsOfContact(event:any, pointOfContact:SearchOption) {
    this.updateFieldArray(this.searchData.pointsOfContactOrgForResource, event.currentTarget.checked, pointOfContact.label);

    this.refreshSearchOptions();
  }*/

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) une année de création que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param creationYear : l'année de création que l'on souhaite ajouter ou retirer.
   */
  public updateCreationYear(event:any, creationYear:SearchOption) {
    if (!creationYear || !event.currentTarget.checked) this.searchData.creationYear = "";
    else this.searchData.creationYear = creationYear.label;

    this.refreshSearchOptions();

  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) une année de publication que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param publicationYear : l'année de publication que l'on souhaite ajouter ou retirer.
   */
  public updatePublicationYear(event:any, publicationYear:SearchOption) {
    if (!publicationYear || !event.currentTarget.checked) this.searchData.publicationYear = "";
    else this.searchData.publicationYear = publicationYear.label;

    this.refreshSearchOptions();
  }

  /**
   * Ajoute ou retire à la SearchData (utilisée par la suite pour la recherche) un statut que
   * l'on vient de cocher ou décocher.
   * @param event : l'évènement de clic, utilisé pour savoir si l'option a été cochée ou décochée.
   * @param status : le statut que l'on souhaite ajouter ou retirer.
   */
  public updateStatus(event:any, status:string) {
    this.updateFieldArray(this.searchData.status, event.currentTarget.checked, status);
  }

  private updateFieldArray(searchField:string[], isChecked, value:string) {
    let index = searchField.findIndex(sf => sf == value);

    if (isChecked) {
      if (index == -1) searchField.push(value);
    } else if (index > -1) searchField.splice(index, 1);
  }

  private refreshSearchOptions() {
    this.dynamicFacetedSearchOptions.options = this._getCheckedOptions();
    this._resourceService.getSearchOptions(this.dynamicFacetedSearchOptions);
  }

  /**
   * Affiche une partie ou la totalité des pôles de données.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateDataPolesDisplay(event:any) {
    event.preventDefault();
    this.showAllDataPoles = !this.showAllDataPoles;
  }

  /**
   * Affiche une partie ou la totalité des types de représentation spatiale.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateSpatialRepresentationsTypesDisplay(event:any) {
    event.preventDefault();
    this.showAllSpatialRepresentationsTypes = !this.showAllSpatialRepresentationsTypes;
  }

  /**
   * Affiche une partie ou la totalité des types de lien.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateLinkTypeDisplay(event:any) {
    event.preventDefault();
    this.showAllLinkTypes = !this.showAllLinkTypes;
  }
  
  /**
   * Affiche une partie ou la totalité des thématiques.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateThematicsDisplay(event:any) {
    event.preventDefault();
    this.showAllThematics = !this.showAllThematics;
  }
  
  /**
   * Affiche une partie ou la totalité des mots-clés.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateKeywordsDisplay(event:any) {
    event.preventDefault();
    this.showAllKeywords = !this.showAllKeywords;
  }
  
  /**
   * Affiche une partie ou la totalité des organisations.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateOrganizationsDisplay(event:any) {
    event.preventDefault();
    this.showAllOrganizations = !this.showAllOrganizations;
  }
    
  /**
   * Affiche une partie ou la totalité des organisations des points de contacts.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
  /* public updatePointsOfContactDisplay(event:any) {
    event.preventDefault();
    this.showAllPointsOfContact = !this.showAllPointsOfContact;
  } */
  
  /**
   * Affiche une partie ou la totalité des années de création.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updateCreationYearsDisplay(event:any) {
    event.preventDefault();
    this.showAllCreationYears = !this.showAllCreationYears;
  }
  
  /**
   * Affiche une partie ou la totalité des années de publication.
   * @param event : évènement déclenché par le bouton et qui soumettrait le formulaire si on ne l'en empêchait pas.
   */
  public updatePublicationYearsDisplay(event:any) {
    event.preventDefault();
    this.showAllPublicationYears = !this.showAllPublicationYears;
  }


  /**
   * Récupère la liste de toutes les options de la recherche à facette actuellement cochées par l'utilisateur.
   * @returns La liste des options actuellement cochées par l'utilisateur.
   */
  private _getCheckedOptions():{ label:string, type:string }[] {
    let options:{ label:string, type:string }[] = [];

    this.addOption(options, this.searchData.typologie, "typologie");

    for (let dataPole of this.searchData.dataPoles) {
      if (!!dataPole && !options.find(o => o.label == dataPole.label)) options.push({ label: dataPole.label, type: "dataPole" });
    }

    this.addOptionArray(options, this.searchData.thematics, "thematic");
    
    this.addOptionArray(options, this.searchData.keywords, "keyword");
    
    this.addOptionArray(options, this.searchData.organizations, "organization");
    
    // Fonctionnalitée désactivée. A réactiver au traitement de l'issue #303
    // this.addOptionArray(options, this.searchData.pointsOfContactOrgForResource, "pointOfContact");

    this.addOption(options, this.searchData.linkType, "linkType");

    this.addOption(options, this.searchData.spatialRepresentationType, "spatialRepresentationType");

    this.addOption(options, this.searchData.creationYear, "creationYear");

    this.addOption(options, this.searchData.publicationYear, "publicationYear");

    return options;
  }

  private addOptionArray(options:{ label:string, type:string }[], searchField:string[], type:string) {
    searchField.forEach(value => this.addOption(options, value, type));
  }

  private addOption(options:{ label:string, type:string }[], searchField:string, type:string) {
    if (!!searchField && !options.find(o => o.label == searchField && o.type == type)) options.push({ label: searchField, type: type });
  }

  public checkFromList(searchList, element): boolean{
    if (!searchList || searchList.length <= 0 ) return false;
    element = element.label ?? element;
    return searchList.findIndex(sf => { sf = sf.label ?? sf; return sf == element}) != -1;
  }

  public checkFromElement(searchElement, element): boolean{
    if (!searchElement) return false;
    element = element.label ?? element;
    return searchElement == element;
  }


  /**
   * Met à jour les emprises visibles sur la carte en fonction des résultats de la page courante
   */
  private _updateResultsLayer() {
    this._resultsLayer.getSource().clear();
    let finalExtent;
    _.each(this.filteredResources, resource => {
      resource.features = [];
      _.each(resource.extents, extent => {
        let coordinates = [
          extent.westBoundLongitude,
          extent.southBoundLatitude,
          extent.eastBoundLongitude,
          extent.northBoundLatitude
        ];
        if (finalExtent) {
          extendExtent(finalExtent, coordinates);
        } else {
          finalExtent = coordinates.slice();
        }

        let feature = new Feature({
          geometry: polygonFromExtent(coordinates)
        });
        resource.features.push(feature);
        this._resultsLayer.getSource().addFeature(feature);
      });
    });

    if (finalExtent) {
      this.map.getView().fit(finalExtent, { padding: [15, 515, 15, 15] });
    }
  }

  /**
   * Met en valeur les resources survolées sur la carte
   * @param event - Event de mouvement de souris de la carte
   */
  private _highlightHoveredFeaturesResources(event) {
    let hoveredResources = [];

    this.map.forEachFeatureAtPixel(event.pixel, (f, layer) => {
      if (this._resultsLayer === layer) {
        let resource = _.find(this.filteredResources, el => el.features.indexOf(f) >= 0);
        if (resource) {
          hoveredResources.push(resource);
        }
      }
    });

    let changed = false;
    _.each(this.filteredResources, r => {
      if (r.features.length === 0) return;

      if (hoveredResources.indexOf(r) >= 0 && !r.hovered) {
        r.hovered = true;
        changed = true;
      } else if (hoveredResources.indexOf(r) < 0 && r.hovered) {
        r.hovered = false;
        changed = true;
      }
    });

    if (changed) {
      this._resultsLayer.changed();
    }
  }

  /**
   * Redessine la carte
   */
  private _redrawMap() {
    if (this.map) {
      this.map.updateSize();
    }
  }

}
