<nav id="menu" class="h-100 d-flex flex-column" [ngClass]="{collapsed: collapsed}">
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-projects" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Mes dépôts"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-folder-open"></em>
        <span i18n>Mes dépôts</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-data" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Mes jeux de données"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-file-alt"></em>
        <span i18n>Mes jeux de données</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-links" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Mes ressources"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-link"></em>
        <span i18n>Mes ressources</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="isAdmin || isModerator">
      <a class="nav-link" routerLink="/records" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Modération des fiches"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-table"></em>
        <span i18n>Fiches à valider</span>
      </a>
    </li>
  </ul>
  <hr>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/projects" routerLinkActive="active" i18n-ngbTooltip
        ngbTooltip="Tous les dépôts" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip"
        container="body">
        <em class="fa fa-folder-open"></em>
        <span i18n>Tous les dépôts</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/services" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Services"
         placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-network-wired"></em>
        <span i18n>Services</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/resources" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Recherche"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-search"></em>
        <span i18n>Recherche</span>
      </a>
    </li>
  </ul>
  <hr>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" href="https://github.com/EasyDataRepository/EaSy-Data-Documentation/blob/main/README.md" target="_blank"
        i18n-ngbTooltip ngbTooltip="Aide" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-question-circle"></em>
        <span i18n>Aide</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/assets/documents/Politique-Protection-DCP-tiers-BRGM-V1.pdf" target="_blank"
        i18n-ngbTooltip ngbTooltip="RGPD" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-lock"></em>
        <span i18n>Données personnelles</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://www.data-terra.org/donnees-services/entrepot-de-donnees-data-terra/" target="_blank"
        i18n-ngbTooltip ngbTooltip="A propos" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-info-circle"></em>
        <span i18n>A propos</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="mailto:entrepot@data-terra.org"
        i18n-ngbTooltip ngbTooltip="Contact" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-at"></em>
        <span i18n>Contact</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/assets/documents/Mention-Légales-EasyData_V1.pdf" target="_blank"
         i18n-ngbTooltip ngbTooltip="Mentions légales" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-balance-scale"></em>
        <span i18n>Mentions légales</span>
      </a>
    </li>
  </ul>
  <ul class="nav flex-column mt-auto">
    <li class="nav-item">
      <button type="button" class="nav-link" (click)="toggleMenu()" i18n-ngbTooltip ngbTooltip="Déplier le menu"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <em class="fa fa-chevron-left"></em>
        <span i18n>Replier le menu</span>
      </button>
    </li>
  </ul>
  <hr class="my-0">
  <div class="align-items-center app-version">
    <p class="mb-0 px-2">
      <ng-container i18n>Version : </ng-container> {{appVersion}}
    </p>
    <p class="mb-0 px-2">
      <ng-container i18n>Du : </ng-container> {{buildDate | date: localeCalendarFormats[locale].format}}
    </p>
    <a href="https://www.brgm.fr" target="_blank" class="d-inline-block ml-auto px-2">www.brgm.fr</a>
  </div>
</nav>
