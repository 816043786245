import { EntityMapper } from "./entity-mapper.model";

/**
 * Entité des résumés de métadonnées
 */
export class Summary extends EntityMapper {

    /**
     * Identifiant de la métadonnée
     */
    identifier:string = "";

    /**
     * Titre de la métadonnée
     */
    title:string = "";

    /**
     * Dans le cas où la métadonnée est un JDD ou un lien, ID du projet auquel elle est associée
     */
    parentUri:string = "";

    /**
     * Auteur de la métadonnée
     */
    author:string = "";

    /**
     * Statut de la métadonnée
     */
    status:string = "";

    /**
     * Langue par défaut de la métadonnée
     */
    defaultLanguage:string = "";

    /**
     * Liste des langues dans lesquelles la métadonnée est renseignée
     */
    languages:string[] = [];

    constructor() {
        super();
        this._mapperDefs = [
            { front : "identifier" , back : "identifier" },
            { front : "title" , back : "title" },
            { front : "projectId" , back : "projectId" },
            { front : "author" , back : "author" },
            { front : "status" , back : "status" },
            { front : "defaultLanguage" , back : "defaultLanguage" },
            { front : "creationDate" , back : "creationDate" },
            { front : "languages" , back : "languages" }
        ];
    }
}
