import { Routes } from '@angular/router';
import { ProjectsComponent, DatasComponent, LinksComponent, PublicProjectsComponent, PublicDatasComponent, PublicLinksComponent } from './components/lists';
import { ProjectDetailComponent, DataDetailComponent, LinkDetailComponent, ResourcesComponent, HelpComponent, ErrorPageComponent, AccountComponent, PublicMetadataDetailComponent, LandpageComponent } from './components/pages';
import { ProjectEditComponent, LinkEditComponent, DataEditComponent } from './components/forms';
import { AuthGuard, AdminGuard } from './guards';
import { MainLayoutComponent, PublicMainLayoutComponent } from './components/layout';
import { GroupsAdminComponent } from './components/admin/lists';
import { RecordsAdminComponent } from './components/admin/records/layout/records.component';
import { PendingRecordsComponent } from './components/admin/records/lists/pending/pending-records.component';
import { AcceptedRecordsComponent } from './components/admin/records/lists/accepted/accepted-records.component';
import { RefusedRecordsComponent } from './components/admin/records/lists/refused/refused-records.component';
import { AdminLayoutComponent } from './components/admin/layout';
import { GroupManageAdminComponent, GroupEditAdminComponent } from './components/admin/forms';
import { UserSearchAdminComponent, RoleManagementAdminComponent } from './components/admin/pages';
import { RoleEditAdminComponent } from './components/admin/forms/role-edit/role-edit.component';
import { RolesAdminComponent } from './components/admin/lists/roles/roles.component';
import {SuiviActivityComponent} from "./components/admin/lists/activity/suivi-activity.component";
import {ServiceComponent} from "./components/pages/service/service.component";

const LinkChildrenRoutes: Routes = [
  {
    path: 'new',
    component: LinkEditComponent,
    data: { breadcrumb: "Création d'une ressource" }
  },
  {
    path: ':id',
    data: { breadcrumb: '', replacementId: 'link_detail' },
    children: [
      { path: '', component: LinkDetailComponent },
      { path: 'edit', component: LinkEditComponent, data: { breadcrumb: "Modification" } }
    ]
  }
];

const DataChildrenRoutes: Routes = [
  {
    path: 'new',
    component: DataEditComponent,
    data: { breadcrumb: "Création d'un jeu de données" }
  },
  {
    path: ':id',
    data: { breadcrumb: '', replacementId: 'data_detail' },
    children: [
      { path: '', component: DataDetailComponent },
      { path: 'edit', component: DataEditComponent, data: { breadcrumb: "Modification" } },
      { path: 'duplicate', component: DataEditComponent, data: { breadcrumb: "Duplication" } }
    ]
  }
];

const ProjectChildrenRoutes: Routes = [
  {
    path: 'new',
    component: ProjectEditComponent,
    data: { breadcrumb: "Création d'un dépôt" }
  },
  {
    path: ':id',
    data: { breadcrumb: '', replacementId: 'project_detail' },
    children: [
      { path: '', component: ProjectDetailComponent },
      { path: 'edit', component: ProjectEditComponent, data: { breadcrumb: "Modification" } },
      { path: 'datas', children: DataChildrenRoutes, data: { breadcrumb: '', abstractBreadcrumb: true } },
      { path: 'links', children: LinkChildrenRoutes, data: { breadcrumb: '', abstractBreadcrumb: true } }
    ]
  }
];

export const AppRoutes: Routes = [
  {
    path: 'public',
    component: PublicMainLayoutComponent,
    children: [
      { path: 'home', component: LandpageComponent },
      {
        path: 'projects',
        data: { breadcrumb: "Les dépôts" },
        children: [
          { path: '', component: PublicProjectsComponent },
          { path: 'metadata/:id', component: PublicMetadataDetailComponent, data: { breadcrumb: "Fiche" } }
        ]
      },
      {
        path: 'datas',
        data: { breadcrumb: "Les jeux de données" },
        children: [
          { path: '', component: PublicDatasComponent },
          { path: 'metadata/:id', component: PublicMetadataDetailComponent, data: { breadcrumb: "Fiche" } }
        ]
      },
      {
        path: 'links',
        data: { breadcrumb: "Les ressources" },
        children: [
          { path: '', component: PublicLinksComponent },
          { path: 'metadata/:id', component: PublicMetadataDetailComponent, data: { breadcrumb: "Fiche" } }
        ]
      },
      {
        path: 'search',
        data: { breadcrumb: "Recherche" },
        children: [
          { path: '', component: ResourcesComponent },
          { path: 'metadata/:id', component: PublicMetadataDetailComponent, data: { breadcrumb: "Fiche" } }
        ]
      },
      { path: 'metadata/:id', component: PublicMetadataDetailComponent },
      { path: 'help', component: HelpComponent },
      { path: 'services', component: ServiceComponent }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'my-projects',
        children: [
          { path: '', component: ProjectsComponent },
          ...ProjectChildrenRoutes
        ],
        data: { breadcrumb: "Mes dépôts" }
      },
      {
        path: 'my-data',
        children: [
          { path: '', component: DatasComponent },
          ...DataChildrenRoutes
        ],
        data: { breadcrumb: "Mes jeux de données" }
      },
      {
        path: 'my-links',
        children: [
          { path: '', component: LinksComponent },
          ...LinkChildrenRoutes
        ],
        data: { breadcrumb: "Mes ressources" }
      },
      {
        path: 'projects',
        children: [
          { path: '', component: ProjectsComponent },
          ...ProjectChildrenRoutes
        ],
        data: { breadcrumb: "Tous les dépôts" }
      },
      { path: 'home', component: LandpageComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'account', component: AccountComponent, data: { breadcrumb: "Mon compte" } },
      { path: 'help', component: HelpComponent, data: { breadcrumb: "Aide" } },
      { path: 'services', component: ServiceComponent },
      {
        path: 'admin',
        component: AdminLayoutComponent,
        data: { breadcrumb: "Administration" },
        canActivate: [AdminGuard],
        children: [
          {
            path: 'groups',
            data: { breadcrumb: "Groupes" },
            children: [
              { path: '', component: GroupsAdminComponent },
              {
                path: ':id', children: [
                  { path: '', component: GroupManageAdminComponent },
                  { path: 'edit', component: GroupEditAdminComponent, data: { breadcrumb: "Modification" } }
                ],
                data: { breadcrumb: '', replacementId: 'group_detail' }
              }
            ]
          },
          { path: 'users', data: { breadcrumb: "Utilisateurs" }, component: UserSearchAdminComponent },
          {
            path: 'roles',
            data: { breadcrumb: "Rôles" },
            children: [
              { path: '', component: RolesAdminComponent},
              { path: ':id',
                children: [
                  { path: '', component : RoleManagementAdminComponent},
                  { path: 'edit', component: RoleEditAdminComponent, data: { breadcrumb: "Modification" } }
                ]
              }
            ]
          },
          { path: 'activity', data: { breadcrumb: "activités" }, component: SuiviActivityComponent },
          { path: '', redirectTo: '/admin/groups', pathMatch: 'full' },
        ]
      },
      { path: '', redirectTo: '/my-projects', pathMatch: 'full' },
      {
        path: 'records',
        data: { breadcrumb: "Modération des fiches" },
        component: RecordsAdminComponent,
        children: [
          { path: 'pending' , data: { breadcrumb: "Fiches à valider" } , component: PendingRecordsComponent },
          { path: 'accepted' , data: { breadcrumb: "Fiches acceptées" } , component: AcceptedRecordsComponent },
          { path: 'refused' , data: { breadcrumb: "Fiches refusées" } , component: RefusedRecordsComponent },
          { path: '' , redirectTo: '/records/pending' , pathMatch: 'full' }
        ]
      }
    ]
  },
  { path: '*', redirectTo: '/public/home' },
  { path: '**', component: ErrorPageComponent }
];
