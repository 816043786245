<nav aria-label="breadcrumb" *ngIf="breadcrumbs && breadcrumbs.length > 1">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs" [ngClass]="{active: last}" [attr.aria-current]="last ? 'page' : null">
      <a [routerLink]="breadcrumb.url" *ngIf="breadcrumb.url" [attr.title]="breadcrumb.label.length>=100 ? breadcrumb.label : ''">
        <ng-container *ngIf="breadcrumb.label.length<100">{{breadcrumb.label}}</ng-container>
        <ng-container *ngIf="breadcrumb.label.length>=100">{{breadcrumb.label.substr(0,100)}}...</ng-container>
      </a>
      <span *ngIf="!breadcrumb.url">{{breadcrumb.label}}</span>
    </li>
  </ol>
</nav>
