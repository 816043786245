import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService, SessionService, UtilsService } from '../services';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../constants';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private token: string;

  constructor(
    public session: SessionService,
    private _oidcSecurityService: OidcSecurityService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("/back/api/")) {
      this._oidcSecurityService.checkAuth(window.location.href,'main').subscribe();

      this._oidcSecurityService.getAccessToken('main').subscribe((token)=> {
        this.token = token
      });

      if (!!this.token) {
        const Authorization = "Bearer " + this.token;
        req = req.clone({ setHeaders: { Authorization: Authorization } });
      }
    }

    return next.handle(req)
      .pipe(
        tap(e => {}, error => {
          if (!!error) {
            if (error.status === 401) this.redirectAuthentication();
            else this.displayServorError(error);
          }
        })
      );
  }

  redirectAuthentication() {
    let windowOrigin = window.location.origin,
      publicUrlsPrefix = windowOrigin + "/#/public";

    if (window.location.href === windowOrigin + "/") {// Root URL
      // User not logged in -> redirect to /public/home
      // User logged in -> redirect to /home
      window.location.href = windowOrigin + (!this.session.currentUser ? "/#/public/home" : "/#/home");
    } else if (!window.location.href.startsWith(publicUrlsPrefix)) {
      // URLs that are public and do not require to be logged in -> nothing to do for now
      // URLs that require to be logged in -> redirect to SSO
      this._oidcSecurityService.authorize('main');
    }
  }

  displayServorError(error) {
    console.error(error);

    let messageCode = error.error?.message;
    if (typeof messageCode !== 'string') messageCode = this.getDefaultMessageCode(error);

    this.translateService.use(this.utilsService.getLocaleFromNavigator());
    this.translateService.get(messageCode).subscribe(message => {
      // Traduction trouvée => on affiche directement le message d'erreur traduit
      if (messageCode !== message) this._toastr.error(message, null, Constants.toastrErrorOptions);
      else {
        // Pas de traduction -> affichage d'un message par défaut
        this.translateService.get(this.getDefaultMessageCode(error)).subscribe(messagedefault => this._toastr.error(messagedefault, null, Constants.toastrErrorOptions));
      }
    });

    this._loader.hide();
  }

  getDefaultMessageCode(error) {
    switch (error.status) {// TODO : en fonction du code d'erreur
      case 503:
        return "error.default503";
      default:
        return "error.default";
    }
  }
}

