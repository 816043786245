import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/models';
import { ProjectService, DataService, LinkService, UserService } from 'src/app/services';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumbs: Breadcrumb[] = [];

  private sub: Subscription = new Subscription();
  private lastWorkflowName: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _projectService: ProjectService,
    private _dataService: DataService,
    private _linkService: LinkService,
    private _userService: UserService
  ) { }

  public ngOnInit() {
    this.generateBreadcrumbs();
    this.sub.add(
      this._router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          distinctUntilChanged()
        )
        .subscribe(event => this.generateBreadcrumbs())
    );

    this.sub.add(this._projectService.project$.subscribe(project => this.setBreadcrumbName(project, 'project_detail')));
    this.sub.add(this._dataService.data$.subscribe(data => this.setBreadcrumbName(data, 'data_detail')));
    this.sub.add(this._linkService.link$.subscribe(link => this.setBreadcrumbName(link, 'link_detail')));
    this.sub.add(this._userService.group$.subscribe(group => this.setBreadcrumbName(group, 'group_detail')));
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setBreadcrumbName(model: any, replacementId: string) {
    let breadcrumb = _.find(this.breadcrumbs, { replacementId: replacementId });
    if (breadcrumb) {
      breadcrumb.label = this.displayName(model);
      breadcrumb.replacementId = null;
    }
  }

  private generateBreadcrumbs() {
    this.breadcrumbs = [];
    let breadcrumbUrl = "";
    let cRoute: ActivatedRoute = this._route;
    while (cRoute.children.length > 0) {
      if (cRoute.routeConfig.data && cRoute.routeConfig.data.breadcrumb != undefined) {
        breadcrumbUrl += "/" + this.replaceParamsBy(cRoute.routeConfig.path, cRoute.snapshot.params);
        if (!cRoute.routeConfig.data.abstractBreadcrumb) {
          let workflowName = cRoute.routeConfig.data.breadcrumb;
          this.breadcrumbs.push({
            label: workflowName,
            url: (breadcrumbUrl.search(/new$/) < 0) ? breadcrumbUrl : '',
            replacementId: cRoute.routeConfig.data.replacementId || null
          });
        }
      }
      cRoute = cRoute.children[0];
    }
    
    if (cRoute.routeConfig.data && cRoute.routeConfig.data.breadcrumb != undefined) {
      let workflowName = cRoute.routeConfig.data.breadcrumb;

      this.breadcrumbs.push({
        label: workflowName,
        url: '',
        replacementId: cRoute.routeConfig.data.replacementId || null
      });
    }
    if(this.breadcrumbs.length > 0) {
      this.breadcrumbs[this.breadcrumbs.length - 1].url = '';
    }
  }

  private replaceParamsBy(breadcrumbUrl: string, params: Object) {
    if (params instanceof Object) {
      _.each(params, (val, key) => {
        breadcrumbUrl = breadcrumbUrl.replace(':' + key, val);
      });
    }
    return breadcrumbUrl;
  }

  public displayName(model:any):string {
    if(model==null) return "";
    if(model.name != undefined && model.name != null && model.name != "") {
      if(model.name.length >= 95) return model.name.substring(0,95)+" ...";
      else return model.name;
    } else {
      if(model.defaultName != null && model.defaultName != "") {
        if(model.defaultName.length >= 95) return model.defaultName.substring(0,95)+" ...";
        else return model.defaultName;
      }
      else return "";
    }
  }

}
