<div [ngSwitch]="type" *ngIf="type" class="h-100">
    <app-public-project-detail *ngSwitchCase="metadataTypeStudy"></app-public-project-detail>
    <app-public-link-detail *ngSwitchCase="metadataTypeLink"></app-public-link-detail>
    <app-public-data-detail *ngSwitchCase="metadataTypeData"></app-public-data-detail>
    <ng-container *ngSwitchDefault>
        <h1>404 - Fiche introuvable</h1>
    </ng-container>
</div>

<div [ngSwitch]="errorStatus" *ngIf="errorStatus">
    <ng-container *ngSwitchCase="403">
        <h1>403 - Fiche non publique</h1>
    </ng-container>
    <ng-container *ngSwitchCase="404">
        <h1>404 - Fiche introuvable</h1>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <h1>404 - Fiche introuvable</h1>
    </ng-container>
</div>