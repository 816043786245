<section id="landpage" class="d-flex flex-column h-100">
  <div class="page-header">
    <img src="/assets/images/EaSyData_PNG.png">
    <h1 class="white-with-border" i18n>Publier, partager et découvrir les données du Système Terre et de l'Environnement</h1>
    <i class="white-with-border" style="font-size: 14px" i18n>Cet entrepôt est l'entrepôt officiel, si vous souhaitez effectuer un dépôt test préalable, merci de vous connecter sur l'interface de test : <a href="https://rec.easydata.earth/" target="_blank">https://rec.easydata.earth/</a></i>
  </div>
  <div class="page-content" style="padding-left: 1.5rem;padding-right: 2.5rem;">
    <button class="btn btn-primary ml-3" style="float: left;" (click)="onBtnSubmit()"><img src="/assets/images/Picto_Blanc_Depot.png">Déposer des données</button>
    <button class="btn btn-primary ml-3" style="float: right;" (click)="onBtnSearch()"><img src="/assets/images/Picto_Blanc_loupe.png">Rechercher des données</button>
  </div>
  <div class="page-content" style="display: flex;justify-content: space-around;">
    <button class="btn btn-primary ml-3" (click)="onBtnHelp()"><img src="/assets/images/Picto_Vierge_Blanc_Aide.png">Aide</button>
  </div>
</section>
