import * as _ from 'lodash';
import { extend as extendExtent } from 'ol/extent';

import { EntityMapper } from "./entity-mapper.model";
import { ClassifiedKeyword } from "./classified-keyword.model";
import { Permission } from './permission.model';
import { Constants } from '../constants/app.constants';
import { Contact } from './contact.model';
import { Registry } from './registry.model';
import { Extent } from './extent.model';
import { Status } from './status.model';
import { Owner } from './owner.model';
import {License} from "./license.model";

export abstract class EntityMetadata extends EntityMapper {

  /**
   * Langage de la metadata
   */
  language: string = "fre";

  /**
    * Liste des languges disponibles pour la metadata
    */
  languagesAvailable: string[] = [ "fre" ];

  /**
   * Identifiant unique
   */
  id: string = null;

  /**
   * Nom
   */
  name: string = "";

  /**
   * Nom par défaut
   */
  defaultName: string = "";

  /**
   * Description
   */
  description: string = "";

  /**
   * Uri de l'entité
   */
  uri: string = "";

  /**
   * Propriétaires
   */
  owners: string[] = [];

  /**
   * Propriétaire
   */
  owner: Owner = new Owner();

  /**
   * Email du propriétaire de l'entité
   */
  ownerEmail: string = "";

  /**
   * Nom de l'organisation du propriétaire de l'entité
   */
  ownerOrganisation: string = "";

  /**
   * Contacts de la ressource
   */
  contacts: Contact[] = [];

  /**
   * Contacts dataterra
   */
  dataterraContact: Contact = null;

  /**
   * Contacts de distribution
   */
  distributorContact: Contact = null;

  /**
   * Description de la liste des emprises
   */
  extentDescription: string = "";

  /**
   * Liste des emprises traitées par l'entité
   */
  extents: Array<Extent> = [];

  /**
   * Liste des mots-clés libres associés
   */
  freeKeywords: Registry[] = [];

  /**
   * Liste des mots-clés libres associés
   */
  classifiedKeywords: ClassifiedKeyword[] = [];

  /**
   * Date de création de la metadonnée
   */
  metadataCreationDate: string = "";

  /**
   * Dernière mise à jour
   */
  lastUpdate: Date = null;

  /**
   * Dernière mise à jour de la metadata
   */
  metadataRevisionDate: Date = null;

  /**
   * Date de publication
   */
  metadataPublicationDate: Date = null;

  /**
   * Permissions pour des groupes
   */
  groupPermissions: Permission[] = [];

  /**
   * Permissions individuelles
   */
  individualPermissions: Permission[] = [];

  /**
   * Liste des thématiques
   */
  thematics: Registry[] = [];

  /**
   * Statut de la métadonnée
   */
  status: Status = new Status();

  /**
   * Utilisateur déposant de la métadonnée
   */
  creator: string = "";

  /**
   * Identifiant de la metadata (URI https)
   */
  uriMetadata: string = "";

  /**
   * Contraintes légales de la metadata
   */
  metadataLegalConstraints: License = null;

  constructor() {
    super();

    this._mapperDefs = [
      { front: "id", back: "fileIdentifier" },
      { front: "name", back: "title" },
      { front: "defaultName", back: "defaultTitle" },
      { front: "description", back: "detailedAbstract" },
      { front: "classifiedKeywords", class: ClassifiedKeyword },
      { front: "extentDescription", back: "extent.description" },
      { front: "uri", back: "ownUri" },
      { front: "ownerEmail", back: "owner.email" },
      { front: "ownerOrganisation", back: "owner.organisation.name" },
      { front: "groupPermissions", class: Permission },
      { front: "individualPermissions", class: Permission },
      { front: "lastUpdate", back: "revisionDate", class: Date },
      { front: "contacts", class: Contact },
      { front: "status", back: "status" },
      { front: "creator", back: "creator" }
    ];
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    if (this._jsonModel.extent && this._jsonModel.extent.geographicElements) {
      _.each(this._jsonModel.extent.geographicElements, extent => {
        let extentEntity:Extent = new Extent();
        extentEntity.geonameId = extent.geonameId;
        extentEntity.description = extent.description;
        extentEntity.westBoundLongitude = extent.westBoundLongitude
        extentEntity.eastBoundLongitude = extent.eastBoundLongitude
        extentEntity.northBoundLatitude = extent.northBoundLatitude
        extentEntity.southBoundLatitude = extent.southBoundLatitude
        this.extents.push(extentEntity);
      });
    }

    this.generateOwners();
    this.thematics = this.getClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME) || [];
    this.thematics.sort();

    return this;
  }

  public serialize() {
    super.serialize();

    this._jsonModel.extent.geographicElements = [];
    _.each(this.extents, extent => {
      if (extent) {
        this._jsonModel.extent.geographicElements.push({
          description : (this.checkGeographicElementDescriptionStructure(extent.description)) ? extent.description : {label:""},
          geonameId : extent.geonameId,
          westBoundLongitude : extent.westBoundLongitude,
          eastBoundLongitude : extent.eastBoundLongitude,
          northBoundLatitude : extent.northBoundLatitude,
          southBoundLatitude : extent.southBoundLatitude
        });
      }
    });

    return this._jsonModel;
  }

  public checkGeographicElementDescriptionStructure(description) {
    if(description==null) return false;
    if(typeof(description)=="string") return false;
    if(description.label==undefined) return false;
    return true;
  }

  /**
   * Génère la liste des propriétaires de la métadonnée à partir des permissions
   */
  public generateOwners() {
    this.owners = [];
    _.each(this.individualPermissions, permission => {
      if (permission.code === 'owner') {
        this.owners.push(permission.userId);
      }
    });
  }

  /**
   * Retrouve un type de mot-clé et y ajoute les nouveaux mot-clés
   * @param keywordType Type de mot-clé
   * @param keywords Liste des mot-clés à ajouter
   * @param cleanFirst (optionnel) Vider les mot-clés de ce type d'abord ? Faux par défaut
   */
  public addClassifiedKeywords(keywordType: string, keywords: string[], cleanFirst: boolean = false) {
    let keywordObject = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (!keywordObject) {
      keywordObject = new ClassifiedKeyword();
      keywordObject.typeCodeValue = keywordType;
      this.classifiedKeywords.push(keywordObject);
    }
    if (cleanFirst) {
      keywordObject.keywords = [];
    }
    _.each(keywords, keyword => {
      keywordObject.keywords.push(keyword);
    });
  }

  /**
   * Retrouve un type de mot-clé et y ajoute les nouveaux mot-clés
   * Cette méthode est utilisé pour le stockage des registres (id + label)
   * @param keywordType Type de mot-clé
   * @param keywords Liste des mot-clés à ajouter (id + label)
   * @param cleanFirst (optionnel) Vider les mot-clés de ce type d'abord ? Faux par défaut
   */
   public addClassifiedKeywordsWithLink(keywordType: string, keywords: Registry[], cleanFirst: boolean = false) {
    let keywordObject = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (!keywordObject) {
      keywordObject = new ClassifiedKeyword();
      keywordObject.typeCodeValue = keywordType;
      this.classifiedKeywords.push(keywordObject);
    }
    if (cleanFirst) {
      keywordObject.keywordsWithLink = [];
    }
    _.each(keywords, keyword => {
      keywordObject.keywordsWithLink.push(keyword);
    });
  }

  /**
   * Supprime des mots-clés d'un type donné. Supprime le type de mot-clé si la liste de mot-clés finale est vide
   * @param keywordType Type de mot-clé
   * @param keywords (optionnel) Liste des mots-clés à supprimer. Supprime le type de mot-clé si non fourni.
   */
  public removeClassifiedKeywords(keywordType: string, keywords?: string[]) {
    let keywordObject = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (keywordObject) {
      if (keywords) {
        _.each(keywords, keyword => {
          const index = keywordObject.keywords.indexOf(keyword);
          if (index > -1) {
            keywordObject.keywords.splice(index, 1);
          }
        });
      } else {
        keywordObject.keywords = [];
      }
      if (keywordObject.keywords.length === 0) {
        this.classifiedKeywords.splice(this.classifiedKeywords.indexOf(keywordObject), 1);
      }
    }
  }

  /**
   * Récupère la liste des mot-clés d'un type donné
   * @param keywordType Type de mot-clés
   */
  public getClassifiedKeywords(keywordType: string): string[] {
    let keywordObject: ClassifiedKeyword = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (keywordObject) {
      return keywordObject.keywords;
    }
    return null;
  }

  /**
   * Récupère la liste des mot-clés d'un type donné
   * @param keywordType Type de mot-clés
   */
   public getClassifiedKeywordsWithLink(keywordType: string): Registry[] {
    let keywordObject: ClassifiedKeyword = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (keywordObject) {
      return keywordObject.keywordsWithLink;
    }
    return null;
  }

  /**
   * Récupère un mot-clé unique d'un type donné
   * @param keywordType Type de mot-clé
   */
  public getUniqueClassifiedKeyword(keywordType: string): string {
    let keywords = this.getClassifiedKeywords(keywordType);
    if (keywords && keywords.length > 0) {
      return keywords[0];
    }
    return null;
  }

  /**
   * Renvoie l'emprise globale de la métadonnée, null si aucune emprise
   */
  public getExtent(): number[] {
    let extent: number[] = null;
    _.each(this.extents, e => {
      if (!extent) {
        extent = _.clone(e);
      } else {
        extent = extendExtent(extent, e);
      }
    });
    return extent;
  }
}
