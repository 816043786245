<div class="modal-header">
  <h2 class="modal-title">Mots-clés additionnels</h2>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body h-100">Les propositions sont issues des vocabulaires disponibles dans le <a href="https://earthportal.eu/" target="_blank">EarthPortal <img src="/assets/images/earthportal_logo.png" alt=""></a></div>
<div class="modal-body align-items-center">
  <vigirisks-table-counter>
    <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
      <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucun résultat</p>
      <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} résultats</p>
    </ng-template>
  </vigirisks-table-counter>
</div>
<div class="data-table">
  <vigirisks-fitrable-table [rows]="annotatorResults">
    <ng-template head-content>
      <th class="btn-cell-head"><!--TODO : bouton Tous ?--></th>
      <th class="w-50" i18n>Identifiant</th>
      <th class="w-50" i18n>Libellé</th>
      <th class="w-50" i18n>Thesaurus d'origine</th>
    </ng-template>
    <ng-template body-content let-annotator>
      <tr>
        <td><input type="checkbox" (change)="updateSelection($event, annotator)"/></td>
        <td><a [href]="annotator.id" target="_blank">{{annotator.id}}</a></td>
        <td>{{annotator.label}}</td>
        <td>{{annotator.ontologyAcronym}}</td>
      </tr>
    </ng-template>
  </vigirisks-fitrable-table>
</div>
<div class="d-flex">
  <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
  <button type="button" class="btn btn-primary ml-auto" (click)="save()" i18n>Enregistrer</button>
</div>