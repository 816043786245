import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
  /**
   * Titre de la popin
   */
  @Input() title;

  /**
   * Message de la popin
   */
  @Input() message;

  /**
   * Warning à ajouter sous le message
   */
  @Input() warning?;

  /**
   * Label du champ
   * Si renseigné, un champ texte obligatoire apparaît
   */
  @Input() inputLabel:string = "";

  /**
   * Classe du bouton de validation
   */
  @Input() confirmClass = "btn-primary";

  /**
   * Texte dans le bouton de validation
   */
  @Input() confirmText;

  /**
   * Texte dans le bouton d'annulation et quitter (active un troisième bouton)
   */
  @Input() cancelAndQuitText = "";

  /**
   * Classe du bouton d'annulation et quitter
   */
  @Input() cancelAndQuitClass = "btn-default";

  // Valeur du champ texte
  private input:string;

  // Si true, affichage d'un message d'erreur "Champ requis" au champ texte
  private inputInvalid:boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (!this.confirmText) {
      this.confirmText = $localize`Confirmer`;
    }
  }

  save() {
    if (!this.input) {
      this.inputInvalid = true;
    } else {
      this.inputInvalid = false;
      this.modal.close(this.input);
    }
  }

  closeWindow() {
    let button = this.elementRef.nativeElement.querySelector("#close-button");
    button.click();
  }

}
