<section id="user-search-admin" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Rôles</h1>
  </div>
  <div class="page-content">
    <div class="row">
      <div class="col-6">
        <form id="user-role-edit" class="edit-form edit-fields-form" [ngClass]="{'ng-submitted': editForm.submitted}"
          #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
          <div>
            <div class="form-group row no-gutters align-items-start">
              <label for="user" class="col-4 mr-3 mb-0 mt-1" i18n>Utilisateur / <em>User</em> *</label>
              <p-autoComplete id="user" name="user" class="w-100" [(ngModel)]="userRole.mail" [suggestions]="emails" appendTo="body"
                inputStyleClass="form-control" styleClass="w-100" [forceSelection]="true"
                (completeMethod)="autocompleteUsers($event)" (onSelect)="loadRoles($event)" required>
              </p-autoComplete>
            </div>
          </div>
          <div>
            <div class="form-group row no-gutters align-items-start">
              <label for="role" class="col-4 mr-3 mb-0 mt-1" i18n>Rôle / <em>Role</em> *</label>
              <select id="role" name="role" class="form-control" [(ngModel)]="userRole.roleId" (change)="refreshThematics()" required>
                <option [ngValue]="null" i18n>-- Choisir un rôle --</option>
                <option *ngFor="let role of allRoles; trackBy: trackById" [ngValue]="role.id">{{role.name}}</option>
              </select>
              <small *ngIf="currentRole!=null">{{currentRole.description}}</small>
            </div>
          </div>
          <div *ngIf="userRole.roleId!=null && adminRole!=null && userRole.roleId!=adminRole.id">
            <ng-container *ngIf="nonUpdatableThematics.length==0">
              <div class="form-group row no-gutters align-items-start">
                <label for="thematics" class="col-4 mr-3 mb-0 mt-1" i18n>Thématiques / <em>Thematics</em> *</label>
                <p-autoComplete id="thematics" name="thematics" class="w-100" [(ngModel)]="userRole.thematics" [suggestions]="allThematics" appendTo="body"
                  inputStyleClass="form-control" styleClass="form-control w-100 dropdown" [forceSelection]="true" field="label" [dropdown]="true" [multiple]="true"
                  (completeMethod)="autocompleteThematics($event)" (onSelect)="removeDuplicates('all')" (onFocus)="autocompleteThematicsFocus(thematicsAutocomplete)" #thematicsAutocomplete required>
                </p-autoComplete>
              </div>
            </ng-container>
            <ng-container *ngIf="nonUpdatableThematics.length>0">
              <div class="form-group row no-gutters align-items-start">
                <label for="thematics" class="col-4 mr-3 mb-0 mt-1" i18n>Thématiques / <em>Thematics</em> *</label>
                <p-autoComplete id="thematics" name="thematics" class="w-100" [(ngModel)]="updatableThematics" [suggestions]="allThematics" appendTo="body"
                  inputStyleClass="form-control" styleClass="form-control w-100 dropdown" [forceSelection]="true" field="label" [dropdown]="true" [multiple]="true"
                  (completeMethod)="autocompleteThematics($event)" (onSelect)="removeDuplicates('updatable')" (onFocus)="autocompleteThematicsFocus(thematicsAutocomplete)" #thematicsAutocomplete required>
                </p-autoComplete>
              </div>
              <div>
                <div>Thématiques non attribuées à votre rôle</div>
                <ul class="vertical-list">
                  <li *ngFor="let thematic of nonUpdatableThematics">{{thematic.label}}</li>
                </ul>
              </div>
            </ng-container>
          </div>
          <button type="submit" class="btn btn-primary ml-auto" i18n>Enregistrer</button>
        </form>
      </div>
      <div class="col-6 table-col">
        <table cellspacing="0" class="permissions-table">
          <thead>
            <tr>
              <td></td>
              <td>Administrateur</td>
              <td>Référent</td>
              <td>Modérateur</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Créer/consulter/modifier/supprimer une fiche</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td class="green"><i class="fa fa-check"></i></td>
            </tr>
            <tr>
              <td>Demander la publication d'une fiche</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td class="green"><i class="fa fa-check"></i></td>
            </tr>
            <tr>
              <td>Nommer un administrateur</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Nommer un référent</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Nommer un modérateur</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
            </tr>
            <tr>
              <td>Accepter la publication d'une fiche</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
              <td class="green"><i class="fa fa-check"></i></td>
            </tr>
            <tr>
              <td>Consulter une fiche non publiée d'un autre utilisateur</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
              <td class="green"><i class="fa fa-check"></i></td>
            </tr>
            <tr>
              <td>Modifier une fiche d'un autre utilisateur</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
              <td class="green"><i class="fa fa-check"></i></td>
            </tr>
            <tr>
              <td>Supprimer une fiche d'un autre utilisateur</td>
              <td class="green"><i class="fa fa-check"></i></td>
              <td></td>
              <td class="green"><i class="fa fa-check"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>